<template>
  <q-dialog v-model="showErrorDialog" no-route-dismiss :persistent="!dismissable">
    <q-card :class="{ 'k-error-card': true, 'k-error-card--dev': showErrorDetails() }">
      <q-card-section>
        <div class="text-h4 text-negative"><q-icon name="error" size="1.4em" /> {{ $t('Errors.header') }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ errorMessage }}
      </q-card-section>

      <q-card-section v-if="errorUserDetailMessage" :class="{ 'q-pt-none': true, 'pre-line': errorUserDetailMessage.includes('\n') }">
        {{ $t('Errors.details') }}: {{ errorUserDetailMessage }}
      </q-card-section>

      <q-card-section v-if="requestId" class="q-pt-none">{{ $t('Errors.exceptionIdentifier') }}: {{ requestId }}</q-card-section>

      <q-card-section v-if="showErrorDetails()">
        <h5>Virheiden tiedot</h5>
        <ErrorDetails v-for="(error, index) in errors" :key="index" :error="error" :index="index + 1" />
        <template v-if="!errors.length">Virhettä ei löydy statesta.</template>
      </q-card-section>

      <q-card-actions v-if="dismissable" align="right">
        <q-btn v-close-popup flat label="OK" color="primary" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import BaseError, { ErrorResponseJSON } from '@/classes/errors/BaseError'
import ClientError from '@/classes/errors/ClientError'
import NetworkError from '@/classes/errors/NetworkError'
import TechnicalError from '@/classes/errors/TechnicalError'
import ErrorDetails from '@/components/ErrorDetails.vue'
import { getString } from '@/utils/i18n'
import { isProduction } from '@/utils/paths'

@Component({
  components: {
    ErrorDetails
  }
})
export class ErrorDialog extends Vue {
  get errors(): BaseError[] {
    return this.$store.errors
  }

  get showErrorDialog(): boolean {
    return this.errors.length > 0
  }

  set showErrorDialog(_: boolean) {
    this.$store.$patch({ errors: [] })
  }

  get dismissable(): boolean {
    return this.$store.appConfig !== null
  }

  showErrorDetails(): boolean {
    // Näytetään virheen tiedot jos ei olla tuotannossa.
    return !isProduction()
  }

  get requestId(): string | undefined {
    return (this.errors.find(err => (err.fetchResponse as ErrorResponseJSON)?.requestId)?.fetchResponse as ErrorResponseJSON)?.requestId
  }

  /**
   * Käyttäjäkelpoinen virheviesti.
   */
  get errorMessage(): string {
    const error = this.errors[0]
    if (error instanceof ClientError) {
      return getString(error.userMessageKey)
    } else if (error instanceof NetworkError) {
      return getString('Errors.network')
    } else {
      return getString('Errors.generic')
    }
  }

  /**
   * Käyttäjäkelpoinen virheen selite.
   */
  get errorUserDetailMessage(): string | undefined {
    const error = this.errors[0]
    if (error instanceof ClientError || error instanceof TechnicalError) {
      return (error.fetchResponse as ErrorResponseJSON)?.detail
    }
  }
}
export default ErrorDialog
</script>

<style lang="scss">
.k-error-card {
  &.k-error-card--dev {
    // Leveämpi dialogi, jos näytetään myös stacktracet
    width: 80vw;
    max-width: 80vw;
  }
  .pre-line {
    white-space: pre-line;
  }
}
</style>
