<template>
  <div class="safebox-access-popup">
    <h6 class="header">{{ $tt('header') }}</h6>
    <p class="description">{{ $tt('description', { context: isOwner ? 'owner' : 'other' }) }}</p>
    <q-list>
      <q-item v-for="access in accesses" :key="access.access.getUniqueId()" clickable>
        <q-item-section side>
          <AccountAvatar :account="access.user" style="color: black" />
        </q-item-section>
        <q-item-section>
          <q-item-label stlye="display: flex; align-items: center">
            {{ access.user.values.given_name.getDisplayValue() }}
            {{ access.user.values.family_name.getDisplayValue() }}
            <span v-if="access.user.values.username.v === $store.user.name" style="font-weight: 600; font-size: 0.8em; color: #5e7388">({{ $tt('me') }})</span>
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-item-label style="font-size: 0.9em; padding-top: 0.2em; width: 8em">
            <q-toggle
              v-if="!isAccessOwner(access.access)"
              dense
              :model-value="access.access.values.role.v === 2"
              checked-icon="fas fa-pen"
              unchecked-icon="fas fa-eye"
              :disable="!isOwner"
              :label="getRole(access.access)"
              @update:model-value="onRoleChange(access.access)"
            />
            <template v-else>
              <div class="owner-toggle">
                <q-icon name="fas fa-crown" />
              </div>
              Omistaja
            </template>
          </q-item-label>
        </q-item-section>
        <q-item-section v-if="showRemovalButtons(access.user.values.username.v)" side style="width: 42px">
          <q-btn flat color="#495A6A" round size="sm" icon="fas fa-user-minus" @click="removeAccess(access.access)" />
        </q-item-section>
        <q-item-section v-else side style="width: 42px"></q-item-section>
      </q-item>
    </q-list>
    <div style="display: flex; gap: 1em; flex-wrap: wrap; margin: 1em; margin-top: 1.5em">
      <q-btn v-if="showInviteButton" style="color: white; background-color: #5e7388; font-size: 0.9em" rounded @click="$router.push('/manage#invite')">
        <q-icon name="fas fa-user-plus" size="1.1em" style="margin-right: 0.75em" />
        {{ $tt('inviteUsers') }}
      </q-btn>
      <q-btn v-if="showLeaveButton" style="color: white; background-color: #5e7388; font-size: 0.9em" rounded @click="leaveSafebox">
        <q-icon name="fas fa-person-to-door" size="1.1em" style="margin-right: 0.75em" />
        {{ $tt('leaveSafebox') }}
      </q-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { TOptions } from 'i18next'
import { Component, Emit, Vue } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import AccountAvatar from '@/components/AccountAvatar.vue'
import { apiFetchJSON, deleteTuple, fetchTuple, updateTuple } from '@/utils/api-functions'
import Imports from '@/utils/Imports'

@Component({
  components: { AccountAvatar }
})
export default class SafeboxAccessPopup extends Vue {
  private accesses: Array<{ access: Tuple; user: Tuple }> = []

  $tt(key: string, options?: TOptions) {
    return this.$t(`SafeboxAccessPopup.${key}`, options)
  }

  get safebox() {
    return Imports.appStore.currentSafebox
  }

  get isOwner() {
    return this.username === this.safebox?.values.username.v
  }

  get isLiteUser() {
    return !!this.$store.user?.attributes.parent?.length
  }

  showRemovalButtons(listUser: string) {
    return this.username !== listUser && (this.isOwner || listUser in this.$store.user!.attributes.children)
  }

  get showInviteButton() {
    return this.isOwner
  }

  get showLeaveButton() {
    return !this.isOwner
  }

  isAccessOwner(access: Tuple) {
    return access.values.username.v === this.safebox?.values.username.v
  }

  getRole(access: Tuple) {
    if (access.values.username.v === this.safebox?.values.username.v) {
      return this.$t('SafeboxAccessPopup.owner')
    }

    return access.values.role.getDisplayValue()
  }

  get username() {
    return this.$store.user!.name
  }

  async leaveSafebox() {
    const access = this.accesses.find(({ user }) => user.values.username.v === this.username)

    if (!access) {
      return
    }

    await this.removeAccess(access.access)
    await this.$router.push('/')
  }

  async mounted() {
    await this.reload()
  }

  @Emit('reload')
  emitReload() {}

  async reload() {
    this.emitReload()

    const response = await apiFetchJSON<TupleJSON[]>(`api/safebox/${Imports.appStore.currentSafebox!.values.id.getDisplayValue()}/safebox_access`)
    const tab = Table.getTable('safebox_access')
    const profileTab = Table.getTable('profile')

    this.accesses = await Promise.all(
      response.map(async tuple => {
        const access = await Tuple.fromJSON(tuple, tab)

        const user = await fetchTuple(profileTab, access.values.username.v!.toString())

        return { access, user }
      })
    )
  }

  async removeAccess(access: Tuple) {
    await deleteTuple(access)

    if (access.values.username.v !== this.username) {
      await this.reload()
    }
  }

  async onRoleChange(access: Tuple) {
    const current = access.values.role.v
    const newRole = current === 1 ? 2 : 1

    access.setValue('role', newRole)

    await updateTuple(access, access.getKeyString())
  }
}
</script>

<style scoped lang="scss">
.safebox-access-popup {
  width: 30em;

  .header {
    font-size: 1em;
    margin: 0.5em 1em 0.5em 1em;
  }

  .description {
    padding: 0 1em;
  }
}

.owner-toggle {
  display: inline-block;
  background: #e6bf2b;
  width: 20px;
  vertical-align: center;
  height: 20px;
  text-align: center;
  line-height: 20px;

  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
  position: relative;
  top: -3px;
  margin: 0 calc(1em - 1px) 0 6px;
  padding-right: 1px;

  i {
    top: -1px;
    left: 0.5px;
  }

  &::before {
    content: '';
    position: absolute;
    background: #c3c3c4;
    left: -6px;
    right: -6px;
    height: 15px;
    top: 2.5px;
    border-radius: 7px;
    z-index: -1;
  }

  &:hover::after {
    transform: scale(140%);
  }

  &::after {
    background: black;
    transition-duration: 250ms;
    opacity: 0.06;
    inset: 0;
    position: absolute;
    content: '';
    z-index: -1;
    border-radius: 9999px;
    transform: scale(90%);
  }
}
</style>
