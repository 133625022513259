import { Component, toNative, Vue } from 'vue-facing-decorator'

import AccessType from '@/classes/AccessType'
import Table from '@/classes/Table'
import type Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'

/** Komponentti, jonka käyttö edellyttää $storeen tallennettua tallelokerovalintaa */
@Component
export class SafeboxMixin extends Vue {
  get safeboxes(): Tuple[] {
    return Imports.appStore.safeboxes
  }

  /** Reaktiivinen käyttäjän valitsema Tallelokero, ainoa tallelokero tai undefined */
  get currentSafebox(): Tuple | undefined {
    return Imports.appStore.currentSafebox ?? this.safeboxes[0]
  }

  beforeMount(): void {
    this.refreshSafeboxes()
  }

  refreshSafeboxes() {
    Imports.appStore.refreshSafeboxes()
  }

  /** Onko valittuna olevaan tallelokeroon kirjoitusoikeus? */
  get isSafeboxWritable(): boolean {
    return Table.getTable('safebox').hasPermission(AccessType.EDIT, this.currentSafebox)
  }
}
export default toNative(SafeboxMixin)
