import dayjs from 'dayjs'
import en from 'dayjs/locale/en'
import fi from 'dayjs/locale/fi'
import sv from 'dayjs/locale/sv'
import i18next, { Resource, ResourceLanguage, TFunction } from 'i18next'
import { Quasar } from 'quasar'

import Imports from '@/utils/Imports'
import { langCookieName, langCookiePath } from '@/utils/paths'
import quasarLangs from '@/utils/quasar-languages'

/** Kannon tukemat kielet. */
const SUPPORTED_LANGS = ['fi', 'en', 'sv']

function getCookie(name: string): string | undefined {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

/** Päättelee käyttäjän kielen keksin ja selaimen localen perusteella. */
export function getUserLang(): string | undefined {
  // Yritetään ensin kaivaa keksistä
  const cookieLang = getCookie(langCookieName)
  let appLanguages: string[] | undefined
  try {
    appLanguages = Imports.store.appConfig?.languages
  } catch (_e) {
    // Storea ei ole vielä initialisoitu, ei tehdä mitään.
  }
  if (cookieLang !== undefined && (appLanguages?.includes(cookieLang) ?? SUPPORTED_LANGS.includes(cookieLang))) {
    return cookieLang
  }

  // Jos ei onnistu, niin selaimen oletuskielestä
  let browserLang = navigator.language
  if (browserLang.includes('-')) {
    browserLang = browserLang.substring(0, browserLang.indexOf('-'))
  }
  if (appLanguages?.includes(browserLang) ?? SUPPORTED_LANGS.includes(browserLang)) {
    return browserLang
  }
}

/**
 * Asettaa sovelluksen kielen väliaikaisesti, kunnes appconfig saadaan haettua.
 * Chrome tunnistaa sivuston kielen käännösehdotuksia varten ennen ensimmäistä fetchiä, joten tässä kohtaa valistunut arvaus on parempi kuin muutoin oletukseksi päätyvä englanti.
 */
export function setEarlyLang(): void {
  Quasar.lang.set(quasarLangs[getUserLang() ?? 'fi'])
}

/** Vaihtaa kielivalinnan eri kirjastoihin. */
export function setLang(lang: string): void {
  // Asetetaan i18next-kirjaston kieli
  i18next.changeLanguage(lang)
  // Asetetaan Quasarin kieli
  Quasar.lang.set(quasarLangs[lang])
  // Asetetaan dayjs:n kieli
  dayjs.locale(lang === 'en' ? en : lang === 'sv' ? sv : fi)
  // Asetetaan kielivalinta keksiin
  document.cookie = `${langCookieName}=${lang}; path=${langCookiePath}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Lax`
  // Vaihdetaan title uudelle kielelle
  document.title = getString('AppTitle', '')
}

/**
 * Päättelee käyttäjän kielen keksin ja selaimen localen perusteella.
 * Palauttaa sovelluksen oletuskielen, jos käyttäjän kieli ei ole tiedossa, tai sovellus ei tue sitä.
 */
export function getUserLangOrDefault(): string {
  const userLang = getUserLang()
  if (userLang && Imports.store.appConfig?.languages.includes(userLang)) {
    return userLang
  }

  // Fallbackina sovelluksen ensimmäinen kieli
  return Imports.store.appConfig?.languages[0] ?? 'fi'
}

/**
 * Palauttaa pyydetyn kieliresurssin.
 */
export function getString(...args: Parameters<TFunction>): ReturnType<TFunction> {
  // Asetetaan lang manuaalisesti, jotta sen vaihtuessa reaktiivisuus toimii
  if (args.length === 1) {
    // getString(key)
    args.push({ lgn: Imports.store?.lang })
  } else if (args.length === 2) {
    if (typeof args[1] === 'object') {
      // getString(key, options)
      args[1].lgn = Imports.store?.lang
    } else {
      // getString(key, defaultValue)
      // @ts-ignore
      args.push({ lgn: Imports.store?.lang })
    }
  } else if (typeof args[2] === 'object') {
    // getString(key, defaultValue, options)
    args[2].lgn = Imports.store?.lang
  }
  return i18next.t(...args)
}

function loadLocaleMessages(): Resource {
  // Ladataan buildissa yhdistetyt locale-jsonit
  const locales = import.meta.glob('@generated/locales-combined/*.json', { eager: true }) as Record<string, ResourceLanguage>
  const resources: Resource = {}
  for (const key of Object.keys(locales)) {
    const localeName = key.substring(key.lastIndexOf('/') + 1, key.lastIndexOf('.'))
    resources[localeName] = { translation: locales[key] }
  }
  return resources
}

export default i18next.init({
  lng: getUserLang(),
  fallbackLng: 'fi',
  supportedLngs: SUPPORTED_LANGS,
  resources: loadLocaleMessages(),
  interpolation: {
    // i18next-vuen dokumentaatio suosittaa escapeValue: false -asetusta, sillä Vue huolehtii tarvittavista escapeista.
    escapeValue: false
  }
})
