<template>
  <div id="app">
    <q-inner-loading :showing="!configReady" class="k-loading" />
    <Layout v-if="configReady" />
    <ErrorDialog />
  </div>
</template>

<script lang="ts">
import { App as KantoApp } from '@kanto/App.vue'
import { Component } from 'vue-facing-decorator'

import EnvBanner from '@/components/EnvBanner.vue'
import ErrorDialog from '@/components/ErrorDialog.vue'
import Layout from '@/Layout.vue'

@Component({
  components: {
    Layout,
    EnvBanner,
    ErrorDialog
  }
})
export default class App extends KantoApp {}
</script>

<style lang="scss">
@import '@/styles/app.scss';

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
