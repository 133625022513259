<template>
  <div class="q-mx-lg q-mb-lg" style="max-width: 70ch">
    <h3 class="text-subtitle1">{{ $t('ExportView.export.title') }}</h3>
    <p>{{ $t('ExportView.export.description') }}</p>
    <q-btn color="primary" @click="handleExport">{{ $t('ExportView.export.button') }}</q-btn>

    <h3 class="text-subtitle1 q-mt-lg">{{ $t('ExportView.import.title') }}</h3>
    <p>{{ $t('ExportView.import.description') }}</p>
    <q-btn color="primary" @click="handleImport">{{ $t('ExportView.import.button') }}</q-btn>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import SafeboxExportDialog from '@/components/SafeboxExportDialog.vue'
import SafeboxImportDialog from '@/components/SafeboxImportDialog.vue'

type CounterName = 'safeboxes' | 'services' | 'notes' | 'attachments'
type ExportStats = Record<CounterName, { current: number; total: number | null }>

@Component({})
export default class ExportView extends Vue {
  blob: Blob | null = null
  stats: ExportStats | null = null
  password: string | null = null

  async handleExport() {
    this.$q.dialog({
      component: SafeboxExportDialog
    })
  }

  async handleImport() {
    this.$q.dialog({
      component: SafeboxImportDialog
    })
  }
}
</script>

<style scoped lang="scss"></style>
