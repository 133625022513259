import Group, { GroupJSON } from '@/classes/Group'
import type Tab from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'

export interface CompositionJSON extends GroupJSON {
  req: boolean
  separator: string
}

/** Yhdistelmäattribuutti: esim. attribuutit a ja b voidaan yhdistää käyttäjän ymmärtämäksi tunnisteeksi 'a/b'. */
export default class Composition extends Group {
  req: boolean
  separator: string

  constructor(json: CompositionJSON, tab: Tab) {
    super(json, tab)
    this.req = json.req
    this.separator = json.separator
  }

  /** Komposition attribuuttien merkkijonoesitys [separator]illa separoituna. */
  getDisplayValue(tuple: Tuple): string {
    const vals = this.attributes.map(attr => tuple.values[attr.name]?.getDisplayValue() ?? '')
    if (vals.some(Boolean)) {
      return vals.join(this.separator)
    }
    return ''
  }
}
