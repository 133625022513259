<template>
  <SafeboxIndicator v-if="show" :tooltip="tooltip">
    <div class="count">{{ count }}</div>
  </SafeboxIndicator>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import SafeboxIndicator from '@/components/SafeboxIndicator.vue'

@Component({
  components: { SafeboxIndicator }
})
export default class SafeboxActivityIndicator extends Vue {
  @Prop({ type: Array })
  readonly safeboxes!: Tuple[]

  get show() {
    return this.count > 0
  }

  get tooltip() {
    return this.$t('SafeboxActivityIndicator.tooltip', { count: this.count })
  }

  get count() {
    return this.safeboxes.map(safebox => safebox.values.unseen_events.v as number).reduce((a, b) => a + b, 0)
  }
}
</script>

<style scoped lang="scss">
.count {
  $size: 1.8rem;

  border-radius: 9999px;
  background-color: white;
  color: #4f5c69;
  display: inline-block;
  width: $size;
  height: $size;
  line-height: $size;
  font-weight: 600;
}
</style>
