import { Component, defineAsyncComponent } from 'vue'

import AccessType from '@/classes/AccessType'
import type Tab from '@/classes/Tab'
import type Table from '@/classes/Table'
import RouteName from '@/utils/RouteName'

/**
 * Oletusviewit kullekin routetyypille.
 * Mahdollista ylikirjoittaa tabikohtaisesti Tab.views avulla.
 */
export const defaultViews = <{ [key in RouteName]: Component }>{
  [RouteName.SEARCH]: defineAsyncComponent(() => import('@/views/SearchView.vue')),
  [RouteName.LISTING]: defineAsyncComponent(() => import('@/views/ListingView.vue')),
  [RouteName.CREATE]: defineAsyncComponent(() => import('@/views/CreatorView.vue')),
  [RouteName.ACTION]: defineAsyncComponent(() => import('@/views/ActionView.vue')),
  [RouteName.JOB]: defineAsyncComponent(() => import('@/views/JobView.vue')),
  [RouteName.REPORT]: defineAsyncComponent(() => import('@/views/ReportView.vue')),
  [RouteName.EDIT]: defineAsyncComponent(() => import('@/views/EditorView.vue')),
  [RouteName.DUPLICATE]: defineAsyncComponent(() => import('@/views/DuplicatorView.vue')),
  [RouteName.VIEW]: defineAsyncComponent(() => import('@/views/DisplayView.vue'))
}

/** Oletusnäkymän kontekstisidonnaisia poikkeuksia */
export function getDefaultView(routeName: RouteName, tab: Tab): Component {
  // Prosessilla varustetun Tablen muokkausnäkymä ohjataan ProcessView:lle
  if ((routeName === RouteName.EDIT || routeName === RouteName.CREATE) && tab.isTable() && (<Table>tab).process) {
    return defineAsyncComponent(() => import('@/views/ProcessView.vue'))
  }
  // Hakunäkymään ohjataan vain, jos hakuun on VIEW-lupa, muuten suoraan listaukseen
  if (routeName === RouteName.SEARCH && !(<Table>tab).search!.hasPermission(AccessType.VIEW)) {
    return getDefaultView(RouteName.LISTING, tab)
  }
  return defaultViews[routeName]
}
