/* eslint-disable vue/one-component-per-file */

import getKantoRoutes, { linkToChild, linkToTab } from '@kanto/utils/routes'
import { defineComponent, h } from 'vue'
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import Imports from '@/utils/Imports'
import RouteName from '@/utils/RouteName'
import AccessManagementView from '@/views/AccessManagementView.vue'
import AppLayout from '@/views/AppLayout.vue'
import LandingPage from '@/views/LandingPage.vue'
import MobileLoginView from '@/views/MobileLoginView.vue'
import PublicLayout from '@/views/PublicLayout.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import SafeboxSelectionView from '@/views/SafeboxSelectionView.vue'

export { linkToChild, linkToTab }

export default function getRoutes(): RouteRecordRaw[] {
  const kantoRoutes = getKantoRoutes()

  const extendKantoRoutesForTab = (name: string, assign: Partial<RouteRecordRaw>) => {
    const tabRoutes = kantoRoutes.filter(route => route.path.match(/\/:tab(\/|$)/))

    return tabRoutes.map(route =>
      Object.assign(
        {},
        route,
        {
          name: `extend-${name}-${String(route.name)}`,
          path: route.path.replace(/\/:tab(\/|$)/g, `/:tab(${name})$1`),
          component: defineComponent({
            name: 'RouteExtensionHelper',
            beforeRouteLeave: to => {
              // Varmistetaan, että navigoinnit tämän tabin eri routejen välillä
              // kohdistuvat näihin laajennettuihin routeihin.

              if (!to?.name?.toString()?.startsWith('extend-') && 'tab' in to.params && to.params.tab === name) {
                return {
                  name: `extend-${name}-${String(to.name)}`,
                  params: to.params
                }
              }
            },
            render: () => (route.component ? h(route.component) : h('div'))
          })
        },
        assign
      )
    )
  }

  return [
    {
      path: '/mobile-login',
      component: MobileLoginView
    },
    {
      path: '/service/:view(listing|search)',
      redirect: '/'
    },
    {
      path: '/',
      component: PublicLayout,
      children: [
        {
          path: '/',
          // Redirectin sijaan component, jotta store ehtii alustua
          component: defineComponent({
            beforeRouteEnter: (_: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext): void => {
              if (!Imports.store.user) {
                next('/welcome')
              } else {
                next('/safebox/listing')
              }
            }
          })
        },
        {
          path: '/registration',
          component: RegistrationView
        },
        {
          path: '/login',
          redirect: () => {
            if (!Imports.store.user) {
              return '/welcome'
            } else {
              return '/safebox/listing'
            }
          }
        },
        {
          path: '/welcome',
          component: LandingPage
        }
      ]
    },
    {
      path: '/',
      component: AppLayout,
      children: (
        [
          {
            path: '/manage',
            component: AccessManagementView,
            meta: {
              appDrawer: false
            }
          },
          {
            path: '/:tab(safebox)/listing',
            component: SafeboxSelectionView,
            beforeEnter(_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
              Imports.appStore.clearSafebox()
              next()
            },
            meta: {
              appDrawer: false
            }
          },
          {
            ...kantoRoutes.find(route => route.name === RouteName.CREATE),
            name: undefined,
            path: '/:tab(safebox)/create',
            meta: {
              appDrawer: false
            }
          },
          {
            ...kantoRoutes.find(route => route.name === RouteName.VIEW),
            name: undefined,
            path: '/:tab(profile)/:key',
            meta: {
              appDrawer: false
            }
          },
          {
            ...kantoRoutes.find(route => route.name === RouteName.LOGOUT),
            meta: {
              appDrawer: false
            }
          },
          ...extendKantoRoutesForTab('whitelist', {
            meta: {
              appDrawer: false
            }
          }),
          ...kantoRoutes.filter(route => route.name !== RouteName.LOGOUT)
        ] as RouteRecordRaw[]
      ).map(route => ({
        ...route,
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          if (!Imports.store.user) {
            next('/')
            return
          }

          if (to.meta?.appDrawer !== false && !Imports.appStore.currentSafebox) {
            next('/safebox/listing')
            return
          }

          if (typeof route.beforeEnter === 'function') {
            // @ts-ignore
            route.beforeEnter(to, from, next)
          } else {
            next()
          }
        }
      }))
    }
  ]
}
