import { ArchiveFileInformation, ArchiveReader } from '@/classes/importer/index'

/**
 * Luokka, jonka avulla voi purkaa 7zip-muotoisia arkistoja. Itse purkaminen tapahtuu Web Workeria hyödyntäen,
 * joten selaimen pääsäiettä ei blockata.
 */
export class SevenZipReader extends ArchiveReader {
  /** Web Worker, jossa purkaminen tapahtuu. */
  private worker: Worker

  /** Salasana arkiston tiedostojen salauksen purkamista varten. */
  private password?: string

  constructor(file: Blob) {
    super()

    this.worker = new Worker(new URL('./SevenZipReaderWorker', import.meta.url), {
      type: 'module'
    })

    this.worker.postMessage({
      type: 'init',
      file
    })
  }

  /**
   * Asettaa salasanan tulevia tiedosto-operaatioita varten.
   */
  async setPassword(password: string) {
    this.password = password
  }

  /**
   * Hakee tiedoston tiedot lukematta tiedoston sisältöä.
   */
  async stat(path: string): Promise<ArchiveFileInformation | null> {
    return new Promise(resolve => {
      const listener = (message: MessageEvent) => {
        if (message.data.type === 'stat') {
          if (message.data.path === path) {
            this.worker.removeEventListener('message', listener)

            if (!message.data.exists) {
              resolve(null)
            } else {
              resolve({
                encrypted: message.data.encrypted
              })
            }
          }
        }
      }

      this.worker.addEventListener('message', listener)

      this.worker.postMessage({
        type: 'stat',
        path
      })
    })
  }

  /**
   * Lukee tiedoston sisällön.
   */
  async read(path: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const handler = (message: MessageEvent) => {
        if (message.data.type === 'file' && message.data.path === path) {
          this.worker.removeEventListener('message', handler)

          if (message.data.content) {
            const { content } = message.data
            resolve(new Blob([content]))
          } else {
            reject(new Error('Failed to read file!'))
          }
        }
      }

      this.worker.addEventListener('message', handler)

      this.worker.postMessage({
        type: 'read',
        path,
        password: this.password
      })
    })
  }
}
