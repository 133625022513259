import { _RouteLocationBase } from 'vue-router'

import ClientError from '@/classes/errors/ClientError'
import TechnicalError from '@/classes/errors/TechnicalError'
import type Tab from '@/classes/Tab'
import type Table from '@/classes/Table'
import Imports from '@/utils/Imports'

/*
 * Kokoelma erinäisiä apufunktioita. Saatavilla myös Vue-komponenteissa, ks. HelperMixin.
 * Funktiot ovat suurimmilta osin rumasti kopioituna, sillä näiden toteutusten kutsuminen HelperMixinistä rikkoo Vuen reaktiivisuuden.
 */

export function getTabName(): string | undefined {
  return firstString(Imports.router.currentRoute.value.params.tab)
}

export function getParentTableName(): string | undefined {
  for (let i = 10; i > 0; i--) {
    const ptable = firstString(Imports.router.currentRoute.value.params['ptable' + i])
    if (ptable !== undefined && ptable !== '') {
      return ptable
    }
  }
}

export function getTab(): Tab | undefined {
  const tabName = getTabName()
  if (!tabName) {
    return undefined
  }
  return Imports.store.appConfig!.tabs[tabName]
}

export function getTabReq(): Tab {
  const tab = getTab()
  if (tab === undefined) {
    throw new ClientError(`Tabia ${getTabName()} ei ole määritelty.`, 'Errors.404')
  }
  return tab
}

export function getTable(): Table | undefined {
  const tab = getTab()
  if (tab?.isTable()) {
    return tab as Table
  }
}

export function getTableReq(): Table {
  const tab = getTab()
  if (!tab?.isTable()) {
    throw new TechnicalError(`Tab ${tab?.name} ei ole table.`)
  } else {
    return tab as Table
  }
}

export function getKey(): string | undefined {
  return firstString(Imports.router.currentRoute.value.params.key)
}

export function getParentKey(): string | undefined {
  for (let i = 10; i > 0; i--) {
    const pkey = firstString(Imports.router.currentRoute.value.params['pkey' + i])
    if (pkey !== undefined && pkey !== '') {
      return pkey
    }
  }
}

export function firstString(s: string | string[]): string | undefined {
  if (Array.isArray(s)) {
    return s[0]
  }
  return s
}

export function stripEmptyRouteParams<T extends _RouteLocationBase>(route: T): T {
  const params = route.params
  for (const key in params) {
    if (params[key] === '') {
      delete params[key]
    }
  }
  return route
}
