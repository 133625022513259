import { Dayjs, isDayjs } from 'dayjs'
import type { RouteRecordName } from 'vue-router'

import type Attribute from '@/classes/Attribute'
import DataType from '@/classes/DataType'
import TechnicalError from '@/classes/errors/TechnicalError'
import type Table from '@/classes/Table'
import type Tuple from '@/classes/Tuple'
import type { RealValueType } from '@/classes/Value'
import Imports from '@/utils/Imports'

enum CompareOperator {
  EQUALS = 'EQUALS',
  GT = 'GT',
  GT_OR_EQUALS = 'GT_OR_EQUALS',
  LT = 'LT',
  LT_OR_EQUALS = 'LT_OR_EQUALS'
}

export interface ComparePredicateJSON {
  compareOperator: CompareOperator
  useOriginal: boolean
}
export interface ValuePredicateJSON extends ComparePredicateJSON {
  attr: string
  values?: string[]
  pattern?: string
  userAttribute?: string
  username?: boolean
  qualifyNull?: boolean
  qualifyChanged?: boolean
  cookieName?: string
  offset?: number
}

export interface ValueComparePredicateJSON extends ComparePredicateJSON {
  attrs: string[]
}

/* eslint-disable no-use-before-define */
export type PredicateJSON =
  | ValuePredicateJSON
  | RolePredicateJSON
  | UserAttributePredicateJSON
  | CookiePredicateJSON
  | ChildPredicateJSON
  | RoutePredicateJSON
  | OrPredicateJSON
  | AndPredicateJSON
  | NotPredicateJSON
/* eslint-enable no-use-before-define */

export interface RolePredicateJSON {
  roles: string[]
}

export interface UserAttributePredicateJSON {
  userAttribute: string
  values: string[]
}

export interface CookiePredicateJSON {
  cookieName: string
  values: string[]
}

export interface ChildPredicateJSON {
  targetTable: string
  predicate?: PredicateJSON
  useOriginal: boolean
}

export interface RoutePredicateJSON {
  routes: RouteRecordName[]
}

export interface OrPredicateJSON {
  orPredicates: PredicateJSON[]
}

export interface AndPredicateJSON {
  andPredicates: PredicateJSON[]
}

export interface NotPredicateJSON {
  notPredicate: PredicateJSON
}

export interface LoggedInPredicateJSON {
  userIsLoggedIn: boolean
}

/** Ehto, joka joko toteutuu jossakin monikossa tai ei. */
export default interface Predicate {
  /** Toteuttaako tuple tämän ehdon? */
  matches(tuple?: Tuple): boolean
  /** Ehtolausekkeen termi, joka estää ehdon toteutumisen; null, jos ehto toteutuu */
  test(tuple?: Tuple): Predicate | null
}

/** {@link RolePredicateJSON}-tyyppitarkistus */
function isRolePredicateJSON(json: unknown): json is RolePredicateJSON {
  return (json as RolePredicateJSON).roles !== undefined
}

/** {@link UserAttributePredicateJSON}-tyyppitarkistus */
function isUserAttributePredicateJSON(json: unknown): json is UserAttributePredicateJSON {
  const userAttributeJSON = json as UserAttributePredicateJSON
  return userAttributeJSON.userAttribute !== undefined && userAttributeJSON.values !== undefined
}

/** {@link CookiePredicateJSON}-tyyppitarkistus */
function isCookiePredicateJSON(json: unknown): json is CookiePredicateJSON {
  const cookiePermissionJSON = json as CookiePredicateJSON
  return cookiePermissionJSON.cookieName !== undefined && cookiePermissionJSON.values !== undefined
}

/** {@link ChildPredicateJSON}-tyyppitarkistus */
function isChildPredicateJSON(json: unknown): json is ChildPredicateJSON {
  return (json as ChildPredicateJSON).targetTable !== undefined
}

/** {@link RoutePredicateJSON}-tyyppitarkistus */
function isRoutePredicateJSON(json: unknown): json is RoutePredicateJSON {
  return (json as RoutePredicateJSON).routes !== undefined
}

/** {@link OrPredicateJSON}-tyyppitarkistus */
function isOrPredicateJSON(json: unknown): json is OrPredicateJSON {
  return (json as OrPredicateJSON).orPredicates !== undefined
}

/** {@link AndPredicateJSON}-tyyppitarkistus */
function isAndPredicateJSON(json: unknown): json is AndPredicateJSON {
  return (json as AndPredicateJSON).andPredicates !== undefined
}

/** {@link NotPredicateJSON}-tyyppitarkistus */
function isNotPredicateJSON(json: unknown): json is NotPredicateJSON {
  return (json as NotPredicateJSON).notPredicate !== undefined
}

/** {@link ValueComparePredicateJSON}-tyyppitarkistus */
function isValueComparePredicateJSON(json: unknown): json is ValueComparePredicateJSON {
  return (json as ValueComparePredicateJSON).attrs !== undefined
}

/** {@link LoggedInPredicateJSON}-tyyppitarkistus */
function isLoggedInPredicateJSON(json: unknown): json is LoggedInPredicateJSON {
  return (json as LoggedInPredicateJSON).userIsLoggedIn !== undefined
}

/** Muodostaa sopivan Predicate-luokan JSONin perusteella. */
export function predicateFromJSON(json: PredicateJSON): Predicate {
  if (isRolePredicateJSON(json)) {
    return new RolePredicate(json)
  } else if (isUserAttributePredicateJSON(json)) {
    return new UserAttributePredicate(json)
  } else if (isCookiePredicateJSON(json)) {
    return new CookiePredicate(json)
  } else if (isChildPredicateJSON(json)) {
    return new ChildPredicate(json)
  } else if (isRoutePredicateJSON(json)) {
    return new RoutePredicate(json)
  } else if (isAndPredicateJSON(json)) {
    return new AndPredicate(json)
  } else if (isOrPredicateJSON(json)) {
    return new OrPredicate(json)
  } else if (isNotPredicateJSON(json)) {
    return new NotPredicate(json)
  } else if (isValueComparePredicateJSON(json)) {
    return new ValueComparePredicate(json)
  } else if (isLoggedInPredicateJSON(json)) {
    return new LoggedInPredicate()
  } else {
    return new ValuePredicate(json)
  }
}

/** Vaihtoehtoiset ehdot koostava luokka. */
export class OrPredicate implements Predicate {
  predicates: Predicate[]

  constructor(json: OrPredicateJSON) {
    this.predicates = json.orPredicates.map(filter => predicateFromJSON(filter))
  }

  matches(tuple?: Tuple): boolean {
    return this.predicates.some(filter => filter.matches(tuple))
  }

  // Jos disjunktio ei toteudu, palautetaan viimeisen predikaatin testitulos
  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this.predicates[this.predicates.length - 1].test(tuple)
  }
}

/** Samanaikaiset ehdot koostava luokka. */
export class AndPredicate implements Predicate {
  predicates: Predicate[]

  constructor(json: AndPredicateJSON) {
    this.predicates = json.andPredicates.map(filter => predicateFromJSON(filter))
  }

  matches(tuple?: Tuple): boolean {
    return this.predicates.every(filter => filter.matches(tuple))
  }

  test(tuple?: Tuple): Predicate | null {
    return this.predicates.find(filter => filter.test(tuple)) ?? null
  }
}

/** Käänteiset ehdot koostava luokka. */
export class NotPredicate implements Predicate {
  predicate: Predicate

  constructor(json: NotPredicateJSON) {
    this.predicate = predicateFromJSON(json.notPredicate)
  }

  matches(tuple?: Tuple): boolean {
    if (
      !tuple &&
      // Predikaatit, jotka eivät käytä tuplea
      !(
        this.predicate instanceof RoutePredicate ||
        this.predicate instanceof UserAttributePredicate ||
        this.predicate instanceof RolePredicate ||
        this.predicate instanceof CookiePredicate
      )
    ) {
      return true
    }
    return !this.predicate.matches(tuple)
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this.predicate
  }
}

class ComparePredicate {
  compareOperator: CompareOperator
  useOriginal: boolean

  constructor(json: ComparePredicateJSON) {
    this.compareOperator = json.compareOperator
    this.useOriginal = json.useOriginal ?? false
  }

  compareValues(v1: Exclude<RealValueType, null>, v2: Exclude<RealValueType, null>): boolean {
    if (isDayjs(v1) && isDayjs(v2)) {
      const diff = v1.diff(v2)
      switch (this.compareOperator) {
        case CompareOperator.EQUALS:
          return diff === 0
        case CompareOperator.GT:
          return diff > 0
        case CompareOperator.GT_OR_EQUALS:
          return diff >= 0
        case CompareOperator.LT:
          return diff < 0
        case CompareOperator.LT_OR_EQUALS:
          return diff <= 0
      }
    }
    switch (this.compareOperator) {
      case CompareOperator.EQUALS:
        return v1 === v2
      case CompareOperator.GT:
        return v1 > v2
      case CompareOperator.GT_OR_EQUALS:
        return v1 >= v2
      case CompareOperator.LT:
        return v1 < v2
      case CompareOperator.LT_OR_EQUALS:
        return v1 <= v2
    }
  }
}

/** Ehto, joka joko toteutuu jossakin monikossa tai ei. */
export class ValuePredicate extends ComparePredicate implements Predicate {
  attrName: string
  tabName?: string
  values?: string[]
  pattern?: RegExp
  userAttribute?: string
  username: boolean
  qualifyNull: boolean
  qualifyChanged: boolean
  offset: number

  constructor(json: ValuePredicateJSON) {
    super(json)
    this.attrName = json.attr.substring(json.attr.indexOf('.') + 1)
    this.tabName = json.attr.includes('.') ? json.attr.substring(0, json.attr.indexOf('.')) : undefined
    this.values = json.values
    this.pattern = json.pattern !== undefined ? new RegExp(json.pattern) : undefined
    this.userAttribute = json.userAttribute
    this.username = json.username ?? false
    this.qualifyNull = json.qualifyNull ?? false
    this.qualifyChanged = json.qualifyChanged ?? false
    this.offset = json.offset ?? 0
  }

  /**
   * Monikko täsmää filtteriin, jos sen relaatiossa on filtterissä nimetty attribuutti ja sitä vastaava arvo täsmää
   * filtterin arvojoukon, patternin tai userAttributen kanssa. Jos monikon relaatiossa ei ole nimettyä attribuuttia,
   * tarkastelua jatketaan rekursiivisesti parent-monikossa.
   */
  matches(tuple?: Tuple): boolean {
    if (!tuple) {
      return true
    }
    const attr = tuple.tab.attributes[this.attrName]
    if (!(attr && (!this.tabName || attr.tab.name === this.tabName)) && tuple.parent) {
      return this.matches(tuple.parent)
    } else {
      const value = (this.useOriginal ? tuple.origTuple ?? tuple : tuple).values[this.attrName]
      if (value?.v == null && this.qualifyNull) {
        return true
      }
      if (this.pattern?.test(value?.valueToString())) {
        return true
      }
      if (this.values !== undefined) {
        if (!value?.hasContent()) return false
        return this.values.some(val => this.compareValues(value.v!, this.getTypedValue(attr, val)))
      }
      if (this.userAttribute !== undefined) {
        const userAttrValue = Imports.store.user?.attributes[this.userAttribute]
        if (!value?.hasContent() || userAttrValue === undefined) return false
        if (Array.isArray(userAttrValue))
          return userAttrValue.some(singleUserAttrValue => this.compareValues(value.v!, this.getTypedValue(attr, singleUserAttrValue)))
        return this.compareValues(value.v!, this.getTypedValue(attr, userAttrValue))
      }
      if (this.username) {
        return value?.valueToString().toLowerCase() === Imports.store.user?.name.toLowerCase()
      }
      if (this.qualifyChanged) {
        return !value?.equals(value?.attribute.defaultValue)
      }
      return false
    }
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }

  /** Arvo, johon vertaillaan lisättynä mahdollisella offsetilla. Avainsana `current` vertailee [attr.currentValue]en. */
  getTypedValue(attr: Attribute, value: string): Exclude<RealValueType, null> {
    let val = value === 'current' ? attr.currentValue! : attr.parseRealValue(value)!
    if (this.offset !== 0) {
      switch (attr.type) {
        case DataType.DATE:
          val = (<Dayjs>val).add(this.offset, 'day')
          break
        case DataType.INTEGER:
        case DataType.YEAR:
          ;(<number>val) += this.offset
          break
        default:
          throw new TechnicalError(`Odottamaton offset ${this.offset} attribuutilla ${attr}`)
      }
    }
    return val
  }
}

/** Monikon kahta tai useampaa arvoa vertaileva ehto. */
export class ValueComparePredicate extends ComparePredicate implements Predicate {
  attrs: string[]

  constructor(json: ValueComparePredicateJSON) {
    super(json)
    this.attrs = json.attrs
  }

  matches(tuple?: Tuple): boolean {
    const tupleOrOrig = this.useOriginal ? tuple?.origTuple ?? tuple : tuple
    return this.attrs.map(a => tupleOrOrig!.values[a]).every((value, i, a) => (i === a.length - 1 ? true : this.compareValues(value.v!, a[i + 1].v!)))
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }
}

/** Ehto toteutuu, jos käyttäjäattribuutin arvo on jokin määrätyistä arvoista. */
export class UserAttributePredicate implements Predicate {
  userAttribute: string
  values: string[]

  constructor(json: UserAttributePredicateJSON) {
    this.userAttribute = json.userAttribute
    this.values = json.values
  }

  matches(_?: Tuple): boolean {
    const userAttrValue = Imports.store.user?.attributes[this.userAttribute]
    if (userAttrValue === undefined) return false
    if (Array.isArray(userAttrValue)) return userAttrValue.some(singleUserAttrValue => this.values.includes(singleUserAttrValue))
    return this.values.includes(userAttrValue)
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }
}

/** Ehto, joka joko toteutuu, jos keksi vastaa jotakin listatuista arvoista. */
export class CookiePredicate implements Predicate {
  cookieName: string
  values: string[]

  constructor(json: CookiePredicateJSON) {
    this.cookieName = json.cookieName
    this.values = json.values
  }

  matches(_?: Tuple): boolean {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${this.cookieName}=`))
      ?.split('=')[1]
    if (cookieValue === undefined) return false
    return this.values.includes(cookieValue)
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }
}

/** Ehto, joka joko toteutuu jos käyttäjä on jossain annetuista rooleista. */
export class RolePredicate implements Predicate {
  roles: string[]

  constructor(json: RolePredicateJSON) {
    this.roles = json.roles
  }

  matches(_?: Tuple): boolean {
    return Imports.store.user?.isUserInAnyRole(...this.roles) === true
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }
}

/**
 * Tarkistaa, onko käsiteltävässä hierarkiassa [targetTable]-lapsia. Jos [predicate] on määritelty, ainakin yhden
 * niistä on toteutettava se.
 */
export class ChildPredicate implements Predicate {
  targetTable: string
  predicate?: Predicate
  useOriginal: boolean

  constructor(json: ChildPredicateJSON) {
    this.targetTable = json.targetTable
    this.predicate = json.predicate ? predicateFromJSON(json.predicate) : undefined
    this.useOriginal = json.useOriginal
  }

  matches(tuple?: Tuple): boolean {
    if (!tuple) return true
    let root = tuple
    while (root.parent) root = root.parent
    if (this.useOriginal) root = root.origTuple ?? root
    return this.childMatches(root)
  }

  /** Etsii [table]-nimiset lapsituplet ja kutsuu [predicate]a nille. */
  childMatches(hierarchy: Tuple): boolean {
    const table = hierarchy.tab as Table
    if (!table.children) return false
    for (const child of Object.values(table.children)) {
      if (child.name === this.targetTable) {
        const kids = hierarchy.children[this.targetTable]
        if (this.predicate) {
          return kids?.some(tuple => this.predicate!.matches(tuple)) ?? false
        } else {
          return kids?.length > 0
        }
      }
      if (child.hasDescendant(this.targetTable)) {
        return hierarchy.children[child.name]?.some(tuple => this.childMatches(tuple)) ?? false
      }
    }
    return false
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }
}

/** Ehto, joka toteutuu jos ollaan nimetyn mukaisessa routessa. Ehto voi toteutua vain frontendissä. */
export class RoutePredicate implements Predicate {
  routes: RouteRecordName[]

  constructor(json: RoutePredicateJSON) {
    this.routes = json.routes
  }

  matches(_?: Tuple): boolean {
    return this.routes.includes(Imports.router.currentRoute.value.name as RouteRecordName)
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }
}

/** Ehto, joka toteutuu jos ollaan nimetyn mukaisessa routessa. Ehto voi toteutua vain frontendissä. */
export class LoggedInPredicate implements Predicate {
  matches(_?: Tuple): boolean {
    return Imports.store.user !== null
  }

  test(tuple?: Tuple): Predicate | null {
    return this.matches(tuple) ? null : this
  }
}
