import AccessControl, { AccessControlJSON } from '@/classes/AccessControl'
import AccessType from '@/classes/AccessType'
import { ActionContext } from '@/classes/ActionContext'
import type Table from '@/classes/Table'
import type Tuple from '@/classes/Tuple'

export interface LinkJSON extends AccessControlJSON {
  name: string
  href: string
  contexts?: (keyof typeof ActionContext)[]
  icon?: string
}

export default class Link extends AccessControl {
  name: string
  href: string
  contexts: ActionContext[]
  icon?: string
  parentTable: Table

  constructor(json: LinkJSON, parentTable: Table) {
    super(json.permissions ?? {})
    this.name = json.name
    this.href = json.href
    this.contexts = json.contexts?.map(contextName => ActionContext[contextName]) ?? []
    this.icon = json.icon
    this.parentTable = parentTable
  }

  /**
   * Palauttaa linkin näyttönimen.
   */
  getDisplayName(): string {
    return this.parentTable.t(`${this.name}.title`)
  }

  /**
   * Palauttaa linkin aputekstin.
   */
  getTitle(): string {
    return this.parentTable.t(`${this.name}.help`, '')
  }

  /**
   * Palauttaa linkin, josta mahdolliset attribuutit on korvattu annetun tuplen arvoilla.
   */
  getHref(tuple?: Tuple): string {
    if (!tuple) {
      return this.href
    }
    let formattedHref = this.href
    for (const attrName in tuple.tab.attributes) {
      const regexp = new RegExp('\\{' + attrName + '\\}', 'gi')
      formattedHref = formattedHref.replace(regexp, tuple.values[attrName]?.valueToString() ?? '')
    }
    return formattedHref
  }

  /** Näkyykö toiminto annetussa kontekstissa annetulla parent-monikolla ja access-tyypillä? */
  isVisibleInContext(context: ActionContext, parentTuple?: Tuple, accessType?: AccessType): boolean {
    return (
      (this.contexts.includes(context) ||
        (this.contexts.includes(ActionContext.LISTING_ANY) && (context === ActionContext.LISTING_EMPTY || context === ActionContext.LISTING_NOT_EMPTY))) &&
      this.hasPermission(accessType ?? AccessType.VIEW, parentTuple)
    )
  }
}
