enum DataType {
  STRING = 'string',
  HTML = 'html',
  INTEGER = 'integer',
  LONG = 'long',
  DECIMAL = 'decimal',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  PASSWORD = 'password',
  FILE = 'file',
  YEAR = 'year',
  EMAIL = 'email',
  BOOLEAN = 'boolean',
  BITMASK = 'bitmask',
  UUID = 'uuid'
}

export default DataType
