import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import { createPinia } from 'pinia'
import { Cookies, Dialog, LoadingBar, Notify, Quasar } from 'quasar'
import { createApp } from 'vue'

import App from '@/App.vue'
import HelperMixin from '@/utils/HelperMixin'
import Imports from '@/utils/Imports'
import { useKantoStore } from '@/utils/store'

/** app ja initApp erillisinä exporteina, jotta viittausta app-instanssiin saadaan hyödynnettyä useammassa paikassa.  */
export const app = createApp(App)

/** Luo ja palauttaa App-instanssin. */
export function initApp() {
  app
    .use(I18NextVue, { i18next })
    .use(Quasar, {
      config: {
        loadingBar: {
          color: 'primary',
          size: '5px',
          position: 'bottom'
        }
      },
      plugins: { Notify, LoadingBar, Cookies, Dialog }
    })
    .use(createPinia())
    .mixin(HelperMixin)
  // $store on määriteltävä `.use(createPinia())` jälkeen, mutta ennen sovelluksen mounttaamista
  app.config.globalProperties.$store = useKantoStore()
  Imports.store = useKantoStore()
  return app
}
