<template>
  <div class="account-page-layout">
    <div class="header">
      <q-btn rounded no-caps icon="fa-duotone fa-arrow-left on-left" class="back-button" @click="leaveSafeboxCreation">
        {{ $t('AccountPageLayout.back') }}
      </q-btn>
      <span class="title">{{ title }}</span>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class AccountPageLayout extends Vue {
  @Prop({ type: String })
  title!: string

  /** Apufunktio, jottei jäädä jumiin näkymään. */
  leaveSafeboxCreation(): void {
    this.$store.$patch({ tuple: null })
    this.$router.replace('/safebox/listing')
  }
}
</script>

<style scoped lang="scss">
.account-page-layout {
  margin: 3rem 100px;

  .header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    margin-bottom: 3em;

    .title {
      grid-column-start: 2;
      justify-self: center;
      font-weight: 600;
      color: black;
      font-size: 1.1rem;
    }

    .back-button {
      background-color: white;
      justify-self: flex-start;
    }
  }
}
</style>
