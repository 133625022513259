import { Component, toNative, Vue } from 'vue-facing-decorator'

import type User from '@/classes/User'

/** Komponentti, jonka käyttö edellyttää $storeen tallennettua tallelokerovalintaa */
@Component
export class UserMixin extends Vue {
  get userFullName(): string {
    return this.$store.user?.attributes.full_name[0] ?? 'N/A'
  }

  get isPro(): boolean {
    return (<User>this.$store.user).isUserInRole('PRO')
  }

  get isBasic(): boolean {
    return (<User>this.$store.user).isUserInRole('BASIC')
  }

  get color(): string {
    // Räätälöidään tämä paremmaksi brändivärien myötä.
    // return this.isPro ? 'primary' : this.isBasic ? 'secondary' : 'accent'
    return '#ffffff'
  }
}

export default toNative(UserMixin)
