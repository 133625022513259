<template>
  <li :key="tuple.getUniqueId()" class="invite" @click="openInvite">
    <AccountAvatar :name="invitorName" size="0.8em" avatar-style="background-color: #B3D9FF; color: white" />
    <span class="text">
      <i18next :translation="$t('SafeboxListing.invites.message')">
        <template #safebox>
          <b style="font-weight: 600">{{ safeboxName }}</b>
        </template>
      </i18next>
    </span>
  </li>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import Value from '@/classes/Value'
import AccountAvatar from '@/components/AccountAvatar.vue'
import InviteDialog from '@/components/InviteDialog.vue'
import { fetchTuple } from '@/utils/api-functions'
import Imports from '@/utils/Imports'

@Component({
  components: { AccountAvatar }
})
export default class InviteBanner extends Vue {
  @Prop({ type: Object })
  tuple!: Tuple

  key: Tuple | null = null

  created() {
    this.fetchKey()
  }

  async fetchKey() {
    const tab = Table.getTable('invite_key')
    const { cryptoKeyId } = await Imports.store.user!.keyPair.get()
    this.key = await fetchTuple(
      tab,
      new Tuple(tab, { invite_id: this.tuple.values.id, cryptkey_id: new Value(tab.attributes.cryptkey_id, cryptoKeyId) }).getKeyString()
    )
  }

  get invitorName() {
    return this.tuple.values.invitor_name.getDisplayValue()
  }

  get safeboxName() {
    if (!this.key) {
      return 'Ladataan...'
    }

    return this.key.values.safebox_name.getDisplayValue()
  }

  openInvite() {
    const dialog = this.$q.dialog({
      component: InviteDialog,
      componentProps: {
        invite: this.tuple,
        inviteKey: this.key
      }
    })

    dialog.onOk(() => this.refresh())
  }

  @Emit('refresh')
  refresh() {}
}
</script>

<style scoped lang="scss">
$min-expanded-width: 20em;
$radius: 1.7em;

.invite {
  width: max-content;
  max-width: 100%;
  background-color: white;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  list-style: none;
  filter: drop-shadow(0 1px 0.1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 2.5px rgba(0, 0, 0, 0.2));
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  gap: 0.75em;
  padding: 0.5em 1em 0.5em 0.6em;

  transition-duration: 300ms;
  cursor: pointer;

  &:hover {
    padding-right: 2em;
  }

  .invite-accept-button {
    background: linear-gradient(to bottom, #00d0f1, #048ed3);
    color: white;
    font-weight: 600;
    height: 2.5em;
    text-transform: none;
    padding: 0 0.5em;
  }

  .invite-decline-button {
    color: #b6144e;
    border: 2px solid #b6144e;
    font-weight: 600;
    height: 2.5em;
    text-transform: none;
    padding: 0 0.5em;
  }

  .text {
    flex-grow: 1;
    display: block;
  }

  position: relative;

  @container notifications (max-width: #{$min-expanded-width}) {
    left: calc(100% - 3.5em);
    align-self: start;
    min-width: $min-expanded-width;

    &:hover {
      left: calc(100% - $min-expanded-width);
    }
  }

  .avatar-root {
    align-self: start;
  }
}
</style>
