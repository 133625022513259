<template>
  <SafeboxIndicator v-if="show" :tooltip="tooltip" class="indicator">
    <q-icon name="fas fa-message" />
    <div class="indicator-text">{{ newMessageCount }}</div>
  </SafeboxIndicator>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import SafeboxIndicator from '@/components/SafeboxIndicator.vue'

@Component({
  components: { SafeboxIndicator }
})
export default class SafeboxMessageIndicator extends Vue {
  @Prop({ type: Array })
  safeboxes!: Tuple[]

  get newMessageCount() {
    return this.safeboxes.map(safebox => this.$store.user?.getNewMessageCount(safebox.values.id.valueToString())).reduce((a: number, b) => a + (b ?? 0), 0)
  }

  get tooltip() {
    return this.$t('SafeboxMessageIndicator.tooltip', { count: this.newMessageCount, context: this.safeboxes.length > 1 ? 'many' : 'one' })
  }

  get show() {
    return this.newMessageCount > 0
  }
}
</script>

<style scoped lang="scss">
.indicator-text {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4f5c69;
  margin-top: -0.2em;
  font-weight: 600;
}

.q-icon {
  position: relative;
  top: 1em;
}
</style>
