<template>
  <q-btn-dropdown
    auto-close
    icon="folder"
    flat
    rounded
    :label="narrow ? undefined : label"
    class="k-user-status"
    style="color: currentColor; background: white"
    size="sm"
    no-caps
  >
    <q-list class="safebox-menu">
      <q-item
        v-for="a in safeboxes"
        :key="a.values.id.v"
        :clickable="a !== currentSafebox"
        :active="a === currentSafebox"
        @click="selectSafebox(a.values.id.v.toString())"
      >
        <q-item-section side>
          <q-icon :name="icon(a)" />
        </q-item-section>
        <q-item-section>
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <q-item-label v-text="a.values.name.v" />
        </q-item-section>
      </q-item>
      <q-separator />
      <q-item to="/safebox/create">
        <q-item-section side>
          <q-icon name="create_new_folder" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Uusi tallelokero</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts">
import { Component, mixins, Prop } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'
import { SafeboxMixin } from '@/utils/SafeboxMixin'

@Component
export default class SafeboxSelector extends mixins(SafeboxMixin) {
  @Prop({ type: Boolean, default: false })
  narrow!: boolean

  get label(): string {
    return <string>this.currentSafebox?.values.name.v ?? ''
  }

  icon(safebox: Tuple): string {
    if (!safebox || <string>safebox.values.username.v === this.$store.user?.name) return 'folder'
    else if ((<string>safebox.values.shared_to.v)?.includes(this.userFullName)) return 'folder_shared'
    else return 'folder_open'
  }

  selectSafebox(id: string): void {
    Imports.appStore.selectSafebox(id)
    this.mitt.emit('safebox_change')
    if (!this.$route.path.startsWith('/dashboard')) {
      this.$router.push({ path: '/dashboard' })
    }
  }

  get userFullName(): string {
    return this.$store.user?.attributes.full_name[0] ?? 'N/A'
  }
}
</script>
