<template>
  <div class="root">
    <!--<router-link :to="profilePath" style="color: inherit; text-decoration: none">-->
    <q-btn-dropdown flat>
      <template #label>
        <AccountAvatar :account="username" style="margin-right: 1em" />
        <span style="font-weight: 500">{{ userFullName ?? 'Loading...' }}</span>
      </template>
      <template #default>
        <q-list>
          <q-item to="/manage">
            <q-item-section>{{ $t('UserStatusView.manage') }}</q-item-section>
          </q-item>
          <q-item>
            <q-item-section>{{ $t('UserStatusView.news') }}</q-item-section>
          </q-item>
          <q-item>
            <q-item-section>{{ $t('UserStatusView.help') }}</q-item-section>
          </q-item>
        </q-list>
      </template>
    </q-btn-dropdown>
    <!--</router-link>-->
    <q-btn rounded style="background-color: white; padding: 0 1em; margin-left: 2em; white-space: nowrap" no-caps to="/logout">
      {{ $t('UserStatusView.logout') }}
    </q-btn>
  </div>
</template>

<script lang="ts">
import { Component, mixins } from 'vue-facing-decorator'

import AccountAvatar from '@/components/AccountAvatar.vue'
import { UserMixin } from '@/utils/UserMixin'

@Component({ name: 'UserStatusView', components: { AccountAvatar } })
export default class UserStatusView extends mixins(UserMixin) {
  get label(): string {
    return <string>this.userFullName
  }

  get username() {
    return this.$store.user?.name ?? ''
  }

  get user() {
    return this.$store.user
  }

  get profilePath(): string {
    return `/profile/${this.$store.user!.name}`
  }
}
</script>

<style lang="scss">
@import '@/styles/quasar.variables.scss';

a {
  display: contents;
}

.root {
  display: flex;
  gap: 1em;
  align-items: center;
}

.k-user-status {
  margin-top: auto;
  margin-bottom: auto;
  font: normal normal 600 15px/15px Poppins;
}

.user-menu .logout {
  text-transform: uppercase;
}
</style>
