<template>
  <q-layout style="display: flex; flex-direction: column">
    <SkipLink />
    <EnvBanner v-if="!isProduction()" />
    <router-view />
    <SessionExpireDialog />
    <UpdateRequiredDialog />
  </q-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import EnvBanner from '@/components/EnvBanner.vue'
import ErrorDialog from '@/components/ErrorDialog.vue'
import SessionExpireDialog from '@/components/SessionExpireDialog.vue'
import SkipLink from '@/components/SkipLink.vue'
import UpdateRequiredDialog from '@/components/UpdateRequiredDialog.vue'
import { isProduction } from '@/utils/paths'

@Component({
  methods: { isProduction },
  components: { EnvBanner, ErrorDialog, UpdateRequiredDialog, SessionExpireDialog, SkipLink }
})
export class Layout extends Vue {}
export default Layout
</script>
