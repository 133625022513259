import { initApp as kantoInitApp } from '@kanto/create-app'
import FloatingVue from 'floating-vue'
import mitt from 'mitt'

import type { MittEvents } from '@/types/mitt'
import { useAppStore } from '@/utils/app-store'
import Imports from '@/utils/Imports'

export * from '@kanto/create-app'

export function initApp() {
  const app = kantoInitApp()

  app.use(FloatingVue, {
    themes: {
      'k-dropdown': {
        $extend: 'dropdown',
        $resetCss: true
      },
      'k-access-popup': {
        $extend: 'dropdown',
        $resetCss: true
      }
    }
  })
  app.config.globalProperties.mitt = mitt<MittEvents>()
  Imports.appStore = useAppStore(app.config.globalProperties.$pinia)
  return app
}
