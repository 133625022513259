import SCS, { SignatureResponse } from './scs'

export default class Signer {
  scs: SCS

  constructor() {
    this.scs = new SCS()
  }

  /**
   * Muotoillaan kortinlukijalle haluttu pyyntö ja lähetetään se
   */
  sign(content: string, keyUsage: string, keyAlgorithm: string, contentType: string, hashAlgorithm: string, signatureType: string): Promise<SignatureResponse> {
    const request = {
      version: this.scs.getVersionInfo()?.version || '1.1',
      selector: {
        keyusages: [keyUsage],
        keyalgorithms: [keyAlgorithm]
      },
      content,
      contentType,
      hashAlgorithm,
      signatureType
    }

    // Pyydetään kortinlukijalta allekirjoitusta
    return this.scs.sign(request)
  }
}
