<template>
  <div class="avatar-stack">
    <AccountAvatar v-for="account in visibleAccounts" :key="account" :account="account" />
    <VDropdown theme="k-access-popup" placement="bottom-end" :skidding="40" :distance="15" style="height: 3em">
      <div class="collapse">
        <span v-if="hiddenAccounts.length > 0" class="count-badge">
          {{ hiddenAccounts.length }}
        </span>
        <q-icon name="fa-solid fa-ellipsis" style="font-size: 1.4em; top: -0.05em" />
      </div>
      <template #popper>
        <div>
          <SafeboxAccessPopup @reload="reload" />
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import AccountAvatar from '@/components/AccountAvatar.vue'
import SafeboxAccessPopup from '@/components/SafeboxAccessPopup.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import Imports from '@/utils/Imports'

@Component({
  components: { SafeboxAccessPopup, AccountAvatar }
})
export default class SafeboxAccessIndicator extends Vue {
  private access: Array<Tuple> = []
  private maxVisibleAccounts = 3

  get accounts() {
    return this.access.map(tuple => tuple.values.username.v)
  }

  get visibleAccounts() {
    return [...this.accounts].splice(0, this.maxVisibleAccounts)
  }

  get hiddenAccounts() {
    return [...this.accounts].splice(this.maxVisibleAccounts)
  }

  async mounted() {
    await this.reload()
  }

  async reload() {
    const response = await apiFetchJSON<TupleJSON[]>(`api/safebox/${Imports.appStore.currentSafebox!.values.id.getDisplayValue()}/safebox_access`)
    const tab = Table.getTable('safebox_access')
    this.access = await Promise.all(response.map(tuple => Tuple.fromJSON(tuple, tab)))
  }
}
</script>

<style scoped lang="scss">
.avatar-stack {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 2em;

  $overlap: 0.5em;

  .avatar,
  .collapse {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;

    &:hover {
      background-color: hsl(0deg 0% 97%);
    }
  }

  .v-popper {
    display: inline-block;
  }

  .collapse {
    display: inline-block;
    top: 0;
    line-height: 3em;
    text-align: center;
    border-radius: 9999px;
    margin-right: 1rem;
    white-space: nowrap;
    width: 3em;
    height: 3em;
    font-weight: 600;
    box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.3);
    position: absolute;
    background-color: white;

    .count-badge {
      $size: 2em;
      width: $size;
      color: white;
      height: $size;
      line-height: $size;
      text-align: center;
      background-color: #5e7388;
      border-radius: 9999px;
      display: block;
      transform: scale(0.75);
      top: #{$size * -0.3};
      right: #{$size * -0.4};
      position: absolute;
      z-index: 1;
      box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.3);
    }
  }

  & > * {
    display: inline-block;

    &:not(:first-child) {
      margin-left: #{-$overlap};
    }
  }
}
</style>
