import type Attribute from '@/classes/Attribute'
import Group, { GroupJSON } from '@/classes/Group'
import type Tab from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'
import { useKantoStore } from '@/utils/store'

export interface LangVersionsJSON extends GroupJSON {
  langversions: { [key: string]: string }
}

/**
 * Kuvaa attribuuttilistan yhdeksi 'kieliversioiduksi' loogiseksi attribuutiksi.
 */
export default class LangVersions extends Group {
  langversions: { [key: string]: Attribute }

  constructor(json: LangVersionsJSON, tab: Tab) {
    super(json, tab)
    this.langversions = Object.entries(json.langversions).reduce((attributes: { [key: string]: Attribute }, [langName, attrName]) => {
      attributes[langName] = tab.attributes[attrName]
      return attributes
    }, {})
  }

  getLabel(): string {
    return this.tab.t(`Attrs.${this.getActiveAttr().name}`)
  }

  getDisplayValue(tuple: Tuple): string {
    return tuple.values[this.getActiveAttr().name]?.getDisplayValue() ?? ''
  }

  /**
   * Palauttaa sovelluksen aktiivisen kielen mukaisen attribuutin.
   */
  getActiveAttr(): Attribute {
    return this.langversions[useKantoStore().lang]
  }
}
