enum TupleAccess {
  NONE = 'none',
  EDIT_ONLY = 'edit_only',
  EDIT_FIRST = 'edit_first',
  VIEW_FIRST = 'view_first',
  INLINE = 'inline',
  INLINE_TABLE = 'inline_table',
  INLINE_ASSOCIATE = 'inline_associate',
  HIERARCHY = 'hierarchy'
}

export default TupleAccess
