<template>
  <q-dialog ref="dialogRef">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ $tt('title') }}</div>
      </q-card-section>
      <q-card-section>
        <p>{{ $tt('description') }}</p>
        <q-input v-model="name" :label="$tt('nameLabel')" />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat rounded @click="onDialogCancel">{{ $tt('cancel') }}</q-btn>
        <q-btn rounded @click="onSave">{{ $tt('create') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useTranslation } from 'i18next-vue'
import { useDialogPluginComponent } from 'quasar'
import { ref } from 'vue'

import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import Value from '@/classes/Value'
import { insertTuple } from '@/utils/api-functions'
import Imports from '@/utils/Imports'

const { dialogRef, onDialogCancel, onDialogOK } = useDialogPluginComponent()

const name = ref('')

const { t: $tt } = useTranslation(undefined, {
  keyPrefix: 'SafeboxGroupCreationDialog'
})

const onSave = async () => {
  const tab = Table.getTab('safebox_group')!
  const tuple = new Tuple(tab, {
    name: new Value(tab.attributes.name, name.value),
    username: new Value(tab.attributes.username, Imports.store.user!.name)
  })
  const inserted = await insertTuple(tuple)
  onDialogOK(inserted)
}
</script>

<style scoped lang="scss">
.q-card {
  border-radius: 10px;
  padding: 0.5em 1em;

  .q-card-actions .q-btn {
    padding: 0 0.75em;
  }
}
</style>
