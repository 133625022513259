import dayjs from 'dayjs'
import { LoadingBar } from 'quasar'

import appVersion from '@/app-version.json'
import ClientError from '@/classes/errors/ClientError'
import NetworkError from '@/classes/errors/NetworkError'
import TechnicalError from '@/classes/errors/TechnicalError'
import { Notification } from '@/classes/Notification'
import { b64DecodeUnicode } from '@/utils/base64'
import Imports from '@/utils/Imports'
import { apiPublicPath } from '@/utils/paths'
import timeout from '@/utils/timeout'

/**
 * REST-rajapintakutsun suoritus ja virheenkäsittely.
 * @param path Kutsun osoite ilman alkuosaa
 * @param options Fetch-kutsun ylimääräiset optiot
 * @param headers Fetch-kutsun ylimääräiset http-otsikot
 * @param timeoutSeconds Odotusaika/s, oletus {@link timeout} s. Jos <= 0, käytetään selaimen oletusta.
 */
export function apiFetch(path: string, options?: RequestInit, headers: Headers = new Headers(), timeoutSeconds = timeout): Promise<Response> {
  LoadingBar.start()
  if (options?.method && Imports.store.csrfToken) {
    // Muille kuin GET-metodeille lisätään CSRF-token
    headers.set('X-CSRF-Token', Imports.store.csrfToken)
  }
  if (headers.get('Accept') === null) {
    headers.set('Accept', 'application/json')
  }
  return fetchInTime(
    `${apiPublicPath}${path}`,
    Object.assign({ headers, credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin' }, options),
    timeoutSeconds
  )
    .catch(error => {
      // Kutsu ei päässyt backendiin saakka.
      throw new NetworkError(error)
    })
    .then(fetched)
    .finally(() => LoadingBar.stop())

  async function fetched(response: Response): Promise<Response> {
    readNotifications(response)
    sessionExpiration(response)
    appVersionCheck(response)

    if (response.ok) {
      if (response.status === 202) {
        const responseJson = await response.json()
        const requestId = responseJson.requestId
        console.log(`Haetaan pitkäkestoisen pyynnön tulos. RequestId: ${requestId}`)
        return fetch(`${apiPublicPath}continue?requestId=${requestId}`)
          .catch(error => {
            throw new NetworkError(error)
          })
          .then(fetched)
      }
      // Pyyntö onnistui tai sisältää validointi-ilmoituksia tms. käyttäjätason opastusta
      return response
    } else {
      // Pyyntö epäonnistui käyttöoikeuksien tms. palvelimella havaittujen syiden vuoksi ja raportoidaan ErrorDialog.vuessa
      const info = `API URL: ${path}`
      const error = [400, 401, 403, 404].includes(response.status)
        ? new ClientError(response.statusText, `Errors.${response.status}`, info)
        : new TechnicalError(`${response.statusText} (${response.status})`, undefined, info)
      const responseText = await response.text()
      if (responseText.length > 0) {
        try {
          error.fetchResponse = JSON.parse(responseText)
        } catch (e) {
          console.warn('Responsen JSON-parsinta epäonnistui.', e)
          error.fetchResponse = responseText
        }
      }
      return Promise.reject(error)
    }
  }
}

/** Kuten fetch, mutta aikakatkaistaan jos timeoutSeconds > 0. */
function fetchInTime(url: string, options: RequestInit, timeoutSeconds: number): Promise<Response> {
  if (timeoutSeconds > 0) {
    const controller = new AbortController()
    const promise = fetch(url, { signal: controller.signal, ...options })
    const timeout = setTimeout(() => controller.abort(), 1000 * timeoutSeconds)
    return promise.finally(() => clearTimeout(timeout))
  } else {
    return fetch(url, options)
  }
}

/** Lukee pyynnön mukana tulevat ilmoitukset ja näyttää ne. */
function readNotifications(response?: Response) {
  Imports.store.dismissNotifications()
  const header = response?.headers.get('Notify')?.split(', ')[0]
  if (header) {
    for (const n of JSON.parse(b64DecodeUnicode(header).toString())) {
      Imports.store.addNotification(Notification.fromJSON(n))
    }
  }
}

/** Lukee pyynnön mukana tulevan X-Session-Expires -istunnon vanhenemistiedon myöhempää käyttöä varten. */
function sessionExpiration(response: Response) {
  const sessionExpiresHeader = getFirstDate(response.headers.get('X-Session-Expires'))
  const serverDateHeader = getFirstDate(response.headers.get('Date'))
  if (sessionExpiresHeader) {
    Imports.store.$patch({ serverDate: serverDateHeader ? dayjs(serverDateHeader) : null, sessionExpires: dayjs(sessionExpiresHeader) })
  }
}

/** Palauttaa useasta pilkulla erotellusta HTTP date -arvosta ensimmäisen. */
function getFirstDate(dates: string | null): string | null {
  if (!dates) {
    return null
  }
  const firstComma = dates.indexOf(', ', 5)
  if (firstComma > 0) {
    return dates.substring(0, firstComma)
  }
  return dates
}

/** Lukee pyynnön mukana tulevan X-App-Version -tiedon ja tallentaa tiedon päivitystarpeesta sessioon. */
function appVersionCheck(response: Response) {
  const appVersionHeader = response.headers.get('X-App-Version')?.split(', ')[0]
  if (process.env.NODE_ENV !== 'development' && appVersionHeader && appVersionHeader !== appVersion.version) {
    console.log(`Update required. Frontend ${appVersion.version}, backend: ${appVersionHeader}`)
    Imports.store.$patch({ updateRequired: true })
  }
}
