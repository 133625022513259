<template>
  <div :class="{ 'k-language-selector': true, 'k-language-selector--minimal': minimal }">
    <q-btn-dropdown v-if="minimal" flat :label="currentLang">
      <q-list>
        <q-item v-for="lang of languages" :key="lang" v-close-popup clickable @click="setLanguage(lang)">
          <q-item-section>
            <q-item-label>{{ $t(`Lang.${lang}`) }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
    <ul v-else>
      <li v-for="lang of languages" :key="lang">
        <span v-if="currentLang === lang">{{ $t(`Lang.${lang}`) }}</span>
        <a v-else href="" @click.prevent="setLanguage(lang)">{{ $t(`Lang.${lang}`) }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export class LanguageSelector extends Vue {
  @Prop({ type: Boolean })
  minimal!: boolean

  get currentLang(): string {
    return this.$store.lang
  }

  get languages(): string[] {
    return this.$store.appConfig?.languages ?? []
  }

  setLanguage(lang: string): void {
    this.$store.setLang(lang)
  }
}
export default LanguageSelector
</script>

<style lang="scss">
.k-language-selector {
  text-align: center;
  margin-top: 1em;

  ul {
    display: inline;
    list-style: none outside none;
    padding-inline-start: 0;

    li {
      display: inline;
    }

    li + li:before {
      content: ' | ';
    }
  }
}
</style>
