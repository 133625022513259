<template>
  <div :class="['safebox-group-wrapper', { expanded }]">
    <div class="safebox-group" @click="onClick">
      <SafeboxGrid v-model="safeboxes" :small="!expanded" :group="group.values.id.v" @select="onSelect" />
      <div class="tools">
        <q-btn v-if="expanded" flat round size="0.6em" icon="fas fa-minimize" @click.stop="onClick" />
        <q-btn v-else flat round size="0.6em" icon="fas fa-expand" @click.stop="onClick" />
        <q-btn flat round size="0.6em" icon="fas fa-pen" @click.stop="onEdit" />
        <q-btn flat round size="0.6em" icon="fas fa-trash" @click.stop="onDelete" />
      </div>
    </div>
    <Transition name="group-indicator-transition">
      <SafeboxIndicators v-if="!expanded" vertical style="margin-right: 4em; margin-top: -0.5em">
        <SafeboxActivityIndicator :safeboxes="safeboxes" />
        <SafeboxMessageIndicator :safeboxes="safeboxes" />
      </SafeboxIndicators>
    </Transition>
    <div class="safebox-group-name">{{ group.values.name.getDisplayValue() }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import SafeboxActivityIndicator from '@/components/SafeboxActivityIndicator.vue'
import SafeboxGrid from '@/components/SafeboxGrid.vue'
import SafeboxIndicators from '@/components/SafeboxIndicators.vue'
import SafeboxMessageIndicator from '@/components/SafeboxMessageIndicator.vue'
import { deleteTuple, updateTuple } from '@/utils/api-functions'
import Imports from '@/utils/Imports'

@Component({
  components: { SafeboxMessageIndicator, SafeboxActivityIndicator, SafeboxIndicators, SafeboxGrid }
})
export default class SafeboxGroup extends Vue {
  @Prop({ type: Object })
  group!: Tuple

  expanded = false

  onClick() {
    this.expanded = !this.expanded
  }

  get safeboxes() {
    return Imports.appStore.safeboxes.filter(tuple => {
      return tuple.values.group.v === this.group.values.id.v
    })
  }

  onDelete() {
    return new Promise<void>(resolve => {
      const dialog = this.$q.dialog({
        title: this.$tt('delete.title'),
        message: this.$tt('delete.message'),
        ok: this.$tt('delete.ok'),
        cancel: this.$tt('delete.cancel')
      })

      dialog.onOk(async () => {
        await deleteTuple(this.group)
        Imports.appStore.refreshSafeboxes()
        resolve()
      })
    })
  }

  $tt(...[key, ...args]: Parameters<typeof this.$t>) {
    return this.$t(`SafeboxGroup.${key}`, ...args)
  }

  onEdit() {
    return new Promise(resolve => {
      const dialog = this.$q.dialog({
        title: this.$tt('edit.title'),
        message: this.$tt('edit.message'),
        prompt: {
          label: this.$tt('edit.label'),
          model: this.group.values.name.getDisplayValue()
        },
        ok: this.$tt('edit.ok'),
        cancel: this.$tt('edit.cancel')
      })

      dialog.onOk(async name => {
        this.group.setValue('name', name)
        const updated = await updateTuple(this.group, this.group.getKeyString())
        resolve(updated)
      })
    })
  }

  onSelect(safebox: Tuple) {
    if (this.expanded) {
      Imports.appStore.selectSafebox(safebox.values.id.toString())
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style scoped lang="scss">
.safebox-group {
  box-shadow:
    0 1px 5px rgba(0, 0, 0, 0.2),
    0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);

  border-radius: 1.75em;
  background: #ffffff78;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .safebox-list {
    justify-content: center;
    width: 100%;

    .safebox-card {
      $size: 4em;

      width: $size;
      height: $size;
    }
  }
}

.safebox-group-name {
  text-align: center;
  color: #6f6f6f;
  font-weight: 500;
}

$max-columns: 4;

.safebox-group {
  --group-duration: 200ms;
  width: calc(2em * #{$max-columns - 1} + 12em * #{$max-columns} + 2em);
}

.safebox-group-wrapper {
  --gap: 1em;
  --card-width: 4em;

  margin-left: -1em;
  margin-bottom: 2em;
  position: relative;

  &.expanded {
    --gap: 2em;
    --card-width: 12em;
  }
}

.tools {
  position: absolute;
  right: 1em;
  top: 100%;
  margin-top: 0.75em;
  height: 2em;
  margin-left: 0.6em;
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

.group-indicator-transition-leave-to :deep(.indicator) {
  transform: scale(0);
}

.group-indicator-transition-leave-to :deep(.indicator),
.group-indicator-transition-enter-from :deep(.indicator) {
  transform: scale(0);
}
</style>
