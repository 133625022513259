import AccessType from '@/classes/AccessType'
import Action, { ActionJSON } from '@/classes/Action'
import { ActionContext } from '@/classes/ActionContext'
import type AppConfig from '@/classes/AppConfig'
import CustomView, { CustomViewJSON } from '@/classes/CustomView'
import TechnicalError from '@/classes/errors/TechnicalError'
import Link, { LinkJSON } from '@/classes/Link'
import type Page from '@/classes/Page'
import Process, { ProcessJSON } from '@/classes/Process'
import Report, { ReportJSON } from '@/classes/Report'
import Search, { SearchJSON } from '@/classes/Search'
import Tab, { TabJSON } from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'
import TupleAccess from '@/classes/TupleAccess'
import RouteName from '@/utils/RouteName'

export interface Summary {
  pivot: string
  aggregateFunction: 'count' | 'sum' | 'average'
  attributes: string[]
}

export interface TableJSON extends TabJSON {
  multiplicityLow?: number
  multiplicityHigh?: number
  formats: string[]
  tupleAccess: TupleAccess
  expand: boolean
  collapse: boolean
  search?: SearchJSON
  process?: ProcessJSON
  children?: TableJSON[]
  actions?: ActionJSON[]
  reports?: ReportJSON[]
  links?: LinkJSON[]
  customViews?: CustomViewJSON[]
  summaries?: Summary[]
}

export default class Table extends Tab {
  multiplicityLow: number
  multiplicityHigh: number
  tupleAccess: TupleAccess
  expand: boolean
  collapse: boolean
  formats: string[]
  search?: Search
  process?: Process
  children?: { [key: string]: Table }
  actions: { [key: string]: Action }
  reports: { [key: string]: Report }
  links: { [key: string]: Link }
  customViews: { [key: string]: CustomView }
  summaries: Summary[]

  constructor(json: TableJSON, page: Page, appConfig: AppConfig) {
    super(json, page, appConfig)
    this.multiplicityLow = json.multiplicityLow ?? 0
    this.multiplicityHigh = json.multiplicityHigh ?? 0
    this.tupleAccess = json.tupleAccess
    this.expand = json.expand
    this.collapse = json.collapse
    this.formats = json.formats ?? []
    this.search = json.search ? new Search(json.search, page, appConfig) : undefined
    this.process = json.process ? new Process(json.process, page, appConfig, this) : undefined
    this.children = json.children?.reduce<{ [key: string]: Table }>((children, childJSON) => {
      const childTable = new Table(childJSON, page, appConfig)
      childTable.parentTable = this
      children[childTable.name] = childTable
      return children
    }, {})
    this.actions =
      json.actions?.reduce<{ [key: string]: Action }>((actions, actionJSON) => {
        const action = new Action(actionJSON, page, appConfig)
        action.parentTable = this
        actions[action.name] = action
        return actions
      }, {}) ?? {}
    this.reports =
      json.reports?.reduce<{ [key: string]: Report }>((reports, reportJSON) => {
        const report = new Report(reportJSON, page, appConfig)
        report.parentTable = this
        reports[report.name] = report
        return reports
      }, {}) ?? {}
    this.links =
      json.links?.reduce<{ [key: string]: Link }>((links, linkJSON) => {
        links[linkJSON.name] = new Link(linkJSON, this)
        return links
      }, {}) ?? {}
    this.customViews =
      json.customViews?.reduce<{ [key: string]: CustomView }>((customViews, customViewJSON) => {
        const customView = new CustomView(customViewJSON, page, appConfig)
        customView.parentTable = this
        customViews[customViewJSON.name] = customView
        return customViews
      }, {}) ?? {}
    this.summaries = json.summaries ?? []
  }

  /** Sisällytetäänkö monikon tiedot parent-monikonsa muokkaus- ja katselunäkymiin? */
  get isInline(): boolean {
    return this.tupleAccess === TupleAccess.INLINE || this.tupleAccess === TupleAccess.INLINE_TABLE || this.tupleAccess === TupleAccess.INLINE_ASSOCIATE
  }

  /** Onko table {@link INLINE_TABLE}-tyyppinen? */
  get isInlineTable(): boolean {
    return this.tupleAccess === TupleAccess.INLINE_TABLE
  }

  /** Onko table {@link INLINE_ASSOCIATE}-tyyppinen? */
  get isInlineAssociate(): boolean {
    return this.tupleAccess === TupleAccess.INLINE_ASSOCIATE
  }

  /** Onko table rekursiivisesti itseensä viittaava? */
  get isHierarchy(): boolean {
    return this.tupleAccess === TupleAccess.HIERARCHY
  }

  /** {@link parentTuple}n yhteydessä esitettävät lapsitaulut */
  getInlineChildTables(parentTuple?: Tuple): Table[] {
    if (this.children !== undefined) {
      if (parentTuple) return Object.values(this.children).filter(child => child.isInline && child.hasPermission(AccessType.VIEW, parentTuple))
      else return Object.values(this.children).filter(child => child.isInline)
    }
    return []
  }

  isTable(): this is Table {
    return true
  }

  asTable(): Table {
    return this
  }

  hasTupleAccess(): boolean {
    return this.tupleAccess !== TupleAccess.NONE && this.tupleAccess !== TupleAccess.INLINE_TABLE
  }

  /** Annetun kontekstin toiminnot, joihin käyttäjällä on käyttöoikeus. */
  getActions(actionContext: ActionContext, parentTuple?: Tuple, accessType?: AccessType): Action[] {
    return Object.values(this.actions).filter(action => action.isVisibleInContext(actionContext, parentTuple, accessType))
  }

  /** Annetun kontekstin raportit, joihin käyttäjällä on lukuoikeus. */
  getReports(actionContext: ActionContext, parentTuple?: Tuple, accessType?: AccessType): Report[] {
    return Object.values(this.reports).filter(report => report.isVisibleInContext(actionContext, parentTuple, accessType))
  }

  /** Annetun kontekstin linkit, joihin käyttäjällä on lukuoikeus. */
  getLinks(actionContext: ActionContext, parentTuple?: Tuple, accessType?: AccessType): Link[] {
    return Object.values(this.links).filter(link => link.isVisibleInContext(actionContext, parentTuple, accessType))
  }

  /**
   * Palauttaa taulun kaikki lapsitabit ilman oikeustarkistuksia.
   */
  getTabs(): Tab[] {
    return (Object.values(this.children ?? {}) as Tab[])
      .concat(Object.values(this.actions))
      .concat(Object.values(this.reports))
      .concat(Object.values(this.customViews))
  }

  /** Onko taululla pyydetyn nimistä jälkeläistä (lasta/lastenlasta)? */
  hasDescendant(tableName: string): boolean {
    if (!this.children) return false
    if (Object.keys(this.children).some(name => name === tableName)) return true
    return Object.values(this.children).some(child => child.hasDescendant(tableName))
  }

  getRouteName(): string {
    switch (this.tupleAccess) {
      case TupleAccess.EDIT_FIRST:
      case TupleAccess.EDIT_ONLY:
      case TupleAccess.HIERARCHY:
        return this.hasPermission(AccessType.EDIT) ? RouteName.EDIT : RouteName.VIEW
      case TupleAccess.VIEW_FIRST:
        return RouteName.VIEW
      case TupleAccess.NONE:
      default:
        throw new TechnicalError(`Taululle ${this.name} ei ole määritelty oletusnäkymää.`)
    }
  }

  /**
   * Palauttaa taulun nimen perusteella.
   */
  static getTable(tableName: string): Table {
    const tab = this.getTab(tableName)
    if (tab instanceof Table) {
      return tab
    }
    throw new TechnicalError(`Taulua ${tableName} ei ole määritelty.`)
  }
}
