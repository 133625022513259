<template>
  <q-dialog ref="dialog" class="mobile-login-dialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ $t(`MobileAuthenticationDialog.${mode}.header`) }}</div>
      </q-card-section>
      <q-card-section>
        {{ $t(`MobileAuthenticationDialog.${mode}.body`) }}
      </q-card-section>
      <q-card-section style="justify-content: center; display: flex">
        <a
          v-if="qrcode && url"
          :href="url"
          style="width: 20em; height: 20em; border: 0.2em solid black; padding: 0.5em; border-radius: 0.74rem; display: block"
        >
          <img :src="qrcode" />
        </a>
        <div v-else style="width: 20em; height: 20em; border: 0.2em solid black; padding: 1em; border-radius: 0.74rem">
          <q-spinner size="100%" thickness="2" />
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn @click="hide">{{ $t('MobileAuthenticationDialog.cancelButton') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import QRCode from 'qrcode'
import { QDialog } from 'quasar'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import User, { UserJSON } from '@/classes/User'
import { apiFetchJSON, logout } from '@/utils/api-functions'
import { arrayBufferToBase64, base64ToArrayBuffer } from '@/utils/crypt'
import { storeAuthIDToIndexedDB } from '@/utils/crypt-utils'
import { apiPublicPath, publicPath } from '@/utils/paths'
import { uuidv4 } from '@/utils/uuid'

type KeyMessage = {
  token: string
  authenticatorId: string
  key: string
  iv: string
}

class Connection {
  private ws: WebSocket

  public token: string | null = null

  private refreshIntervalId: ReturnType<typeof setInterval> | null = null

  constructor() {
    const url = `wss://${document.location.host}${apiPublicPath}mobile-login-websocket`
    this.ws = new WebSocket(url)
    this.ws.addEventListener('message', this.handleMessage.bind(this))
    this.ws.addEventListener('open', () => {
      this.refresh()
      this.refreshIntervalId = setInterval(this.refresh.bind(this), 10000)
    })
  }

  close() {
    if (this.refreshIntervalId) {
      clearTimeout(this.refreshIntervalId)
      this.ws.close()
    }
  }

  async refresh() {
    this.ws.send(
      JSON.stringify({
        id: uuidv4(),
        type: 'refresh'
      })
    )
  }

  public onToken: (token: string) => Promise<void> | void = () => {}

  public onKey: (value: KeyMessage) => Promise<void> | void = () => {}

  private async handleMessage(event: MessageEvent<string>) {
    const payload = JSON.parse(event.data)

    if (payload.type === 'token') {
      await this.onToken(payload.token)
    } else if (payload.type === 'key') {
      try {
        await this.onKey(payload)
      } catch (err) {
        if (err instanceof UserDeclinedAuthenticationAttempt) {
          this.ws.send(
            JSON.stringify({
              id: uuidv4(),
              reply: payload.id,
              error: 'user-declined'
            })
          )

          return
        }

        this.ws.send(
          JSON.stringify({
            id: uuidv4(),
            reply: payload.id,
            error: 'unknown'
          })
        )

        throw err
      }

      this.ws.send(
        JSON.stringify({
          id: uuidv4(),
          reply: payload.id,
          error: false
        })
      )
    }
  }
}

type AuthURLParams = {
  token: string
  secret: string
  name?: string
}

class UserDeclinedAuthenticationAttempt extends Error {}

export type OKPayload = {
  authenticatorId: string
  key: CryptoKey
}

@Component({})
export default class MobileAuthenticationDialog extends Vue {
  private conn: Connection | null = null
  url: string = ''
  qrcode: string | null = null
  private channelKey!: CryptoKey
  private secret!: Uint8Array

  @Prop({ type: String })
  private name?: string

  get mode() {
    return this.name ? 'register' : 'auth'
  }

  async created() {
    // Avataan WebSocket-yhteys ja odotetaan, että palvelin toimittaa
    // autentikaatioyritykselle uniikin tokenin.
    this.conn = new Connection()
    this.conn.onKey = this.onKey.bind(this)
    this.conn.onToken = this.onToken.bind(this)

    this.secret = await this.generateSecret()
  }

  beforeUnmount() {
    this.conn?.close()
  }

  async onToken(token: string) {
    this.channelKey = await this.deriveKey(token)

    const params: AuthURLParams = {
      secret: arrayBufferToBase64(this.secret),
      token
    }

    if (this.name) {
      params.name = this.name
    }

    // Muodostataan URL, joka avataan mobiililaitteella QR-koodin avustuksella.
    // Huomaa, että URLin sisältämät parametrit välitetään sen hash-osassa,
    // jottei salaisuus päädy palvelimelle mobiililaitteen ladatessa sivua.
    // URLissa myös mukana oleva token väliteään myöhemmin palvelimelle, joten
    // sen osalta voitaisiin käyttää tavanomaisesti URLin query-osaa.
    this.url = `https://${document.location.host}${publicPath}mobile-login#${new URLSearchParams(params)}`

    this.qrcode = await QRCode.toDataURL(this.url)
  }

  /**
   * Tapahtumakäsittelijä, joka suoritetaan kun mobiililaitteelta vastaanotetaan salausavain.
   */
  async onKey(message: KeyMessage) {
    const key = await this.decryptKey(message.token, message.iv, message.key)
    this.ok({
      authenticatorId: message.authenticatorId,
      key
    })
    this.hide()
  }

  @Emit('ok')
  ok(payload: OKPayload) {
    return payload
  }

  /**
   * Purkaa mobiililaitteelta saadun avaimen salauksen.
   */
  async decryptKey(token: string, encodedIv: string, encodedKey: string) {
    const wrappingKey = await this.deriveKey(token)
    const iv = base64ToArrayBuffer(encodedIv)
    const wrappedKey = base64ToArrayBuffer(encodedKey)

    return crypto.subtle.unwrapKey('raw', wrappedKey, wrappingKey, { name: 'AES-GCM', iv }, { name: 'AES-GCM' }, true, ['encrypt', 'wrapKey', 'unwrapKey'])
  }

  /**
   * Generoi ja palauttaa satunnaisen salaisuuden, jota käytetään varsinaisen salausavaimen johtamiseen.
   */
  async generateSecret() {
    const array = new Uint8Array(128)
    crypto.getRandomValues(array)
    return array
  }

  /**
   * Johtaa salausavaimen annetusta salaisuudesta.
   */
  async deriveKey(token: string) {
    const secret = await crypto.subtle.importKey('raw', this.secret, 'PBKDF2', false, ['deriveKey'])

    return crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: base64ToArrayBuffer(token),
        iterations: 1000,
        hash: 'SHA-256'
      },
      secret,
      { name: 'AES-GCM', length: 256 },
      true,
      ['unwrapKey']
    )
  }

  /**
   * Kirjaa käyttäjän sisään mobiililaitteelta vastaanotettujen tietojen perusteella.
   */
  async loginUser(authenticatorId: string, key: CryptoKey) {
    const userJSON = await apiFetchJSON<UserJSON>(`login`, {
      method: 'POST',
      body: JSON.stringify({ username: authenticatorId, password: '' })
    })

    return new Promise<void>((resolve, reject) => {
      const dialog = this.$q.dialog({
        title: this.$t('MobileAuthenticationDialog.confirmationDialog.title'),
        message: this.$t('MobileAuthenticationDialog.confirmationDialog.message', { name: userJSON.attributes.full_name }),
        cancel: this.$t('MobileAuthenticationDialog.confirmationDialog.cancel'),
        ok: this.$t('MobileAuthenticationDialog.confirmationDialog.ok')
      })

      dialog.onOk(async () => {
        this.$store.$patch({ csrfToken: userJSON.csrfToken })
        const user = new User(userJSON, key, { id: authenticatorId } as Credential)
        this.$store.$patch({ user })
        storeAuthIDToIndexedDB(authenticatorId)
        this.$router.replace('/safebox/listing')

        resolve()
      })

      dialog.onCancel(async () => {
        await logout()
        this.notify(new Notification(NotificationLevel.INFO, this.$t('MobileAuthenticationDialog.loginCancelledNotification')))
        reject(new UserDeclinedAuthenticationAttempt())
      })
    })
  }

  @Emit('hide')
  onHide() {}

  public show() {
    ;(this.$refs.dialog as QDialog).show()
  }

  public hide() {
    ;(this.$refs.dialog as QDialog).hide()
  }
}
</script>

<style lang="scss">
.mobile-login-dialog {
  .q-card {
    flex: 1;
    text-align: center;
    transition-duration: 150ms;
    background-color: white;
    border-radius: 0.75rem;
  }

  img {
    $size: 100%;

    width: $size;
    height: $size;
  }
}
</style>
