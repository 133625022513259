import AccessType from '@/classes/AccessType'
import { ActionContext } from '@/classes/ActionContext'
import type AppConfig from '@/classes/AppConfig'
import type Page from '@/classes/Page'
import Tab, { TabJSON } from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'

export interface ReportJSON extends TabJSON {
  contexts?: (keyof typeof ActionContext)[]
  icon?: string
  formats: string[]
}

export default class Report extends Tab {
  contexts: ActionContext[]
  icon?: string
  formats: string[]

  constructor(json: ReportJSON, page: Page, appConfig: AppConfig) {
    super(json, page, appConfig)
    this.contexts = json.contexts?.map(contextName => ActionContext[contextName]) ?? []
    this.icon = json.icon
    this.formats = json.formats
  }

  /** Onko toiminnolla attribuutteja? */
  hasAttributes(): boolean {
    return Object.keys(this.attributes).length > 0
  }

  /** Näkyykö raportti annetussa kontekstissa annetulla parent-monikolla ja access-tyypillä? */
  isVisibleInContext(context: ActionContext, parentTuple?: Tuple, accessType?: AccessType): boolean {
    return (
      (this.contexts.includes(context) ||
        (this.contexts.includes(ActionContext.LISTING_ANY) && (context === ActionContext.LISTING_EMPTY || context === ActionContext.LISTING_NOT_EMPTY))) &&
      this.isVisible(parentTuple, accessType)
    )
  }
}
