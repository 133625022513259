<template>
  <q-list>
    <q-item v-for="label in labels" :key="label.values.id?.valueToString()" dense clickable @click="onLabelClicked(label)">
      <q-item-section>
        <q-item-label side>
          <q-badge rounded outline :class="{ 'label-badge': true, selected: isSelected(label) }">
            {{ label.values.name.getDisplayValue() }}
          </q-badge>
          <span class="label-count">{{ label.values.count.getDisplayValue() }}</span>
        </q-item-label>
      </q-item-section>
      <q-menu v-if="hasPermissions(label)" context-menu>
        <q-list>
          <q-item v-close-popup clickable dense @click="onEdit(label)">
            <q-item-section side>
              <q-icon name="fa-solid fa-pen" size="1.2em" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $tt('edit') }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable dense @click="onDelete(label)">
            <q-item-section side>
              <q-icon name="fa-solid fa-trash" size="1.2em" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $tt('delete') }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-item>
  </q-list>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import AccessType from '@/classes/AccessType'
import Tuple from '@/classes/Tuple'
import { deleteTuple, updateTuple } from '@/utils/api-functions'
import Imports from '@/utils/Imports'

@Component({})
export default class SidebarLabelsView extends Vue {
  $tt(key: string, ...args: unknown[]) {
    // @ts-ignore
    return this.$t(`SidebarLabelsView.${key}`, ...args)
  }

  hasPermissions(label: Tuple) {
    return label.tab.hasPermission(AccessType.EDIT, label)
  }

  get labels(): Tuple[] {
    return Imports.appStore.labels
  }

  get selectedLabels(): Tuple[] {
    return Imports.appStore.selectedLabels
  }

  isSelected(label: Tuple) {
    return this.selectedLabels.findIndex((sl: Tuple) => sl.values.id.v === label.values.id.v) !== -1
  }

  onLabelClicked(label: Tuple) {
    const index = this.selectedLabels.findIndex((sl: Tuple) => sl.values.id.v === label.values.id.v)

    if (index === -1) {
      Imports.appStore.selectLabel(label)
    } else {
      Imports.appStore.unselectLabel(label)
    }
  }

  async onDelete(label: Tuple) {
    await deleteTuple(label)
  }

  async edit(label: Tuple) {
    return new Promise<Tuple | null>(resolve => {
      const dialog = this.$q.dialog({
        title: this.$tt('renamePrompt'),
        message: this.$tt('renameMessage', { label: label.values.name.getDisplayValue() }),
        prompt: {
          model: label.values.name.getDisplayValue(),
          type: 'text',
          isValid: (value: string) => !this.labels.some(l => l.values.name.getDisplayValue() === value),
          label: this.$tt('renameLabel')
        },
        ok: this.$tt('renameOk'),
        cancel: this.$tt('renameCancel')
      })

      dialog.onOk(async (name: string) => {
        label.setValue('name', name)
        const updated = await updateTuple(label, label.getKeyString())
        resolve(updated)
      })

      dialog.onCancel(() => resolve(null))
    })
  }

  async onEdit(label: Tuple) {
    const updated = await this.edit(label)

    if (updated !== null) {
      Imports.appStore.refreshLabels()
    }
  }
}
</script>

<style scoped lang="scss">
.label-count {
  margin-left: 1em;
  font-size: 0.9em;
}

.label-badge {
  //color: #707070;
  user-select: none;
  //padding: 0.3em 0.5em;
  display: inline-block;
  cursor: pointer;

  font-size: 11px;
  color: #5e5d5e;
  border-color: #7b787a;
  padding: 0.4em 0.75em;

  &.selected {
    background-color: #707070;
    color: #f1f2f4;
    border-color: #707070;
  }
}
</style>
