import type Attribute from '@/classes/Attribute'
import Tab from '@/classes/Tab'
import type { TupleJSON } from '@/classes/Tuple'
import Value from '@/classes/Value'
import { uuidv4 } from '@/utils/uuid'

const ADVANCED_SEARCH_OPS = ['eq', 'contains', 'lt', 'gt'] as const
export const ADVANCED_SEARCH_OP_LABELS = { eq: '=', contains: '⊇', lt: '<', gt: '>' }
export type AdvancedSearchOp = (typeof ADVANCED_SEARCH_OPS)[number]

/** Tarkistaa, että op-string on kelvollinen. */
export function checkAdvancedSearchOp(op: string): AdvancedSearchOp {
  return ADVANCED_SEARCH_OPS.includes(op as AdvancedSearchOp) ? (op as AdvancedSearchOp) : 'eq'
}

export default class AdvancedSearchParam {
  attr: Attribute
  op: AdvancedSearchOp
  val: Value
  uniqueId: string = uuidv4()

  constructor(attr: Attribute, op: AdvancedSearchOp, val: Value) {
    this.attr = attr
    this.op = op
    this.val = val
  }

  toJSON(): TupleJSON {
    return { values: { attr: { v: `${this.attr.tab.name}-${this.attr.name}` }, op: { v: this.op }, val: this.val.toJSON() } }
  }

  static fromJSON(json: TupleJSON) {
    const [tabName, attrName] = (json.values.attr.v as string).split('-', 2)
    const tab = Tab.getTab(tabName)!
    const attr = tab.attributes[attrName]
    return new AdvancedSearchParam(attr, json.values.op.v as AdvancedSearchOp, Value.fromJSON(json.values.val, attr))
  }
}
