export type Handle = number

type QueueEntry = {
  handle: Handle
  handOver: () => void
}

/**
 * Luokka, joka toteuttaa asynkronisen semafori-synkronisaatioprimitiivin.
 */
export class Semaphore {
  /** Lista kahvoista, joiden haltijoilla on tällä hetkellä suoritusvuoro . */
  private handles = new Set<number>()

  /** Laskuri kahvojen juoksevaa numerointia varten. */
  private counter = 0

  /** Lista kahvoista, joiden haltijat odottavat suoritusvuoroaan. */
  private queue: QueueEntry[] = []

  /**
   * Alustaa uuden semaforin, joka sallii enimmillään {@link capacity}
   * samanaikaista suorittajaa.
   *
   * @param capacity - Rinnakkaisten suorittajien enimmäismäärä.
   */
  constructor(private _capacity: number) {}

  /** Rinnakkaisten suorittajien enimmäismäärä. */
  get capacity() {
    return this._capacity
  }

  /** Suorituksessa olevien kahvojen lukumäärä. */
  get active() {
    return this.handles.size
  }

  /**
   * Odottaa suoritusvuoroa ja palauttaa kahvan, jolla suoritusvuoro voidaan antaa
   * eteenpäin {@link #release}-metodia käyttäen.
   */
  async acquire(): Promise<Handle> {
    const handle = this.counter++

    return new Promise(resolve => {
      const handOver = () => {
        this.handles.add(handle)
        resolve(handle)
      }

      if (this.active < this.capacity) {
        handOver()
      } else {
        this.queue.push({
          handle,
          handOver
        })
      }
    })
  }

  /**
   * Luovuttaa suoritusvuoron seuraavalle jonossa olijalle, jos sellainen on,
   * ja poistaa nykyisen kahvan suoritusviorossa olevien listalta.
   *
   * @param handle - Kahva, joka on saatu {@link #acquire}-metodilta suoritusvuoron yhteydessä.
   */
  release(handle: Handle) {
    if (!this.handles.has(handle)) {
      return
    }

    this.handles.delete(handle)
    const [entry] = this.queue.splice(0, 1)

    if (!entry) {
      return
    }

    entry.handOver()
  }

  /**
   * Suorittaa {@link scope}-funktion, odottaen ensin suoritusvuoroa ja varmistaen,
   * että suoritusvuoro luovutetaan eteenpäin funktion suorituksen jälkeen.
   *
   * @param scope - Suoritettava asynkroninen funktio.
   */
  async with<T>(scope: () => Promise<T>): Promise<T> {
    const handle = await this.acquire()

    try {
      const result = await scope()
      return result
    } finally {
      this.release(handle)
    }
  }
}
