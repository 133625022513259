import _, { flatten, groupBy } from 'lodash-es'

import Tuple from '@/classes/Tuple'

/**
 * Funktio, joka välitetään vierailijafunktioille viimeisenä argumenttina, jonka kutsuminen suorittaa seuraavana
 * suoritusvuorossa olevat vierailijafunktiot.
 *
 * @param tuple Tuple, joka välitetään seuraaville vierailijoille.
 * @param context Konteksti, joka välitetään seuraaville vierailijoille.
 *
 * @returns Tuplen, jonka on käsitellyt kaikki seuraavat vierailijat, ja jonka kaikki lapset on käsitelty.
 */
export type NextFunction<Context> = (tuple: Tuple, context: Context) => Promise<Tuple | null>

type TupleVisitorReturnTypeInner<Context> = { tuple: Tuple; context: Context } | Tuple | null | undefined | void
type TupleVisitorReturnType<Context> = Promise<TupleVisitorReturnTypeInner<Context>> | TupleVisitorReturnTypeInner<Context>

/**
 * Vierailijafunktio, jota {@link TupleHierarchyVisitor} kutsuu.
 *
 * @param tuple Tuple, joka on käsiteltävänä.
 * @param context Nykyinen konteksti, joka on välitetty parent-Tuplen vierailijoilta alas.
 * @param next Funktio, jota kutsuttaessa suoritus siirtyy seuraavana vuorossa olevalle vierailijalle.
 *             Jos funktiota ei kutsuta vierailijan aikana, se suoritetaan implisiittisesti heti vierailijan
 *             suorituksen jälkeen.
 *
 * @returns
 *
 * Vieralijafunktio voi palauttaa jonkin suraavista arvoista:
 *
 *   - `{ tuple: {@link Tuple}; context: {@link Context} }`: Palautettu tuple ja konteksti välitetään seuraavana
 *      suoritusvuorossa olevalle vierailijalle.
 *   - `{@link Tuple}`: Palautettu tuple ja konteksti, jolla tätä vierailijaa kutsuttiin, välitetään seuraavana
 *      suoritusvuorossa olevalle vierailijalle.
 *   - `null`: Tämä {@link Tuple} poistetaan hierarkiasta, eikä sen lapsia käsitellä.
 *   - `undefined`: Seuraavalle vierailijalle välitetään sama tuple ja konteksti, kuin tällekin vierailijalle.
 */
export type TupleVisitor<Context = void> = (tuple: Tuple, context: Context, next: NextFunction<Context>) => TupleVisitorReturnType<Context>

/**
 * Luokka, joka toteuttaa yleistetyn tavan käydä läpi ja muuntaa Tuple-hierarkioita.
 */
export class TupleHierarchyVisitor<Context = void> {
  /**
   * Taulu vierailijafunktioita tualun tyypin mukaan.
   * Jokaisen vierailijafunktion yhteydessä on suoritusjärjestyksen määräävä numero.
   *
   * @private
   */
  private visitors: Map<string, Array<[number, TupleVisitor<Context>]>> = new Map()

  /**
   * Laskuri jonka mukaan vierailijafunktioille annetaan järjestysnumero,
   * jonka takaa niiden suorituksen määrittelyjärjestyksessä.
   * */
  private visitorCount = 0

  /**
   * Rekisteröi vierailijafunktion, joka suoritetaan kun hierarkiassa kohdataan {@link table}-taulun instanssia
   * esittävä {@link Tuple}.
   *
   * @param table Taulun nimi, jonka tyyppisille tupleille vierailijafunktio suoritetaan.
   * @param visitor Vierailijafunktio.
   */
  public on(table: string, visitor: TupleVisitor<Context>) {
    const number = this.visitorCount++

    const visitors = this.visitors.get(table)

    if (visitors) {
      visitors.push([number, visitor])
    } else {
      this.visitors.set(table, [[number, visitor]])
    }
  }

  /**
   * Vierailijafunktio, joka suoritetaan viimeisenä jokaisella hierarkian tuplelle.
   * Vastaa Tuplen lapsien läpikäymisestä.
   *
   * Tuplen lapsihierarkiat käsitellään rinnakkaisesti.
   */
  defaultVisitor = async (tuple: Tuple, context: Context) => {
    const children = _.flatten(Object.values(tuple.children))

    const results = await Promise.all(
      children.map(async child => {
        const result = await this.visit(child, context)
        return result ? [result] : []
      })
    )

    tuple.children = groupBy(flatten(results), child => child.tab.name)
  }

  /**
   * Suorittaa seuraavan vierailijafunktion annetulle tuplelle.
   *
   * Tämä metodi suorittaa itsensä rekursiivisesti, kunnes {@link visitors}-lista vierailijafunktioista on tyhjä.
   *
   * @param tuple Tuple, jolle vierailijafunktio suoritetaan.
   * @param context Konteksi, joka välitetään vierailijafunktiolle.
   * @param visitors Lista vierailijafunktioista. Listan ensimmäinen funtio suoritetaan.
   * @private
   */
  private async _visit(tuple: Tuple, context: Context, visitors: TupleVisitor<Context>[]): Promise<Tuple | null> {
    const [visitor, ...nextVisitors] = visitors

    if (!visitor) {
      return tuple
    }

    let nextCalled = false

    const next = (tuple: Tuple, context: Context) => {
      nextCalled = true
      return this._visit(tuple, context, nextVisitors)
    }

    const result = await visitor(tuple, context, next)

    let resultTuple = tuple
    let resultContext = context

    if (result instanceof Tuple) {
      resultTuple = result
    } else if (result) {
      resultTuple = result?.tuple ?? resultTuple
      resultContext = result?.context ?? resultContext
    } else if (result === null) {
      return null
    }

    if (!nextCalled) {
      return next(resultTuple, resultContext)
    }

    return resultTuple
  }

  /**
   * Käy läpi annetut Tuple-hierarkian käyttäen määriteltyjä vierailijafunktioita ja palauttaa lopullisen Tuplen.
   *
   * @param initialTuple Hierarkian juuri-Tuple
   * @param initialContext Alustava konteksti, joka välitetään vierailijafunktioille
   */
  public async visit(initialTuple: Tuple, initialContext: Context): Promise<Tuple | null> {
    // Luodaan lista suoritettavista vierailijoista. Lista koostuu
    //   1. Tuplen taululle määritellyistä vierailijoista
    //   2. Vierailijoista, jotka on määritelty suoritettavaksi kaikkien tuplejen kohdalla
    //   3. Oletusvierailijasta, joka suorittaa tämän metodin kaikille tuple lapsihierarkioille

    const visitors = [...(this.visitors.get(initialTuple.tab.name) ?? [[0, () => Promise.resolve()]]), ...(this.visitors.get('*') ?? [])]

    // Järjestetään vierailijat niiden määrittelyjärjestyksen mukaan.
    const orderedVisitors = visitors.sort((a, b) => a[0] - b[0]).map(pair => pair[1])

    orderedVisitors.push(this.defaultVisitor)

    return this._visit(initialTuple, initialContext, orderedVisitors)
  }
}
