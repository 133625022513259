import AccessControl, { AccessControlJSON } from '@/classes/AccessControl'
import Action, { ActionJSON } from '@/classes/Action'
import type AppConfig from '@/classes/AppConfig'
import CustomView, { CustomViewJSON } from '@/classes/CustomView'
import Job, { JobJSON } from '@/classes/Job'
import Report, { ReportJSON } from '@/classes/Report'
import type Tab from '@/classes/Tab'
import Table, { TableJSON } from '@/classes/Table'
import { getString } from '@/utils/i18n'

export interface PageJSON extends AccessControlJSON {
  name: string
  icon?: string
  collapse?: boolean
  url?: string
  target?: string
  subPages?: PageJSON[]
  tables?: TableJSON[]
  actions?: ActionJSON[]
  jobs?: JobJSON[]
  reports?: ReportJSON[]
  customViews?: CustomViewJSON[]
}

export default class Page extends AccessControl {
  name: string
  icon?: string
  collapse: boolean
  url?: string
  target?: string
  subPages: Page[] = []
  tabs: Tab[] = []

  constructor(json: PageJSON, appConfig: AppConfig) {
    super(json.permissions ?? {})
    this.name = json.name
    this.icon = json.icon
    this.url = json.url // Vain LinkPage-tyyppiset sivut
    this.target = json.target
    this.collapse = json.collapse ?? false

    for (const tableJSON of json.tables ?? []) {
      this.tabs.push(new Table(tableJSON, this, appConfig))
    }
    for (const actionJSON of json.actions ?? []) {
      this.tabs.push(new Action(actionJSON, this, appConfig))
    }
    for (const jobJSON of json.jobs ?? []) {
      this.tabs.push(new Job(jobJSON, this, appConfig))
    }
    for (const reportJSON of json.reports ?? []) {
      this.tabs.push(new Report(reportJSON, this, appConfig))
    }
    for (const customViewJSON of json.customViews ?? []) {
      this.tabs.push(new CustomView(customViewJSON, this, appConfig))
    }

    this.subPages = json.subPages?.map((subPageJSON): Page => new Page(subPageJSON, appConfig)) || []
  }

  /** Sivun otsikko. */
  getLabel(): string {
    return getString(`Pages.${this.name}`)
  }
}
