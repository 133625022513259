import { isDayjs } from 'dayjs'
import EventEmitter from 'eventemitter2'
import * as ExcelJS from 'exceljs'

import TechnicalError from '@/classes/errors/TechnicalError'
import Table from '@/classes/Table'
import Tuple, { CRYPT_DATA_ATTR_NAME, CRYPT_IV_ATTR_NAME, TupleJSON } from '@/classes/Tuple'
import type { RealValueType } from '@/classes/Value'
import { apiFetchJSON, fetchFile, fetchTuple } from '@/utils/api-functions'
import { decryptBufferToBuffer } from '@/utils/crypt'
import Imports from '@/utils/Imports'
import { Mutex } from '@/utils/mutex'
import { apiPath } from '@/utils/paths'
import { setSafeboxCookie } from '@/utils/safebox'
import { Semaphore } from '@/utils/semaphore'
import { TupleHierarchyVisitor, TupleVisitor } from '@/utils/TupleHierarchyVisitor'

/** Suurin sallittu määrä rinnakkaisia liitteiden latauksia. */
const DOWNLOAD_CONCURRENCY_LIMIT = 4

const toDate = (v: RealValueType) => (isDayjs(v) ? v.toDate() : null)

const PASSWORD_ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'

const generatePassword = (length = 20, characters = PASSWORD_ALPHABET) =>
  Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map(x => characters[x % characters.length])
    .join('')

export const ARCHIVE_CATALOG_PATH = '.digua/catalog.json'

type CounterName = 'safeboxes' | 'services' | 'notes' | 'attachments'
type ExportStats = Record<CounterName, { current: number; total: number | null }>

export type ExportOptions = {
  /** Lista taulujen nimistä, joita ei tule sisällyttää vientiarkistoon lapsitauluineen. */
  skip?: string[]

  /**
   * Salasana, jolla arkisto salataa. Vaihtoehtoisesti false, jos arkistoa ei haluta salattavan
   * tai true jos salasanana halutaan käyttää satunnaisgeneroitua merkkijonoa.
   */
  password?: boolean | string
}

export type ArchiveCreatorOptions = {
  password?: string
  onProgress?: (progress: number) => void
}

export interface ArchiveCreatorFactory {
  (options: ArchiveCreatorOptions): Promise<ArchiveCreator>
}

export interface ArchiveCreator {
  add(path: string, content: Blob): Promise<void>
  close(): Promise<Blob>
}

type ExportVisitorContext = {
  servicesWorksheet?: ExcelJS.Worksheet
  notesWorksheet?: ExcelJS.Worksheet
  worksheetRow?: number
}

type ExportTupleVisitor = TupleVisitor<ExportVisitorContext>

const isNotNull = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined

const STATUS_MESSAGES: Record<string, string> = {
  safebox: 'Käsitellään tietolokeroa...',
  service: 'Käsitellään tietokorttia...',
  note: 'Käsitellään muistilappua...',
  service_attachment: 'Käsitellään liitettä...'
}

/**
 * Luokka, joka toteuttaa tietolokeroiden viemisen. Pakkausformaatti, jossa tietolokeron tiedot tallennetaan,
 * on riippuvainen luokalle annettavasta {@link ArchiveCreator} toteutuksesta.
 */
export class Exporter extends EventEmitter {
  /** Salasana, jolla luotavan arkiston sisältö salataan. */
  public password: string | null = null
  private tupleCounts: Record<string, { current: number; total: number }> = {}

  /**
   * Palauttaa käytettävän {@link ArchiveCreator}-instanssin.
   */
  async getArchive() {
    if ('then' in this._archive) {
      this._archive = await this._archive
    }

    return this._archive
  }

  /**
   * Vientiarkiston luomiseen käytettävä {@link ArchiveCreator} tai {@link Promise}, joka palauttaa sen.
   * @private
   */
  private _archive: Promise<ArchiveCreator> | ArchiveCreator

  /**
   * Excel Workbook, johon luodaan viennin aikana ihmisluettava katalogi vietyjen tietolokeroiden sisällöstä.
   * @private
   */
  private workbook = new ExcelJS.Workbook()

  /**
   * Taulu, jossa pidetään kirjaa käsiteltyjen tuplejen määristä, prosessin raportointia varen.
   * @private
   */
  private stats: ExportStats = {
    safeboxes: { current: 0, total: null },
    notes: { current: 0, total: null },
    services: { current: 0, total: null },
    attachments: { current: 0, total: null }
  }

  /**
   * {@link TupleHierarchyVisitor}-instanssi, jota käyttäen tietolokeroiden sisältö käydään läpi.
   * @private
   */
  private visitor: TupleHierarchyVisitor<ExportVisitorContext> = new TupleHierarchyVisitor()

  private maxLabels: number = 0
  private maxAttachments: number = 0

  /** Lista tauluista, joiden vieminen tulee ohittaa. */
  private skip: Set<string> = new Set(['safebox_key'])

  /**
   * Alustaa uuden instanssin.
   *
   * @param creator - Tehdasfunktio, joka palauttaa {@link ArchiveCreator}-instanssin,
   *                  jota käytetään vientiarkiston luomiseen.
   */
  constructor(creator: ArchiveCreatorFactory, options?: ExportOptions) {
    super()

    if (options?.password) {
      if (options.password === true) {
        this.password = generatePassword()
      } else {
        this.password = options.password
      }
    }

    this._archive = creator({
      password: this.password ?? undefined,
      onProgress: this.onArchiveProgress
    })

    options?.skip?.forEach(name => this.skip.add(name))

    this.visitor.on('*', this.omitSkippedTables)
    this.visitor.on('*', this.removeCryptAttrs)
    this.visitor.on('*', this.removeDeadReferences)
    this.visitor.on('*', this.updateStats)
    this.visitor.on('safebox', this.handleSafebox)
    this.visitor.on('service', this.handleService)
    this.visitor.on('note', this.handleNote)
    this.visitor.on('service_attachment', this.handleAttachment)

    this.visitor.on('safebox_key', async () => null)
  }

  omitSkippedTables: ExportTupleVisitor = async (tuple, _context, _next) => {
    if (this.skip.has(tuple.tab.name)) {
      return null
    }
  }

  removeDeadReferences: ExportTupleVisitor = async (tuple, _context, _next) => {
    for (const attr of Object.values(tuple.tab.attributes)) {
      if (attr.reference) {
        if (this.skip.has(attr.reference.table)) {
          tuple.resetValue(attr)
        }
      }
    }

    return tuple
  }

  onArchiveProgress = (progress: number) => {
    this.emit('status', `Pakataan... (${Math.round(progress * 100)}%)`)
  }

  /**
   * Poistaa {@link Tuple tupleista} niissä mahdollisesti olevan salatun tiedon,
   * jottei arkistossa jo kertaalleen salaamattomana olevia tietoja toistata myös
   * salattuna.
   *
   * Salatut tiedot (cryptiv ja cryptdata -attribuutit) poistetaan vasta tuplen käsittelyn jälkeen,
   * sillä jotkin operaatiot, kuten liitteiden lataus, vaativat näitä.
   */
  removeCryptAttrs: ExportTupleVisitor = async (tuple, ctx, next) => {
    const result = await next(tuple, ctx)

    if (!result) return null

    if (result.values[CRYPT_IV_ATTR_NAME]) {
      delete result.values[CRYPT_IV_ATTR_NAME]
    }

    if (result.values[CRYPT_DATA_ATTR_NAME]) {
      delete result.values[CRYPT_DATA_ATTR_NAME]
    }

    return result
  }

  /** Laskuri tietolokeroiden numeroimiseksi Excelissä. */
  private safeboxCount = 0

  /**
   * Taulu, jossa avaimet ovat `service_attachment`-tuplejen ID:itä ja
   * arvot näiden liitteiden sijainti (polku) vientiarkistossa.
   * */
  private attachmentMap: Record<string, string> = {}

  /**
   * Lukko, jolla varmistetaan, että tietolokerot käsitellään vuoron perään, eikä limittäin.
   * Tämä vaaditaan, sillä tietolokeron sisällön hakemisen aikana globaalin tietolokero-keksin
   * täytyy olla asetettuna oikeaan arvoon.
   */
  private safeboxMutex = new Mutex()

  /**
   * Käsittelee tietolokeron viemistä varten.
   *
   * - Luo tietolokeroa vastaavat välilehdet Excel-tiedostoon, joihin lapsien käsittelijät kirjoittavat omat tietonsa.
   * - Asettaa tietolokero-keksin vastaamaan käsiteltävänä olevaa tietolokeroa.
   */
  handleSafebox: ExportTupleVisitor = async (tuple, context, next) =>
    this.safeboxMutex.with(async () => {
      const name = tuple.values.name.getDisplayValue()
      console.log(`setSafeboxCookie(${tuple.values.id.valueToString()})`)
      setSafeboxCookie(tuple.values.id.valueToString())

      // this.incrementCounter('attachments', 0, tuple.values.attachments.v as number)

      const safeboxNo = ++this.safeboxCount
      const servicesWorksheet = await this.workbook.addWorksheet(`Tietolokero ${safeboxNo} (Tietokortit)`)
      const notesWorksheet = await this.workbook.addWorksheet(`Tietolokero ${safeboxNo} (Muistilaput)`)

      const createHeader = async (worksheet: ExcelJS.Worksheet, type: string) => {
        worksheet.addRows([
          ['', 'Tietolokero', name],
          ['', 'Sisältö', type],
          ['', 'Luotu', new Date()],
          ['', 'Luoja', Imports.store.user?.attributes.full_name[0]],
          []
        ])

        worksheet.mergeCells('A1:A4')

        const logoUrl = (await import('../../images/digua-logo.png')).default

        const response = await fetch(logoUrl)
        const logoBlob = await response.blob()

        const logoDataUrl = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader()

          reader.onloadend = () => resolve(reader.result as string)
          reader.onerror = reject

          reader.readAsDataURL(logoBlob)
        })

        const logo = this.workbook.addImage({
          base64: logoDataUrl,
          extension: 'png'
        })

        worksheet.addImage(logo, {
          tl: { col: 0.4, row: 1.3 },
          ext: { width: 129, height: 26 }
        })

        worksheet.columns[0].width = 20

        worksheet.getCell('A1').alignment = {
          vertical: 'middle',
          horizontal: 'center'
        }
      }

      await Promise.all([createHeader(servicesWorksheet, 'Tietokortit'), createHeader(notesWorksheet, 'Muistilaput')])

      const repeat = <T>(count: number, callback: (i: number) => T) => new Array(count).fill(true).map((_, i) => callback(i))

      servicesWorksheet.addRow([
        'Tietokortti',
        'Luotu',
        'Muokattu',
        'Lisätiedot',
        'Linkki',
        ...repeat(this.maxLabels, i => `Tunniste #${i + 1}`),
        ...repeat(this.maxAttachments, i => `Liite #${i + 1}`)
      ])

      notesWorksheet.addRow(['Päivämäärä', 'Aika', 'Tila', 'Tietokortti', 'Kuvaus'])

      // Kutsutaan nextiä eksplisiittisesti, jotta lapsien käsittely tapahtuu mutexin sisällä.
      const result = await next(tuple, { servicesWorksheet, notesWorksheet })

      if (!result) return null

      const getCellValueWidth = (value: ExcelJS.CellValue): number => {
        if (!value) {
          return 10
        }

        if (typeof value === 'string') {
          return value.length
        }

        if (value instanceof Date) {
          return 10
        }

        if (typeof value === 'object' && 'text' in value) {
          return value.text.length
        }

        return 10
      }

      // Säädetään sarakkeiden leveydet niiden sisällön perusteella
      for (const worksheet of [servicesWorksheet, notesWorksheet]) {
        worksheet.columns.forEach(function (column) {
          let maxLength = 0
          column.eachCell?.({ includeEmpty: true }, function (cell) {
            const columnLength = getCellValueWidth(cell.value)
            if (columnLength > maxLength) {
              maxLength = columnLength
            }
          })
          column.width = Math.max(10, column.width ?? 0, maxLength)
        })
      }

      return result
    })

  /**
   * Käsittelee tietokortin viemistä varten.
   *
   * - Kirjoittaa tietokortin tiedot tietolokeroa vastaavan Excel-välilehden loppuun.
   */
  handleService: ExportTupleVisitor = async (service, context) => {
    const { servicesWorksheet: worksheet } = context

    if (!worksheet) {
      throw new Error('Worksheet does not exist!')
    }

    const row = worksheet.addRow([
      service.values.name.getDisplayValue(),
      toDate(service.values.created.v),
      toDate(service.values.modified.v),
      service.values.notes?.getDisplayValue() ?? '',
      service.values.link?.getDisplayValue() ?? ''
    ])

    const worksheetRow = row.number

    if (service.children.service_label) {
      let i = 0
      for (const tuple of service.children.service_label) {
        const label = await this.getLabel(tuple.values.label.v as number)
        worksheet.getRow(worksheetRow).getCell(6 + i).value = label.values.name.getDisplayValue()
        i++
      }
    }

    return {
      tuple: service,
      context: {
        ...context,
        worksheetRow
      }
    }
  }

  labels: Map<number, Tuple> = new Map()

  async getLabel(id: number) {
    const tab = Table.getTable('label')!
    let label = this.labels.get(id)

    if (label) {
      return label
    }

    label = await fetchTuple(tab, `${id}`)
    this.labels.set(id, label)
    return label
  }

  private attachmentSemaphore = new Semaphore(DOWNLOAD_CONCURRENCY_LIMIT)

  /**
   * Käsittelee tietokortin liitteen viemistä varten.
   *
   * - Lataa liitteen sisällön ja kirjoittaa sen arkistoon.
   * - Lisää linkin liittetiedostoon Exceliin vastaavan tietokortin kohdalle.
   * - Kirjaa liiteen sijainnin {@link attachmentMap}-tauluun, joka sisällytetään arkistoon.
   */
  handleAttachment: ExportTupleVisitor = async (attachment, context) => {
    const { servicesWorksheet: worksheet, worksheetRow } = context

    if (!worksheet || worksheetRow === undefined) {
      throw new Error('Worksheet does not exist!')
    }

    const filename = attachment.values.file.getDisplayValue()

    const blob = await this.attachmentSemaphore.with(() => fetchFile(attachment, attachment.values.file.attribute))

    const cryptIv = attachment.values[CRYPT_IV_ATTR_NAME]?.valueToString()
    const decryptKey = await Imports.store.user?.getEncryptKey(attachment)

    if (!decryptKey || !cryptIv) {
      throw new TechnicalError('Kryptausavainta ei ole määritelty. Ylikirjoita getEncryptKey() User.ts:ssä.')
    }

    const decoded = new Blob([await decryptBufferToBuffer(await blob.arrayBuffer(), cryptIv, decryptKey)])

    const service = attachment.parent!
    const safebox = service.parent!

    if (!safebox) {
      throw new Error('Liite ei kuulu tietolokerooon!')
    }

    const i = attachment.parent?.children?.service_attachment?.findIndex(c => c.values.id.v === attachment.values.id.v)

    if (i === undefined) {
      throw new Error('Out-of-hierarchy attachment!')
    }

    const path = `Tietolokeron ${safebox.values.name.getDisplayValue()} (#${safebox.values.id.getDisplayValue()}) liitteet/Tietokortti ${service.values.id.getDisplayValue()}: ${service.values.name.getDisplayValue()}/Liite ${
      i + 1
    }: ${filename}`

    const archive = await this.getArchive()

    await archive.add(path, decoded)

    this.attachmentMap[attachment.values.id.valueToString()] = path

    attachment.values.file.v = path

    const fileRow = worksheet.getRow(worksheetRow)

    const value = {
      text: `Liite ${i + 1}: ${attachment.values.file.getDisplayValue()}`,
      hyperlink: path,
      tooltip: attachment.values.file.getDisplayValue()
    }

    fileRow.getCell(6 + this.maxLabels + i).value = value
  }

  /**
   * Käsittelee muistilapun viemistä varten.
   */
  handleNote: ExportTupleVisitor = async (tuple, context) => {
    const { notesWorksheet: worksheet } = context

    if (!worksheet) {
      throw new Error(`Worksheet not initialized!`)
    }

    worksheet.addRow([
      toDate(tuple.values.date.v),
      tuple.values.time.getDisplayValue(),
      tuple.values.status.getDisplayValue(),
      tuple.values.service.v ? tuple.values.service.getDisplayValue() : '',
      tuple.values.description.v
    ])
  }

  /**
   * Inkrementoi taulua vastaavaa tilastoa {@link tupleCounts}-taulussa.
   */
  private incrementCounter(name: string) {
    if (this.tupleCounts[name]) {
      this.tupleCounts[name].current++
      this.emit('stats', this.tupleCounts)
    }
  }

  /**
   * Vierailijafunktio, joka pitää {@link tupleCounts}-taulussa olevaa tilastoa käsiteltyjen tuplejen määristä.
   */
  updateStats: ExportTupleVisitor = async (tuple, ctx, next) => {
    const { current, total } = this.tupleCounts[tuple.tab.name]

    const message = STATUS_MESSAGES[tuple.tab.name]

    if (message) {
      this.emit('status', `${message} (${current + 1}/${total})`)
    }

    const result = await next(tuple, ctx)

    if (!result) return null

    this.incrementCounter(tuple.tab.name)
    return result
  }

  /**
   * Laskee hierarkiassa olevien tuplejen kokonaismäärät niiden tauluittain ja päivittää ne {@link tupleVounts}-tauluun.
   * Laskee myös enimmäismäärän tunnisteille ja liitteille tietokorttikohtaisesti.
   */
  private async countTuples(tuple: Tuple) {
    const visitor = new TupleHierarchyVisitor<Record<string, number>>()

    visitor.on('service', async (tuple, __, next) => {
      const counters = {
        service_label: 0,
        service_attachment: 0
      }

      await next(tuple, counters)

      this.maxLabels = Math.max(this.maxLabels, counters.service_label ?? 0)
      this.maxAttachments = Math.max(this.maxAttachments, counters.service_attachment ?? 0)
    })

    visitor.on('*', (tuple, ctx) => {
      ctx[tuple.tab.name] += 1
    })

    visitor.on('*', tuple => {
      const name = tuple.tab.name

      if (!this.tupleCounts[name]) {
        this.tupleCounts[name] = { current: 0, total: 1 }
      } else {
        this.tupleCounts[name].total++
      }
    })

    await visitor.visit(tuple, {})
  }

  /**
   * Hakee tietolokeron kaikki tiedot käyttäen sitä varten toteutettua API-kutsua.
   * Poiketen tavallisesta listauskutsusta, tämä kutsu palauttaa kaikki tuplejen kentät.
   *
   * @param id Haettavan tietolokeron ID.
   */
  async fetchSafebox(id: number) {
    const response = await apiFetchJSON<TupleJSON>(`${apiPath}/export-safebox/${id}`)
    const tab = Table.getTable('safebox')

    return Tuple.fromJSON(response, tab)
  }

  /**
   * Lataa annettujen tietolokeroiden sisällön ja luo niistä sekä ihmis- että koneluettavan arkiston.
   *
   * @param safeboxIds Lista ladattavien tietolokeroiden ID:istä.
   * @returns Arkistotiedosto, joka sisältää tietolokeroiden sisällön.
   */
  async export(safeboxIds: number[]): Promise<Blob> {
    const safeboxes = (
      await Promise.all(
        safeboxIds.map(async id => {
          const tuple = await this.fetchSafebox(id)
          await this.countTuples(tuple)
          const processed = await this.visitor.visit(tuple, {})
          const json = await processed?.toJSON(undefined, false)
          return json
        })
      )
    ).filter(isNotNull)

    const buffer = await this.workbook.xlsx.writeBuffer()

    const encoder = new TextEncoder()

    const catalogContent = {
      safeboxes,
      attachments: this.attachmentMap
    }

    const archive = await this.getArchive()

    await archive.add(ARCHIVE_CATALOG_PATH, new Blob([encoder.encode(JSON.stringify(catalogContent))]))

    await archive.add('Catalog.xlsx', new Blob([buffer]))

    return archive.close()
  }
}
