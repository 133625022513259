<template>
  <div>
    <p class="description">
      <i18next :translation="$t('InvitationView.description')">
        <template #link>
          <a href="https://www.suomi.fi/omat-tiedot/henkilotiedot">{{ $t('InvitationView.descriptionLink') }}</a>
        </template>
      </i18next>
    </p>
    <div class="col q-gutter-md q-mt-md" style="width: fit-content">
      <div style="display: flex; gap: 1em">
        <q-input
          v-model="satu"
          style="flex-grow: 1; flex-basis: 1px"
          dense
          outlined
          :label="$t('InvitationView.satu')"
          :error="!isSatuValid && satu !== null"
          :error-message="satuErrorMessage"
          @input="selectedLiteUser = null"
        />
        <div style="line-height: 40px">{{ $t('InvitationView.or') }}</div>
        <q-select
          v-model="selectedLiteUser"
          :label="$t('InvitationView.liteUser')"
          style="flex-grow: 1; flex-basis: 1px"
          dense
          outlined
          :options="liteUsers"
          :error="false"
          error-message="-"
        />
      </div>
      <q-list bordered class="rounded-borders">
        <q-item-label header>{{ $t('AccountManagementView.selectSafeboxesHeader') }}</q-item-label>
        <q-item v-if="displayedSafeboxes.length === 0">
          <q-item-section side top>
            <q-item-label>{{ $t('InvitationView.noOwnedSafeboxes') }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-for="safebox in displayedSafeboxes" :key="safebox.getUniqueId()" tag="label">
          <q-item-section side top>
            <q-checkbox :model-value="safeboxSelected(safebox)" @update:model-value="setSafeboxSelected(safebox, $event)" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ safebox.values.name.getDisplayValue() }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <q-btn :disable="!valid" color="blue" @click="sendInvite">{{ $t('InvitationView.send') }}</q-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, mixins, Watch } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import { apiFetchJSON, fetchUserKeys, FetchUserKeysOptions, insertTuple } from '@/utils/api-functions'
import { arrayBufferToBase64 } from '@/utils/crypt'
import Imports from '@/utils/Imports'
import { withSafeboxCookie } from '@/utils/safebox'
import { SafeboxMixin } from '@/utils/SafeboxMixin'
import { uuidv4 } from '@/utils/uuid'

const CHECKSUM_ALPHABET = '0123456789ABCDEFHJKLMNPRSTUVWXY'

@Component({})
export default class InvitationView extends mixins(SafeboxMixin) {
  private satu: string | null = null
  private accountNotFound: boolean | null = null
  private safeboxIds: Array<string> = []
  private selectedLiteUser: { value: string } | null = null
  private liteUserTuples: Array<Tuple> = []

  get valid() {
    return this.safeboxIds.length > 0 && (this.satu || this.selectedLiteUser)
  }

  @Watch('selectedLiteUser')
  onLiteUserChange() {
    this.satu = null
  }

  get liteUsers() {
    return this.liteUserTuples.map(tuple => ({
      value: tuple.getKeyString(),
      label: `${tuple.values.given_name.getDisplayValue()} ${tuple.values.family_name.getDisplayValue()}`
    }))
  }

  async reloadLiteUsers() {
    const tab = Table.getTable('profile')
    const tuples = await apiFetchJSON<TupleJSON[]>(`api/profile?parent_account=${encodeURIComponent(this.$store.user!.name)}`)
    this.liteUserTuples = await Promise.all(tuples.map(tuple => Tuple.fromJSON(tuple, tab)))
  }

  created() {
    this.reloadLiteUsers()
  }

  get displayedSafeboxes() {
    const username = this.$store.user!.name

    return this.safeboxes.filter(safebox => {
      const owner = safebox.values.username.v

      return owner === username
    })
  }

  private safeboxSelected(safebox: Tuple) {
    return this.safeboxIds.includes(safebox.values.id.toString())
  }

  private setSafeboxSelected(safebox: Tuple, selected: boolean) {
    const id = safebox.values.id.toString()
    const index = this.safeboxIds.indexOf(id)

    if (selected && index === -1) {
      this.safeboxIds.push(id)
    } else if (!selected && index > -1) {
      this.safeboxIds.splice(index, 1)
    }
  }

  get isSatuValid() {
    if (this.accountNotFound === true) {
      return false
    }

    if (this.satu === null) {
      return false
    }

    if (this.satu.startsWith('T-')) {
      return true
    }

    const content = this.satu.substring(0, this.satu.length - 1)

    const sum = parseInt(content, 10) /* [...content].map((char) => parseInt(char, 10)).reduce((a, b) => a + b, 0) */ % 31
    const checksum = CHECKSUM_ALPHABET.charAt(sum)

    return this.satu.charAt(this.satu.length - 1) === checksum
  }

  get satuErrorMessage() {
    if (this.accountNotFound) {
      return this.$t('InvitationView.accountNotFound')
    }

    return this.$t('InvitationView.invalidGovId')
  }

  @Watch('satu')
  private onSatuChange() {
    this.accountNotFound = null
  }

  get safeboxOptions() {
    return this.safeboxes.map(safebox => ({
      value: safebox.values.id.v,
      label: safebox.values.name.getDisplayValue()
    }))
  }

  async encryptKey(inviteeKey: CryptoKey, safeboKey: CryptoKey) {
    const encrypted = await crypto.subtle.wrapKey('raw', safeboKey, inviteeKey, { name: 'RSA-OAEP' })
    return arrayBufferToBase64(encrypted)
  }

  async sendInvite() {
    if (this.safeboxIds.length === 0) {
      return
    }

    let criteria: FetchUserKeysOptions

    if (this.satu) {
      criteria = { govId: this.satu }
    } else if (this.selectedLiteUser) {
      criteria = { username: this.selectedLiteUser.value }
    } else {
      return
    }

    const { keys, username } = await fetchUserKeys(criteria)

    if (keys.length === 0 || !username) {
      this.accountNotFound = true
      return
    }

    let success = true

    for (const id of this.safeboxIds) {
      await this.sendInviteForSafebox(parseInt(id, 10), keys)

      if (this.$store.lastFetchReturnedError) {
        success = false
      }
    }

    if (success) {
      this.notify(new Notification(NotificationLevel.SUCCESS, this.$t('InvitationView.successNotification')))
      this.satu = null
      this.safeboxIds = []
    }
  }

  async sendInviteForSafebox(safeboxId: number, inviteeKeys: Array<{ id: string; key: CryptoKey }>) {
    const safeboxKey = this.$store.user!.safeboxCryptKeys[safeboxId]

    if (!safeboxKey) {
      return
    }

    const safebox = this.safeboxes.find(safebox => safebox.values.id.v === safeboxId)

    if (!safebox) {
      return
    }

    const keyTab = Table.getTable('invite_key')
    const inviteTab = Table.getTable('invite')

    const invite = new Tuple(inviteTab)

    const keys = await Promise.all(
      inviteeKeys.map(async inviteeKey => {
        const tuple = new Tuple(keyTab)

        const exported = arrayBufferToBase64(await crypto.subtle.exportKey('raw', safeboxKey))

        tuple.setValue('cryptkey_id', inviteeKey.id)
        tuple.setValue('safebox_key', exported)
        tuple.setValue('safebox_name', safebox.values.name.getDisplayValue())
        tuple.parent = invite

        return tuple
      })
    )

    let username

    if (this.satu) {
      const result = await fetchUserKeys({ govId: this.satu })
      username = result.username
    } else if (this.selectedLiteUser) {
      username = this.selectedLiteUser.value
    } else {
      throw new Error('Unreachable!')
    }

    if (!username) {
      throw new Error('No username found for invited user!')
    }

    invite.setValue('id', uuidv4())
    invite.setValue('invitee', username)
    invite.setValue('invitor', Imports.store.user!.name)
    invite.setValue('safebox', safeboxId)
    invite.setValue('role', 1)

    invite.children.invite_key = keys

    await withSafeboxCookie(safeboxId.toString(), () => insertTuple(invite))
  }
}
</script>

<style scoped lang="scss">
.description {
  max-width: 70ch;
}
</style>
