enum OptionStyle {
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  TEXT = 'text',
  BUTTONGROUP = 'buttongroup',
  TOGGLE = 'toggle'
}

export default OptionStyle
