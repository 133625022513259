/**
 * Apufunktioita, jotka liitetään jokaiseen komponenttiin.
 */
import { TFunction } from 'i18next'
import { ComponentOptions } from 'vue'

import ClientError from '@/classes/errors/ClientError'
import TechnicalError from '@/classes/errors/TechnicalError'
import { Notification, NotificationLevel } from '@/classes/Notification'
import type { ValueComparePredicate, ValuePredicate } from '@/classes/Predicate'
import type Tab from '@/classes/Tab'
import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import { firstString } from '@/utils/helpers'

export default {
  methods: {
    getTabName(): string | undefined {
      return firstString(this.$route.params.tab)
    },

    getParentTableName(): string | undefined {
      for (let i = 10; i > 0; i--) {
        const ptable = this.$route.params['ptable' + i]
        if (ptable !== undefined && ptable !== '') {
          return firstString(ptable)
        }
      }
    },

    getTab(): Tab | undefined {
      const tabName = this.getTabName()
      if (tabName === undefined) {
        return undefined
      }
      return this.$store.appConfig.tabs[tabName]
    },

    getTabReq(): Tab {
      const tab = this.getTab()
      if (tab === undefined) {
        throw new ClientError(`Tabia ${this.getTabName()} ei ole määritelty.`, 'Errors.404')
      }
      return tab
    },

    getTable(): Table | undefined {
      const tab = this.getTab()
      if (tab instanceof Table) {
        return tab
      }
    },

    getTableReq(): Table {
      const tab = this.getTab()
      if (!(tab instanceof Table)) {
        throw new TechnicalError(`Tab ${tab?.name} ei ole table.`)
      } else {
        return tab
      }
    },

    getKey(): string | undefined {
      return firstString(this.$route.params.key)
    },

    getParentKey(): string | undefined {
      for (let i = 10; i > 0; i--) {
        const pkey = this.$route.params['pkey' + i]
        if (pkey !== undefined && pkey !== '') {
          return firstString(pkey)
        }
      }
    },

    /**
     * Palauttaa joko storessa olevat hakuehdot, tai luo uuden hakuehdot-Tuplen query-parametreista.
     */
    getSearchTuple(): Tuple | undefined {
      const table = this.getTable()
      if (table) {
        const searchTuple = Tuple.fromStrings(this.$route.query, table.search ?? table)
        const storedSearch = this.$store.searchTuples[table.name]
        if (storedSearch?.equals(searchTuple)) {
          // Säilössä on jo vastaavat hakuehdot, joten käytetään niitä, jotta
          // vältytään mahdollisten referenssien displayValueiden fetchaamiselta.
          return storedSearch
        }
        // Haetaan mahdolliset referenssien aukikirjoitukset
        searchTuple.fetchDisplayValues()
        this.$store.searchTuples[table.name] = searchTuple
        return searchTuple
      }
    },

    notify(notification: Notification): void {
      this.$store.addNotification(notification)
    },

    /**
     * Apufunktio Tabien kieliresurssien hakemiseen: haetaan ensisijaisesti tauluspesifiä
     * kieliresurssia `Tabs.taulunnimi.key`, toissijaisesti yleistä kieliresurssia `Tab.key`.
     * Ks. myös Table.t().
     */
    $tTab(...args: Parameters<TFunction>): string {
      return this.getTabReq().t(...args)
    },

    /**
     * Tarkistaa annetun monikon tarkisteet ja ilmoittaa käyttäjälle niiden epäonnistuessa.
     * @return true, jos virheitä ei löytynyt ja voidaan jatkaa
     */
    check(tuple: Tuple): boolean {
      // Tarkisteet, jotka eivät mene läpi, kootaan virheilmoitukseksi
      const errors = Object.entries(tuple.tab.check)
        .filter(check => !check[1].matches(tuple))
        .map(failingCheck => {
          // Luetaan virheilmoitus subjectin mukaisesta kieliresurssista...
          const message = tuple.tab.t(failingCheck[0])
          // ja kiinnitetään se myös mahdolliseen Valueen, johon tarkiste liittyi
          const failingPredicate = failingCheck[1].test(tuple)
          const failingAttr = (failingPredicate as ValuePredicate)?.attrName ?? (failingPredicate as ValueComparePredicate)?.attrs[0]
          if (failingAttr) {
            tuple.values[failingAttr].e = message
          }
          return message
        })
        .join(' ')
      if (errors.length > 0) {
        this.notify(new Notification(NotificationLevel.ERROR, errors))
        return false
      }
      return true
    }
  }
} as ComponentOptions
