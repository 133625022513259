import dayjs, { Dayjs } from 'dayjs'
import DOMPurify from 'dompurify'

import type Attribute from '@/classes/Attribute'
import Composition from '@/classes/Composition'
import DataType from '@/classes/DataType'
import LangVersions from '@/classes/LangVersions'
import type Tuple from '@/classes/Tuple'
import CustomValue from '@/classes/Value'
import { JSONPrimitive } from '@/types/json'
import { fetchDisplayValue } from '@/utils/api-functions'
import { getString } from '@/utils/i18n'

export type RealValueType = string | number | boolean | Dayjs | File | bigint | null

export interface ValueJSON {
  v?: RealValueType
  d?: string
  p?: string
  e?: string
  children?: ValueJSON[] // ValueTree
  values?: ValueJSON[] // MultiValue
  isTmpKey?: boolean
}

export default class Value {
  /** Oikea arvo (realvalue), tyyppi vaihtelee. */
  v: RealValueType
  /** Display value, käyttäjälle näytettävä arvo. */
  d: string | null
  /** Syötteessä havaitun virheen selitys käyttäjän kielellä. */
  e: string | null = null
  /** Path eli tiedostoarvon väliaikainen sijainti palvelimella */
  p: string | null
  /** Hierarkisen arvon lapsiarvot */
  children: Value[] | null = null
  /** Arvoksi annetaan true, jos valuelle on jouduttu antamaan väliaikainen tunnistetieto. */
  isTmpKey = false
  attribute: Attribute

  constructor(
    attribute: Attribute,
    value: RealValueType = null,
    displayValue: string | null = null,
    error: string | null = null,
    path: string | null = null,
    children: Value[] | null = null,
    doNotTrim = false,
    isTmpKey = false
  ) {
    this.v = value
    this.d = displayValue
    this.e = error
    this.p = path
    this.children = children
    this.isTmpKey = isTmpKey
    this.attribute = attribute
    if (typeof this.v === 'string' && !doNotTrim) {
      // Trimmataan syötteet
      this.v = this.v.trim()
    }
  }

  /** Vakio-optioiden näyttönimen aukikirjoitus kieliresursseista */
  get optionLabel(): string {
    const tab = this.attribute.tab
    // Haetaan taulukohtaisista ja yhteisistä kieliresursseista
    const optionKey = `Attrs.${this.attribute.name}_options.${this.v ?? 'null'}`
    const keys = [`Tabs.${tab.name}.${optionKey}`, `Tabs.${tab.langKeyName}.${optionKey}`, `Tab.${optionKey}`]
    if (this.attribute.type === DataType.BOOLEAN) {
      // Booleaneilla fallbackina yleiset Kyllä/Ei-arvot
      keys.push(`Tab.options.${this.v ?? 'null'}`)
    }
    if (tab.name.endsWith('_search')) {
      // Jos kyseessä on haku, etsitään myös ei-hakutaulun resursseista
      keys.push(`Tabs.${tab.parentTable?.langKeyName}.Attrs.${this.attribute.name}_options.${this.v}`)
    }
    // Jos kieliresursseja ei lödy, fallbackina valuen arvo sellaisenaan
    return getString(keys, this.v != null ? String(this.v) : '')
  }

  /** Ryhmän displayValue, jos attribuutti on osa kompositiota tai on kieliversioitu. Muutoin valuen oma displayValue. */
  getContainerDisplayValue(tuple: Tuple): string {
    return this.attribute.group instanceof Composition || this.attribute.group instanceof LangVersions
      ? this.attribute.group.getDisplayValue(tuple)
      : this.getDisplayValue()
  }

  /** Arvo muotoiltuna käyttäjän ymmärtämäksi tekstiksi, vain lukua (ei muokkausta) varten. Vrt. getContainerDisplayValue. */
  getDisplayValue(): string {
    if (this.attribute.options != null) {
      return this.optionLabel
    } else if (this.attribute.type === DataType.HTML && this.d) {
      return DOMPurify.sanitize(this.d)
    }

    let displayValue
    if (this.d != null) {
      // Oletuksena arvoon jo kiinnitetty aukikirjoitus
      displayValue = this.d
    } else if (this.v != null) {
      // Toissijaisesti arvo itse, sillä Valuella ei ole displayValueta määritelty, mikäli se olisi identtinen valuen kanssa
      if (dayjs.isDayjs(this.v)) {
        displayValue = this.v.format(this.attribute.type === DataType.DATE ? 'D.M.YYYY' : 'D.M.YYYY HH:mm:ss')
      } else if (this.v instanceof File) {
        displayValue = this.v.name
      } else {
        displayValue = String(this.v)
      }
    } else {
      return ''
    }

    const unit = this.attribute.getUnit()
    // eslint-disable-next-line no-irregular-whitespace
    displayValue = unit ? `${displayValue} ${unit}` : displayValue

    return displayValue
  }

  /**
   * Tarkistaa vastaako tämän valuen realvalue toisen valuen realvaluea.
   */
  equals(other?: Value | null): boolean {
    if (other == null) {
      return false
    }
    if (other.v === this.v) {
      return true
    }
    return dayjs.isDayjs(this.v) && dayjs.isDayjs(other.v) && this.v.isSame(other.v)
  }

  /**
   * Määrittelee sisältääkö Value oikeasti oleellisen realvaluen.
   */
  hasContent(): boolean {
    return !(this.v == null || (typeof this.v === 'string' && this.v.length === 0))
  }

  /** Onko tämä value MultiValue? */
  isMultiValue(): boolean {
    return false
  }

  /**
   * Hakee viittauksen displayValue backendistä
   */
  fetchDisplayValue(): void {
    if (this.attribute.reference && this.d === null) {
      this.d = '...'
      fetchDisplayValue(this).then(valueJSON => {
        this.d = valueJSON.d ?? null
      })
    }
  }

  /**
   * Palauttaa realvaluen JSON-kelpoisena arvona.
   */
  valueToJSON(): JSONPrimitive {
    if (dayjs.isDayjs(this.v)) {
      return this.attribute.type === DataType.DATE ? this.v.format('YYYY-MM-DD') : this.v.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
    } else if (this.attribute.type === DataType.LONG) {
      return this.v?.toString() ?? null
    } else if (this.attribute.type === DataType.FILE && this.hasContent()) {
      // Itse tiedosto on uploadattu jo etukäteen, joten Tuplen JSON-esityksen mukana tarvitsee tässä viedä vain mikä tahansa non-NULL-arvo
      return '(file)'
    } else {
      return <JSONPrimitive>this.v
    }
  }

  /**
   * Palauttaa realvaluen merkkijonona.
   */
  valueToString(): string {
    if (this.v === null) {
      return ''
    }
    return String(this.valueToJSON())
  }

  /**
   * Kuten {@link valueToString}, mutta /-merkit escapetaan, jotta niitä voidaan käyttää multivalueiden erottimina.
   */
  valueToUrl(): string {
    return this.valueToString().replace('\\', '\\\\').replace('/', '\\/')
  }

  toJSON(): ValueJSON {
    return {
      v: this.isMultiValue() && this.attribute.type !== DataType.FILE ? undefined : this.valueToJSON(),
      d: this.p !== null ? (this.attribute.isCrypted ? '(crypted)' : this.d ?? undefined) : undefined,
      e: this.e ?? undefined,
      p: this.p ?? undefined,
      isTmpKey: this.isTmpKey || undefined
    }
  }

  /** Debuggausta varten */
  toString(): string {
    return JSON.stringify(this.valueToJSON())
  }

  static fromJSON(json: ValueJSON, attribute: Attribute): Value {
    if (attribute.interval) {
      const val = attribute.parseUrlValue(json.v as string)
      val.e = json.e ?? null
      return val
    } else if (json.values) {
      const values = json.values.map(v => Value.fromJSON(v, attribute))
      return attribute.createMultiValue(values ?? [])
    }
    const realValue = this.getRealValue(json, attribute)
    const children = json.children?.map(valueJson => Value.fromJSON(valueJson, attribute))
    // Haetaan luotava Value-class importin kautta, jotta saadaan mahdolliset räätälöinnit käyttöön.
    return new CustomValue(attribute, realValue, json.d, json.e, json.p, children)
  }

  private static getRealValue(json: ValueJSON, attribute: Attribute): RealValueType {
    if (typeof json.v === 'string' && (attribute.type === DataType.DATE || attribute.type === DataType.DATETIME)) {
      // Päivämääriä käsitellään dayjs-instansseina.
      return dayjs(json.v)
    } else if (typeof json.v === 'string' && attribute.type === DataType.LONG) {
      return BigInt(json.v)
    } else if (attribute.type === DataType.FILE) {
      // Tiedostoja ei haeta monikon muun datan mukana; asetellaan FileInputFieldiin d:ssä ujutettu tiedostonimi
      return json.v ? new File([], <string>json.d) : null
    }
    // Muut JSON-valuen tietotyypin mukaan
    return json.v ?? null
  }
}
