<template>
  <div class="indicator">
    <slot />
    <q-tooltip>{{ tooltip }}</q-tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class SafeboxIndicator extends Vue {
  @Prop({ type: String })
  tooltip!: string
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.indicator {
  width: $indicator-size;
  height: $indicator-size;
  border-radius: 9999px;
  background-color: #5e7388;
  color: white;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  transition-property: transform;
  transition-duration: $group-transition-duration;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
  text-align: center;

  &.blue {
    background-color: #b2d8fe;
  }

  &.pink {
    background-color: #fdb1e6;
  }

  &.yellow {
    background-color: #fee0b2;
  }

  .indicator-text {
    font-size: #{$indicator-size * 0.4};
    font-weight: 600;
  }

  .indicator-text,
  .q-icon {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
  }

  &:hover {
    transform: scale(1.05);
  }

  :deep(.q-icon) {
    font-size: #{$indicator-size * 0.4};
    inset: 0;
  }
}
</style>
