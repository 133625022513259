import { Component, defineAsyncComponent } from 'vue'

import type AppConfig from '@/classes/AppConfig'
import type Attribute from '@/classes/Attribute'
import DataType from '@/classes/DataType'
import TechnicalError from '@/classes/errors/TechnicalError'
import type Group from '@/classes/Group'
import OptionStyle from '@/classes/OptionStyle'
import type Table from '@/classes/Table'

/** Valitsee attribuutille InputField-implementaation. Omassa tiedostossaan syklisten importtien välttämiseksi. */
export function selectInputField(attr: Attribute, appConfig: AppConfig): Component {
  if (attr.reference) {
    if (attr.reference.navigable) {
      return defineAsyncComponent(() => import('@/components/form/inputfields/NavigableReferenceInputField.vue'))
    } else if (attr.tab.isTable() && isInlineSiblingReference(getRootTable(attr.tab), appConfig.tabs[attr.reference.table] as Table)) {
      return defineAsyncComponent(() => import('@/components/form/inputfields/SiblingReferenceInputField.vue'))
    } else if (!attr.reference.table.startsWith('@') && (appConfig.tabs[attr.reference.table] as Table).isHierarchy) {
      return defineAsyncComponent(() => import('@/components/form/inputfields/TreeInputField.vue'))
    } else {
      return defineAsyncComponent(() => import('@/components/form/inputfields/ReferenceInputField.vue'))
    }
  } else if (attr.interval) {
    return attr.type === DataType.DATE
      ? defineAsyncComponent(() => import('@/components/form/inputfields/DateIntervalInputField.vue'))
      : defineAsyncComponent(() => import('@/components/form/inputfields/IntervalInputField.vue'))
  } else if (attr.options) {
    switch (attr.optionStyle) {
      case OptionStyle.CHECKBOX:
        return attr.multiple
          ? defineAsyncComponent(() => import('@/components/form/inputfields/CheckboxInputField.vue'))
          : defineAsyncComponent(() => import('@/components/form/inputfields/BooleanInputField.vue'))
      case OptionStyle.TOGGLE:
        return defineAsyncComponent(() => import('@/components/form/inputfields/ToggleInputField.vue'))
      case OptionStyle.RADIO:
        return defineAsyncComponent(() => import('@/components/form/inputfields/RadioInputField.vue'))
      case OptionStyle.BUTTONGROUP:
      case OptionStyle.SELECT:
      case OptionStyle.TEXT:
        return defineAsyncComponent(() => import('@/components/form/inputfields/OptionInputField.vue'))
      default:
        return defineAsyncComponent(() => import('@/components/form/inputfields/OptionInputField.vue'))
    }
  } else {
    switch (attr.type) {
      case DataType.PASSWORD:
        return defineAsyncComponent(() => import('@/components/form/inputfields/PasswordInputField.vue'))
      case DataType.EMAIL:
        return defineAsyncComponent(() => import('@/components/form/inputfields/EmailInputField.vue'))
      case DataType.DATE:
        return defineAsyncComponent(() => import('@/components/form/inputfields/DateInputField.vue'))
      case DataType.DATETIME:
        return defineAsyncComponent(() => import('@/components/form/inputfields/DateTimeInputField.vue'))
      case DataType.TIME:
        return defineAsyncComponent(() => import('@/components/form/inputfields/TimeInputField.vue'))
      case DataType.FILE:
        return defineAsyncComponent(() => import('@/components/form/inputfields/FileInputField.vue'))
      case DataType.INTEGER:
      case DataType.LONG:
      case DataType.DECIMAL:
      case DataType.YEAR:
        return defineAsyncComponent(() => import('@/components/form/inputfields/NumberInputField.vue'))
      case DataType.HTML:
        return defineAsyncComponent(() => import('@/components/form/inputfields/HtmlInputField.vue'))
      case DataType.STRING:
      case DataType.BITMASK:
      case DataType.UUID:
        return attr.tab.name.endsWith('_search')
          ? defineAsyncComponent(() => import('@/components/form/inputfields/SearchInputField.vue'))
          : defineAsyncComponent(() => import('@/components/form/inputfields/InputField.vue'))
      default:
        throw new TechnicalError(`Attribuutille ${attr.tab.name}-${attr.name} (${attr.type}) ei pystytty määrittämään InputFieldiä.`)
    }
  }
}

export function selectGroupInputField(group: Group): Component {
  switch (group.type) {
    case 'choice':
      return defineAsyncComponent(() => import('@/components/form/inputfields/ChoiceInputField.vue'))
    case 'composition':
      return defineAsyncComponent(() => import('@/components/form/inputfields/CompositionInputField.vue'))
    case 'dateRange':
      return defineAsyncComponent(() => import('@/components/form/inputfields/DateRangeInputField.vue'))
    case 'row':
    case 'langversions':
      return defineAsyncComponent(() => import('@/components/form/inputfields/RowInputField.vue'))
    case 'block':
      return defineAsyncComponent(() => import('@/components/form/inputfields/BlockInputField.vue'))
    default:
      throw new TechnicalError(`Odottamaton ryhmätyyppi ${group.type}`)
  }
}

/**
 * Tarkastelee rekursiivisesti, onko root-taulussa itsessään tai sen inline-lapsi (tai lapsenlapsi)
 * -tauluissa referenssitaulua.
 * */
function isInlineSiblingReference(rootTab: Table, referenceTable: Table): boolean {
  if (rootTab === referenceTable && getRootTable(rootTab) !== rootTab) {
    return true
  }
  const inlineChildren = rootTab.getInlineChildTables()
  if (inlineChildren) {
    for (const inlineTable of inlineChildren) {
      if (isInlineSiblingReference(inlineTable, referenceTable)) {
        return true
      }
    }
  }
  return false
}

/** Haetaan vanhin parentti, joka toteuttaa inline-lapsi -ketjun. */
function getRootTable(table: Table): Table {
  let parent = table
  while (parent) {
    if (!parent.parentTable?.getInlineChildTables().includes(parent)) {
      return parent
    }
    parent = parent.parentTable
  }
  return parent
}
