<template>
  <div id="app-layout">
    <div id="app-underlay">
      <PageHeader class="header" :drawer-open="isDrawerRoute" />
      <transition name="app-underlay">
        <div v-if="!isDrawerRoute" id="safebox-selection-view">
          <router-view />
        </div>
      </transition>
    </div>
    <transition name="app-drawer">
      <div v-if="isDrawerRoute" id="app-main-drawer">
        <SidebarView v-if="displaySidebar" :narrow="sidebarCollapsed" style="flex-shrink: 0" @collapsed-change="sidebarCollapsed = $event" />
        <div style="padding: 3em; flex-grow: 1; overflow-y: scroll">
          <div class="top-navigation">
            <div style="display: flex; gap: 1em; justify-content: center; grid-column-start: 2">
              <q-btn icon="view_quilt" :class="{ active: isRouteActive('/dashboard') }" to="/dashboard" />
              <q-btn icon="list" :class="{ active: isRouteActive('/list') }" to="/list" />
              <q-btn icon="sticky_note_2" :class="{ active: isRouteActive('/notes') }" to="/notes" />
            </div>
            <SafeboxAccessIndicator style="grid-column-start: 3; justify-self: flex-end" />
          </div>
          <router-view />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import ErrorDialog from '@/components/ErrorDialog.vue'
import PageHeader from '@/components/PageHeader.vue'
import SafeboxAccessIndicator from '@/components/SafeboxAccessIndicator.vue'
import SafeboxSelector from '@/components/SafeboxSelector.vue'
import SessionExpireDialog from '@/components/SessionExpireDialog.vue'
import SkipLink from '@/components/SkipLink.vue'
import UpdateRequiredDialog from '@/components/UpdateRequiredDialog.vue'
import SafeboxSelectionView from '@/views/SafeboxSelectionView.vue'
import SidebarView from '@/views/SidebarView.vue'

@Component({
  components: {
    SafeboxAccessIndicator,
    SafeboxSelector,
    SkipLink,
    UpdateRequiredDialog,
    SessionExpireDialog,
    SidebarView,
    PageHeader,
    ErrorDialog,
    SafeboxSelectionView
  }
})
export default class AppLayout extends Vue {
  sidebarCollapsed = window.innerWidth < 1150

  private isRouteActive(route: string): boolean {
    return this.$route.fullPath.startsWith(route)
  }

  get isDrawerRoute() {
    return this.$route.meta?.appDrawer !== false
  }

  get displaySidebar() {
    return !!this.$store.user
  }
}
</script>

<style scoped lang="scss">
.q-page-container {
  padding-top: 0 !important;
}
</style>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

#app-underlay {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

#app-layout {
  position: relative;
  background-color: #f1f2f4;
  overflow: hidden;
  flex-grow: 1;

  .header {
    grid-column: span 2;
  }

  #app-content {
    padding: 2rem;
    grid-row: 2;
    grid-column: 2;
    overflow: scroll;
  }

  #safebox-selection-view {
    flex-grow: 1;
  }
}

#app-main-drawer {
  transition: top 200ms;
  position: absolute;
  top: $header-height;
  left: 0;
  right: 0;
  height: calc(100dvh - $header-height);
  background: white;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  display: flex;
}

.top-navigation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 33px;
  margin-bottom: 2em;
  margin-top: -1em;
}

.top-navigation .q-btn {
  width: 5em;
  background: white;
  border-radius: 0.5em;
  color: darken(#5e7388, 10);

  &.active {
    background-color: #5e7388;
    color: white;
  }
}
</style>

<style lang="scss">
.app-drawer-enter-active,
.app-drawer-leave-active {
  transition: top 200ms;
}

.app-drawer-enter-from,
.app-drawer-leave-to {
  top: 100dvh !important;
}

.app-underlay-leave-active {
  transition-delay: 200ms;
}
</style>
