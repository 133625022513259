/** Sovelluksen absoluuttinen polku domainin juureen nähden. Päättyy aina '/'-merkkiin. Oletuksena pelkkä '/'. */
// @ts-ignore
export const publicPath = import.meta.env.DEV ? import.meta.env.BASE_URL : window.publicPath

// Tuodaan ajonaikainen env-tieto backendistä, sillä import.meta.env on CI:n buildaamissa ympäristöissä aina production
// @ts-ignore
export const env = window.env ?? import.meta.env.MODE

/** Ollaanko tuotantoympäristössä? */
export function isProduction(): boolean {
  return env?.toLowerCase() === 'production'
}

/** REST-API-kutsujen tekeemiseen käytettävä polku domainin juureen nähden. */
export const apiPublicPath = publicPath

/** Tauluoperaatioiden alipolku. */
export const apiPath = 'api'

/** Lang-keksin polku domainin juureen nähden. */
export const langCookiePath = publicPath
export const langCookieName = 'lang'
