import type AppConfig from '@/classes/AppConfig'
import type Page from '@/classes/Page'
import Step, { StepJSON } from '@/classes/Step'
import type Table from '@/classes/Table'

export interface ProcessJSON {
  lastIndexAttrName: string
  currentIndexAttrName: string
  steps: StepJSON[]
}

export default class Process {
  lastIndexAttrName: string
  currentIndexAttrName: string
  steps: Step[]
  table: Table

  constructor(json: ProcessJSON, page: Page, appConfig: AppConfig, table: Table) {
    this.table = table
    this.lastIndexAttrName = json.lastIndexAttrName
    this.currentIndexAttrName = json.currentIndexAttrName
    this.steps = json.steps.map(stepJSON => new Step(stepJSON, page, appConfig, this))
  }
}
