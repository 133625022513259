import dayjs from 'dayjs'
import { Component } from 'vue'

import AccessControl, { AccessControlJSON } from '@/classes/AccessControl'
import AccessType from '@/classes/AccessType'
import Composition from '@/classes/Composition'
import DataType from '@/classes/DataType'
import type Group from '@/classes/Group'
import IntervalValue from '@/classes/IntervalValue'
import LangVersions from '@/classes/LangVersions'
import MultiValue from '@/classes/MultiValue'
import OptionStyle from '@/classes/OptionStyle'
import Predicate, { predicateFromJSON, PredicateJSON } from '@/classes/Predicate'
import type Tab from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'
import Value, { RealValueType, ValueJSON } from '@/classes/Value'

export type RangeType = { min: number | null; max: number | null } | undefined
export type EmbedType = 'image' | 'object' | undefined

export interface ReferenceJSON {
  attribute: string
  table: string
  navigable: boolean
  display?: string
  listDisplayValue: boolean
}

export interface AttributeJSON extends AccessControlJSON {
  name: string
  type: DataType
  key: boolean
  req: boolean
  range: RangeType
  length?: number
  multirow?: boolean
  embed: EmbedType
  mimetypes?: string[]
  interval: boolean
  isFinal: boolean
  initialValue: string
  listingLength?: number
  reference?: ReferenceJSON
  options?: ValueJSON[]
  optionStyle?: OptionStyle
  multiple: boolean
  isCrypted: boolean
  requiredPredicate?: PredicateJSON
  filterAttributes?: string[]
  defaultDependencies?: string[]
  filenameDependency?: string
}

export default class Attribute extends AccessControl {
  name: string
  type: DataType
  key: boolean
  req: boolean
  range: RangeType
  length?: number
  multirow: boolean
  embed: EmbedType
  /** Sallitut tiedostomuodot, jos attribuutti on tiedosto. */
  mimetypes: string[]
  interval: boolean
  /** Muokattavissa, jos monikko on uusi tai arvo on toistaiseksi määrittelemättä. */
  isFinal: boolean
  reference?: { attribute: string; table: string; navigable: boolean; display?: string; listDisplayValue: boolean }
  options?: Value[]
  optionStyle?: OptionStyle
  requiredPredicate?: Predicate
  /** Attribuutin sisäkkäisin ryhmä, määritellän vasta kun ryhmät luodaan. */
  group?: Group
  initialValue?: string
  /** Monivalinta? */
  multiple: boolean
  /** Salataanko tieto frontendissä? */
  isCrypted: boolean
  /** Viimeisin attribuutille haettu oletusarvo */
  defaultValue: Value | null = null
  listingLength: number
  tab: Tab
  inputField!: Component
  filterAttributes?: string[]
  defaultDependencies?: string[]
  /** FilenameGenerator-tuki cryptatuille tiedostoille. */
  filenameDependency?: string

  constructor(json: AttributeJSON, tab: Tab) {
    super(json.permissions)
    this.tab = tab
    this.name = json.name
    this.type = json.type
    this.key = json.key
    this.req = json.req
    this.range = json.range
    this.length = json.length
    this.multirow = json.multirow ?? false
    this.embed = json.embed
    this.mimetypes = json.mimetypes ?? []
    this.reference = json.reference
    this.options = json.options?.map((valueJSON): Value => Value.fromJSON(valueJSON, this))
    this.optionStyle = json.optionStyle
    this.requiredPredicate = json.requiredPredicate ? predicateFromJSON(json.requiredPredicate) : undefined
    this.interval = json.interval
    this.filterAttributes = json.filterAttributes
    this.defaultDependencies = json.defaultDependencies
    this.filenameDependency = json.filenameDependency
    this.isFinal = json.isFinal
    this.initialValue = json.initialValue
    this.listingLength = json.listingLength ?? 0
    this.multiple = json.multiple ?? false
    this.isCrypted = json.isCrypted
  }

  /**
   * Palauttaa annetuna valuen realValueta vastaavan option-listauksen Valuen.
   */
  getOption(value?: Value): Value | undefined {
    if (!value) {
      return
    }
    return this.options?.find((opt): boolean => opt.v === value.v)
  }

  /**
   * Ryhmän id:nä käytettävä merkkijono, jos attribuutti on osa eksplisiittisesti määriteltyä ryhmää.
   * Muutoin attribuutin itsensä id-merkkijono.
   */
  getContainerIdString(): string {
    if (this.group !== this.tab.rootGroup) {
      return this.group!.getIdString()
    } else {
      return this.getIdString()
    }
  }

  /**
   * Palauttaa attribuutin id:nä käytettävän merkkijonon.
   */
  getIdString(): string {
    return `attr-${this.tab.name}-${this.name}`
  }

  /** Attribuutin otsikko. */
  getLabel(): string {
    return this.tab.t(`Attrs.${this.name}`)
  }

  /** Attribuutin arvon yksikkö kieliresursseista tai tyhjä merkkijono. */
  getUnit(): string {
    return this.tab.t(`Attrs.${this.name}_unit`, '')
  }

  /** Ryhmän otsikko, jos tämä attribuutti kuuluu kompositioon tai on kieliversioitu, muuten attribuutin oma otsikko. */
  getContainerLabel(): string {
    return this.group instanceof Composition || this.group instanceof LangVersions ? this.group.getLabel() : this.getLabel()
  }

  /** Attribuutin vinkkiteksti _hint-avaimesta tai undefined. Hint-teksti on aina näkyvissä kentän alapuolella. */
  getHint(): string | undefined {
    return this.tab.t(`Attrs.${this.name}_hint`, '')
  }

  /** Attribuutin vinkkiteksti _tip-avaimesta tai undefined. Tip-teksti näytetään hoveroimalla help-kuvaketta kentän oikeassa reunassa. */
  getTip(): string | undefined {
    return this.tab.t(`Attrs.${this.name}_tip`, '')
  }

  /**
   * Viittaako tämä attribuutti isätauluunsa?
   */
  isParentReference(): boolean {
    if (!this.reference) {
      return false
    }
    return this.reference?.table === this.tab.parentTable?.name
  }

  /** True, jos attribuutti on annetun ryhmän ensimmäinen attribuutti */
  isFirstOf(group?: Group): boolean {
    return this === group?.attributes[0]
  }

  /** Attribuuttia ei huomioida iteraatioissa, jos se kuuluu kompositioon muttei ole sen ensimmäinen attribuutti. */
  isIterable(): boolean {
    return !(this.group instanceof Composition) || this.isFirstOf(this.group)
  }

  /** Resetoi arvon tuplessa, jos näkyvyys-/muokattavuusehdot eivät täyty. */
  checkPermission(tuple: Tuple): void {
    if (
      (!this.hasPermission(AccessType.VIEW, tuple) && this.isResetValueAfterPredicateCheck(AccessType.VIEW)) ||
      (!this.hasPermission(AccessType.EDIT, tuple) && this.isResetValueAfterPredicateCheck(AccessType.EDIT))
    ) {
      tuple.resetValue(this)
    } else {
      tuple.preselect(this)
    }
  }

  /** Näytetäänkö attribuutti käyttöliittymällä? */
  isVisible(accessType: AccessType, isEditor: boolean, tuple?: Tuple, showParent?: boolean): boolean {
    if (!showParent && this.isParentReference()) {
      return false
    }
    // Näytetään langVersions-kenttä vain ensimmäisen jäsenen kohdalla. Editorissa näytetään kentät erillisinä.
    if (!isEditor && this.group instanceof LangVersions && Object.values(this.group.attributes)[0] !== this) {
      return false
    }

    // Muuten pyydetyn oikeuden mukaisesti
    return this.hasPermission(accessType, tuple)
  }

  isRequired(tuple?: Tuple | null): boolean {
    if (!tuple || !this.requiredPredicate) {
      return this.req
    } else {
      return this.req || this.requiredPredicate.matches(tuple)
    }
  }

  get currentValue(): RealValueType {
    switch (this.type) {
      case DataType.DATETIME:
      case DataType.DATE:
        return dayjs()
      case DataType.TIME:
        return dayjs().format('HH:mm')
      case DataType.YEAR:
        return dayjs().year()
    }
    return null
  }

  createInitialValue(): Value {
    if (this.initialValue === 'current') {
      return new Value(this, this.currentValue)
    }
    if (this.interval) {
      return new IntervalValue(this, new Value(this), new Value(this))
    }
    // Pakollisilla boolean-kentillä oletusarvo on tyypin mukainen false
    if (this.req && (this.optionStyle === OptionStyle.CHECKBOX || this.optionStyle === OptionStyle.TOGGLE)) {
      return new Value(this, this.type === DataType.INTEGER ? 0 : false)
    }
    return new Value(this, this.parseRealValue(this.initialValue ?? null))
  }

  /**
   * Muodostaa Valuen string-muotoisesta URLissa olleesta arvosta, jossa /-merkit on escapettu.
   */
  parseUrlValue(stringValue: string | null, isAdvancedSearch = false): Value {
    if (this.interval) {
      const split = stringValue?.split('/')
      const lower = split && split.length > 0 && split[0].length > 0 ? this.parseSingleStringValue(split[0]) : new Value(this)
      const upper = split && split.length > 1 && split[1].length > 0 ? this.parseSingleStringValue(split[1]) : new Value(this)
      return new IntervalValue(this, lower, upper)
    } else if (this.multiple || (isAdvancedSearch && (this.reference || this.options || this.type === DataType.STRING))) {
      // Palautetaan MultiValue myös joissain !this.multiple -tapauksissa, jotta tarkennetun haun parametrit saadaan oikeassa muodossa.
      const values = stringValue == null ? [] : this.splitMultivalueString(stringValue).map(v => this.parseSingleStringValue(v))
      return new MultiValue(this, values)
    }
    return this.parseSingleStringValue(stringValue)
  }

  parseSingleStringValue(stringValue: string | null): Value {
    return new Value(this, this.parseRealValue(this.unescapeString(stringValue)))
  }

  private unescapeString(val: string | null): string | null {
    return val?.replace('\\/', '/').replace('\\\\', '\\') ?? null
  }

  /** Palastelee multivaluen sisältävän stringin, arrayksi stringejä. Korvaa lookbehind-regexillä, kunhan Safari tukee niitä. */
  private splitMultivalueString(stringValue: string): string[] {
    const values: string[] = []
    let prevChar = ''
    let prevStartPos = 0
    for (let i = 0; i < stringValue.length; i++) {
      const c = stringValue[i]
      if (prevChar !== '\\' && c === '/') {
        values.push(stringValue.substring(prevStartPos, i))
        prevStartPos = i + 1
      }
      prevChar = c
    }
    values.push(stringValue.substring(prevStartPos))
    return values
  }

  /**
   * Muodostaa RealValuen string-muotoisesta arvosta.
   */
  parseRealValue(stringValue: string | null): RealValueType {
    let val: RealValueType = stringValue

    if (stringValue !== null) {
      switch (this.type) {
        case DataType.DATE:
        case DataType.DATETIME:
          val = dayjs(stringValue)
          break
        case DataType.INTEGER:
        case DataType.YEAR:
          val = parseInt(stringValue)
          break
        case DataType.BOOLEAN:
          val = stringValue === 'true'
          break
        case DataType.LONG:
          val = BigInt(stringValue)
      }
    }
    return val
  }

  /** Workaround dependency cyclen kiertämiselle, sillä Valuesta ei voida importoida sen subclassia. */
  createMultiValue(values: Value[]): MultiValue {
    return new MultiValue(this, values)
  }

  /** Debuggausta varten */
  toString(): string {
    return this.name
  }
}
