import { isArray } from 'lodash-es'
import { toRaw } from 'vue'

import type Action from '@/classes/Action'
import type { ActionResponseJSON } from '@/classes/Action'
import type Attribute from '@/classes/Attribute'
import TechnicalError from '@/classes/errors/TechnicalError'
import type Job from '@/classes/Job'
import type ReferencesJSON from '@/classes/ReferencesJSON'
import type Report from '@/classes/Report'
import Tab from '@/classes/Tab'
import type Table from '@/classes/Table'
import Tuple, { CRYPT_IV_ATTR_NAME, TupleJSON } from '@/classes/Tuple'
import User, { LogoutJSON, UserJSON } from '@/classes/User'
import Value, { ValueJSON } from '@/classes/Value'
import { getTabUrl as customGetTabUrl } from '@/utils/api-functions'
import { apiFetch } from '@/utils/api-functions-base'
import { createIV, decryptBufferToBuffer, encryptBufferToBuffer } from '@/utils/crypt'
import { firstString, getKey, getTabName } from '@/utils/helpers'
import Imports from '@/utils/Imports'
import { apiPath } from '@/utils/paths'

/**
 * Palvelimelta {@link apiFetch}-kutsulla haettu <T>-rajapintaobjekti tai No Content -tapauksessa tyhjä objekti.
 * @param url Kohdeosoite
 * @param options Ylimääräiset fetch-optiot
 * @param timeoutSeconds Jos määrittelemättä, käytetään oletusaikakatkaisua.
 */
export function apiFetchJSON<T>(url: string, options?: RequestInit, timeoutSeconds?: number): Promise<T> {
  const headers = new Headers()
  headers.set('Content-Type', 'application/json; charset=UTF-8')
  return apiFetch(url, options, headers, timeoutSeconds).then(r => {
    return r.status === 204 ? <T>{} : (r.json() as Promise<T>)
  })
}

/**
 * Hakee yksittäisen tuplen.
 */
export function fetchTuple(table: Table, key: string): Promise<Tuple> {
  return apiFetchJSON<TupleJSON>(customGetTabUrl(table, key)).then(tupleJSON => Tuple.fromJSON(tupleJSON, table))
}

/** Hakee monikkoja annetuilla kriteereillä. Jos hakua ei sallita, rejectiin virhekuvaukset sisältävä hakumonikko. */
export function fetchTuples(table: Table, searchTuple?: Tuple): Promise<Tuple[] | Tuple> {
  let url = customGetTabUrl(table)
  if (searchTuple) {
    if (toRaw(searchTuple.tab) === toRaw(table.search)) {
      url += '_search'
    }
    url += '?' + createQueryFromTuple(searchTuple)
  }
  return apiFetchJSON<TupleJSON[] | TupleJSON>(url).then(tupleJSONs => {
    if (Array.isArray(tupleJSONs)) {
      return Promise.all(tupleJSONs.map(json => Tuple.fromJSON(json, table, searchTuple?.parent)))
    } else {
      return Tuple.fromJSON(<TupleJSON>tupleJSONs, table.search!, searchTuple?.parent).then(tuple => tuple)
    }
  })
}

/** Vie listauksen valitut rivit export-toiminnolla tiedostoon. */
export function exportSelected(table: Table, tuples: Tuple[]): void {
  const url = customGetTabUrl(table, undefined, 'export')
  const body = JSON.stringify({ tuples: tuples.map(tuple => tuple.getKeyString()) })
  apiFetch(url, { method: 'POST', body }).then(loadExport)
}

/** Hakuehtojen mukainen export-kysely. Jos response.status ok, voidaan viedä tiedostoon esim. {@link loadExport}:lla */
export function exportSearch(table: Table, searchTuple?: Tuple): Promise<Response> {
  let url = customGetTabUrl(table, undefined, 'export')
  if (searchTuple && searchTuple.tab === table.search) {
    url += '?' + createQueryFromTuple(searchTuple)
  }
  return apiFetch(url)
}

/** Exportin sisältävän api-vastauksen lataus tiedostoon. Ks. {@link exportSearch}, {@link exportSelected} */
export function loadExport(response: Response) {
  const filename = parseFilename(response) || 'export'
  response.blob().then(blob => downloadFile(blob, filename))
}

/** Hakee parent-monikon refValuen yksilöimän lapsimonikon. */
export function fetchChild(parent: Tuple, refValue: Value): Promise<Tuple> {
  const refTable = Tab.getTab(refValue.attribute.reference!.table) as Table
  const parentPrefix = parent.hasKey() ? `${parent.tab.name}/${parent.getKeyString()}/` : ''
  return apiFetchJSON<TupleJSON>(`${apiPath}/${parentPrefix}${refTable.name}/${refValue.toString()}`).then(tupleJSON =>
    Tuple.fromJSON(tupleJSON, refTable, parent)
  )
}

/**
 * Hakee attribuutin viittaaman listauksen.
 */
export function fetchReferences(attr: Attribute, tuple: Tuple | null, searchFilter: string, page: number): Promise<ReferencesJSON> {
  let filterString = ''
  if (tuple) {
    const filterAttributes = Object.values(tuple.tab.attributes).filter(a => a.filterAttributes?.includes(attr.name) || a.key)
    const filterValues = filterAttributes.map(a => tuple.values[a.name]).filter(value => value?.hasContent())
    filterString = filterValues.map(value => `${value.attribute.name}=${value.valueToString()}`).join('&')
  }
  if (filterString.length > 0) {
    filterString = `&${filterString}`
  }
  const tabUrl = customGetTabUrl(attr.tab, tuple?.hasKey() ? tuple?.getKeyString() : undefined)
  return apiFetchJSON<ReferencesJSON>(`${tabUrl}/ref?attr=${attr.name}&search-filter=${searchFilter}&page=${page}${filterString}`)
}

/**
 * Hakee valuen displayValuen.
 */
export function fetchDisplayValue(value: Value): Promise<ValueJSON> {
  return apiFetchJSON<ValueJSON>(`resolve?t=${value.attribute.tab.name}&attr=${value.attribute.name}&value=${value.valueToString()}&lang=${Imports.store.lang}`)
}

/** Tallentaa uuden ja palauttaa sen päivitetyt tiedot. */
export function insertTuple(tuple: Tuple): Promise<Tuple> {
  // Backendin upsert-logiikka riippuu originalKey-propertystä, nollataan se insertiin osumiseksi
  const origTuple = tuple.origTuple
  tuple.origTuple = undefined

  return tuple.toJSON().then(tupleJSON => {
    tuple.origTuple = origTuple
    return apiFetchJSON<TupleJSON>(customGetTabUrl(tuple.tab), {
      method: 'POST',
      body: JSON.stringify(tupleJSON)
    }).then(tupleJSON => Tuple.fromJSON(tupleJSON, tuple.tab, tuple.parent))
  })
}

/** Luo uuden monikon alkuarvoineen ja palauttaa tiedot. [initial] sisältää mahdolliset käyttäjäperäiset alkuarvot. */
export function newTuple(initial: Tuple): Promise<Tuple> {
  return initial.toJSON().then(json => {
    return apiFetchJSON<TupleJSON>(customGetTabUrl(initial.tab, undefined, 'new'), {
      method: 'POST',
      body: JSON.stringify(json)
    }).then(tupleJSON => Tuple.fromJSON(tupleJSON, initial.tab, initial.parent))
  })
}

/**
 * Päivittää yksittäisen tuplen ja palauttaa ajantasaisen tuplen.
 */
export function updateTuple(tuple: Tuple, origKey: string): Promise<Tuple> {
  return tuple.toJSON().then(tupleJSON => {
    return apiFetchJSON<TupleJSON>(customGetTabUrl(tuple.tab, origKey), {
      method: 'PUT',
      body: JSON.stringify(tupleJSON)
    }).then(tupleJSON => Tuple.fromJSON(tupleJSON, tuple.tab, tuple.parent))
  })
}

/**
 * Muodostaa urlin query-osan annetun tuplen valueiden perusteeella.
 */
export function createQueryFromTuple(tuple: Tuple): string {
  return Object.entries(tuple.toStringObject())
    .reduce<string[]>((params, [key, val]) => {
      for (const singleVal of isArray(val) ? val : [val]) {
        params.push(`${key}=${encodeURIComponent(singleVal)}`)
      }
      return params
    }, [])
    .join('&')
}

/**
 * Hakee tiedoston
 */
export function fetchFile(tuple: Tuple, attr: Attribute): Promise<Blob> {
  return apiFetch(customGetTabUrl(tuple.tab, tuple.getKeyString(), `${attr.name}/file`), {
    method: 'GET'
  }).then(response => {
    return response.blob()
  })
}

/**
 * Hakee tiedoston ja purkaa sen salauksen, mikäli se on salattu.
 */
export async function fetchAndDecryptFile(tuple: Tuple, attr: Attribute): Promise<Blob> {
  const blob = await fetchFile(tuple, attr)

  if (attr.isCrypted) {
    const cryptIv = tuple?.values[CRYPT_IV_ATTR_NAME]?.valueToString()
    const decryptKey = await Imports.store.user?.getEncryptKey(tuple)
    if (!decryptKey) {
      throw new TechnicalError('Kryptausavainta ei ole määritelty. Ylikirjoita getEncryptKey() User.ts:ssä.')
    }

    return new Blob([await decryptBufferToBuffer(await blob.arrayBuffer(), cryptIv, decryptKey)])
  }

  return blob
}

/**
 * Hakee tiedoston ladattavaksi
 */
export async function fetchFileForDownload(tuple: Tuple, attr: Attribute): Promise<void> {
  const fileValue = Object.values(tuple.values).find(value => {
    return value.attribute === attr
  }) as Value
  const filename = (<File>fileValue.v).name
  const blob = await fetchFile(tuple, attr)
  if (attr.isCrypted) {
    const cryptIv = tuple?.values[CRYPT_IV_ATTR_NAME]?.valueToString()
    const decryptKey = await Imports.store.user?.getEncryptKey(tuple)
    if (!decryptKey) {
      throw new TechnicalError('Kryptausavainta ei ole määritelty. Ylikirjoita getEncryptKey() User.ts:ssä.')
    }
    return downloadFile(new Blob([await decryptBufferToBuffer(await blob.arrayBuffer(), cryptIv, decryptKey)]), filename)
  } else return downloadFile(blob, filename)
}

/** Syöttää annetun Blobin käyttäjälle nimettynä tiedostolatauksena */
export function downloadFile(blob: Blob, filename: string): void {
  // msSaveOrOpenBlob-erikoiskäsittely IE11:tä-varten
  // @ts-ignore
  if (navigator.msSaveOrOpenBlob) {
    // @ts-ignore
    navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
}

/** Jäsentää filename-osan responsen Content-Disposition-otsikosta */
export function parseFilename(response: Response): string | undefined {
  return response.headers.get('Content-Disposition')?.match(/filename="(.+)"/)?.[1]
}

export interface UploadResponseJSON {
  path: string
}

/** Välivarastoi tiedoston ja palauttaa vastauksen, jossa väliaikaistiedoston nimi */
export async function uploadFile(file: File, attribute?: Attribute, tuple?: Tuple | null): Promise<UploadResponseJSON> {
  let newFile = file
  if (attribute?.isCrypted && tuple) {
    const buffer = await file.arrayBuffer()
    const cryptIv = (tuple?.values[CRYPT_IV_ATTR_NAME]?.v as string) ?? createIV()
    tuple.setValue(CRYPT_IV_ATTR_NAME, cryptIv)
    const encryptKey = await Imports.store.user?.getEncryptKey(tuple)
    if (!encryptKey) {
      throw new TechnicalError('Kryptausavainta ei ole määritelty. Ylikirjoita getEncryptKey() User.ts:ssä.')
    }
    newFile = new File([await encryptBufferToBuffer(buffer, cryptIv, encryptKey)], '(crypted)', { type: file.type })
    // Jos tiedoston nimi säilötään FilenameGeneratorilla  toiseen attribuuttiin, laitetaan se talteen tässä
    Object.values(tuple?.tab.attributes)
      .filter(a => a.filenameDependency === attribute.name)
      .forEach(a => {
        tuple.setValue(a.name, file.name)
      })
  }
  const data = new FormData()
  data.append('file', newFile)
  const resp = await apiFetch(`${apiPath}/upload`, { method: 'post', body: data }, undefined, 0)
  return await resp.json()
}

/**
 * Poistaa annetun tuplen.
 */
export function deleteTuple(tuple: Tuple): Promise<Response> {
  return apiFetch(customGetTabUrl(tuple.tab, tuple.getKeyString()), { method: 'DELETE' })
}

/**
 * Poistaa listan monikkoja.
 */
export function deleteTuples(tuples: Tuple[]): Promise<Response> {
  const keys = tuples.map((tuple: Tuple) => tuple.getKeyString())
  return apiFetch(`${apiPath}/${tuples[0].tab.name}`, { method: 'DELETE', body: JSON.stringify(keys) })
}

/**
 * Suorittaa actionin backendissa.
 */
export function executeAction(action: Action, tuples: Tuple[] | undefined, parameters?: Tuple): Promise<ActionResponseJSON> {
  return (parameters ? parameters.toJSON() : Promise.resolve(undefined)).then(async tupleJSON => {
    const body = JSON.stringify({
      parameters: tupleJSON,
      tuples: tuples ? await Promise.all(tuples?.map(tuple => tuple.toJSON())) : undefined
    })
    return apiFetchJSON<ActionResponseJSON>(customGetTabUrl(action), { method: 'POST', body })
  })
}

/**
 * Suorittaa actionin backendissa ja ojentaa paluuviestissä saatavan tiedoston käyttäjälle.
 */
export function executeActionDownload(action: Action, tuples: Tuple[] | undefined, parameters?: Tuple): Promise<void> {
  return (parameters ? parameters.toJSON() : Promise.resolve(undefined)).then(async tupleJSON => {
    const body = JSON.stringify({
      parameters: tupleJSON,
      tuples: tuples ? await Promise.all(tuples?.map(tuple => tuple.toJSON())) : undefined
    })
    let filename: string
    return apiFetch(customGetTabUrl(action), { method: 'POST', body }, acceptAny())
      .then(response => {
        filename = parseFilename(response) ?? ''
        return response.blob()
      })
      .then(blob => downloadFile(blob, filename))
  })
}

/**
 * Suorittaa eräajon backendissa.
 */
export function executeJob(job: Job, tuple: Tuple): Promise<Response> {
  return tuple.toJSON().then(tupleJSON => {
    const body = JSON.stringify(tupleJSON)
    const headers = acceptAny()
    headers.set('Connection', 'keep-alive')
    return apiFetch(customGetTabUrl(job), { method: 'POST', body }, headers)
  })
}

/** Luo raportin backendissa. */
export function generateReport(report: Report, format: string, tuples: Tuple[], parameters?: Tuple): Promise<Response> {
  return (parameters ? parameters.toJSON() : Promise.resolve(undefined)).then(async tupleJSON => {
    const body = JSON.stringify({
      parameters: tupleJSON,
      tuples: tuples ? await Promise.all(tuples?.map(tuple => tuple.toJSON())) : undefined,
      format
    })
    return apiFetch(customGetTabUrl(report), { method: 'POST', body }, acceptAny())
  })
}

/** Generoi annetun monikon ekspansion */
export function expandTuple(tuple: Tuple, format?: string): Promise<Response> {
  const body = format ? JSON.stringify({ format }) : ''
  return apiFetch(customGetTabUrl(tuple.tab, tuple.getKeyString(), 'expand'), { method: 'POST', body })
}

/** Hakee annetun monikon attribuutille palvelimelta oletusarvon. Undefined -> arvo ei muutu. */
export function fetchDefaultValue(tuple: Tuple, attribute: Attribute): Promise<Value | undefined> {
  return tuple.toJSON().then(tupleJSON => {
    const body = JSON.stringify(tupleJSON)
    return apiFetchJSON<ValueJSON>(customGetTabUrl(tuple.tab, attribute.name, 'default'), { method: 'POST', body }).then(json =>
      // Jos paluuarvo on tyhjä JSONObject, ei oletusarvoa aseteta (eri asia kuin null-oletusarvo)
      Object.keys(json).length === 0 ? undefined : Value.fromJSON(json, attribute)
    )
  })
}

/** Yrittää tunnistautua backendiin annetuilla tunnuksilla. Palauttaa joko luodun User-olion, tai rejectaa promisen tunnistautumisen epäonnistuessa. */
export function login(username: string, password: string): Promise<User> {
  return apiFetchJSON<UserJSON>(`login`, {
    method: 'POST',
    body: JSON.stringify({ username, password })
  }).then(userJSON => {
    Imports.store.$patch({ csrfToken: userJSON.csrfToken })
    return new User(userJSON)
  })
}

/** Tuhoaa käyttäjän session backendissä. */
export function logout(): Promise<void> {
  return apiFetchJSON<LogoutJSON>(`logout`, { method: 'POST' }).then(logoutJSON => {
    Imports.store.$patch({ csrfToken: logoutJSON.csrfToken })
  })
}

/** Palauttaa Tabin API-urlin. */
export function getTabUrl(tab: Tab, key?: string, command?: string): string {
  let url = `${apiPath}/`
  const currentTabName = getTabName()
  if (currentTabName !== undefined) {
    if (tab.name === currentTabName) {
      // Pyydettiin urlin mukaista aktiivista tabia -> lisätään parent-tiedot urlin perusteella
      url += getParentUrl()
    } else if (tab.parentTable?.name === currentTabName) {
      // Pyydettiin urlin mukaisen aktiivisen tabin lasta -> lisätään parent-tiedot urlin perusteella
      // Jos aktiivisen tabin key ei ole tiedossa, käytetään "null":ia, jotta saadaan muodostettua ehjä parent-ketju (tapaus: action listauksessa)
      url += `${getParentUrl()}${currentTabName}/${encodeURIComponent(getKey() ?? 'null')}/`
    }
  }
  url += tab.name
  if (key !== undefined) {
    url += `/${encodeURIComponent(key)}`
  }
  if (command !== undefined) {
    url += `/${command}`
  }
  return url
}

/** Palauttaa aktiivisen urlin parent-osuuden. */
function getParentUrl(): string {
  let url = ''
  for (let i = 1; i <= 10; i++) {
    const currentRoute = Imports.router.currentRoute.value
    const parentTableName = firstString(currentRoute.params['ptable' + i])
    const parentKey = firstString(currentRoute.params['pkey' + i])
    if (parentTableName !== undefined && parentTableName !== '' && parentKey !== undefined && parentKey !== '') {
      url += `${parentTableName}/${encodeURIComponent(parentKey)}/`
    }
  }
  return url
}

/** HTTP-otsikkotietue, jossa asetettu "Accept: \*\/\*" */
function acceptAny(): Headers {
  const headers = new Headers()
  headers.set('Accept', '*/*')
  return headers
}
