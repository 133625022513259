<template>
  <q-dialog ref="dialog" @hide="onHide">
    <q-stepper ref="stepper" v-model="step" @transition="onStepChange">
      <q-step name="upload" :title="$t('SafeboxImportDialog.steps.upload.title')">
        <p>{{ $t('SafeboxImportDialog.steps.upload.message') }}</p>
        <q-btn color="primary" @click="handleUpload">{{ $t('SafeboxImportDialog.steps.upload.uploadButton') }}</q-btn>
      </q-step>
      <q-step name="password" :title="$t('SafeboxImportDialog.steps.password.title')">
        <p>{{ $t('SafeboxImportDialog.steps.password.message') }}</p>
        <q-input
          v-model="password"
          dense
          :label="$t('SafeboxImportDialog.steps.password.passwordLabel')"
          :error="passwordIsInvalid"
          :error-message="$t('SafeboxImportDialog.steps.password.passwordError')"
        />
        <q-btn color="primary" @click="checkPassword">{{ $t('SafeboxImportDialog.steps.password.openButton') }}</q-btn>
      </q-step>
      <q-step name="processing" :title="$t('SafeboxImportDialog.steps.processing.title')">
        <div style="text-align: center">
          <q-spinner size="xl" color="primary" />
          <div class="q-mt-md">{{ status }}</div>
        </div>
      </q-step>
      <q-step name="done" :title="$t('SafeboxImportDialog.steps.done.title')">
        <p>{{ $t('SafeboxImportDialog.steps.done.message') }}</p>
        <q-table :rows="summaryData" :columns="summaryColumns" row-key="id" hide-bottom />
        <q-btn color="primary" class="q-mt-lg" @click="onHide">{{ $t('SafeboxImportDialog.steps.done.closeButton') }}</q-btn>
      </q-step>
    </q-stepper>
  </q-dialog>
</template>

<script lang="ts">
import { QDialog, QStepper, QTable } from 'quasar'
import { Component, Emit, Vue } from 'vue-facing-decorator'

import { ARCHIVE_CATALOG_PATH } from '@/classes/exporter'
import { ArchiveReader, Importer, NameConflictResolution } from '@/classes/importer'
import { SevenZipReader } from '@/classes/importer/SevenZipReader'
import { Notification, NotificationLevel } from '@/classes/Notification'
import Tuple from '@/classes/Tuple'
import SafeboxImportConflictResolutionDialog from '@/components/SafeboxImportConflictResolutionDialog.vue'
import Imports from '@/utils/Imports'

@Component({})
export default class SafeboxImportDialog extends Vue {
  private step = 'upload'
  private safeboxes: Tuple[] = []
  private password = ''
  private passwordIsInvalid = false
  private file?: File
  private reader?: ArchiveReader
  private importer = new Importer()
  private imported: Tuple[] = []
  private status: string = 'Valmistellaan...'

  private summaryColumns: QTable['columns'] = [
    {
      name: 'safebox',
      label: 'Tietolokero',
      field: 'name'
    },
    {
      name: 'services',
      label: 'Tietokortit',
      field: 'services'
    },
    {
      name: 'notes',
      label: 'Muistilaput',
      field: 'notes'
    },
    {
      name: 'attachments',
      label: 'Liitteet',
      field: 'attachments'
    }
  ]

  get summaryData() {
    if (!this.imported) {
      return []
    }

    return this.imported.map(tuple => {
      return {
        id: tuple.values.id.v,
        name: tuple.values.name.getDisplayValue(),
        services: tuple.children.service?.length ?? 0,
        notes: tuple.children.note?.length ?? 0,
        attachments: (tuple.children.service ?? []).flatMap(service => service.children.service_attachment ?? []).length
      }
    })
  }

  created() {
    this.importer.onNameConflict = this.showNameConflictDialog.bind(this)
    this.importer.on('status', status => (this.status = status))
  }

  async checkPassword() {
    if (!this.reader) {
      throw new Error('Reader is not initialized!')
    }

    try {
      this.reader.setPassword(this.password)
      await this.reader.read(ARCHIVE_CATALOG_PATH)
      this.notify(new Notification(NotificationLevel.SUCCESS, 'Paketti avattu onnistuneesti.'))
      this.passwordIsInvalid = false
      ;(this.$refs.stepper as QStepper).goTo('processing')
    } catch (err) {
      console.log(err)
      this.passwordIsInvalid = true
    }
  }

  async readFile(): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      const el = document.createElement('input')
      el.type = 'file'

      el.addEventListener('change', () => {
        const file = el.files?.[0]

        if (file) {
          resolve(file)
        } else {
          reject(new Error('No file selected'))
        }
      })

      el.click()
    })
  }

  async showNameConflictDialog(safebox: Tuple) {
    return new Promise<NameConflictResolution>(resolve => {
      const dialog = this.$q.dialog({
        component: SafeboxImportConflictResolutionDialog,
        componentProps: {
          name: safebox.values.name.getDisplayValue()
        },
        persistent: true
      })

      dialog.onCancel(() => resolve({ resolution: 'skip' }))
      dialog.onOk(resolve)
    })
  }

  private async handleUpload() {
    const file = await this.readFile()
    this.reader = new SevenZipReader(file)

    const stat = await this.reader.stat(ARCHIVE_CATALOG_PATH)

    if (!stat) {
      this.notify(new Notification(NotificationLevel.ERROR, this.$t('SafeboxImportDialog.archiveIsNotDigua')))

      return
    }

    if (stat.encrypted) {
      ;(this.$refs.stepper as QStepper).goTo('password')
    } else {
      ;(this.$refs.stepper as QStepper).goTo('processing')
    }
  }

  private onStepChange(step: string) {
    if (step === 'processing') {
      this.executeImport()
    }
  }

  private async executeImport() {
    this.imported = await this.importer.importFrom(this.reader!)
    Imports.appStore.refreshSafeboxes()
    ;(this.$refs.stepper as QStepper).goTo('done')
  }

  public show() {
    ;(this.$refs.dialog as QDialog).show()
  }

  public hide() {
    ;(this.$refs.dialog as QDialog).hide()
  }

  @Emit('hide')
  private onHide() {}
}
</script>

<style scoped></style>
