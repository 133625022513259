<template>
  <q-btn id="k-skiplink" type="a" href="#k-app-content" color="primary" :label="$t('SkipLink.label')" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export class SkipLink extends Vue {}
export default SkipLink
</script>

<style lang="scss">
#k-skiplink {
  z-index: 1001;
  position: absolute;
  transform: translateY(-110%);
  transition: transform 0.3s;
  &:focus {
    transform: translateY(0%);
  }
}
</style>
