import { RouteLocationRaw } from 'vue-router'

import AccessType from '@/classes/AccessType'
import { ActionContext } from '@/classes/ActionContext'
import type AppConfig from '@/classes/AppConfig'
import type Page from '@/classes/Page'
import Tab, { TabJSON } from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'
import type { TupleJSON } from '@/classes/Tuple'

export interface ActionJSON extends TabJSON {
  contexts?: (keyof typeof ActionContext)[]
  icon?: string
  download: boolean
}

/**
 * Backendin paluuviesti toimintoa suoritettaessa.
 */
export interface ActionResponseJSON {
  /** Routeriin pushattava URL */
  redirect?: string
  /** Haetaanko toiminnon isämonikon sisältö uudelleen? TUPLE_VIEW/TUPLE_EDIT/LISTING-tilanteille, joilla ei ole erillistä toimintonäkymää. */
  reload?: boolean
  /** Suljetaanko ActionDialog? */
  closeDialog?: boolean
  /** Parameters-monikko validointivirheiden kanssa. */
  parameters?: TupleJSON
}

export default class Action extends Tab {
  contexts: ActionContext[]
  icon?: string
  /** Onko actionin response ladattava tiedosto? */
  download: boolean

  constructor(json: ActionJSON, page: Page, appConfig: AppConfig) {
    super(json, page, appConfig)
    this.contexts = json.contexts?.map(contextName => ActionContext[contextName]) ?? []
    this.icon = json.icon
    this.download = json.download
  }

  /** Onko toiminnolla attribuutteja? */
  hasAttributes(): boolean {
    return Object.keys(this.attributes).length > 0
  }

  /**
   * Palauttaa vue-routerille annettavan reititystiedon tämän toiminnon suoritusnäkymään.
   */
  getRoute(parentTuple?: Tuple): RouteLocationRaw {
    const params: { [key: string]: string } = { tab: this.name }
    if (parentTuple) {
      params.ptable1 = parentTuple?.tab.name
      params.pkey1 = parentTuple?.getKeyString()
    }
    return { name: 'action', params }
  }

  /** Näkyykö toiminto annetussa kontekstissa annetulla parent-monikolla ja access-tyypillä? */
  isVisibleInContext(context: ActionContext, parentTuple?: Tuple, accessType?: AccessType): boolean {
    return (
      (this.contexts.includes(context) ||
        (this.contexts.includes(ActionContext.LISTING_ANY) && (context === ActionContext.LISTING_EMPTY || context === ActionContext.LISTING_NOT_EMPTY))) &&
      this.isVisible(parentTuple, accessType)
    )
  }
}
