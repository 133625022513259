<template>
  <q-form @submit="submit">
    <q-card class="k-login">
      <q-card-section class="text-h5 bg-primary text-white">{{ $t('LoginView.headerText') }}</q-card-section>
      <q-card-section horizontal>
        <q-card-section v-if="useUserPassAuth" class="col">
          <q-input id="username" v-model="username" type="text" autocomplete="username" :label="$t('LoginView.username')" autofocus />
          <q-input id="password" v-model="password" type="password" autocomplete="current-password" :label="$t('LoginView.password')" />
        </q-card-section>
        <q-card-section v-if="useSSOAuth" class="col flex flex-center">
          <SSOLoginButton :url="ssoLoginUrl" />
        </q-card-section>
      </q-card-section>
      <q-card-actions>
        <q-btn type="submit" color="primary" :label="$t('LoginView.loginPrompt')" />
      </q-card-actions>
    </q-card>
  </q-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { ErrorResponseJSON } from '@/classes/errors/BaseError'
import ClientError from '@/classes/errors/ClientError'
import { Notification, NotificationLevel } from '@/classes/Notification'
import User from '@/classes/User'
import SSOLoginButton from '@/components/buttons/SSOLoginButton.vue'
import { login } from '@/utils/api-functions'

@Component({
  components: {
    SSOLoginButton
  }
})
export class LoginView extends Vue {
  username = ''
  password = ''

  get useUserPassAuth(): boolean {
    return this.$store.appConfig?.loginMethod.userpass === true
  }

  get useSSOAuth(): boolean {
    return this.$store.appConfig?.loginMethod.sso === true
  }

  /** Palauttaa URL:n, johon siirrytään SSO-kirjautumisen aloittamiseksi. */
  get ssoLoginUrl(): string {
    return (
      this.$store.appConfig?.loginMethod.ssoLoginUrl +
      (this.$store.loginRedirect ? '?redirect=' + encodeURIComponent(this.$router.resolve(this.$store.loginRedirect).href) : '')
    )
  }

  created(): void {
    if (!this.useUserPassAuth && !this.useSSOAuth) {
      // Sovellus ei tue sisäänkirjautumista; ohjataan juureen
      this.$router.replace('/')
      return
    }
    if (this.useSSOAuth && !this.useUserPassAuth) {
      // Jos käytössä vain SSO-autentikointi, ei näytetä LoginViewiä vaan ohjataan suoraan SSO-kirjautumiseen.
      window.location.assign(this.ssoLoginUrl)
    }
    // Jos SSO-kirjautuminen epäonnistui, näytetään sopiva virhe käyttäjälle.
    if (this.$route.query.loginError === 'unknownIdpFromDs') {
      const dsUrl = this.$store.appConfig!.loginMethod.dsUrl
      this.notify(
        new Notification(
          NotificationLevel.ERROR,
          this.$t('LoginView.loginFailedTitle'),
          this.$t('LoginView.loginFailedUnknownIdpFromDs', { ds: `<a href="${dsUrl}" target="_blank">${dsUrl}</a>`, interpolation: { escapeValue: false } }),
          true,
          { html: true }
        )
      )
    } else if (this.$route.query.loginError) {
      this.notify(
        new Notification(NotificationLevel.ERROR, this.$t('LoginView.loginFailed.title'), this.$t(`LoginView.loginFailed.${this.$route.query.loginError}`))
      )
    }
  }

  submit(event: Event): Promise<User | void> {
    event.preventDefault()
    return login(this.username, this.password)
      .then(user => {
        this.$store.$patch({ user })

        const redirectRoute = this.$store.loginRedirect
        if (redirectRoute !== null) {
          // Siirrytään polkuun jota käyttäjä yritti katsoa ennen kirjautumista
          this.$store.$patch({ loginRedirect: null })
          this.$router.replace(redirectRoute)
        } else {
          this.$router.replace('/')
        }
        return user
      })
      .catch(error => {
        if (!this.$store.lastFetchReturnedError) {
          if (error instanceof ClientError) {
            // Näytetään mm. CSRF-virheen viesti käyttäjälle
            const details = (error?.fetchResponse as ErrorResponseJSON)?.detail ?? this.$t('LoginView.loginFailed.generic')
            this.notify(new Notification(NotificationLevel.WARNING, this.$t('LoginView.loginFailed.title'), details))
            return
          }
          // Jos login-kutsun mukana ei tullut viestiä, näytetään geneerinen kirjautuminen epäonnistui -viesti
          this.notify(new Notification(NotificationLevel.WARNING, this.$t('LoginView.loginFailed.title'), this.$t('LoginView.loginFailed.generic')))
          // Heitetään muut virheet eteenpäin, jotta yleinen virheenkäsittely esittää tapahtuneen virheen käyttäjälle
        } else throw error
      })
  }

  beforeRouteEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
    if (this.$store.user) {
      // Käyttäjä on jo kirjautunut, ohjataan juureen
      next('/')
    } else {
      next()
    }
  }
}
export default LoginView
</script>

<style lang="scss"></style>
