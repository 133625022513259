import type AppConfig from '@/classes/AppConfig'
import type Page from '@/classes/Page'
import type Process from '@/classes/Process'
import Table from '@/classes/Table'

export interface StepContentJSON {
  table: string
  group?: string
}

export interface StepJSON {
  name: string
  contents: StepContentJSON[]
}

export class StepContent {
  tableName: string
  groupName?: string

  constructor(json: StepContentJSON) {
    this.tableName = json.table
    this.groupName = json.group
  }

  getLabel(): string {
    const table = Table.getTable(this.tableName)
    if (this.groupName !== undefined) {
      return table.rootGroup.findGroup(this.groupName)?.getLabel() ?? ''
    }
    return table.getLabel()
  }

  toString(): string {
    return this.tableName + (this.groupName ?? '')
  }
}

export default class Step {
  name: string
  process: Process
  contents: StepContent[]

  constructor(json: StepJSON, page: Page, appConfig: AppConfig, process: Process) {
    this.name = json.name
    this.process = process
    this.contents = json.contents?.map(json => new StepContent(json)) ?? []
  }

  /** Stepin otsikko. */
  getLabel(): string {
    return this.process.table.t(`process.${this.name}`)
  }
}
