<template>
  <ul>
    <SidebarTimelineItem v-for="item in displayItems" :key="item.id" :item="item" :indent="indent" :color="color" />
  </ul>
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'

import { TimelineObject } from '@/components/SidebarTimelineView.vue'
import Imports from '@/utils/Imports'

@Component({
  components: {
    SidebarTimelineItem: defineAsyncComponent(() => import('@/components/SidebarTimelineItem.vue'))
  }
})
export default class SidebarTimelineItemList extends Vue {
  @Prop({ type: String })
  parent?: string

  @Prop({ type: Number, default: 0 })
  indent!: number

  @Prop({ type: String })
  color!: string

  @Prop({ type: Array })
  items?: TimelineObject[]

  get timeline() {
    return Imports.appStore.getTimeline(this.parent).items
  }

  get displayItems() {
    return this.items ?? this.timeline
  }

  async reloadTimeline() {
    if (!this.parent || this.items) {
      return
    }

    await Imports.appStore.fetchTimeline(this.parent)
  }

  created() {
    this.reloadTimeline()
  }
}
</script>

<style scoped lang="scss">
ul {
  padding: 0;
  margin: 0;
}
</style>
