<template>
  <q-dialog v-model="showDialog" no-route-dismiss no-backdrop-dismiss no-esc-dismiss>
    <q-card class="session-expire-dialog">
      <q-card-section class="text-h5">{{ $t('SessionExpireDialog.header') }}</q-card-section>
      <q-card-section>{{ $t('SessionExpireDialog.content') }}</q-card-section>
      <q-card-actions align="center">
        <q-btn v-close-popup flat :label="$t('SessionExpireDialog.continue')" color="primary" @click="continueUsing" />
        <q-btn v-close-popup flat :label="$t('SessionExpireDialog.logout')" color="primary" @click="logout" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Dayjs } from 'dayjs'
import { Component, Vue, Watch } from 'vue-facing-decorator'

import { apiFetch } from '@/utils/api-functions-base'
import { apiPath, publicPath } from '@/utils/paths'

@Component
export class SessionExpireDialog extends Vue {
  showDialog = false

  dialogTimer?: ReturnType<typeof setTimeout>
  logoutTimer?: ReturnType<typeof setTimeout>

  get serverDate(): Dayjs | null {
    return this.$store.serverDate
  }

  get sessionExpires(): Dayjs | null {
    return this.$store.sessionExpires
  }

  @Watch('sessionExpires')
  sessionExpiresWatcher(sessionExpires: Dayjs | null): void {
    if (this.dialogTimer) {
      clearTimeout(this.dialogTimer)
    }
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer)
    }
    if (sessionExpires == null) return

    // Käytetään ensisijaisesti palvelimen palauttamaa aikaa vertailuun, jotta verkkoviiveet eivät vaikuta laskentaan ja jotta käyttäjän koneen kellon vääristymät eivät vääristä tulosta.
    const now = this.serverDate?.toDate().getTime() ?? new Date().getTime()
    const timediff = sessionExpires.toDate().getTime() - now
    // Varoitellaan viisi minuuttia ennen istunnon päättymistä
    const warningTimeSeconds = 5 * 60
    // Automattinen uloskirjautuminen vasta istunnon päättymisen jälkeen, jotta ei epähuomiossa jatketa istuntoa
    const autologoutOvertimeSeconds = 1
    // Näytetään dialog 5min ennen session päättymistä
    this.dialogTimer = setTimeout(
      () => {
        this.showDialog = true
      },
      timediff - warningTimeSeconds * 1000
    )
    this.logoutTimer = setTimeout(
      () => {
        // Ohjataan vain etusivulle logoutin sijaan, jotta ei tapeta mahdollisesti toisessa tabissa jatkuvaa sessiota
        window.location.assign(publicPath)
        this.showDialog = false
      },
      timediff + autologoutOvertimeSeconds * 1000
    )
  }

  continueUsing(): void {
    apiFetch(apiPath + '/refresh-session')
    // Fetchin paluuarvo ei ole kiinnostava, tehdään vain tyhjä pyyntö jotta sessio refreshautuu.
    this.showDialog = false
  }

  logout(): void {
    window.location.assign(publicPath + 'logout')
    this.showDialog = false
  }
}
export default SessionExpireDialog
</script>
