<template>
  <SafeboxIndicator v-if="show" :tooltip="tooltip">
    <q-icon name="fas fa-user-group" />
  </SafeboxIndicator>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import SafeboxIndicator from '@/components/SafeboxIndicator.vue'
import { apiFetchJSON } from '@/utils/api-functions'

@Component({
  components: { SafeboxIndicator }
})
export default class SafeboxIndicators extends Vue {
  @Prop({ type: Object })
  readonly safebox!: Tuple

  access: Tuple[] = []

  @Watch('safebox', { immediate: true })
  async onSafeboxChange(safebox: Tuple) {
    const response = await apiFetchJSON<TupleJSON[]>(`api/safebox/${safebox.getKeyString()}/safebox_access`)
    const tab = Table.getTable('safebox_access')
    this.access = await Promise.all(response.map(tuple => Tuple.fromJSON(tuple, tab)))
  }

  get show() {
    return this.access.length > 1
  }

  get tooltip() {
    return this.$t('SafeboxShareIndicator.tooltip', { count: this.access.length })
  }
}
</script>

<style lang="scss" scoped>
.q-icon {
  margin-top: -0.2em;
}
</style>
