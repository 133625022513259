import type Attribute from '@/classes/Attribute'
import DataType from '@/classes/DataType'
import Value, { ValueJSON } from '@/classes/Value'
import { JSONPrimitive } from '@/types/json'

/** Useita arvoja sisältävä laajennus monivalintaisille parametreille. */
export default class MultiValue extends Value {
  values: Value[]

  constructor(attribute: Attribute, values: Value[]) {
    super(attribute)
    this.values = values
    this.d = this.getDisplayValue()
    this.p = this.values.some(value => value.p != null) ? this.values.map(value => value.p).join(';') : null
  }

  hasContent(): boolean {
    return this.values.some(value => value.hasContent())
  }

  isMultiValue(): boolean {
    return true
  }

  getDisplayValue(): string {
    if (this.attribute.type === DataType.FILE) {
      return this.values.map(value => value.getDisplayValue()).join(';')
    } else {
      return this.values.map(value => value.getDisplayValue()).join('/')
    }
  }

  fetchDisplayValue(): void {
    for (const val of this.values) {
      val.fetchDisplayValue()
    }
  }

  valueToJSON(): JSONPrimitive {
    if (this.attribute.type === DataType.FILE) {
      return this.values.map(value => value.valueToJSON()).join(';')
    } else {
      return this.values.map(value => value.valueToJSON()).join('/')
    }
  }

  valueToString(): string {
    return this.values.map(value => value.valueToString()).join('/')
  }

  valueToUrl(): string {
    return this.values.map(value => value.valueToUrl()).join('/')
  }

  toJSON(): ValueJSON {
    const json = super.toJSON()
    json.values = this.values.map(v => v.toJSON())
    return json
  }
}
