<template>
  <AccountPageLayout :title="$t('AccessManagementView.title')">
    <q-list separator bordered class="rounded-borders" style="background-color: white">
      <q-expansion-item :ref="sectionRef('invite')" switch-toggle-side>
        <template #header>
          <q-item-section style="padding: 0.5em 0">
            <q-item-label>
              <b style="font-weight: 600; margin-right: 1em; display: inline-block; margin-bottom: 0.2em">{{ $t('AccessManagementView.inviteHeader') }}</b>
              <br />
              <span style="font-size: 0.9em">{{ $t('AccessManagementView.inviteSubtitle') }}</span>
            </q-item-label>
          </q-item-section>
        </template>
        <q-card>
          <q-card-section class="drawer-content">
            <InvitationView />
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item :ref="sectionRef('lite-users')" switch-toggle-side>
        <template #header>
          <q-item-section style="padding: 0.5em 0">
            <q-item-label>
              <b style="font-weight: 600; margin-right: 1em; display: inline-block; margin-bottom: 0.2em">{{ $t('AccessManagementView.liteUsersHeader') }}</b>
              <br />
              <span style="font-size: 0.9em">{{ $t('AccessManagementView.liteUsersSubtitle') }}</span>
            </q-item-label>
          </q-item-section>
        </template>
        <q-card>
          <q-card-section class="drawer-content">
            <AccountManagementView />
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item :ref="sectionRef('import-export')" switch-toggle-side>
        <template #header>
          <q-item-section style="padding: 0.5em 0">
            <q-item-label>
              <b style="font-weight: 600; margin-right: 1em; display: inline-block; margin-bottom: 0.2em">{{
                $t('AccessManagementView.importExportHeader')
              }}</b>
              <br />
              <span style="font-size: 0.9em">{{ $t('AccessManagementView.importExportSubtitle') }}</span>
            </q-item-label>
          </q-item-section>
        </template>
        <q-card>
          <ExportView />
        </q-card>
      </q-expansion-item>
      <q-expansion-item switch-toggle-side>
        <template #header>
          <q-item-section style="padding: 0.5em 0">
            <q-item-label>
              <b style="font-weight: 600; margin-right: 1em; display: inline-block; margin-bottom: 0.2em">{{
                $t('AccessManagementView.managePermissionsHeader')
              }}</b>
              <br />
              <span style="font-size: 0.9em">{{ $t('AccessManagementView.managePermissionsSubtitle') }}</span>
            </q-item-label>
          </q-item-section>
        </template>
        <q-card>
          <q-card-section class="drawer-content">{{ $t('AccessManagementView.comingSoon') }}</q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item switch-toggle-side>
        <template #header>
          <q-item-section style="padding: 0.5em 0">
            <q-item-label>
              <b style="font-weight: 600; margin-right: 1em; display: inline-block; margin-bottom: 0.2em">{{ $t('AccessManagementView.manageKeys') }}</b>
              <br />
              <span style="font-size: 0.9em">{{ $t('AccessManagementView.manageKeysSubtitle') }}</span>
            </q-item-label>
          </q-item-section>
        </template>
        <q-card-section class="drawer-content">
          <div>
            <p style="max-width: 70ch">{{ $t('AccessManagementView.keyDescription') }}</p>
            <q-list bordered separator class="q-mb-lg rounded-borders" style="background-color: white">
              <q-item-label header>{{ $t('AccessManagementView.keys') }}</q-item-label>
              <template v-if="securityKeys.length > 0">
                <q-item v-for="secKey in securityKeys" :key="secKey.id">
                  <q-item-section style="max-width: 100px; padding-left: 20px">
                    <img src="../images/cryptokey-icon.svg" alt="cryptkey" style="width: 70px; height: 70px" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ `Yubikey-${secKey.id}` }}</q-item-label>
                  </q-item-section>
                  <q-item-section>
                    <q-expansion-item flat class="secKeyInfoButton" label="Avaimen tiedot">
                      <q-item-label class="secKeyInfo">{{ secKey.authenticator_id }}</q-item-label>
                    </q-expansion-item>
                  </q-item-section>
                  <q-item-section style="display: inline-flex; justify-content: center; align-items: center">
                    <q-btn
                      v-if="secKey.authenticator_id != $store.user!.currentAuthenticatorId"
                      flat
                      :label="$t('AccessManagementView.deleteKey.title')"
                      :disabled="secKey.authenticator_id == $store.user!.currentAuthenticatorId"
                      style="white-space: nowrap; max-width: 15ch; color: #ff0c39"
                      @click="initiateDeletion(secKey)"
                    />
                    <div v-else style="color: #ff0c39">Avainta, jolla olet kirjautuneena ei voi poistaa</div>
                  </q-item-section>
                  <q-dialog v-model="showDeleteConfirm" content-class="k-delete-confirm">
                    <q-card>
                      <q-card-section class="row items-center">
                        <q-avatar icon="delete" color="primary" text-color="white" />
                        <span class="q-ml-sm">{{ $t('AccessManagementView.deleteKey.question') }}</span>
                      </q-card-section>

                      <q-card-actions align="right">
                        <q-btn v-close-popup :label="$t('AccessManagementView.deleteKey.confirm')" color="negative" icon="delete" @click="deleteKey" />
                        <q-btn v-close-popup flat :label="$t('AccessManagementView.deleteKey.cancel')" color="primary" />
                      </q-card-actions>
                    </q-card>
                  </q-dialog>
                </q-item>
              </template>
            </q-list>
            <q-card-section>
              <div class="q-gutter-md col" style="width: fit-content">
                <q-btn color="blue" :label="$t('AccessManagementView.addKey')" style="white-space: nowrap" @click="addKey" />
              </div>
            </q-card-section>
          </div>
        </q-card-section>
      </q-expansion-item>
      <q-expansion-item switch-toggle-side>
        <template #header>
          <q-item-section style="padding: 0.5em 0">
            <q-item-label>
              <b style="font-weight: 600; margin-right: 1em; display: inline-block; margin-bottom: 0.2em">{{ $t('AccessManagementView.deleteAccount') }}</b>
              <br />
              <span style="font-size: 0.9em">{{ $t('AccessManagementView.deleteAccountSubtitle') }}</span>
            </q-item-label>
          </q-item-section>
        </template>
        <q-card>
          <q-card-section class="drawer-content">
            <p>{{ $t('AccessManagementView.deleteAccountDescription') }}</p>
            <q-btn color="red" @click="deleteAccount">{{ $t('AccessManagementView.deleteAccountButton') }}</q-btn>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </AccountPageLayout>
</template>

<script lang="ts">
import { QExpansionItem } from 'quasar'
import { Component, Vue } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import User, { CryptkeyJSON, deleteKey, registerKey, UserJSON } from '@/classes/User'
import AccountDeletionDialog from '@/components/AccountDeletionDialog.vue'
import AccountPageLayout from '@/components/AccountPageLayout.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import { createUserKey, getAesWrappingKeyFromCredential, storeAuthIDToIndexedDB, webAuthnLogin, webauthnRegister } from '@/utils/crypt-utils'
import AccountManagementView from '@/views/AccountManagementView.vue'
import ExportView from '@/views/ExportView.vue'
import InvitationView from '@/views/InvitationView.vue'

@Component({
  name: 'AccessManagementView',
  methods: { webauthnRegister },
  components: { ExportView, AccountManagementView, InvitationView, AccountPageLayout }
})
export default class AccessManagementView extends Vue {
  private showDeleteConfirm = false
  private keyToDelete?: string | undefined
  securityKeys: CryptkeyJSON[] = []

  beforeMount() {
    this.refreshKeys()
  }

  private refreshKeys(): void {
    this.securityKeys = this.$store.user!.cryptkeys
  }

  /** Puretaan käyttäjän avaimet ja ohjataan eteenpäin */
  async afterLogin(userJSON: UserJSON, credential: Credential): Promise<void> {
    this.$store.$patch({ csrfToken: userJSON.csrfToken })
    const user = new User(userJSON, await getAesWrappingKeyFromCredential(credential), credential)
    this.$store.$patch({ user })
    storeAuthIDToIndexedDB(credential.id)
    this.securityKeys = user.cryptkeys
  }

  private async generateKey() {
    const user = this.$store.user
    // Asetetaan tunnus salauslaitteelle
    const credential = await webauthnRegister(user!.attributes.full_name[0] + '-' + user!.cryptkeys.length, user).catch(error => {
      console.error(error)
    })
    // @ts-ignore
    console.debug('WebAuthn registration credential', credential, credential?.getClientExtensionResults())
    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed')))
      return
    }
    // @ts-ignore
    if (!credential?.getClientExtensionResults()?.prf?.enabled && !credential?.getClientExtensionResults()?.largeBlob?.supported) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      return
    }

    return credential
  }

  async addKey(): Promise<void> {
    const createdCredential = await this.generateKey()
    if (!createdCredential) return

    let userCryptKey: CryptoKey | null = null
    let largeBlob: ArrayBuffer | undefined
    // @ts-ignore
    if (!createdCredential.getClientExtensionResults()?.prf?.enabled) {
      userCryptKey = await createUserKey()
      largeBlob = await crypto.subtle.exportKey('raw', userCryptKey)
    }

    const credential = await webAuthnLogin([createdCredential.id], largeBlob).catch(error => {
      console.error(error)
      // Näytetään käyttäjälle geneerinen virheilmoitus seuraavassa ehtolauseessa
    })
    // @ts-ignore
    console.debug('WebAuthn login credential', credential, credential?.getClientExtensionResults())
    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('AccessManagementView.loginFailed.title')))
      return
      // @ts-ignore
    } else if (!credential?.getClientExtensionResults()?.prf?.results?.first && !credential?.getClientExtensionResults()?.largeBlob?.written) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      return
    }
    const aesWrappingKey = userCryptKey ?? (await getAesWrappingKeyFromCredential(credential))

    const safeboxes = Object.entries(this.$store.user!.safeboxCryptKeys).map(([id, key]) => ({ id: parseInt(id, 10), key: key as CryptoKey }))

    await registerKey(credential.id, aesWrappingKey, safeboxes)

    const userJSON = await apiFetchJSON<UserJSON>(`login`, {
      method: 'POST',
      // TODO: Vie credentialista tarvittavat osat backendiin ja tarkista, että käytettiin oikeaa challengea
      body: JSON.stringify({ username: credential.id, password: '' })
    }).catch(error => {
      if (!this.$store.lastFetchReturnedError) {
        // Jos login-kutsun mukana ei tullut viestiä, näytetään geneerinen kirjautuminen epäonnistui -viesti
        this.notify(
          new Notification(NotificationLevel.WARNING, this.$t('AccessManagementView.loginFailed.title'), this.$t('AccessManagementView.loginFailed.generic'))
        )
        // Heitetään muut virheet eteenpäin, jotta yleinen virheenkäsittely esittää tapahtuneen virheen käyttäjälle
      } else throw error
    })
    if (userJSON) {
      await this.afterLogin(userJSON, credential)
      this.notify(new Notification(NotificationLevel.SUCCESS, this.$t('AccessManagementView.keyAdded')))
    }
  }

  /** Apufunktio avaimen poistoa varten, kun dialogin painike saattaa osoittaa väärään alkioon.n */
  private initiateDeletion(secKey: CryptkeyJSON) {
    if (secKey.authenticator_id !== this.$store.user!.currentAuthenticatorId) {
      this.showDeleteConfirm = true
      this.keyToDelete = secKey.authenticator_id
    } else {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('AccessManagementView.deleteKey.forbidden')))
    }
  }

  async deleteKey(): Promise<void> {
    const usedAuth = this.$store.user!.currentAuthenticatorId
    // Ei anneta poistaa kirjautumiseen käytettyä avainta
    if (this.keyToDelete && usedAuth !== this.keyToDelete) {
      await deleteKey(this.keyToDelete, usedAuth!)
        .then(_ => {
          this.$store.user!.cryptkeys = this.$store.user!.cryptkeys.filter((key: CryptkeyJSON) => key.authenticator_id !== this.keyToDelete)
          this.refreshKeys()
          this.keyToDelete = undefined
          this.notify(new Notification(NotificationLevel.SUCCESS, this.$t('AccessManagementView.deleteKey.success')))
        })
        .catch(error => {
          this.notify(new Notification(NotificationLevel.ERROR, this.$t('AccessManagementView.deleteKey.error'), error))
        })
    }
  }

  async deleteAccount() {
    this.$q.dialog({
      component: AccountDeletionDialog
    })
  }

  sectionRefs: Record<string, QExpansionItem> = {}

  sectionRef(name: string) {
    return (el: QExpansionItem) => (this.sectionRefs[name] = el)
  }

  mounted() {
    const hash = document.location.hash.substring(1)

    this.sectionRefs[hash]?.show()
  }
}
</script>

<style scoped lang="scss">
.secKeyInfoButton {
  max-width: 21ch;
  color: #048ed3;
}

.secKeyInfo {
  padding-top: 10px;
  overflow: hidden;
  overflow-wrap: break-word;
  text-align: center;
  max-width: 21ch;
  color: #707070;
}

.drawer-content {
  padding: 1em 2em 2.5em 2em;
}
</style>
