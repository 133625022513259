import { TFunction } from 'i18next'

import type AppConfig from '@/classes/AppConfig'
import TechnicalError from '@/classes/errors/TechnicalError'
import type Page from '@/classes/Page'
import Tab, { TabJSON } from '@/classes/Tab'
import type Table from '@/classes/Table'
import { getString } from '@/utils/i18n'

export interface SearchJSON extends TabJSON {
  redirectSingle: boolean
}

export default class Search extends Tab {
  redirectSingle: boolean
  // parentTable on haulla aina määritelty
  parentTable: Table

  constructor(json: SearchJSON, page: Page, appConfig: AppConfig) {
    super(json, page, appConfig)
    this.redirectSingle = json.redirectSingle

    const parentTab = appConfig.tabs[json.name.substring(0, json.name.length - 7)]
    if (!parentTab.isTable()) {
      throw new TechnicalError(`Haun ${json.name} parent-taulua ei löydy.`)
    }
    this.parentTable = parentTab
  }

  /**
   * Onko tämä Tab Search?
   */
  isSearch(): this is Search {
    return true
  }

  /**
   * Kuten Tab.t, mutta käytetään ensisijaisesti Searchin kieliresursseja.
   */
  t(...args: Parameters<TFunction>): string {
    const key = args.shift()
    const restArgs = args as unknown as DropFirst<Parameters<TFunction>>
    const tabName = this.parentTable.name
    const fullKey = `Tabs.${tabName}.${key}`
    // Lisätään tabikohtainen avain myös listan viimeiseksi, jotta se näkyy käyttöliittymällä avaimen puuttuessa.
    return getString([`Tabs.${tabName}.Search.${key}`, fullKey, `Tab.${key}`, fullKey], ...restArgs)
  }
}
