import type Attribute from '@/classes/Attribute'
import Value from '@/classes/Value'
import { JSONPrimitive } from '@/types/json'

export default class IntervalValue extends Value {
  lower: Value
  upper: Value

  /** Intervalliarvolla ei ole varsinaista arvoa, vain lower ja upper */
  constructor(attribute: Attribute, lower: Value, upper: Value) {
    super(attribute)
    this.attribute = attribute
    this.v = null
    this.lower = lower
    this.upper = upper
  }

  hasContent(): boolean {
    return this.lower.hasContent() || this.upper.hasContent()
  }

  getDisplayValue(): string {
    return this.hasContent() ? `${this.lower.getDisplayValue()}–${this.upper.getDisplayValue()}` : ''
  }

  valueToJSON(): JSONPrimitive {
    return this.valueToString()
  }

  valueToString(): string {
    return `${this.lower.valueToString()}/${this.upper.valueToString()}`
  }

  valueToUrl(): string {
    return this.valueToString()
  }

  equals(other?: Value): boolean {
    if (other == null) {
      return false
    }
    const otherInterval = other as IntervalValue
    return this.lower.equals(otherInterval.lower) && this.upper.equals(otherInterval.upper)
  }
}
