import User from '@/classes/User'
import { base64ToArrayBuffer } from '@/utils/crypt'

/** Luo RSA public keyn private keyn pohjalta. */
export function getPublicKey(privateKey: CryptoKey): Promise<CryptoKey> {
  return crypto.subtle.exportKey('jwk', privateKey).then(jwkPrivateKey => {
    // Poistetaan private keyn tiedot
    delete jwkPrivateKey.d
    delete jwkPrivateKey.dp
    delete jwkPrivateKey.dq
    delete jwkPrivateKey.q
    delete jwkPrivateKey.qi
    // eslint-disable-next-line camelcase
    jwkPrivateKey.key_ops = ['encrypt', 'wrapKey']
    return crypto.subtle.importKey(
      'jwk',
      jwkPrivateKey,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-512'
      },
      true,
      ['encrypt', 'wrapKey']
    )
  })
}

/** Luo käyttäjän avainparin salaamiseen/purkamiseen käytetyn CryptoKeyn annetun stringin perusteella. */
export function createAesWrappingKeyFromString(key: string): Promise<CryptoKey> {
  return crypto.subtle.importKey('raw', new TextEncoder().encode(key), 'PBKDF2', false, ['deriveBits', 'deriveKey']).then(keyMaterial => {
    return window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: new Uint8Array(16).fill(0),
        iterations: 100000,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-GCM', length: 256 },
      true,
      ['wrapKey', 'unwrapKey']
    )
  })
}

// PRF-operaatoissa allekirjoitettava suola. Tämän voisi vaihtaa kovakoodatusta käyttäjäkohtaiseksi ja backendissa generoiduksi.
export const authenticatorFirstSalt = new Uint8Array([
  180, 156, 20, 81, 229, 68, 242, 187, 109, 101, 53, 34, 202, 210, 125, 155, 152, 81, 179, 55, 222, 102, 218, 91, 145, 206, 235, 228, 63, 9, 64, 165
]).buffer

/** Rekisteröi uuden WebAuthn-laitteen. */
export async function webauthnRegister(name: string, user: User | null): Promise<Credential | null> {
  // Ei rekisteröidä jo rekisteröidyillä avaimilla
  const excludeCredentials: PublicKeyCredentialDescriptor[] = []
  if (user) {
    // eslint-disable-next-line array-callback-return
    user.cryptkeys.map(it => {
      if (it.authenticator_id) {
        excludeCredentials.push({
          id: base64ToArrayBuffer(it.authenticator_id),
          type: 'public-key'
        })
      }
    })

    // eslint-disable-next-line array-callback-return
    user.attributes.children_keys.map(it => {
      excludeCredentials.push({
        id: base64ToArrayBuffer(it),
        type: 'public-key'
      })
    })
  }
  return await navigator.credentials.create({
    publicKey: {
      // Satunnaisella challengella on tarkoitus varmistaa, että autentikointilaite on käyttäjän hallussa.
      // Meillä ei kuitenkaan ole mahdollisuutta varmistua tästä, sillä koko salausprosessi tapahtuu selaimessa.
      challenge: new Uint8Array([1, 2, 3, 4]),
      rp: {
        name: 'Digua'
      },
      user: {
        id: new TextEncoder().encode(name),
        name,
        displayName: name
      },
      pubKeyCredParams: [
        { alg: -8, type: 'public-key' }, // Ed25519
        { alg: -7, type: 'public-key' }, // ES256
        { alg: -257, type: 'public-key' } // RS256
      ],
      authenticatorSelection: {
        // Vaaditaan PIN-koodin tai vastaavan käyttöä
        userVerification: 'required',
        // Vaaditaan avaimen tallentaminen autentikointilaitteeseen siten, että se saadaan kirjautuessa pyydettyä ilman tiedossa olevaa ID:tä
        residentKey: 'required',
        // Sama kuin residentKey: 'required', mutta vanhemmille laitteille
        requireResidentKey: true
      },
      extensions: {
        // @ts-ignore
        prf: {
          eval: {
            first: authenticatorFirstSalt
          }
        }
      },
      excludeCredentials
    }
  })
}

/** Kirjautuu sisään WebAuthn-laitteella. */
export async function webAuthnLogin(ids: string[] = [], setLargeBlob?: ArrayBuffer): Promise<Credential | null> {
  return await navigator.credentials.get({
    publicKey: {
      challenge: new Uint8Array([1, 2, 3, 4]),
      allowCredentials: ids.map(id => {
        return {
          id: base64ToArrayBuffer(id),
          type: 'public-key'
        }
      }),
      userVerification: 'required',
      extensions: {
        // @ts-ignore
        prf: {
          eval: {
            first: authenticatorFirstSalt
          }
        },
        largeBlob: setLargeBlob ? { write: setLargeBlob } : { read: true }
      }
    }
  })
}

/** Muodostaa AES-salausavaimen autentikointilaitteen ja Webauthn prf-laajennoksen avulla tai importoi largeBlobina olevan avaimen. */
export async function getAesWrappingKeyFromCredential(authCredential: Credential): Promise<CryptoKey> {
  // @ts-ignore
  const extensions = authCredential.getClientExtensionResults()
  if (extensions.prf?.results?.first) {
    // Muodostetaan PRF:n avulla
    const inputKeyMaterial = new Uint8Array(extensions.prf?.results?.first)
    const keyDerivationKey = await crypto.subtle.importKey('raw', inputKeyMaterial, 'HKDF', false, ['deriveKey'])

    return crypto.subtle.deriveKey(
      {
        name: 'HKDF',
        info: new Uint8Array(),
        salt: authenticatorFirstSalt, // Tämänkin voisi vaihtaa kovakoodatusta avainkohtaiseksi.
        hash: 'SHA-256'
      },
      keyDerivationKey,
      { name: 'AES-GCM', length: 256 },
      true,
      ['wrapKey', 'unwrapKey']
    )
  } else {
    // Importoidaan largeBlobista
    return crypto.subtle.importKey('raw', new Uint8Array(extensions.largeBlob.blob), { name: 'AES-GCM' }, true, ['wrapKey', 'unwrapKey'])
  }
}

/** Säilöö salausavaimen ID:n IndexedDB:hen. */
export function storeAuthIDToIndexedDB(authID: string): void {
  const db = indexedDB.open('authDB', 1)
  db.onupgradeneeded = () => {
    db.result.createObjectStore('auth', { keyPath: 'id' })
  }
  db.onsuccess = () => {
    const transaction = db.result.transaction('auth', 'readwrite')
    transaction.objectStore('auth').put({ id: 'key', key: authID })
  }
}

/** Hakee IndexedDB:hen säilötyn salausavaimen ID:n */
export function getAuthIDFromIndexedDB(): Promise<string> {
  return new Promise((resolve, reject) => {
    const db = indexedDB.open('authDB', 1)
    db.onupgradeneeded = () => {
      db.result.createObjectStore('auth', { keyPath: 'id' })
    }
    db.onsuccess = () => {
      const transaction = db.result.transaction('auth', 'readonly')
      const request = transaction.objectStore('auth').get('key')
      request.onsuccess = () => {
        if (request.result?.key) {
          resolve(request.result.key)
        } else {
          reject(new Error('AuthID:tä ei löytynyt IndexedDB:stä.'))
        }
      }
      request.onerror = () => {
        reject(request.error)
      }
    }
  })
}

/** Poistaa IndexedDB:hen säilötyn salausavaimen ID:n. */
export function removeAuthIDFromIndexedDB(): Promise<void> {
  return new Promise((resolve, reject) => {
    const db = indexedDB.open('authDB', 1)
    db.onupgradeneeded = () => {
      db.result.createObjectStore('auth', { keyPath: 'id' })
    }
    db.onsuccess = () => {
      const transaction = db.result.transaction('auth', 'readwrite')
      transaction.objectStore('auth').delete('key')
      transaction.oncomplete = () => {
        resolve()
      }
      transaction.onerror = () => {
        reject(transaction.error)
      }
    }
  })
}

/** Säilöö CryptoKeyPairin IndexedDB:hen. */
export function storeKeyPairToIndexedDB(cryptoKey: OpenedCryptKey): void {
  const db = indexedDB.open('keyDB', 1)
  db.onupgradeneeded = () => {
    db.result.createObjectStore('keys', { keyPath: 'id' })
  }
  db.onsuccess = () => {
    const transaction = db.result.transaction('keys', 'readwrite')
    transaction.objectStore('keys').put({ id: 'key', key: cryptoKey.key, cryptoKeyId: cryptoKey.cryptoKeyId })
  }
}

export type OpenedCryptKey = {
  key: CryptoKeyPair
  cryptoKeyId?: number
}

/** Hakee IndexedDB:hen säilötyn CryptoKeyPairin. */
export function getKeypairFromIndexedDB(): Promise<OpenedCryptKey> {
  return new Promise((resolve, reject) => {
    const db = indexedDB.open('keyDB', 1)
    db.onupgradeneeded = () => {
      db.result.createObjectStore('keys', { keyPath: 'id' })
    }
    db.onsuccess = () => {
      const transaction = db.result.transaction('keys', 'readonly')
      const request = transaction.objectStore('keys').get('key')
      request.onsuccess = () => {
        if (request.result?.key) {
          resolve(request.result)
        } else {
          reject(new Error('Avainparia ei löytynyt IndexedDB:stä.'))
        }
      }
      request.onerror = () => {
        reject(request.error)
      }
    }
  })
}

/** Poistaa IndexedDB:hen säilötyn CryptoKeyPairin. */
export function removeKeypairFromIndexedDB(): Promise<void> {
  return new Promise((resolve, reject) => {
    const db = indexedDB.open('keyDB', 1)
    db.onupgradeneeded = () => {
      db.result.createObjectStore('keys', { keyPath: 'id' })
    }
    db.onsuccess = () => {
      const transaction = db.result.transaction('keys', 'readwrite')
      transaction.objectStore('keys').delete('key')
      transaction.oncomplete = () => {
        resolve()
      }
      transaction.onerror = () => {
        reject(transaction.error)
      }
    }
  })
}

export async function createSafeboxKey(): Promise<CryptoKey> {
  return crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256
    },
    true,
    ['encrypt', 'decrypt']
  )
}

/** Luo käyttäjälle webauthn largeBlob:lla tallennettavan avaimen. */
export async function createUserKey(): Promise<CryptoKey> {
  return crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256
    },
    true,
    ['wrapKey', 'unwrapKey']
  )
}
