<template>
  <div></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import { logout } from '@/utils/api-functions'

@Component
export class LogoutView extends Vue {
  beforeMount(): void {
    if (this.$store.user?.sso && this.$store.appConfig?.loginMethod.ssoLogoutUrl) {
      window.location.assign(this.$store.appConfig.loginMethod.ssoLogoutUrl)
    } else {
      logout().then(() => {
        this.$store.$patch({ user: null, sessionExpires: null, tuple: null })
        // $patch mergeää annetut objectit, joten tyhjän objektin asetus täytyy tehdä sen ohi
        this.$store.searchTuples = {}
        this.$router.replace('/')
      })
    }
  }
}
export default LogoutView
</script>
