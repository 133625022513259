import { TFunction } from 'i18next'
import { Component } from 'vue'

import AccessControl, { AccessControlJSON } from '@/classes/AccessControl'
import AccessType from '@/classes/AccessType'
import type AppConfig from '@/classes/AppConfig'
import Attribute, { AttributeJSON } from '@/classes/Attribute'
import Block, { BlockJSON } from '@/classes/Block'
import Choice, { ChoiceJSON } from '@/classes/Choice'
import Composition, { CompositionJSON } from '@/classes/Composition'
import DateRange, { DateRangeJSON } from '@/classes/DateRange'
import TechnicalError from '@/classes/errors/TechnicalError'
import Group, { GroupJSON } from '@/classes/Group'
import LangVersions, { LangVersionsJSON } from '@/classes/LangVersions'
import type Page from '@/classes/Page'
import Predicate, { predicateFromJSON, PredicateJSON } from '@/classes/Predicate'
import Row, { RowJSON } from '@/classes/Row'
import type Search from '@/classes/Search'
import type Table from '@/classes/Table'
import type Tuple from '@/classes/Tuple'
import { getString } from '@/utils/i18n'
import Imports from '@/utils/Imports'
import RouteName from '@/utils/RouteName'

export interface TabJSON extends AccessControlJSON {
  name: string
  langKeyName?: string
  attributes?: AttributeJSON[]
  groups?: GroupJSON[]
  check: { [key: string]: PredicateJSON }
}

export default class Tab extends AccessControl {
  name: string
  langKeyName: string
  attributes: { [key: string]: Attribute } = {}
  /** Kaikki attribuutit ja ryhmät sisältävä juuriryhmä */
  rootGroup: Group
  parentTable?: Table
  page: Page
  /** Mahdolliset ylikirjoitetut view-komponentit. */
  views: { [key in RouteName]?: Component } = {}
  check: { [key: string]: Predicate } = {}

  constructor(json: TabJSON, page: Page, appConfig: AppConfig) {
    super(json.permissions ?? {})
    appConfig.tabs[json.name] = this

    // Asetetaan ensin perustiedot, jotta ne ovat käytettävissä attribuutteja määritellessä
    this.name = json.name
    this.langKeyName = json.langKeyName ?? json.name
    this.page = page

    // Luodaan attribuutit
    for (const attrJSON of json.attributes ?? []) {
      this.attributes[attrJSON.name] = new Attribute(attrJSON, this)
    }

    // Alustetaan ryhmät implisiittisen juuriryhmän alle
    this.rootGroup = createGroup({ name: this.name, type: 'block', attributes: Object.keys(this.attributes), groups: json.groups }, this)
    this.rootGroup.initialize()

    // Määritetään tarkisteet
    this.check = Object.fromEntries(Object.entries(json.check).map(([subject, predicate]) => [subject, predicateFromJSON(predicate)]))
  }

  /**
   * Palauttaa avainattribuutit arrayna.
   */
  getKeyAttributes(): Attribute[] {
    return Object.values(this.attributes).filter(attr => attr.key)
  }

  /** Tabin otsikko. */
  getLabel(): string {
    return this.t('title')
  }

  /**
   * Onko tämä Tab Table?
   * Apumetodi erityisesti templateista kutsuttavaksi, sillä templatesta ei voi käyttää importoituja viittauksia.
   */
  isTable(): this is Table {
    return false
  }

  /** Palauttaa tämän tabin Tablena tai undefined, jos tämä tab ei ole Table. */
  asTable(): Table | undefined {
    return undefined
  }

  /**
   * Onko tämä Tab Search?
   */
  isSearch(): this is Search {
    return false
  }

  /** Onko käyttäjällä haluttu oikeus (oletuksena VIEW) tähän Tabiin? */
  isVisible(parentTuple?: Tuple, accessType?: AccessType): boolean {
    return this.hasPermission(accessType ?? AccessType.VIEW, parentTuple)
  }

  /**
   * Onko annettu tab tämän tabin parent tai jonkin parentin parent.
   */
  hasAncestor(tab: Tab): boolean {
    if (this.parentTable === tab) {
      return true
    }
    return this.parentTable?.hasAncestor(tab) ?? false
  }

  /**
   * Apufunktio Tabien kieliresurssien hakemiseen. Haetaan prioriteettijärjestyksessä:
   * 1. Tauluspesifiä kieliresurssia `Tabs.taulunnimi.key`
   * 2. Taululle määritellyn kieliresurssinimen mukaisesti `Tabs.langKeyName.key`
   * 3. Yleistä kieliresurssia `Tab.key`
   *
   * Ks. myös HelperMixin.$tTab().
   */
  t(...args: Parameters<TFunction>): string {
    const key = args.shift()
    const restArgs = args as unknown as DropFirst<Parameters<TFunction>>
    // Lisätään tabikohtainen avain myös listan viimeiseksi, jotta se näkyy käyttöliittymällä avaimen puuttuessa.
    const fullKey = `Tabs.${this.name}.${key}`
    return getString([fullKey, `Tabs.${this.langKeyName}.${key}`, `Tab.${key}`, fullKey], ...restArgs)
  }

  /**
   * Palauttaa tabin nimen perusteella.
   */
  static getTab(tabName?: string): Tab | undefined {
    if (tabName === undefined) {
      return undefined
    }
    return Imports.store.appConfig!.tabs[tabName]
  }
}

/** Group-luokan ulkopuolella syklisen importin välttämiseksi. */
export function createGroup(json: GroupJSON, tab: Tab): Group {
  switch (json?.type) {
    case 'composition':
      return new Composition(<CompositionJSON>json, tab)
    case 'dateRange':
      return new DateRange(<DateRangeJSON>json, tab)
    case 'choice':
      return new Choice(<ChoiceJSON>json, tab)
    case 'row':
      return new Row(<RowJSON>json, tab)
    case 'block':
      return new Block(<BlockJSON>json, tab)
    case 'langversions':
      return new LangVersions(<LangVersionsJSON>json, tab)
    default:
      throw new TechnicalError(`Odottamaton ${json.type}`)
  }
}
