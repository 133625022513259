<template>
  <q-dialog ref="dialog" @hide="onHide">
    <q-card>
      <q-card-section>
        <div class="text-h6">Tietolokeron poistaminen</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <i18next :translation="$t('SafeboxDeletionDialog.message')">
          <template #safebox>
            <strong>{{ safebox.values.name.getDisplayValue() }}</strong>
          </template>
          <template #export>
            <strong>{{ $t('SafeboxDeletionDialog.export') }}</strong>
          </template>
          <template #delete>
            <strong>{{ $t('SafeboxDeletionDialog.delete') }}</strong>
          </template>
        </i18next>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat @click="onHide">{{ $t('SafeboxDeletionDialog.cancel') }}</q-btn>
        <q-btn flat @click="onExport">{{ $t('SafeboxDeletionDialog.export') }}</q-btn>
        <q-btn color="red" @click="onDelete">{{ $t('SafeboxDeletionDialog.delete') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import SafeboxExportDialog from '@/components/SafeboxExportDialog.vue'
import { deleteTuple } from '@/utils/api-functions'
import Imports from '@/utils/Imports'
import { setSafeboxCookie } from '@/utils/safebox'
import { QDialog } from '~quasar'

@Component({})
export default class SafeboxDeletionDialog extends Vue {
  @Prop({ type: Object })
  safebox!: Tuple

  @Emit('hide')
  onHide() {}

  public show() {
    ;(this.$refs.dialog as QDialog).show()
  }

  public hide() {
    ;(this.$refs.dialog as QDialog).hide()
  }

  onExport() {
    this.$q.dialog({
      component: SafeboxExportDialog,
      componentProps: {
        preselect: [this.safebox.values.id.v]
      }
    })
  }

  async onDelete() {
    setSafeboxCookie(this.safebox.getKeyString())
    await deleteTuple(this.safebox)
    setSafeboxCookie(null)
    Imports.appStore.refreshSafeboxes()
    this.$emit('ok')
    this.hide()
  }
}
</script>

<style scoped lang="scss"></style>
