import TechnicalError from '@/classes/errors/TechnicalError'

/** Salaa datan annetulla IV:llä ja avaimella. */
export async function encryptString(data: string, iv: string, key: CryptoKey): Promise<string> {
  return arrayBufferToBase64(await encryptBufferToBuffer(new TextEncoder().encode(data), iv, key))
}

/** Palauttaa avainta vastaavan algoritmin parametreineen. */
function getAlgorithmFromKey(key: CryptoKey, iv: string = '') {
  if (key.algorithm.name === 'AES-GCM') {
    return {
      name: 'AES-GCM',
      iv: new TextEncoder().encode(atob(iv))
    }
  } else if (key.algorithm.name === 'RSA-OAEP') {
    return {
      name: 'RSA-OAEP'
    }
  } else {
    throw new Error(`Avaimen algoritmi ${key.algorithm.name} ei ole tuettu!`)
  }
}

/** Salaa datan annetulla IV:llä ja avaimella. */
export async function encryptBufferToBuffer(data: BufferSource, iv: string, key: CryptoKey): Promise<ArrayBuffer> {
  try {
    const algorithm = getAlgorithmFromKey(key, iv)
    return await crypto.subtle.encrypt(algorithm, key, data)
  } catch (err) {
    throw new TechnicalError('Salaus epäonnistui', err as Error, `data: ${data}, iv: ${iv}`)
  }
}

/** Purkaa salatun datan annetulla IV:llä ja avaimella. */
export async function decryptBufferToBuffer(data: ArrayBuffer, iv: string, key: CryptoKey): Promise<ArrayBuffer> {
  try {
    const algorithm = getAlgorithmFromKey(key, iv)
    return await crypto.subtle.decrypt(algorithm, key, data)
  } catch (err) {
    throw new TechnicalError('Salauksen purku epäonnistui', err as Error, `data: ${data}, iv: ${iv}`)
  }
}

/** Purkaa salatun datan annetulla IV:llä ja avaimella. */
export async function decryptStringToString(data: string, iv: string, key: CryptoKey): Promise<string> {
  return new TextDecoder().decode(await decryptBufferToBuffer(base64ToArrayBuffer(data), iv, key))
}

/** Luo uuden satunnaisen IV:n. */
export function createIV(): string {
  const array = new Uint8Array(12)
  window.crypto.getRandomValues(array)
  return btoa(String.fromCharCode(...array))
}

/** Muuntaa ArrayBufferin base64-stringiksi. */
export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const bytes = new Uint8Array(buffer)
  let binary = ''
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

/** Muuntaa base64-stringin ArrayBufferiksi. Muunnetaan tarvittaess URL-safe base64 normaaliksi base64:ksi. */
export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  return Uint8Array.from(atob(base64.replace(/-/g, '+').replace(/_/g, '/')), c => c.charCodeAt(0)).buffer
}
