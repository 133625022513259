<template>
  <li class="day-item" :style="{ '--indent': indent }" :class="{ 'has-children': item.children, new: isNew }">
    <div class="item-header" @click="toggleExpand">
      <div class="item-time">
        {{ dayjs(item.timestamp).format('HH:mm') }}
      </div>
      <div class="item-message">
        <TimelineMessage :format="lokikuvaus">
          <template #profile="{ value, argument }">
            <VTooltip style="display: inline-block">
              <AccountAvatar
                :deleted="argument?.includes('{{') || argument === ''"
                :with-name="argument?.includes('{{') || argument === '' ? true : 'given'"
                :name="argument?.includes('{{') || argument === '' ? 'Poistettu Käyttäjä' : argument"
                :account="value"
                size="0.5em"
                color="rgb(221 224 227)"
                style="margin: 0 0.1em; color: rgb(94, 115, 136); font-weight: 600"
              />
              <template #popper>
                <div>
                  {{ argument?.includes('{{') || argument === '' ? 'Poistettu käyttäjä' : argument ?? getAccountName(value) }}
                </div>
              </template>
            </VTooltip>
          </template>
          <template #note="{ value, argument }">
            <RouterLink v-if="item.info.exists !== false" :to="`/note/${value}`" style="color: rgb(94, 115, 136); font-weight: 600">{{ argument }}</RouterLink>
            <strong v-else style="color: rgb(94, 115, 136); font-weight: 600; cursor: not-allowed">{{ argument }}</strong>
          </template>
          <template #service="{ value, argument }">
            <RouterLink v-if="item.info.exists !== false" :to="`/service/${value}`" style="color: rgb(94, 115, 136); font-weight: 600">{{
              argument
            }}</RouterLink>
            <strong v-else style="color: rgb(94, 115, 136); font-weight: 600; cursor: not-allowed">{{ argument }}</strong>
          </template>
        </TimelineMessage>
      </div>
      <span v-if="item.children" class="item-expand">
        <q-icon name="fas fa-chevron-down" :style="{ transform: expanded ? 'rotate(180deg)' : '' }" />
      </span>
    </div>
    <q-slide-transition>
      <SidebarTimelineItemList v-if="expanded" :parent="item.id" :color="color" :indent="indent + 1" />
    </q-slide-transition>
  </li>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { h, VNode } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import AccountAvatar from '@/components/AccountAvatar.vue'
import SidebarTimelineItemList from '@/components/SidebarTimelineItemList.vue'
import type { TimelineObject } from '@/components/SidebarTimelineView.vue'

const MESSAGE_INTERPOLATION_REGEXP = /\[\[([^\][|]*)\|([^\][|]*)(?:\|([^\][|]*))?\]\]/g

@Component({})
class TimelineMessage extends Vue {
  @Prop({ type: String })
  format!: string

  render() {
    const children: (VNode | string)[] = []

    const matches = [...this.format.matchAll(MESSAGE_INTERPOLATION_REGEXP)] as Array<RegExpMatchArray & { index: number }>

    if (matches.length === 0) {
      return h('span', {}, [this.format])
    }

    const segments: Array<RegExpMatchArray | string> = []

    const first = matches[0]

    if (first) {
      segments.push(this.format.substring(0, first.index))
    }

    matches.forEach((match, index) => {
      if (index > 0) {
        const last = matches[index - 1]
        segments.push(this.format.substring(last.index + last[0].length, match.index))
      }

      segments.push(match)
    })

    const last = matches[matches.length - 1]
    segments.push(this.format.substring(last.index + last[0].length))

    segments.forEach(segment => {
      if (typeof segment === 'string') {
        children.push(segment)
        return
      }

      const value = segment[1]
      const type = segment[2]
      const argument = segment[3]

      const slot = this.$slots[type] ?? this.$slots.fallback

      if (!slot) {
        return
      }

      const slotChildren = slot({ value, argument })

      if (slotChildren) {
        children.push(...slotChildren)
      }
    })

    return h('span', {}, children)
  }
}

@Component({
  methods: { dayjs },
  components: { SidebarTimelineItemList, AccountAvatar, TimelineMessage }
})
export default class SidebarTimelineItem extends Vue {
  @Prop({ type: String })
  color!: string

  @Prop({ type: Object })
  item!: TimelineObject

  @Prop({ type: Number, default: 0 })
  indent!: number

  /** Tuple, joka sisältää tietoja alkuperäisestä poistetusta tuplesta. */
  deleted?: Tuple

  private expanded = false

  get isNew() {
    return this.item.new && this.item.username !== this.$store.user?.name
  }

  toggleExpand() {
    if (this.item.children) {
      this.expanded = !this.expanded
    }
  }

  actionKey(type: string) {
    return {
      '1': 'created',
      '2': 'viewed',
      '3': 'edited',
      '4': 'deleted',
      '5': 'searched',
      '6': 'exported',
      '7': 'released',
      '8': 'action'
    }[type]
  }

  @Watch('item', { immediate: true })
  async onItemChange() {
    if (this.item.deleted) {
      this.deleted = await Tuple.fromJSON(
        {
          values: Object.fromEntries(Object.entries(this.item.deleted).map(([key, value]) => [key, { v: value }]))
        },
        Table.getTable('deleted')
      )
    } else {
      this.deleted = undefined
    }
  }

  get criteria() {
    return Object.fromEntries(this.item.criteria.split(/\s+and\s+/i).map(segment => segment.split('=', 2)))
  }

  get lokikuvaus(): string {
    /* eslint-disable camelcase */
    const { tbl_name, type } = this.item

    const actionKey = this.actionKey(type)

    const context = this.item.username === this.$store.user?.name ? 'self' : undefined

    return this.$t(`SidebarTimelineView.actions.${tbl_name}.${actionKey}`, {
      ...this.translateObject({ ...this.item, ids: this.criteria, tuple: null }, [tbl_name], context),
      context,
      tuple: new Proxy(
        {},
        {
          get: (_target, prop) => {
            const value = this.item.tuple?.values?.[prop.toString()]?.getDisplayValue()

            if (value) {
              return value
            } else if (this.deleted) {
              return this.deleted?.values?.name?.getDisplayValue()
            } else {
              return this.$t('SidebarTimelineView.defaultValue', { id: this.criteria.id, context })
            }
          }
        }
      )
    })
  }

  translateObject(object: Record<string, unknown>, path: string[], context?: string): Record<string, unknown> {
    function isRecord(value: unknown): value is Record<string, unknown> {
      return typeof value === 'object' && value !== null
    }

    return Object.fromEntries(
      Object.entries(object).map(([key, value]) => {
        if (typeof value === 'string') {
          // Vue handles escaping the messages, so disabling escaping at i18next level prevents double-escaping.
          return [
            key,
            this.$t(`SidebarTimelineView.values.${path.join('.')}.${key}.${value}`, {
              context,
              interpolation: { escapeValue: false },
              defaultValue: `${value}`
            })
          ]
        }

        if (isRecord(value)) {
          return [key, this.translateObject(value, [...path, key], context)]
        }

        return [key, value]
      })
    )
  }
}
</script>

<style scoped lang="scss">
.day-item {
  list-style: none;
  margin-top: 0.5em;
  --nest-indent: 2em;

  &.new {
    .item-header::before,
    .item-header::after {
      $size: 5px;

      content: '';
      position: absolute;
      width: $size;
      height: $size;
      background-color: #2196f3;
      border-radius: 9999px;
      left: -0.75em;
    }

    @keyframes pulse {
      from {
        transform: scale3d(1, 1, 1);
        opacity: 0.8;
      }

      33% {
        transform: scale3d(1, 1, 1);
        opacity: 0.8;
      }

      to {
        transform: scale3d(3, 3, 3);
        opacity: 0;
      }
    }

    .item-header::before {
      opacity: 0.2;
      animation: pulse ease-out 1500ms infinite;
    }
  }

  .item-header {
    display: flex;
    align-items: center;
    gap: 1em;
    position: relative;
  }

  &.has-children .item-header {
    cursor: pointer;
  }

  .item-children {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .item-message {
    color: black;
    padding-left: calc(var(--indent) * 2em);
  }

  .item-expand .q-icon {
    transition-duration: 300ms;
    margin-left: 0em;
    position: relative;
    top: -0.1em;
    color: #b5b5b5;
  }
}
</style>
