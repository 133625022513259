<template>
  <q-dialog ref="dialog" @hide="onHide">
    <q-stepper ref="stepper" v-model="step" style="width: 100rem" animated @transition="onStepChanged">
      <q-step name="select-safeboxes" :title="$t('SafeboxExportDialog.steps.select-safeboxes.title')">
        <p>{{ $t('SafeboxExportDialog.steps.select-safeboxes.message') }}</p>
        <q-list bordered class="rounded-borders">
          <q-item-label header>{{ $t('SafeboxExportDialog.steps.select-safeboxes.selectSafeboxesHeader') }}</q-item-label>
          <div style="max-height: 25rem; overflow-y: scroll">
            <q-item v-for="safebox in safeboxes" :key="safebox.getUniqueId()" tag="label">
              <q-item-section side top>
                <q-checkbox :model-value="safeboxSelected(safebox)" @update:model-value="setSafeboxSelected(safebox, $event)" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ safebox.values.name.getDisplayValue() }}</q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </q-list>
        <q-checkbox v-model="encrypt" :label="$t('SafeboxExportDialog.steps.select-safeboxes.encryptLabel')" style="margin-left: 17px; margin-top: 16px" />
        <div v-if="!encrypt" class="alert">
          <q-icon name="fas fa-triangle-exclamation" />
          <div>
            <h6>{{ $t('SafeboxExportDialog.steps.select-safeboxes.unencryptedDisclaimerTitle') }}</h6>
            <p>{{ $t('SafeboxExportDialog.steps.select-safeboxes.unencryptedDisclaimerBody') }}</p>
          </div>
        </div>
        <p class="responsibility-disclaimer">{{ $t('SafeboxExportDialog.steps.select-safeboxes.exportDisclaimer') }}</p>
        <q-expansion-item>
          <template #header>
            <q-item-section style="padding: 0.5em 0">
              <q-item-label>
                {{ $t('SafeboxExportDialog.steps.select-safeboxes.advancedSettings.header') }}
              </q-item-label>
            </q-item-section>
          </template>
          <q-list>
            <q-item>
              <q-item-label>
                <q-checkbox v-model="exportServices" :label="$t('SafeboxExportDialog.steps.select-safeboxes.advancedSettings.exportServices')" />
              </q-item-label>
            </q-item>
            <q-item>
              <q-item-label>
                <q-checkbox v-model="exportNotes" :label="$t('SafeboxExportDialog.steps.select-safeboxes.advancedSettings.exportNotes')" />
              </q-item-label>
            </q-item>
          </q-list>
        </q-expansion-item>
      </q-step>
      <q-step name="export" :title="$t('SafeboxExportDialog.steps.export.title')">
        <div style="text-align: center">
          <q-spinner size="xl" color="primary" />
          <div class="q-mt-md">{{ status }}</div>
        </div>
      </q-step>
      <q-step name="download" :title="$t('SafeboxExportDialog.steps.download.title')">
        <p>{{ $t('SafeboxExportDialog.steps.download.message') }}</p>
        <q-input v-if="password" readonly filled standout :model-value="password" :label="$t('SafeboxExportDialog.steps.download.passwordLabel')">
          <template #append>
            <q-btn color="white" @click="copyPassword">
              <q-icon name="fa-solid fa-copy" size="1.3em" style="color: #6f6f6f" />
            </q-btn>
          </template>
        </q-input>
        <div v-if="encrypt" class="alert">
          <q-icon name="fas fa-triangle-exclamation" />
          <div>
            <i18next :translation="$t('SafeboxExportDialog.steps.download.passwordAlert')">
              <template #strong>
                <strong>{{ $t('SafeboxExportDialog.steps.download.passwordAlertStrong') }}</strong>
              </template>
            </i18next>
          </div>
        </div>
        <div v-else class="alert">
          <q-icon name="fas fa-triangle-exclamation" />
          <div>
            <h6>{{ $t('SafeboxExportDialog.steps.download.unencryptedDisclaimerBody') }}</h6>
            <p>{{ $t('SafeboxExportDialog.steps.download.unencryptedDisclaimerBody') }}</p>
          </div>
        </div>
      </q-step>
      <template #navigation>
        <q-stepper-navigation class="row q-mt-md q-gutter-md">
          <q-btn v-if="step === 'download'" color="primary" no-caps @click="handleDownload">{{
            $t('SafeboxExportDialog.steps.download.downloadButton', { size: blobSize })
          }}</q-btn>
          <div style="flex-grow: 1" />
          <q-btn v-if="step !== 'download'" @click="onHide()">{{ $t('SafeboxExportDialog.cancel') }}</q-btn>
          <q-btn v-if="step === 'download'" @click="onHide()">{{ $t('SafeboxExportDialog.done') }}</q-btn>
          <q-btn v-else color="primary" :disabled="step !== 'select-safeboxes'" @click="$refs.stepper.goTo('export')">{{
            $t('SafeboxExportDialog.start')
          }}</q-btn>
        </q-stepper-navigation>
      </template>
    </q-stepper>
  </q-dialog>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { QDialog } from 'quasar'
import { Component, Emit, mixins, Prop, Watch } from 'vue-facing-decorator'

import { Exporter } from '@/classes/exporter'
import { SevenZipCreator } from '@/classes/exporter/SevenZipCreator'
import { Notification, NotificationLevel } from '@/classes/Notification'
import Tuple from '@/classes/Tuple'
import { apiFetchJSON } from '@/utils/api-functions'
import { apiPath } from '@/utils/paths'
import { SafeboxMixin } from '@/utils/SafeboxMixin'

@Component({})
export default class SafeboxExportDialog extends mixins(SafeboxMixin) {
  private step = 'select-safeboxes'
  private safeboxIds: number[] = []
  private blob: Blob | null = null
  private status: string = ''
  private encrypt = true
  private exportServices = true
  private exportNotes = true
  private password: string | null = null

  beforeMount() {
    this.status = this.$t('SafeboxExportDialog.preparing')
  }

  async copyPassword() {
    if (!this.password) {
      return
    }

    await navigator.clipboard.writeText(this.password)
    this.notify(new Notification(NotificationLevel.SUCCESS, this.$t('SafeboxExportDialog.passwordCopied')))
  }

  get blobSize() {
    if (!this.blob) {
      return ''
    }

    const bytes = this.blob.size

    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = 2
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  @Prop({ type: Array, default: [] })
  private preselect!: string[]

  @Watch('preselect', { immediate: true })
  private onPreselectChange(value: number[]) {
    this.safeboxIds = [...value]
  }

  public show() {
    ;(this.$refs.dialog as QDialog).show()
  }

  public hide() {
    ;(this.$refs.dialog as QDialog).hide()
  }

  private onStepChanged(step: string) {
    if (step === 'export') {
      this.runExport()
    }
  }

  private async logExport() {
    await Promise.all(
      this.safeboxIds.map(safebox =>
        apiFetchJSON(`${apiPath}/log`, {
          method: 'POST',
          body: JSON.stringify({
            safebox,
            action: 'export'
          })
        })
      )
    )
  }

  private async runExport() {
    const skip = []

    if (!this.exportServices) {
      skip.push('service')
    }

    if (!this.exportNotes) {
      skip.push('note')
    }

    const exporter = new Exporter(SevenZipCreator.factory, {
      password: this.encrypt,
      skip
    })

    exporter.on('status', status => (this.status = status))

    await this.logExport()
    this.blob = await exporter.export(this.safeboxIds)
    this.password = exporter.password
    this.step = 'download'
  }

  private safeboxSelected(safebox: Tuple) {
    return this.safeboxIds.includes(safebox.values.id.v as number)
  }

  private setSafeboxSelected(safebox: Tuple, selected: boolean) {
    const id = safebox.values.id.v as number
    const index = this.safeboxIds.indexOf(id)

    if (selected && index === -1) {
      this.safeboxIds.push(id)
    } else if (!selected && index > -1) {
      this.safeboxIds.splice(index, 1)
    }
  }

  @Emit('hide')
  onHide() {}

  private handleDownload() {
    if (!this.blob) {
      return
    }

    const timestamp = dayjs().format('YYYY-MM-DDTHH-mm-ss')
    const a = document.createElement('a')
    a.href = URL.createObjectURL(this.blob)
    a.download = `${this.$t('SafeboxExportDialog.archiveName')}-${timestamp}.7z`
    a.click()
    URL.revokeObjectURL(a.href)
  }
}
</script>

<style scoped lang="scss">
.responsibility-disclaimer {
  margin: 16px 27px;
}

.alert {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 2px solid #d32f2f;
  padding: 16px 16px;
  margin: 16px 0;

  .q-icon {
    margin: 0 22px 0 6px;
    font-size: 1.5rem;
    color: #d32f2f;
  }

  h6 {
    font-size: 1em;
    line-height: 1.5em;
    margin: 0 0 10px 0;
  }

  p {
    margin: 0;
  }
}
</style>
