import TechnicalError from '@/classes/errors/TechnicalError'
import type Tab from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'
import type { TupleJSON } from '@/classes/Tuple'

export interface UserJSON {
  name: string
  roles: string[]
  attributes: { [key: string]: string[] }
  sso?: boolean
  csrfToken: string
}

export interface LogoutJSON {
  csrfToken: string
}

export default class User {
  name: string
  roles: string[]
  attributes: { [key: string]: string[] } = {}
  /** Onko käyttäjä kirjautunut SSO:n avulla. */
  sso: boolean

  constructor(json: UserJSON) {
    this.name = json.name
    this.roles = json.roles
    this.attributes = json.attributes
    this.sso = json.sso ?? false
  }

  /** Palauttaa tiedon, onko nykyisellä käyttäjällä mainitun roolin jäsenyys. */
  isUserInRole(role: string): boolean {
    return this.roles.includes(role)
  }

  /** Palauttaa true, jos käyttäjä on missä tahansa listatuista rooleista. */
  isUserInAnyRole(...roles: string[]): boolean {
    for (const role of roles) {
      if (this.isUserInRole(role)) {
        return true
      }
    }
    return false
  }

  /** Crypted-attribuuttien salaukseen käytettävä avain. Räätälöitävä sovelluksessa. */
  async getEncryptKey(_tuple: Tuple): Promise<CryptoKey | undefined> {
    throw new TechnicalError('Kryptausavainta ei ole määritelty. Ylikirjoita getEncryptKey() User.ts:ssä.')
  }

  /** Crypted-attribuuttien purkuun käytettävä avain. Räätälöitävä sovelluksessa. */
  async getDecryptKey(_tupleJSON: TupleJSON, _tab: Tab, _parentTuple?: Tuple): Promise<CryptoKey | undefined> {
    throw new TechnicalError('Kryptausavainta ei ole määritelty. Ylikirjoita getDecryptKey() User.ts:ssä.')
  }
}
