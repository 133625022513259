<template>
  <div id="public-layout">
    <div class="public-header">
      <h1>
        <router-link to="/welcome">
          <img />
        </router-link>
      </h1>
    </div>
    <div class="public-layout-content">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component({})
export default class PublicLayout extends Vue {}
</script>

<style scoped lang="scss">
.public-header {
  color: white;
  display: flex;
  padding: 0 5rem;
  justify-content: space-between;
  align-items: center;
  height: 8rem;

  h1 {
    font-size: 3rem;
    font-weight: bold;

    img {
      width: 125px;
      height: 34px;
      mask: url('../images/digua-logo.svg');
      mask-position: center center;
      mask-repeat: no-repeat;
      background-color: #272a2f;
    }
  }

  button {
    height: 2.5em;
    padding: 0.2em 1em;
    border: 2px solid currentColor;
    border-radius: 9999px;
    background: transparent;
    color: inherit;
    cursor: pointer;
  }
}

#public-layout {
  min-height: 100%;
  background-color: #eef1f4;
}
</style>
