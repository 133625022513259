/**
 * Suoritusjonon alkio.
 */
type QueueEntry = {
  /** Alkiota vastaava kahva. */
  handle: LockHandle

  /** Funktio, jota kutsumalla suoritus siirtyy kahvan haltijalle. */
  handOver: () => void
}

/**
 * Lukon kahva, jonka avulla lukko voidaan vapauttaa.
 */
export type LockHandle = number

/**
 * Luokka, joka toteuttaa mutex-synkronisaatioprimitiivin asynkronisesti.
 *
 * Luokkaa voidaan käyttää varmistamaan, ettei selain suorita tiettyä osaa asynkronisesta
 * koodista limittäin itsensä tai muun asynkronisen koodin kanssa.
 */
export class Mutex {
  /**
   * Laskuri, jolla annetaan kaikille kahvoille yksilöllinen juokseva numero.
   */
  private counter: number = 0

  /**
   * Lista aktiivisista kahvoista.
   *
   * Listan ensimmäinen solu sisältää kahvan, joka on tällä hetkellä suorituksessa.
   * Loput solut sisältävät vuoroaan odottavia kahvoja.
   */
  private queue: Array<QueueEntry> = []

  /**
   * Liity suoritusjonoon ja odota vuoron saamista.
   *
   * @return Kahvan numero, jolla lukko voidaan vapauttaa.
   */
  async acquire(): Promise<LockHandle> {
    return new Promise<LockHandle>(resolve => {
      const handle = ++this.counter
      this.queue.push({
        handle,
        handOver: () => {
          resolve(handle)
        }
      })

      if (this.queue.length === 1) {
        this.queue[0].handOver()
      }
    })
  }

  /**
   * Vapauttaa lukon ja siirtää suoritusvuoron seuraavalle jonossa olevalle kahvalle, jos sellainen on.
   *
   * @param handle Vapautettavan kahvan numero.
   */
  release(handle: LockHandle) {
    if (this.queue[0].handle === handle) {
      this.queue.splice(0, 1)

      if (this.queue.length > 0) {
        this.queue[0].handOver()
      }
    }
  }

  /**
   * Suorittaa annetun funktion odottaen ensin lukon saamista ja huolehtien sen vapauttamisesta funktion suorituksen jälkeen.
   *
   * @param scope Funktio, joka suoritetaan lukon hallussapidon aikana.
   */
  async with<V>(scope: () => Promise<V>): Promise<V> {
    const lock = await this.acquire()

    try {
      // HUOM: Tämä "turha" await on tarpeellinen finally-blockin takia!
      const result = await scope()
      return result
    } finally {
      this.release(lock)
    }
  }
}
