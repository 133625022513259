<template>
  <div class="safebox-search" @keydown.esc="$event.target.blur()">
    <q-input
      ref="input"
      v-model="search"
      rounded
      standout="search-standout"
      dense
      :placeholder="$tt('searchPlaceholder')"
      @keydown.stop.up="handleFocus(searchResults.length - 1)"
      @keydown.stop.down="handleFocus(0)"
      @keydown.enter="searchResults[0] && onSelect(searchResults[0])"
    >
      <template #append>
        <q-icon name="search" />
      </template>
    </q-input>
    <div class="safebox-popup">
      <ul class="safebox-popup-list">
        <li
          v-for="(safebox, index) in searchResults"
          :ref="el => el && (items[index] = el)"
          :key="safebox.getUniqueId()"
          tabindex="0"
          class="safebox-popup-item"
          @click="onSelect(safebox)"
          @keydown.stop.enter="onSelect(safebox)"
          @keydown.stop.up="handleFocus(index - 1)"
          @keydown.down="handleFocus(index + 1)"
        >
          <div class="safebox-popup-image" :style="getSafeboxBackgroundStyles(safebox)"></div>
          <div>
            <div class="safebox-popup-name">
              <Highlight :text="safebox.values.name.getDisplayValue()" :highlight="search.trim()" />
            </div>
            <div class="safebox-popup-owner">
              <q-icon name="far fa-crown" />
              {{ safebox.values.owner_name.getDisplayValue() }}
              <q-tooltip :delay="500" :offset="[0, 5]">{{ $tt('ownerTooltip') }}</q-tooltip>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { QInput } from 'quasar'
import { computed, defineComponent, h } from 'vue'
import { Component, mixins } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'
import { getBackgroundStyles } from '@/utils/safebox'
import { SafeboxMixin } from '@/utils/SafeboxMixin'

const Highlight = defineComponent(
  props => {
    const segments = computed(() => {
      if (props.highlight === '') {
        return [[0, props.text.length]]
      }

      const segments: [number, number][] = []

      const textLower = props.text.toLowerCase()
      const highlightLower = props.highlight.toLowerCase()

      let i = 0
      let match

      while ((match = textLower.indexOf(highlightLower, i)) !== -1) {
        segments.push([i, match])
        segments.push([match, match + props.highlight.length])
        i = match + props.highlight.length
      }

      segments.push([i, props.text.length])

      return segments
    })

    return () =>
      h(
        'span',
        segments.value.map(([start, end], i) => {
          const text = props.text.substring(start, end)

          return i % 2 === 0 ? text : h('span', { class: 'highlight' }, text)
        })
      )
  },
  {
    props: {
      text: {
        type: String,
        required: true
      },
      highlight: {
        type: String,
        required: true
      }
    }
  }
)

@Component({
  name: 'SafeboxSearch',
  mixins: [SafeboxMixin],
  components: { Highlight }
})
export default class SafeboxSearch extends mixins(SafeboxMixin) {
  search = ''

  items: HTMLElement[] = []

  declare $refs: {
    input: QInput
  }

  get searchResults() {
    if (this.search === '') return this.safeboxes

    return this.safeboxes.filter(tuple => tuple.values.name.getDisplayValue().toLowerCase().includes(this.search.toLowerCase()))
  }

  onSelect(safebox: Tuple) {
    Imports.appStore.selectSafebox(safebox.values.id.toString())
    this.$router.push('/dashboard')
  }

  getSafeboxBackgroundStyles(safebox: Tuple) {
    return getBackgroundStyles(safebox)
  }

  handleFocus(index: number) {
    if (index < 0 || index >= this.searchResults.length) {
      this.$refs.input.focus()
    } else {
      this.items[index].focus()
    }
  }

  public focus() {
    this.$refs.input.focus()
  }

  $tt(...[key, ...args]: Parameters<typeof this.$t>) {
    return this.$t(`SafeboxSearch.${key}`, ...args)
  }
}
</script>

<style scoped lang="scss">
@use 'sass:math';

.safebox-search {
  grid-column: 2;
  justify-self: center;
  position: relative;

  $offset: 1em;
  $max-height: #{(4em + 1em) * 3.5};

  .q-input {
    :deep(.q-field__control) {
      background-color: white;
      box-shadow:
        0 1px 5px rgba(0, 0, 0, 0.2),
        0 2px 2px rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    :deep(.q-field__append) {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &:focus-within .safebox-popup {
    opacity: 1;
    display: inline-block;
    max-height: calc(40px + 2 * #{$offset} + #{$max-height} + 1em);
    height: max-content;
  }

  & :deep(.search-standout) {
    background-color: white;
    color: black;

    input {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .safebox-popup {
    transition-duration: 500ms;
    position: absolute;
    left: #{-1 * $offset};
    right: #{-1 * $offset};
    bottom: #{-1 * $offset};
    margin: 0;
    overflow: hidden;
    background-color: white;
    z-index: -1;
    max-height: 0;
    border-radius: calc(20px + #{$offset});
    box-shadow:
      0 1px 5px rgba(0, 0, 0, 0.2),
      0 2px 2px rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12);
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75em;

    padding: 0 calc((1em - 5px) * 0.5) 0 1em;

    &::after {
      content: '';
      position: absolute;
      inset: 0 0 #{-$offset} 0;
      box-shadow:
        0px 1em 1em 0px white inset,
        0px calc(-2 * (40px + 2 * #{$offset} + 0.5em)) 0.5em calc(-1 * (40px + 2 * #{$offset} + 0.5em)) white inset;
      border-radius: calc($offset + 20px);
      pointer-events: none;
    }

    .safebox-popup-list {
      margin: 0;
      padding: 2em calc((1em - 5px) * 0.5) calc(40px + 2 * #{$offset} + 1em) 0;
      overflow-y: auto;
      max-height: calc(40px + 2 * #{$offset} + #{$max-height} + 2em);
      height: max-content;

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(0 0 0 / 20%);
      }

      &::-webkit-scrollbar {
        max-width: 5px;
      }
    }

    .safebox-popup-item {
      height: 5em;
      display: flex;
      align-items: center;
      gap: 1em;
      padding: 0.5em;
      border-radius: 0.5em;
      cursor: pointer;
      flex-shrink: 0;

      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.05);
        outline: none;
      }

      .safebox-popup-image {
        height: 100%;
        aspect-ratio: #{math.div(169, 284)};
        background-size: cover;
        border-radius: 0.3em;
        box-shadow:
          0 2px 5px 0px rgba(0, 0, 0, 0.2),
          0px 0px 5px 0px rgba(0, 0, 0, 0.33) inset;
      }

      .safebox-popup-name {
        white-space: preserve;

        :deep(.highlight) {
          display: inline-block;
          position: relative;

          &::before {
            $padding: 0.2em;

            content: '';
            position: absolute;
            z-index: -1;
            display: block;
            inset: 0;
            background-color: #cdd3d9;
            color: black;
            border-radius: 0.25em;
            padding: 0 #{$padding};
            margin: 0 #{-1 * $padding};
            overflow: hidden;
            vertical-align: bottom;
            white-space: nowrap;
          }
        }
      }

      .safebox-popup-owner {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.9em;

        .q-icon {
          margin-right: 0.33em;
        }
      }
    }
  }
}
</style>
