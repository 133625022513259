import dayjs, { Dayjs } from 'dayjs'
import { cloneDeepWith, isArray, isEmpty } from 'lodash-es'
import { markRaw } from 'vue'
import { LocationQuery } from 'vue-router'

import AccessType from '@/classes/AccessType'
import type Action from '@/classes/Action'
import type { ActionContext } from '@/classes/ActionContext'
import AdvancedSearchParam, { ADVANCED_SEARCH_OP_LABELS, checkAdvancedSearchOp } from '@/classes/AdvancedSearchParam'
import Attribute from '@/classes/Attribute'
import DataType from '@/classes/DataType'
import TechnicalError from '@/classes/errors/TechnicalError'
import type Link from '@/classes/Link'
import type Report from '@/classes/Report'
import Tab from '@/classes/Tab'
import type Table from '@/classes/Table'
import CustomTuple from '@/classes/Tuple'
import Value, { RealValueType, ValueJSON } from '@/classes/Value'
import { newTuple } from '@/utils/api-functions'
import { createIV, decryptStringToString, encryptString } from '@/utils/crypt'
import Imports from '@/utils/Imports'
import { uuidv4 } from '@/utils/uuid'

export interface TupleJSON {
  values: { [key: string]: ValueJSON }
  children?: { [key: string]: TupleJSON[] }
  /** Mahdollinen original tuplen key stringinä, ks. {@see Tuple.getKeyString()} */
  origKey?: string
  dirty?: boolean
  delete?: boolean
  parent?: TupleJSON
  tmpKey?: string
}

/** Attribuutti, joka sisältää salattujen attribuuttien tiedot. */
export const CRYPT_DATA_ATTR_NAME = 'cryptdata'
export const CRYPT_IV_ATTR_NAME = 'cryptiv'

export default class Tuple {
  tab: Tab
  values: { [key: string]: Value } = {}
  children: { [key: string]: Tuple[] } = {}
  /** Ovatko listat lapsista mahdollisesti vaillinaisia? */
  incompleteChildren = false
  /** Mahdollinen alkuperäinen versio tästä tuplesta. */
  origTuple?: Tuple
  /** Ollaanko tämä lapsi poistamassa? */
  delete = false
  /** Onko monikossa tallentamattomia tietoja */
  dirty = false
  /** Onko monikko listauksessa valittuna */
  selected = false
  /** Monikon isämonikko */
  parent: Tuple | null = null
  /** Uniikki UUID tuplejen erottelemiseen v-forilla listatessa. */
  private uniqueId?: string
  /** Mahdolliset tarkennetun haun rajausarvot. */
  advancedSearchParams: AdvancedSearchParam[] = []

  constructor(tab: Tab, values?: { [key: string]: Value }) {
    this.tab = tab
    if (values) {
      this.values = values
    }
  }

  /**
   * Palauttaa joko ainoan avaimen arvon sellaisenaan, tai komposiittiavaimen |-merkeillä eroteltuna
   */
  getKeyString(): string {
    return this.tab
      .getKeyAttributes()
      .map(attr => this.values[attr.name]?.valueToString())
      .join('|')
  }

  /**
   * Onko tuplen avainarvot määritelty?
   */
  hasKey(): boolean {
    return this.tab.getKeyAttributes().every(attr => this.values[attr.name]?.hasContent() ?? false)
  }

  /**
   * Palauttaa uniikin UUIDv4-tunnisteen v-forilla listausta varten, kun tiedetään ettei tuplella ole vielä kunnollista keytä.
   */
  getUniqueId(): string {
    if (!this.uniqueId) {
      this.uniqueId = uuidv4()
    }
    return this.uniqueId
  }

  /**
   * Lisää tarvittaessa tyhjät valuet kaikille attribuuteille
   */
  createEmptyValues(): void {
    Object.values(this.tab.attributes).forEach(attr => {
      if (!this.values[attr.name]) {
        this.values[attr.name] = attr.parseUrlValue(null)
      }
    })
  }

  /**
   * Tyhjentää tuplen arvot.
   */
  clear(): void {
    for (const valueName of Object.keys(this.values)) {
      delete this.values[valueName]
    }
  }

  /** Palauttaa yksittäisen attribuutin alkuarvon, jos nykyinen arvo poikkeaa siitä. */
  resetValue(attr: Attribute): void {
    if (this.values[attr.name]) {
      const initialValue = attr.createInitialValue()
      if (!initialValue.equals(this.values[attr.name])) {
        console.debug(`reset ${attr} -> ${initialValue}`)
        this.values[attr.name] = initialValue
      }
    }
  }

  /** Jos pakollisella attribuutilla ei ole initialValueta, mutta on vain yksi optio, valitaan se. */
  preselect(attr: Attribute): void {
    if (attr.isRequired(this) && !attr.initialValue && attr.options?.length === 1 && !attr.options[0].equals(this.values[attr.name])) {
      console.debug(`preselect ${attr} -> ${attr.options[0]}`)
      this.values[attr.name] = attr.options[0]
    }
  }

  /**
   * Onko yhdelläkään tuplen valuella arvoa? Huomioidaan myös tarkennetun haun valuet.
   */
  hasContent(): boolean {
    return Object.values(this.values).some(val => val.hasContent()) || this.advancedSearchParams.some(param => param.val.hasContent())
  }

  /**
   * Onko tämä tuple identtinen annetun tuplen kanssa, mukaanlukien lapset ja tarkennetun haun ehdot?
   */
  equals(other?: Tuple | null): boolean {
    if (other == null || other.tab.name !== this.tab.name) {
      return false
    }
    for (const attr of Object.values(this.tab.attributes)) {
      const thisVal = this.values[attr.name]
      const otherVal = other.values[attr.name]
      if (thisVal == null && otherVal == null) {
        continue
      }
      if (thisVal == null && otherVal != null) {
        return false
      }
      if (!thisVal.equals(otherVal)) {
        return false
      }
    }

    for (const childTable of Object.keys(this.children)) {
      if (this.children[childTable].length !== (other.children[childTable]?.length ?? 0)) {
        return false
      }
      for (let i = 0; i < this.children[childTable].length; i++) {
        const child = this.children[childTable][i]
        if (!child.equals(other.children[childTable][i])) {
          return false
        }
        if (child.delete) {
          return false
        }
      }
    }

    if (this.advancedSearchParams.length !== other.advancedSearchParams.length) {
      return false
    }
    for (let i = 0; i < other.advancedSearchParams.length; i++) {
      const thisParam = this.advancedSearchParams[i]
      const otherParam = other.advancedSearchParams[i]
      if (thisParam.attr !== otherParam.attr || thisParam.op !== otherParam.op || !thisParam.val.equals(otherParam.val)) {
        return false
      }
    }
    return true
  }

  /**
   * Onko tuplen tai sen lapsien sisältö muuttunut tai tuple merkitty poistettavaksi viimeisimmän
   * resetOriginal-kutsun jälkeen?
   */
  hasChanged(): boolean {
    return !this.equals(this.origTuple) || this.delete || this.dirty
  }

  /**
   * Kopioi origTupleen uuden kloonin tästä instanssista. Asetetaan myös lasten origTuple-viittaukset kloonattuihin lapsitupleihin.
   */
  resetOriginal(): void {
    // Jätetään parent ja origTuple kloonaamatta ja asetetaan parent-viite kloonatulle tuplelle
    this.setOrigTuple(
      cloneDeepWith(this, (value, key) => {
        if (value instanceof Tuple) {
          if (key === 'parent') return null
          if (key === 'origTuple') return undefined
        }
        // Tab- ja Attribute -rakenteita ei myöskään ole mielekästä kloonata
        if (value instanceof Tab) {
          if (key === 'tab') return value
        }
        if (value instanceof Attribute) {
          if (key === 'attribute') return value
        }
      }) as Tuple
    )
  }

  /** Asetetaan lasten origTuple-viittaukset annettuihin orig-lapsitupleihin. */
  setOrigTuple(orig: Tuple): void {
    function setOrigAndParent(tuple: Tuple): void {
      for (const childName of Object.keys(tuple.children)) {
        const childArray = tuple.children[childName]
        const origChildArray = tuple.origTuple!.children[childName]
        for (let j = 0; j < childArray.length; j++) {
          const child = childArray[j]
          if (!origChildArray || origChildArray.length <= j) {
            // Jos uuden lapsen tallennuksessa oli validointivirhe, on se edelleen uusi ja siltä puuttuu orig-tuple
            break
          }
          child.origTuple = markRaw(origChildArray[j])
          child.origTuple.parent = tuple.origTuple!
          setOrigAndParent(child)
        }
      }
    }
    // Jätetään origTuple reaktiivisusutarkastelun ulkopuolelle, jotta tuplen käsittelyä saadaan tehostettua
    this.origTuple = markRaw(orig)
    this.origTuple.parent = this.parent
    this.origTuple.dirty = false
    setOrigAndParent(this)
  }

  /**
   * Hakee reference-valueiden puuttuvat displayValuet backendistä.
   */
  fetchDisplayValues(): void {
    for (const val of Object.values(this.values)) {
      val.fetchDisplayValue()
    }
    for (const childList of Object.values(this.children)) {
      for (const child of childList) {
        child.fetchDisplayValues()
      }
    }
    for (const asParam of this.advancedSearchParams) {
      asParam.val.fetchDisplayValue()
    }
  }

  /** Poistaa monikosta avaimet ja tiedostoarvot uudeksi kopiointia varten. */
  clearNonDuplicables(): void {
    for (const keyAttr of Object.values(this.tab.attributes).filter(attr => attr.key || attr.type === DataType.FILE)) {
      this.values[keyAttr.name] = new Value(keyAttr)
    }
  }

  /** Palauttaa valuet attribuuttien mukaisessa järjestyksessä. */
  getValuesArray(): Value[] {
    return Object.values(this.tab.attributes).map(attr => this.values[attr.name])
  }

  /**
   * Palauttaa murupolussa käytetyn otsikon tai lyhyen sisältökuvauksen.
   * Yksittäinen arvo näytetään ilman labelia, useampi arvo labelien kanssa.
   */
  getTitle(): string {
    const titleValues = this.getValuesArray()
      // Näytetään vain kunnollisen arvon sisältävät valuet, joihin käyttäjällä on oikeus
      .filter(
        val =>
          val &&
          val.hasContent() &&
          val.attribute.isVisible(AccessType.TITLE, false, this, false) &&
          val.attribute.type !== DataType.HTML &&
          // kompositiot vain ensimmäisen jäsenen kohdalla
          val.attribute.isIterable()
      )
    if (titleValues.length === 1) {
      return titleValues[0].getContainerDisplayValue(this)
    } else {
      return titleValues.map(val => `${val.attribute.getContainerLabel()}: ${val.getContainerDisplayValue(this)}`).join(', ')
    }
  }

  /** Table-tyypin monikkoa käsittelevät toiminnot tai undefined */
  getActions(context: ActionContext): Action[] | undefined {
    return this.tab.isTable() ? (this.tab as Table).getActions(context, this, AccessType.VIEW) : undefined
  }

  /** Table-tyypin monikkoon liittyvät tulosteet tai undefined */
  getReports(context: ActionContext): Report[] | undefined {
    return this.tab.isTable() ? (this.tab as Table).getReports(context, this, AccessType.VIEW) : undefined
  }

  /** Table-tyypin monikkoon liittyvät linkit tai undefined */
  getLinks(context: ActionContext): Link[] | undefined {
    return this.tab.isTable() ? (this.tab as Table).getLinks(context, this, AccessType.VIEW) : undefined
  }

  /**
   * Apufunktio valuen asettamiseksi.
   */
  setValue(attrName: string, value: RealValueType, displayValue: string | null = null): void {
    const val = this.values[attrName]
    if (val) {
      val.v = value
      val.d = displayValue
    } else {
      const attr = this.tab.attributes[attrName]
      this.values[attrName] = new Value(attr, value, displayValue)
    }
  }

  /** Diskriminoi ts. resetoi ne monikkohierarkian arvot, joiden attribuutteihin liittyvät ehdot eivät täyty */
  checkPermissions(): void {
    for (const attr of Object.values(this.tab.attributes)) {
      if (attr.isPredicatePermission(AccessType.VIEW) || attr.isPredicatePermission(AccessType.EDIT)) {
        attr.checkPermission(this)
      }
    }
    for (const childTable of Object.keys(this.children)) {
      for (let i = 0; i < this.children[childTable].length; i++) {
        const child = this.children[childTable][i]
        child.checkPermissions()
      }
    }
    if (!this.tab.hasPermission(AccessType.ADVANCED_SEARCH, this) && this.tab.isResetValueAfterPredicateCheck(AccessType.ADVANCED_SEARCH))
      this.advancedSearchParams.length = 0
  }

  /** Lisätään multiplicityLown mukaiset uudet inline-lapsituplet. */
  addNewChildren(): void {
    if (this.tab.isTable()) {
      for (const childTableName in this.tab.asTable().children) {
        const childTable = Tab.getTab(childTableName)?.asTable()
        if (childTable && !childTable.isInlineAssociate) {
          let i = this.children[childTableName]?.length || 0
          for (i; i < childTable.multiplicityLow; i++) {
            const initial = new CustomTuple(childTable)
            initial.parent = this
            newTuple(initial).then(newTuple => {
              this.children[childTableName].push(newTuple)
            })
          }
        }
      }
    }
  }

  /** Kertoo, onko tuplen valueissa validointivirheitä (silloinkin, kun virheet on jo ehditty tyhjentään storesta). */
  valuesHasErrors(): boolean {
    if (Object.values(this.values).some(v => v.e !== null)) {
      return true
    } else {
      return Object.values(this.children).some(tupleList => tupleList.some(child => child.valuesHasErrors()))
    }
  }

  /**
   * Palauttaa käyttäjäkelpoisen esityksen tuplesta.
   */
  toString(): string {
    return this.getValuesArray()
      .filter(
        val =>
          val &&
          // Näytetään vain kunnollisen arvon sisältävät valuet
          val.hasContent() &&
          // joihin käyttäjällä on oikeus
          val.attribute.isVisible(AccessType.VIEW, false, this, false) &&
          // ja kompositiot vain ensimmäisen jäsenen kohdalla
          val.attribute.isIterable()
      )
      .map(val => `${val.attribute.getContainerLabel()}: ${val.getContainerDisplayValue(this)}`)
      .concat(this.advancedSearchParams.map(param => `${param.attr.getLabel()} ${ADVANCED_SEARCH_OP_LABELS[param.op]} ${param.val.getDisplayValue()}`))
      .join(', ')
  }

  /**
   * Muodostaa hakuehtojen urlin muodostamiseen käytettävän parametriston.
   * Käänteinen operaatio ks. {@link fromStrings}.
   */
  toStringObject(): { [key: string]: string | string[] } {
    const params = Object.entries(this.values).reduce<{ [key: string]: string | string[] }>((params, [name, val]) => {
      if (val.hasContent()) {
        params[name] = val.valueToUrl()
      }
      return params
    }, {})
    for (const param of this.advancedSearchParams) {
      if (param.val.hasContent()) {
        const key = `as-${param.attr.tab.name}-${param.attr.name}-${param.op}`
        if (params[key]) {
          if (isArray(params[key])) (params[key] as string[]).push(param.val.valueToUrl())
          else params[key] = [params[key] as string, param.val.valueToUrl()]
        } else params[key] = param.val.valueToUrl()
      }
    }
    return params
  }

  /** Muodostaa Tuple-hierarkian mukaisen JSONin. Muuttuneet monikot merkitään palvelinkäsittelyä varten. */
  async toJSON(previousParent: Tuple | string | null | undefined = undefined, encrypt = true): Promise<TupleJSON> {
    previousParent = previousParent === undefined ? this : previousParent

    if (typeof previousParent === 'string') {
      // JSON.stringifyn kutsumana toJSON saa parametrina mahdollisen propertyn nimen tai arrayn indeksin stringinä.
      // Käyttäydytään näille tapauksille vastaavasti, kuin parametri puuttuisi kokonaan.
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      previousParent = this
    }

    if (encrypt) {
      // Salatut valuet
      const valuesToCrypt = Object.entries(this.values).filter(([, val]) => val.attribute.isCrypted && val.attribute.type !== DataType.FILE && val.hasContent())
      if (valuesToCrypt.length > 0) {
        const encryptKey = await Imports.store.user?.getEncryptKey(this)
        if (!encryptKey) {
          throw new TechnicalError(`Kryptausavainta taululle ${this.tab.name} ei ole määritelty. Ylikirjoita getEncryptKey() User.ts:ssä.`)
        }
        const valuesToCryptJSON = valuesToCrypt.reduce<{ [key: string]: ValueJSON }>((values, [name, val]) => {
          values[name] = val.toJSON()
          return values
        }, {})
        const cryptIv = (this.values[CRYPT_IV_ATTR_NAME]?.v as string) ?? createIV()
        this.setValue(CRYPT_IV_ATTR_NAME, cryptIv)
        this.setValue(CRYPT_DATA_ATTR_NAME, await encryptString(JSON.stringify(valuesToCryptJSON), cryptIv, encryptKey))
      }
    }

    // Salaamattomat valuet
    const values = Object.entries(this.values)
      .filter(([, val]) => !encrypt || !val.attribute.isCrypted || val.attribute.type === DataType.FILE)
      .reduce<{ [key: string]: ValueJSON }>((values, [name, val]) => {
        values[name] = val.toJSON()
        return values
      }, {})

    const tupleJSON: TupleJSON = { values }
    // Lapsituplet
    const childrenPromises = Object.entries(this.children).reduce<Promise<{ [key: string]: TupleJSON[] }>>(async (childrenPromise, [name, tuples]) => {
      const children = await childrenPromise
      // Jos ollaan juurituplen parentien tulostuksessa, ei tulosteta lasta jonka parent tämä tuple on
      children[name] = await Promise.all(tuples.filter(tuple => tuple !== previousParent).map(async tuple => await tuple.toJSON(null, encrypt)))
      return children
    }, Promise.resolve({}))
    tupleJSON.children = await childrenPromises
    if (this.advancedSearchParams.length > 0) {
      tupleJSON.children[`${this.tab.name}_as`] = this.advancedSearchParams.map(asParam => asParam.toJSON())
    }
    if (isEmpty(tupleJSON.children)) {
      tupleJSON.children = undefined
    }
    tupleJSON.origKey = this.origTuple?.hasKey() ? this.origTuple?.getKeyString() : undefined
    // Ei tulosteta lasten parenteja
    if (previousParent) {
      tupleJSON.parent = await this.parent?.toJSON(this, encrypt)
    }
    // Onko tuplea muokattu?
    tupleJSON.dirty = this.hasChanged()
    // Merkitään tuple poistettavaksi
    if (this.delete) {
      tupleJSON.delete = true
    }
    if (!this.hasKey()) {
      tupleJSON.tmpKey = this.getUniqueId()
    }
    return tupleJSON
  }

  static async fromJSON(json: TupleJSON, tab: Tab, parent?: Tuple | null): Promise<Tuple> {
    // Haetaan luotava Tuple-class importin kautta, jotta saadaan mahdolliset räätälöinnit käyttöön.
    const tuple = new CustomTuple(tab)
    tuple.parent = parent ?? null
    for (const [attrName, valueJSON] of Object.entries(json.values)) {
      const attr = tab.attributes[attrName]
      if (!attr) {
        throw new TechnicalError(`JSON-parsinta epäonnistui: taulussa ${tab.name} ei ole attribuuttia ${attrName}`)
      }
      if (attrName === CRYPT_DATA_ATTR_NAME && json.values[CRYPT_DATA_ATTR_NAME]?.v) {
        const decryptKey = await Imports.store.user?.getDecryptKey(json, tab, parent ?? undefined)
        if (!decryptKey) {
          throw new TechnicalError(`Kryptausavainta taululle ${tab.name} ei ole määritelty. Ylikirjoita getDecryptKey() User.ts:ssä.`)
        }
        const cryptData = json.values[CRYPT_DATA_ATTR_NAME].v as string
        const cryptIv = json.values[CRYPT_IV_ATTR_NAME].v as string
        const decryptedJSON = JSON.parse(await decryptStringToString(cryptData, cryptIv, decryptKey)) as {
          [key: string]: ValueJSON
        }
        Object.entries(decryptedJSON).forEach(([attrName, valueJSON]) => {
          const attr = tab.attributes[attrName]
          if (!attr) {
            throw new TechnicalError(`JSON-parsinta epäonnistui: taulussa ${tab.name} ei ole attribuuttia ${attrName}`)
          }
          tuple.values[attrName] = Value.fromJSON(valueJSON, attr)
        })
      } else {
        tuple.values[attrName] = Value.fromJSON(valueJSON, attr)
      }
    }
    // Lisätään cryptatuissa kentissä säilöttyjen tiedostojen nimet file-Valueille.
    Object.values(tab.attributes)
      .filter(attr => attr.isCrypted && attr.filenameDependency !== undefined)
      .forEach(attr => {
        const fileVal = tuple.values[attr.filenameDependency!]
        if (fileVal?.hasContent()) {
          fileVal.d = tuple.values[attr.name]?.valueToString()
          fileVal.v = new File([], fileVal.d)
        }
      })
    // Lisätään tyhjät valuet mahdollisille puuttuneille cryptatuille kentille
    Object.values(tab.attributes)
      .filter(attr => attr.isCrypted)
      .forEach(attr => {
        if (!tuple.values[attr.name]) {
          tuple.values[attr.name] = new Value(attr)
        }
      })
    if (json.children) {
      await Promise.all(
        Object.entries(json.children).map(async ([name, jsonTuples]) => {
          if (name === `${tab.name}_as`) {
            tuple.advancedSearchParams = jsonTuples.map(paramJson => AdvancedSearchParam.fromJSON(paramJson))
            return
          }
          const table = tab as Table
          const childTable = table.isHierarchy ? table : table.children?.[name]
          if (!childTable) throw new TechnicalError(`Taululla ${tab.name} ei ole lapsitaulua ${name}.`)
          tuple.children[name] = await Promise.all(jsonTuples.map(jsonTuple => CustomTuple.fromJSON(jsonTuple, childTable, tuple)))
        })
      )
    }
    tuple.delete = json.delete ?? false
    if (json.parent && tab.parentTable) {
      tuple.parent = await CustomTuple.fromJSON(json.parent, tab.parentTable)
      tuple.parent.incompleteChildren = true
      if (!tuple.parent.children[tab.name]) {
        tuple.parent.children[tab.name] = []
      }
      tuple.parent.children[tab.name].push(tuple)
    }
    tuple.dirty = json.dirty ?? false
    return tuple
  }

  /**
   * Tuple annetusta string-arvoja sisältävästä objektista, esim. $route.query:stä.
   * Käänteinen operaatio ks. {@link toStringObject}.
   */
  static fromStrings(strings: LocationQuery, tab: Tab): Tuple {
    function queryParamToValue(tab: Tab, attrName: string, val: string | null, isAdvancedSearch = false) {
      const attr = tab.attributes[attrName]
      if (attr) {
        return attr.parseUrlValue(val, isAdvancedSearch)
      }
    }
    const advancedSearchParams: AdvancedSearchParam[] = []
    const values = Object.entries(strings).reduce<{ [key: string]: Value }>((values, [name, vals]) => {
      if (name.startsWith('as-')) {
        // Tarkennetun haun parametri
        const [, tabName, attrName, op] = name.split('-', 4)
        const tab = Tab.getTab(tabName)
        if (tab) {
          for (const val of Array.isArray(vals) ? vals : [vals]) {
            const value = queryParamToValue(tab, attrName, val, true)
            if (value) advancedSearchParams.push(new AdvancedSearchParam(tab.attributes[attrName], checkAdvancedSearchOp(op), value))
          }
        }
      } else {
        // Normaalin haun parametri
        const value = queryParamToValue(tab, name, Array.isArray(vals) ? vals[0] : vals)
        if (value) values[name] = value
      }
      return values
    }, {})
    const newTuple = new CustomTuple(tab, values)
    // Lisätään puuttuvat tyhjät valuet, jotta equals-vertailu toimisi
    newTuple.createEmptyValues()
    if (advancedSearchParams.length > 0) newTuple.advancedSearchParams = advancedSearchParams
    return newTuple
  }

  /**
   * Muodostaa avaintuplen annetusta avainstringistä.
   * Käänteinen operaatio {@link getKeyString}:lle.
   */
  static fromKeyString(key: string, tab: Tab): Tuple {
    const keyAttrs = tab.getKeyAttributes()
    const keyStrings = key.split('|')
    const tuple = new CustomTuple(tab)
    for (let i = 0; i < keyAttrs.length; i++) {
      const attr = keyAttrs[i]
      tuple.values[attr.name] = attr.parseUrlValue(keyStrings[i])
    }
    return tuple
  }

  /** Jakaa annetut monikot annetun attribuutin arvojen mukaisiin ryhmiin. */
  static groupBy(tuples: Tuple[], attribute: string): { [key: string]: Tuple[] } {
    return tuples.reduce(
      (acc, current) => {
        const group = current.values[attribute].getDisplayValue()
        if (!acc[group]) acc[group] = []
        acc[group].push(current)
        return acc
      },
      {} as Record<string, Tuple[]>
    )
  }

  /**
   * Annetut monikot nimetyn attribuutin mukaisessa järjestyksessä nousevasti tai laskevasti
   * Vaikutteita otettu Quasarin toteutuksesta (https://github.com/quasarframework/quasar/blob/dev/ui/src/components/table/table-sort.js).
   */
  static sort(tuples: Tuple[], attribute: string, descending: boolean): Tuple[] {
    if (tuples.length < 2) {
      return tuples
    }
    const attr = tuples[0].tab.attributes[attribute]
    const dir = descending ? -1 : 1

    return tuples.sort((tupleA, tupleB) => {
      let valueA =
        (attr.type === DataType.INTEGER || attr.type === DataType.STRING) && tupleA.values[attribute].d !== null
          ? tupleA.values[attribute].d
          : tupleA.values[attribute].v
      let valueB =
        (attr.type === DataType.INTEGER || attr.type === DataType.STRING) && tupleB.values[attribute].d !== null
          ? tupleB.values[attribute].d
          : tupleB.values[attribute].v

      if (valueA === null) {
        return -1 * dir
      } else if (valueB === null) {
        return dir
      }

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return (<number>valueA - <number>valueB) * dir
      } else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
        return ((<boolean>valueA ? 1 : 0) - (<boolean>valueB ? 1 : 0)) * dir
      } else if (dayjs.isDayjs(valueA) && dayjs.isDayjs(valueB)) {
        return (<Dayjs>valueA).diff(<Dayjs>valueB) * dir
      } else {
        ;[valueA, valueB] = [valueA, valueB].map(s => (s + '').toLocaleString().toLowerCase())
        return valueA.localeCompare(valueB, Imports.store.lang, { numeric: true }) * dir
      }
    })
  }
}
