import { mountApp as kantoMountApp } from '@kanto/utils/main-hooks'
import { defineAsyncComponent } from 'vue'

import Imports from '@/utils/Imports'
import RouteName from '@/utils/RouteName'
export * from '@kanto/utils/main-hooks'

export function mountApp(): void {
  kantoMountApp()
  Imports.store.appConfigPromise!.then(() => {
    Imports.store.appConfig!.tabs.dashboard.views[RouteName.CUSTOMVIEW] = defineAsyncComponent(() => import('@/views/Dashboard.vue'))
    Imports.store.appConfig!.tabs.list.views[RouteName.CUSTOMVIEW] = defineAsyncComponent(() => import('@/views/ListView.vue'))
    Imports.store.appConfig!.tabs.notes.views[RouteName.CUSTOMVIEW] = defineAsyncComponent(() => import('@/views/Notes.vue'))
    Imports.store.appConfig!.tabs.note.views[RouteName.LISTING] = defineAsyncComponent(() => import('@/views/Notes.vue'))
    Imports.store.appConfig!.tabs.safebox.views[RouteName.LISTING] = defineAsyncComponent(() => import('@/views/SafeboxSelectionView.vue'))
    Imports.store.appConfig!.tabs.service.views[RouteName.LISTING] = defineAsyncComponent(() => import('@/views/Dashboard.vue'))
    Imports.store.appConfig!.tabs.safebox.views[RouteName.CREATE] = defineAsyncComponent(() => import('@/views/SafeboxCreatorView.vue'))
    Imports.store.appConfig!.tabs.service.views[RouteName.CREATE] = defineAsyncComponent(() => import('@/views/ServiceCreatorView.vue'))
    Imports.store.appConfig!.tabs.service.views[RouteName.EDIT] = defineAsyncComponent(() => import('@/views/ServiceEditorView.vue'))
  })
}
