import { ArchiveCreator, ArchiveCreatorFactory, ArchiveCreatorOptions } from '@/classes/exporter/index'

/**
 * Luokka, jonka avulla voidaan luoda 7zip-tyyppisiä arkistoja.
 */
export class SevenZipCreator implements ArchiveCreator {
  /**
   * Tehdasfunktio, joka luo {@link SevenZipCreator}-instanssin annetuilla asetuksilla.
   */
  static factory: ArchiveCreatorFactory = async options => {
    const worker = new Worker(new URL('./SevenZipCreatorWorker', import.meta.url), {
      type: 'module'
    })

    return new SevenZipCreator(worker, options)
  }

  constructor(
    private worker: Worker,
    private options: ArchiveCreatorOptions
  ) {
    worker.postMessage({
      type: 'init',
      options: {
        password: options.password
      }
    })
  }

  /**
   * Salaa ja lisää tiedoston arkistoon.
   *
   * @param path Tiedoston sijainti arkistossa.
   * @param content Tiedoston sisältö.
   */
  async add(path: string, content: Blob): Promise<void> {
    this.worker.postMessage({
      type: 'add',
      path,
      content
    })
  }

  /**
   * Viimeistelee arkiston ja palauttaa sen kokonaisuudessaan.
   */
  close(): Promise<Blob> {
    return new Promise<Blob>(resolve => {
      this.worker.addEventListener('message', message => {
        if (message.data.type === 'result') {
          resolve(message.data.blob)
        } else if (message.data.type === 'progress') {
          this.options.onProgress?.(message.data.value)
        }
      })

      this.worker.postMessage({
        type: 'close'
      })
    })
  }
}
