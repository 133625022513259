<template>
  <q-dialog ref="dialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ $tt('title') }}</div>
      </q-card-section>
      <q-card-section v-if="!editTuple" style="display: flex; gap: 5em">
        <q-spinner />
      </q-card-section>
      <q-card-section v-else style="display: flex; gap: 5em">
        <div>
          <q-input v-model="editTuple.values.name.v" :label="$tt('nameFieldLabel')" />
        </div>
        <div class="background-preview" :style="backgroundStyle">
          <q-btn :loading="processingBackground" icon="fas fa-arrow-up-from-bracket" @click="uploadBackground">Lataa taustakuva</q-btn>
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn flat rounded icon="fas fa-trash" class="button-delete" @click="onDelete">{{ $tt('actions.delete') }}</q-btn>
        <div style="flex-grow: 1" />
        <q-btn rounded flat class="button-cancel" @click="hide">{{ $tt('actions.cancel') }}</q-btn>
        <q-btn rounded class="button-save" @click="save">{{ $tt('actions.save') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import Compressor from 'compressorjs'
import { QDialog } from 'quasar'
import { rgbaToThumbHash } from 'thumbhash'
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import Tuple from '@/classes/Tuple'
import Value from '@/classes/Value'
import SafeboxDeletionDialog from '@/components/SafeboxDeletionDialog.vue'
import { updateTuple, uploadFile } from '@/utils/api-functions'
import { arrayBufferToBase64 } from '@/utils/crypt'
import Imports from '@/utils/Imports'
import { getBackgroundStyles } from '@/utils/safebox'

@Component({})
export default class SafeboxEditDialog extends Vue {
  @Prop({ type: Object })
  safebox!: Tuple

  editTuple?: Tuple

  @Watch('safebox', { immediate: true })
  async onSafeboxChange() {
    this.editTuple = await Tuple.fromJSON(await this.safebox.toJSON(), this.safebox.tab)
  }

  processingBackground = false

  declare $refs: {
    dialog: QDialog
  }

  $tt(...[key, ...args]: Parameters<typeof this.$t>) {
    return this.$t(`SafeboxEditDialog.${key}`, ...args)
  }

  get background() {
    return this.editTuple?.values?.background?.v as File | undefined
  }

  uploadBackground() {
    const el = document.createElement('input')
    el.type = 'file'

    el.addEventListener('change', () => {
      const file = el.files?.[0]

      if (file) {
        this.handleBackground(file)
      }
    })

    el.click()
  }

  async handleBackground(file: File) {
    this.processingBackground = true

    try {
      await Promise.all([this.setBackground(file), this.setThumbhash(file)])
    } finally {
      this.processingBackground = false
    }
  }

  async getThumbhash(file: Blob) {
    return new Promise<string>((resolve, reject) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        maxWidth: 100,
        maxHeight: 100,
        resize: 'cover',
        mimeType: 'image/webp',

        drew: (context, canvas) => {
          const pixels = context.getImageData(0, 0, canvas.width, canvas.height)
          console.log(canvas.height, canvas.width, pixels.height, pixels.width)
          const hash = rgbaToThumbHash(pixels.width, pixels.height, pixels.data)
          resolve(arrayBufferToBase64(hash))
        },

        error: reject
      })
    })
  }

  async setThumbhash(file: File) {
    const hash = await this.getThumbhash(file)
    this.editTuple?.setValue('background_thumbhash', hash)
  }

  async resizeBackground(file: Blob) {
    return new Promise<Blob>((resolve, reject) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        maxWidth: 169 * 2,
        maxHeight: 284 * 2,
        resize: 'cover',
        mimeType: 'image/webp',

        success: resolve,
        error: reject
      })
    })
  }

  async setBackground(original: File) {
    if (this.editTuple) {
      const blob = await this.resizeBackground(original)
      const attr = this.editTuple.tab.attributes.background
      const resized = new File([blob], 'background.webp')
      const uploaded = await uploadFile(resized, attr, this.editTuple)
      const value = new Value(attr, resized, resized.name, null, uploaded.path)
      this.editTuple.values.background = value
    }
  }

  get backgroundStyle() {
    return getBackgroundStyles(this.editTuple)
  }

  async save() {
    if (!this.editTuple) return
    if (this.editTuple!.values.name.valueToString().trim() === '') {
      this.notify(new Notification(NotificationLevel.WARNING, 'Lokeron nimi ei saa olla tyhjä'))
      return this.editTuple!
    }

    if (
      Imports.appStore.safeboxes.some(
        (s: Tuple) => s.values.name.valueToString().trim().toLowerCase() === this.editTuple!.values.name.valueToString().trim().toLowerCase()
      )
    ) {
      this.notify(new Notification(NotificationLevel.WARNING, 'Lokeron nimen tulee olla uniikki'))
      return this.editTuple!
    }
    await updateTuple(this.editTuple, this.editTuple.getKeyString())
    this.hide()
  }

  public hide() {
    this.$refs.dialog.hide()
  }

  private onDelete() {
    const dialog = this.$q.dialog({
      component: SafeboxDeletionDialog,
      componentProps: {
        safebox: this.editTuple
      }
    })

    dialog.onOk(() => this.hide())
  }
}
</script>

<style scoped lang="scss">
@use 'sass:math';

.q-card {
  border-radius: 10px;
  padding: 0.5em 1em;

  .background-preview {
    width: 15em;
    aspect-ratio: #{math.div(169, 284)};
    background-position: center;
    background-size: cover;
    border-radius: 0.75em;
    box-shadow:
      0 1px 5px rgba(0, 0, 0, 0.2),
      0 2px 2px rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;

    .q-btn {
      background: white;
      color: black;
      font-size: 0.8em;
      padding: 0.75em 1em;

      :deep(.q-icon) {
        margin-right: 0.5em;
        font-size: 1.5em;
      }
    }
  }

  :deep(.q-card__actions) {
    .q-btn {
      padding: 0 0.75em;
    }

    .button-save {
      background-color: #5d7287;
      color: white;
    }

    .button-delete {
      color: #e53935;

      .q-icon {
        font-size: 1.5em;
        margin-right: 0.5em;
      }
    }
  }
}
</style>
