/* eslint-disable vue/one-component-per-file */
import { Component, ConcreteComponent, defineComponent, FunctionalComponent, h, VNode } from 'vue'
import { NavigationGuardNext, RouteLocationNamedRaw, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import AccessType from '@/classes/AccessType'
import Action from '@/classes/Action'
import CustomView from '@/classes/CustomView'
import ClientError from '@/classes/errors/ClientError'
import Job from '@/classes/Job'
import { Notification, NotificationLevel } from '@/classes/Notification'
import Report from '@/classes/Report'
import Tab from '@/classes/Tab'
import Table from '@/classes/Table'
import { getDefaultView } from '@/utils/default-views'
import { firstString } from '@/utils/helpers'
import { getString } from '@/utils/i18n'
import Imports from '@/utils/Imports'
import RouteName from '@/utils/RouteName'
import { useKantoStore } from '@/utils/store'
import LoginView from '@/views/LoginView.vue'
import LogoutView from '@/views/LogoutView.vue'

/**
 * Luo linkin annetun tabin oletusnäkymään.
 */
export function linkToTab(tabName: string): RouteLocationNamedRaw {
  const tab = Tab.getTab(tabName)
  let name: string
  if (tab instanceof Action) {
    name = RouteName.ACTION
  } else if (tab instanceof Job) {
    name = RouteName.JOB
  } else if (tab instanceof Report) {
    name = RouteName.REPORT
  } else if (tab instanceof CustomView) {
    name = `${RouteName.CUSTOMVIEW}-${tabName}`
  } else {
    // Mikäli taululla on hakunäkymä, siirrytään siihen. Muutoin näytetään listaus.
    name = tab instanceof Table && tab.search ? RouteName.SEARCH : RouteName.LISTING
  }

  return { name, params: tab instanceof CustomView ? {} : { tab: tabName } }
}

/** Linkki lapsitabin oletusnäkymään nykyisessä parent-hierarkiassa. */
export function linkToChild(tab: Tab, route: RouteLocationNormalized): RouteLocationNamedRaw {
  const link = linkToTab(tab.name)
  link.params = Object.assign({}, route.params)
  link.params.tab = tab.name
  let i = 1
  while (link.params['pkey' + i] !== undefined) {
    i++
  }
  link.params['ptable' + i] = route.params.tab
  link.params['pkey' + i] = route.params.key
  return link
}

/**
 * Palauttaa ensimmäisen taulun, mihin käyttäjälle on katseluoikeus.
 */
function getFirstViewableTab(): Tab | undefined {
  for (const tab of Object.values(useKantoStore().appConfig?.tabs ?? [])) {
    if (tab.hasPermission(AccessType.VIEW)) {
      return tab
    }
  }
}

/**
 * Etsitään komponentin (tai sen super-komponenttien) beforeRouteEnter-funktio.
 */
async function getBeforeRouteEnter(
  viewComponent: Exclude<ConcreteComponent, FunctionalComponent>
): Promise<(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => void | undefined> {
  if (viewComponent.name === 'AsyncComponentWrapper') {
    if (viewComponent.__asyncResolved) {
      // Jos defineAsyncComponent:lla ladattu komponentti on jo käytetty, __asyncResolved sisältää suoraan komponentin
      viewComponent = viewComponent.__asyncResolved
    } else {
      // Muutoin odotellaan komponentin latautuminen
      viewComponent = await viewComponent.__asyncLoader()
    }
  }
  // beforeRouteEnter saattaa olla funktio tai array funktioita
  let beforeRouteEnter = viewComponent.methods?.beforeRouteEnter
  if (beforeRouteEnter === undefined && viewComponent.extends !== undefined) {
    // Toteutus saatetaan joutua onkimaan super-tiedoista, jos tätä komponenttia ei ole vielä käytetty.
    beforeRouteEnter = await getBeforeRouteEnter(viewComponent.extends)
  }
  return beforeRouteEnter as (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => void | undefined
}

/**
 * Luo apukomponentin, joka valitsee rendattavan View-komponentin aktiivisen tabin perusteella.
 * Apukomponentti huolehtii myös beforeRouteEnter-kutsujen delegoinnista rendattavalle View-komponentille.
 */
export function createNavigationComponent(routeName: RouteName): Component {
  async function beforeRouteEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
    if (!to.params.tab && typeof to.name === 'string' && to.name?.startsWith(RouteName.CUSTOMVIEW)) {
      // Lisätään CustomView-tabin nimi parametreihin, sillä route-määrittelyssä tab-parametri on korvattu vakiolla, jotta route toimisi.
      to.params.tab = to.name.split('-', 2)[1]
    }
    const tab = Tab.getTab(firstString(to.params.tab))
    if (tab === undefined) {
      throw new ClientError(`Tabia ${to.params.tab} ei ole määritelty.`, 'Errors.404')
    }
    const view = tab.views[routeName] ?? getDefaultView(routeName, tab)
    const beforeRouteEnter = await getBeforeRouteEnter(view as Exclude<ConcreteComponent, FunctionalComponent>)
    if (beforeRouteEnter !== undefined) {
      // Komponentilla on beforeRouteEnter-funktio -> kutsutaan sitä
      beforeRouteEnter(to, from, next)
    } else {
      // Komponentilla ei ole beforeRouteEnter-funktiota -> päästetään läpi ilman tarkistuksia
      next()
    }
  }

  return defineComponent({
    name: 'RouteHelper',
    // Tämän apukomponentin näkökulmasta eri routejen välillä vaihto on routeEnterin sijaan routeUpdate,
    // joten tehdään beforeRouteUpdatessa samat tarkistukset kuin beforeRouteEnterissä.
    beforeRouteUpdate: beforeRouteEnter,
    beforeRouteEnter,
    beforeRouteLeave: function (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean | void {
      // Kaivetaan rendattu view-instanssi tämän komponentin lapsikomponentista...
      let viewInstance = this.$.subTree?.component
      if (!viewInstance?.ctx?.beforeRouteEnter) {
        // ...tai lapsen lapsesta, jos komponentti on ladattu defineAsyncComponent:lla.
        viewInstance = viewInstance?.subTree?.component
      }
      // Ja sen mahdollinen beforeRouteLeave-funktio
      const func = viewInstance?.ctx?.beforeRouteLeave
      const beforeRouteLeaveFunction = Array.isArray(func) ? func[0] : func
      if (beforeRouteLeaveFunction) {
        // Bindataan view-instanssi, jotta this olisi käytettävissä
        return beforeRouteLeaveFunction.bind(viewInstance)(to, from)
      }
    },
    render(): VNode {
      // Rendataan pyydetty view-komponentti
      const tab = this.getTabReq()
      return h(tab.views[routeName] ?? getDefaultView(routeName, tab))
    }
  })
}

/** Palauttaa routelistauksen. */
export default function getRoutes(): RouteRecordRaw[] {
  const routes = [
    // :table on taulun nimi ja :key tuplen avainattribuutin arvo. Useamman PK:n tauluja ei vielä tueta.
    { path: '/login', name: RouteName.LOGIN, component: LoginView },
    { path: '/logout', name: RouteName.LOGOUT, component: LogoutView },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/search',
      name: RouteName.SEARCH,
      component: createNavigationComponent(RouteName.SEARCH)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/listing',
      name: RouteName.LISTING,
      component: createNavigationComponent(RouteName.LISTING)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/create',
      name: RouteName.CREATE,
      component: createNavigationComponent(RouteName.CREATE)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/action',
      name: RouteName.ACTION,
      component: createNavigationComponent(RouteName.ACTION)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/report',
      name: RouteName.REPORT,
      component: createNavigationComponent(RouteName.REPORT)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/job',
      name: RouteName.JOB,
      component: createNavigationComponent(RouteName.JOB)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/:key/edit',
      name: RouteName.EDIT,
      component: createNavigationComponent(RouteName.EDIT)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/:key/duplicate',
      name: RouteName.DUPLICATE,
      component: createNavigationComponent(RouteName.DUPLICATE)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab/:key',
      name: RouteName.VIEW,
      component: createNavigationComponent(RouteName.VIEW)
    },
    {
      path: '/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/:tab',
      name: 'tab-root',
      component: defineComponent({
        beforeRouteEnter: (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext): void => {
          const location = linkToTab(firstString(to.params.tab)!)
          location.params = to.params
          next(location)
        }
      })
    },
    // Lisätään viimeisenä juuriroute ensimmäiseen tabiin, mihin käyttäjällä on katseluoikeus.
    {
      path: '/',
      name: 'root',
      component: defineComponent({
        beforeRouteEnter: (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext): void => {
          const store = useKantoStore()
          if (to.query.logoutStatus === 'error') {
            // SSO-uloskirjautuminen epäonnistui, näytetään virhe käyttäjälle.
            store.addNotification(new Notification(NotificationLevel.ERROR, getString('Errors.logout')))
          }
          const firstTab = getFirstViewableTab()
          if (!firstTab) {
            // Ei oikeuksia yhteenkään tabiin -> ohjataan loginiin
            next({ name: 'login' })
            return
          }
          next(linkToTab(firstTab.name))
        }
      })
    }
  ] as RouteRecordRaw[]

  // Lisätään aluksi AppConfigissa määritellyille CustomVieweille routet, jotta ne saavat suuremman prioriteetin.
  if (!Imports.store.appConfig) {
    console.warn('AppConfigia ei ole vielä ladattu, CustomView-routeja ei voida lisätä.')
  } else {
    for (const tab of Object.values(Imports.store.appConfig!.tabs)) {
      if (tab instanceof CustomView) {
        routes.unshift({
          path: `/:ptable1?/:pkey1?/:ptable2?/:pkey2?/:ptable3?/:pkey3?/:ptable4?/:pkey4?/:ptable5?/:pkey5?/:ptable6?/:pkey6?/:ptable7?/:pkey7?/:ptable8?/:pkey8?/:ptable9?/:pkey9?/:ptable10?/:pkey10?/${tab.name}`,
          name: `${RouteName.CUSTOMVIEW}-${tab.name}`,
          component: createNavigationComponent(RouteName.CUSTOMVIEW)
        })
      }
    }
  }
  return routes
}
