import Predicate, { predicateFromJSON, PredicateJSON } from '@/classes/Predicate'

export type PredicatePermissionJSON = { predicate: PredicateJSON; resetValues: boolean }

/** Predicate-tyyppitarkistus */
export function isPredicatePermissionJSON(json: unknown): json is PredicatePermissionJSON {
  return (json as PredicatePermissionJSON).predicate !== undefined
}

export default class PredicatePermission {
  predicate: Predicate
  /** Nullataanko attribuuttia vastaava arvo, jos oikeus puuttuu? Käytössä vain attribuutin VIEW- ja EDIT-oikeuksissa. */
  resetValues: boolean

  constructor(json: PredicatePermissionJSON) {
    this.predicate = predicateFromJSON(json.predicate)
    this.resetValues = json.resetValues
  }
}
