<template>
  <div v-if="$store.user && !$route.path.startsWith('/logout')" class="header-style" @click="unselectSafebox">
    <router-link class="digua-logo" to="/safebox/listing" @click.stop>
      <img src="../images/digua-logo.svg" alt="digua" />
    </router-link>
    <q-btn v-if="currentSafebox !== null && drawerOpen" class="safebox-button" @click="unselectSafebox">
      <div class="safebox-button-content">
        <q-icon name="fa-solid fa-cabinet-filing" class="safebox-button-icon" />
        <span class="safebox-button-text">{{ currentSafebox.values.name.getDisplayValue() }}</span>
      </div>
    </q-btn>
    <UserStatusView class="user-status-view" @click.stop="" />
  </div>
</template>

<script lang="ts">
import { Component, mixins, Prop } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'
import { NavigationMixin } from '@/utils/NavigationMixin'
import { publicPath } from '@/utils/paths'
import UserStatusView from '@/views/UserStatusView.vue'

@Component({
  components: { UserStatusView }
})
export default class PageHeader extends mixins(NavigationMixin) {
  @Prop({ type: Boolean })
  drawerOpen!: boolean

  get dashboardLink(): string {
    return publicPath
  }

  get currentSafebox(): Tuple | null {
    return Imports.appStore.currentSafebox
  }

  unselectSafebox() {
    if (this.drawerOpen) {
      this.$router.push('/safebox/listing').catch(e => {
        console.log('Error while navigating to /safebox/listing', e)
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/quasar.variables.scss';

.header-style {
  position: relative;
  height: $header-height;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0 100px;
  flex-shrink: 0;
}

.digua-logo {
  justify-self: flex-start;
  align-self: center;
  cursor: pointer;
  grid-column: 1;
}

.safebox-button {
  justify-self: center;
  grid-column: 2;
  background-color: white;
  height: 3em;
  border-radius: 0.5em;
  padding: 0 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  .safebox-button-content {
    display: inline-flex;
    align-items: center;
  }
}

.safebox-button-icon {
  color: #00d0f1;
  margin-right: 0.5em;
}

.safebox-button-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  // Kohdassa 1135px UI-muutenkin hajoaa
  @media (min-width: 1135px) {
    max-width: 300px;
  }

  @media (min-width: 1200px) {
    max-width: 400px;
  }

  @media (min-width: 1440px) {
    max-width: 500px;
  }

  @media (min-width: 1680px) {
    max-width: 600px;
  }
}

.user-status-view {
  justify-self: flex-end;
  grid-column: 3;
}
</style>
