import { Component, shallowRef } from 'vue'

import AccessType from '@/classes/AccessType'
import type Attribute from '@/classes/Attribute'
import { selectGroupInputField } from '@/classes/DefaultInputFields'
import Tab, { createGroup } from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'

export interface GroupJSON {
  name: string
  type: string
  attributes: string[]
  groups?: GroupJSON[]
}

/** Attribuutteja ryhmittelevä rakenne. */
export default class Group {
  name: string
  type: string
  /** Ryhmään tai sen sisäkkäisiin ryhmiin kuuluvat attribuutit. */
  attributes: Attribute[]
  tab: Tab
  inputField!: Component
  groups: Group[]

  constructor(json: GroupJSON, tab: Tab) {
    this.name = json.name
    this.type = json.type
    this.tab = tab
    this.attributes = json.attributes.map(attrName => tab.attributes[attrName])
    this.groups = json.groups?.map(groupJSON => createGroup(groupJSON, tab)) ?? []
    this.groups.forEach(group => {
      group.initialize()
    })
  }

  /** Ryhmän id:nä käytettävän merkkijonon. */
  getIdString(): string {
    return `group-${this.tab.name}-${this.name}`
  }

  /** Ryhmän otsikko. */
  getLabel(): string {
    return this.tab.t(`Attrs.${this.name}`, '')
  }

  /** Ryhmän vinkkiteksti _hint-avaimesta. */
  getHint(): string {
    return this.tab.t(`Attrs.${this.name}_hint`, '')
  }

  /** Ryhmän readonly-esitys annetun tuplen arvoilla. Oletuksena "v1, v2, v3" jne. */
  getDisplayValue(tuple: Tuple): string {
    return this.attributes.map(attr => tuple.values[attr.name]?.getDisplayValue() ?? '').join(', ')
  }

  initialize(): void {
    this.inputField = shallowRef(selectGroupInputField(this))
    this.attributes.forEach(attr => {
      // Merkitään attribuutille sisäkkäisin ryhmä iterointia varten
      if (!attr.group) attr.group = this
    })
    this.groups.forEach(group => group.initialize())
  }

  /** Ryhmä piilotetaan, jos mihinkään sen attribuuttiin ei ole VIEW-oikeutta. */
  isVisible(tuple: Tuple): boolean {
    return this.attributes.some(attr => attr.hasPermission(AccessType.VIEW, tuple))
  }

  /** Palauttaa ryhmän (tai aliryhmän), jolla annettu nimi */
  findGroup(name: string): Group {
    if (this.name === name) return this
    return <Group>this.groups.find(g => g.findGroup(name))
  }
}
