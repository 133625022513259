<template>
  <div :class="['sidebar', { narrow }]">
    <div class="layout-selector-section">
      <q-btn
        icon="timeline"
        :style="getButtonStyle(activeView === 'SidebarTimelineView')"
        @click="(activeView = 'SidebarTimelineView'), $emit('collapsedChange', false)"
      />
      <q-btn
        icon="fa-solid fa-comments"
        :style="getButtonStyle(activeView === 'SidebarChatView')"
        @click="(activeView = 'SidebarChatView'), $emit('collapsedChange', false)"
      >
        <div v-if="hasNewChatMessages()" :title="$t('SidebarChatView.newChatMessages')" class="new_message_indicator"></div>
      </q-btn>
      <q-btn
        icon="fa-duotone fa-tags"
        :style="getButtonStyle(activeView === 'SidebarLabelsView')"
        @click="(activeView = 'SidebarLabelsView'), $emit('collapsedChange', false)"
      />
    </div>
    <div class="sidebar-content">
      <component :is="activeView" v-if="activeView" />
    </div>
    <div style="flex-grow: 1"></div>
    <div class="collapse-icon-section">
      <q-btn v-if="!narrow" class="collapse-icon" flat icon="fa-solid fa-left-to-line" @click="(activeView = null), $emit('collapsedChange', true)" />
      <q-btn v-else class="collapse-icon" flat icon="fa-solid fa-right-from-line" @click="$emit('collapsedChange', false)" />
    </div>
  </div>
</template>

<script lang="ts">
import type { CSSProperties } from 'vue'
import { defineAsyncComponent } from 'vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'

import SafeboxSelector from '@/components/SafeboxSelector.vue'
import SidebarChatView from '@/components/SidebarChatView.vue'
import SidebarLabelsView from '@/components/SidebarLabelsView.vue'
import SidebarTimelineView from '@/components/SidebarTimelineView.vue'
import Imports from '@/utils/Imports'
import LanguageSelector from '@/views/LanguageSelector.vue'
import UserStatusView from '@/views/UserStatusView.vue'

type SidebarViewType = 'SidebarTimelineView' | 'SidebarChatView' | 'SidebarLabelsView'

@Component({
  components: {
    SafeboxSelector,
    PageListView: defineAsyncComponent(() => import('@/views/PageListView.vue')),
    LanguageSelector,
    UserStatusView,
    SidebarTimelineView,
    SidebarChatView,
    SidebarLabelsView
  }
})
export default class SidebarView extends Vue {
  @Prop({ type: Boolean, default: false })
  narrow!: boolean

  activeView: SidebarViewType | null = this.getActiveView()

  getButtonStyle(route?: string | boolean) {
    const styles: CSSProperties = {
      width: '5em',
      background: 'white',
      borderRadius: '0.5em'
    }

    if (route && ((typeof route === 'string' && this.$route.fullPath.startsWith(route)) || (typeof route === 'boolean' && route))) {
      styles.background = '#5e7388'
      styles.color = 'white'
    }

    return styles
  }

  getActiveView() {
    if (this.narrow) {
      return null
    } else {
      return 'SidebarTimelineView'
    }
  }

  get isMultiLingual(): boolean {
    return (this.$store.appConfig?.languages.length ?? 1) > 1
  }

  private routeTo(route: string): void {
    if (this.$router.currentRoute.value.path !== route) {
      this.activeView = null
      this.$router.push(route)
    }
  }

  private hasNewChatMessages(): boolean {
    return (this.$store.user?.getNewMessageCount(Imports.appStore.currentSafebox?.values.id.valueToString()) ?? 0) > 0
  }
}
</script>

<style lang="scss">
.sidebar.narrow .safebox-selector-section .q-btn {
  background: transparent !important;

  .q-btn__content {
    font-size: 1.5em;
    position: relative;
    left: 0.5em;

    .q-icon:last-child {
      position: relative;
      left: -0.3em;
    }
  }
}
</style>

<style scoped lang="scss">
.sidebar {
  width: 630px;
  height: 100%;
  background-color: white;
  color: #707070;
  padding: 3rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #e4e4e4;
  transition-property: width;
  transition-duration: 100ms;
  overflow: hidden;

  .safebox-selector-section {
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 0 3em;

    .q-icon {
      font-size: 3em;
    }

    span {
      font-size: 1.1em;
      font-weight: bold;
      position: relative;
      top: 0.2em;
      color: black;
      margin-left: 0.5em;
    }

    .spacer {
      flex-grow: 1;
    }
  }

  .new_message_indicator {
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    background-color: #ff5353;
  }

  .layout-selector-section {
    display: flex;
    justify-content: center;
    width: 30em;
    gap: 33px;
    margin-top: 1em;
  }

  .sidebar-content {
    align-self: stretch;
    margin-top: 2.5em;
    overflow: hidden;
    height: 100%;
  }

  .collapse-icon-section {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    width: 7rem;
    margin-bottom: 2rem;
    margin-left: -3rem;
  }

  &.narrow {
    width: 7rem;
    padding: 0;
    padding-top: 3em;

    .safebox-selector-section {
      padding: 0;
      display: flex;
      justify-content: center;
    }

    .layout-selector-section {
      width: auto;
      flex-direction: column;
      gap: 1rem;
    }

    .sidebar-content {
      display: none;
    }

    .collapse-icon-section {
      margin-left: 0;
    }
  }
}
</style>
