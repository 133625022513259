<template>
  <div id="app">
    <q-inner-loading :showing="!configReady" class="k-loading" />
    <Layout v-if="configReady" />
    <ErrorDialog />
  </div>
</template>

<script lang="ts">
import { markRaw } from 'vue'
import { Component, Vue } from 'vue-facing-decorator'

import AppConfig, { AppConfigJSON } from '@/classes/AppConfig'
import NetworkError from '@/classes/errors/NetworkError'
import TechnicalError from '@/classes/errors/TechnicalError'
import User from '@/classes/User'
import ErrorDialog from '@/components/ErrorDialog.vue'
import { app } from '@/create-app'
import Layout from '@/Layout.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import { getUserLangOrDefault } from '@/utils/i18n'
import { createKantoRouter } from '@/utils/router'

@Component({
  components: {
    ErrorDialog,
    Layout
  }
})
export class App extends Vue {
  /** Onko appConfig ja muut konfiguroinnit valmiit sovelluksen näyttämiseksi? */
  configReady = false

  beforeCreate(): void {
    this.$store.$patch({
      appConfigPromise: apiFetchJSON<AppConfigJSON>('appconfig')
        .catch(error => {
          throw new NetworkError(error)
        })
        .then(appConfigJSON => {
          const appConfig = markRaw(new AppConfig(appConfigJSON))
          this.$store.$patch({ appConfig, appConfigPromise: null, csrfToken: appConfigJSON.csrfToken })
          this.$store.setLang(getUserLangOrDefault())
          if (appConfigJSON.user) {
            // Jos käyttäjällä on vanha sessio voimassa, asetetaan käyttäjätiedot paikoilleen.
            this.$store.$patch({ user: new User(appConfigJSON.user) })
          }
          // Määritellään router vasta AppConfigin haun jälkeen, jotta routeihin saadaan heti lisättyä myös CustomViewit.
          app.use(createKantoRouter())
          this.$router.isReady().then(() => {
            // Näytetään sovellus vasta kun vue-router on saanut beforeEach-hookin suoritettua, jotta $route:na ei pääse näkymään hetkellisesti '/'.
            this.configReady = true
          })
          return this.$store.appConfig!
        })
        .catch(error => {
          if (error instanceof NetworkError) {
            throw error
          }
          throw new TechnicalError('Sovelluskonfiguraation käsittely epäonnistui: ' + error, error instanceof Error ? error : undefined)
        })
    })
  }
}
export default App
</script>

<style lang="scss">
@import '@/styles/app.scss';

.k-button {
  margin-top: 10px;
  margin-left: 10px;
}
#k-app-content {
  padding: 1em;
  .k-breadcrumbs {
    margin-bottom: 1em;
  }
}
</style>
