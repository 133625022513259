import { Notify } from 'quasar'

export enum NotificationLevel {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

/** Palvelimelta siirrettävä notifikaatiorakenne. */
export interface NotificationJSON {
  level: NotificationLevel
  title: string
  explanation: string | undefined
  dismissable: boolean
}

export type NotifyOptions = Omit<Exclude<Parameters<typeof Notify.create>[0], string>, 'message'>

export class Notification {
  level: NotificationLevel
  title: string
  explanation: string | undefined
  dismissable: boolean
  /** Aikaleima, milloin tämä ilmoitus näytettiin käyttäjälle. */
  displayTime: number
  options: NotifyOptions
  protected dismissFunction?: () => void

  /** Käyttäjälle näytettävä ilmoitus, oletuksea ei vaadi kuittausta */
  constructor(level: NotificationLevel, title: string, explanation?: string, dismissable = false, options?: NotifyOptions) {
    this.level = level
    this.title = title
    this.explanation = explanation
    this.dismissable = dismissable
    this.displayTime = Date.now()
    this.options = options ?? {}
  }

  /** Näytetään käyttäjälle annettu ilmoitus. */
  show(): void {
    switch (this.level) {
      case NotificationLevel.SUCCESS:
        this.options.color = 'positive'
        this.options.icon = 'check'
        break
      case NotificationLevel.INFO:
        this.options.color = 'info'
        this.options.icon = 'info'
        break
      case NotificationLevel.WARNING:
        this.options.color = 'warning'
        this.options.icon = 'warning'
        break
      case NotificationLevel.ERROR:
        this.options.color = 'negative'
        this.options.icon = 'error'
        break
    }
    if (this.dismissable) {
      this.options.timeout = 0
      this.options.actions = [{ icon: 'close', color: 'white' }]
    }
    this.dismissFunction = Notify.create({ ...this.options, message: this.title, caption: this.explanation })
  }

  /** Piilottaa näkyvissä olevan ilmoituksen. */
  dismiss(): void {
    this.dismissFunction?.()
  }

  static fromJSON(json: NotificationJSON): Notification {
    return new Notification(json.level, json.title, json.explanation, json.dismissable)
  }
}
