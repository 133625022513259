<template>
  <q-dialog v-model="showDialog" no-route-dismiss no-backdrop-dismiss no-esc-dismiss>
    <q-card class="update-required-dialog">
      <q-card-section>
        <div class="text-h5"><q-icon name="warning" color="warning" size="1.4em" /> {{ $t('UpdateRequiredDialog.header') }}</div>
      </q-card-section>

      <q-card-section>
        {{ $t('UpdateRequiredDialog.content') }}
      </q-card-section>

      <q-card-actions align="between">
        <q-btn flat :label="$t('UpdateRequiredDialog.reload')" color="primary" :loading="clicked" @click="reload" />
        <q-btn v-close-popup flat :label="$t('UpdateRequiredDialog.continue')" color="primary" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export class UpdateRequiredDialog extends Vue {
  dismissed = false
  clicked = false

  get updateRequired(): boolean {
    return this.$store.updateRequired
  }

  get showDialog(): boolean {
    return this.updateRequired && !this.dismissed
  }

  set showDialog(_: boolean) {
    this.dismissed = true
  }

  reload(): void {
    window.location.reload()
    this.clicked = true
  }
}
export default UpdateRequiredDialog
</script>

<style lang="scss">
.session-expire-dialog {
  padding: 1em;

  .q-btn {
    padding: 5px;
  }
}
</style>
