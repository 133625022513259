<template>
  <q-btn :href="url" color="primary" size="lg">SSO Login</q-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({ emits: ['click'] })
/** Ylikirjoita tämä komponentti LoginViewissä näytettävän SSO-napin räätälöimiseksi. */
export class SSOLoginButton extends Vue {
  @Prop()
  url!: string
}
export default SSOLoginButton
</script>

<style lang="scss"></style>
