import { Router } from 'vue-router'

import type { useKantoStore } from '@/utils/store'

/**
 * Apuluokka routerin ja storen käsittelyyn ilman tarvetta importoida niitä. Näin saadaan vältettyä syklisiä riippuvuuksia.
 * Käytä vain Vue-komponenttien ulkopuolella, sillä Vue-komponenteissa on käytettävissä this.$router ja this.$store.
 *
 * Exportoidaan class nimettynä exporttina, jotta sen laajennus onnistuu sovelluksessa.
 */
export class ImportsClass {
  router!: Router
  // Alustetaan dummy-objectilla, jotta frontin virheiden lokitus backendiin onnistuisi, vaikka storea ei ole vielä alustettu.
  store: ReturnType<typeof useKantoStore> = {} as ReturnType<typeof useKantoStore>
}
export default new ImportsClass()
