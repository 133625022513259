import { Dayjs } from 'dayjs'
import { defineStore } from 'pinia'
import type { RouteLocationNormalized } from 'vue-router'

import type AppConfig from '@/classes/AppConfig'
import type BaseError from '@/classes/errors/BaseError'
import { Notification, NotificationLevel } from '@/classes/Notification'
import type Tab from '@/classes/Tab'
import type Table from '@/classes/Table'
import type Tuple from '@/classes/Tuple'
import type User from '@/classes/User'
import { Pagination } from '@/types/quasar-additions'
import { getUserLang, setLang } from '@/utils/i18n'

export interface KantoState {
  /** Sovelluksen konfiguraatio. Alustetaan mainissa ennen Vue-instanssin luontia. */
  appConfig: AppConfig | null
  /** Promise AppConfigin latauksen ajaksi. */
  appConfigPromise: Promise<AppConfig> | null
  /** Sovelluksen aktiivinen kieli */
  lang: string
  /** CSRF-token */
  csrfToken: string | null
  /** Kulloinkin käsiteltävä tuple */
  tuple: Tuple | null
  /** Parent-hierarkia. Erillisenä tuplesta, jotta parentiin päästään käsiksi myös uuden tuplen fetchin aikana. */
  parentTuple: Tuple | null
  /** Käyttäjän syöttämät hakuehdot tauluittain. Avaimena on taulun nimi ilman "_search"-päätettä. */
  searchTuples: { [key: string]: Tuple }
  /** Sovelluksessa tapahtunut virhe, joka estää normaalin suorituksen */
  errors: BaseError[]
  /** Sisäänkirjautunut käyttäjä */
  user: User | null
  /** Taulujen sivutusasetukset. */
  paginations: { [key: string]: Pagination }
  /** Tabien collapse-asetukset. */
  collapse: { [key: string]: boolean }
  /** Polku johon palataan loginin jälkeen */
  loginRedirect: RouteLocationNormalized | null
  /** Listauksessa valitut monikot taulun nimellä indeksoituna */
  selectedRows: { [key: string]: Tuple[] }
  /** Käyttäjälle näytettävät ilmoitukset */
  notifications: Notification[]
  /** Sisälsikö viimeisin fetch ERROR-tasoisen notifikaation? */
  lastFetchReturnedError: boolean
  /** Palvelimen päiväys ja kellonaika viimeisimmän responsen hetkellä */
  serverDate: Dayjs | null
  /** Käyttäjän istunnon happanemishetki */
  sessionExpires: Dayjs | null
  /** Onko käyttäjä ohjattu ainoaan hakutulokseen? */
  searchRedirected: boolean
  /** Onko frontendin versio vanhempi kuin backendin? */
  updateRequired: boolean
  /** Viimeisin hetki, jolloin käyttäjä on tehnyt jotain (klikannut tai kirjoittanut) */
  lastUserAction: number
}

export const useKantoStore = defineStore({
  id: 'kanto',
  state: (): KantoState => ({
    appConfig: null,
    appConfigPromise: null,
    lang: getUserLang() ?? 'fi',
    csrfToken: null,
    tuple: null,
    parentTuple: null,
    searchTuples: {},
    errors: [],
    user: null,
    paginations: {},
    collapse: {},
    loginRedirect: null,
    selectedRows: {},
    notifications: [],
    lastFetchReturnedError: false,
    serverDate: null,
    sessionExpires: null,
    searchRedirected: false,
    updateRequired: false,
    lastUserAction: Date.now()
  }),
  getters: {
    getAncestorTuple: state => {
      return (tab: Tab) => {
        let tuple = state.tuple ?? state.parentTuple
        while (tuple && tuple.tab.name !== tab.name) {
          tuple = tuple.parent
        }
        return tuple
      }
    }
  },
  actions: {
    setLang(lang: string): void {
      this.lang = lang
      setLang(lang)
    },
    setTuple(tuple: Tuple | null): void {
      this.tuple = tuple
      if (tuple?.parent) {
        // Uuden tuplen mukana parent-tieto -> päivitetään se parentTupleen
        this.parentTuple = tuple.parent
      } else if (tuple) {
        // Tuplelta puuttuu parent-tieto -> asetetaan se parentTuplesta
        tuple.parent = this.parentTuple
      }
    },
    clearSearchTuples(table: Table): void {
      // Poistetaan annetun tablen ja sen mahdollisten lapsien searchTuplet
      delete this.searchTuples[table.name]
      if (table.children) {
        for (const child of Object.values(table.children)) {
          this.clearSearchTuples(child)
        }
      }
    },
    addNotification(notification: Notification): void {
      if (notification.level === NotificationLevel.SUCCESS && this.notifications.some(n => n.level === NotificationLevel.SUCCESS)) {
        // Ollaan näyttämässä uutta SUCCESS-ilmoitusta ja saman tasoinen ilmoitus on jo näkyvissä -> piilotetaan vanha SUCCESS-ilmoitus
        this.notifications = this.notifications.filter(n => {
          const dismiss = n.level === NotificationLevel.SUCCESS
          if (dismiss) {
            n.dismiss()
          }
          return !dismiss
        })
      }
      this.notifications.push(notification)
      if (notification.level === NotificationLevel.ERROR) {
        this.lastFetchReturnedError = true
      }
      notification.show()
    },
    /** Piilottaa ilmoitukset, jotka ovat olleet näkyvillä ennen käyttäjän viimeisintä klikkiä/painallusta. */
    dismissNotifications(): void {
      this.lastFetchReturnedError = false
      this.notifications = this.notifications.filter(notification => {
        const dismiss = notification.displayTime < this.lastUserAction
        if (dismiss) {
          // Piilotetaan tarpeeksi kauan näytetyt notifikaatiot
          notification.dismiss()
        }
        // Loput jätetään storeen säilöön
        return !dismiss
      })
    }
  }
})

function logUserActionTime() {
  useKantoStore().lastUserAction = Date.now()
}
document.addEventListener('click', logUserActionTime, { passive: true })
document.addEventListener('keydown', logUserActionTime, { passive: true })

// @ts-ignore
if (window.Cypress) {
  // E2E-testeille store-viittaus
  // @ts-ignore
  window.useKantoStore = useKantoStore
}
