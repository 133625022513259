import {
  apiFetchJSON,
  deleteTuple as kDeleteTuple,
  deleteTuples as kDeleteTuples,
  insertTuple as kInsertTuple,
  logout as kLogout,
  updateTuple as kUpdateTuple
} from '@kanto/utils/api-functions'
import EventEmitter from 'eventemitter2'

import Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'
import { apiPath } from '@/utils/paths'

export * from '@kanto/utils/api-functions'

/**
 * - Päivitetään tallelokerovalitsin aina, kun tallelokeroja muutetaan.
 * - Päivitetään LabelMenu aina, kun labeleita tai palveluita muutetaan. Erityisesti service_label.count tarvitaan
 * poistodialogia varten, vaikka labelien joukko ei muuttuisi.
 */
function changed(tuple: Tuple): void {
  const tab = tuple.tab

  if (tab.name === 'safebox') Imports.appStore.refreshSafeboxes()
  if (['label', 'service'].includes(tab.name)) Imports.appStore.refreshLabels()
}

export const tupleMutationEventBus = new EventEmitter({
  wildcard: true
})

tupleMutationEventBus.on('insert.*', changed)
tupleMutationEventBus.on('update.*', changed)
tupleMutationEventBus.on('delete.*', changed)

export async function logout() {
  await kLogout()
  Imports.appStore.logout()
}

export function insertTuple(tuple: Tuple): Promise<Tuple> {
  return kInsertTuple(tuple).then(t => {
    tupleMutationEventBus.emit(['insert', t.tab.name], t)
    return t
  })
}

export function updateTuple(tuple: Tuple, origKey: string): Promise<Tuple> {
  return kUpdateTuple(tuple, origKey).then(t => {
    tupleMutationEventBus.emit(['update', t.tab.name], t)
    return t
  })
}

async function saveDeleteInfo(tuple: Tuple) {
  if (tuple.tab.name === 'service') {
    const { default: Table } = await import('@/classes/Table')
    const deletionInfo = new Tuple(Table.getTable('deleted'))
    deletionInfo.setValue('table_name', tuple.tab.name)
    deletionInfo.setValue('safebox', Imports.appStore.currentSafebox!.values.id?.v)
    deletionInfo.setValue(
      'keys',
      Object.values(tuple.tab.attributes)
        .filter(attr => attr.key || attr.isParentReference())
        .map(attr => `${attr.name}=${tuple.values[attr.name].v}`)
        .join(' and ')
    )
    deletionInfo.setValue('name', tuple.values.name.getDisplayValue())

    await insertTuple(deletionInfo)
  }
}

export async function deleteTuple(tuple: Tuple): Promise<Response> {
  await saveDeleteInfo(tuple)

  return kDeleteTuple(tuple).then(r => {
    tupleMutationEventBus.emit(['delete', tuple.tab.name], tuple)
    return r
  })
}

export async function deleteTuples(tuples: Tuple[]): Promise<Response> {
  await Promise.all(tuples.map(saveDeleteInfo))

  return kDeleteTuples(tuples).then(r => {
    tuples.forEach(tuple => tupleMutationEventBus.emit(['delete', tuple.tab.name], tuple))
    return r
  })
}

export type FetchUserKeysOptions = { username: string } | { govId: string }

export type UserCryptoKey = {
  id: string
  key: CryptoKey
}

export type UserCryptoKeysResponse = {
  username?: string
  keys: Array<{
    id: string
    publicKey: JsonWebKey
  }>
}

export type FetchUserKeysResult = {
  keys: Array<UserCryptoKey>
  username?: string
}

export async function fetchUserKeys(options: FetchUserKeysOptions): Promise<FetchUserKeysResult> {
  let query

  if ('govId' in options) {
    query = `gov_id=${encodeURIComponent(options.govId)}`
  } else if ('username' in options) {
    query = `username=${encodeURIComponent(options.username)}`
  } else {
    throw new Error('Unreachable code reached!')
  }

  const url = `${apiPath}/keys?${query}`
  const { keys, username } = await apiFetchJSON<UserCryptoKeysResponse>(url)

  const importKey = (key: JsonWebKey) =>
    crypto.subtle.importKey(
      'jwk',
      key,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-512'
      },
      true,
      ['encrypt', 'wrapKey']
    )

  return {
    keys: await Promise.all(keys.map(async key => ({ id: key.id, key: await importKey(key.publicKey) }))),
    username
  }
}
