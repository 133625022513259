/**
 * Taulu, jonka arvoja voi odottaa asynkronisesti, jos niitä ei ole vielä määritelty.
 */
export class AsyncMap<K, V> {
  /** Taulu asetetuista arvoista. */
  private values: Map<K, V> = new Map()

  /**
   * Taulu avaimista, joille ei ole vielä asetettu arvoa, mutta joiden arvoa odotetaan.
   * Arvot ovat funktioita, jotka odottavat näiden avainten arvoja.
   */
  private listeners: Map<K, Array<(value: V) => void>> = new Map()

  /**
   * Palauttaa avainta vastaavan arvon, jos sellainen on määritelty.
   * Muutoin odottaa, kunnes avainta vastaava arvo asetetaan tauluun.
   */
  public async get(key: K): Promise<V> {
    const value = this.values.get(key)

    if (value) {
      return value
    }

    return new Promise(resolve => {
      let listeners = this.listeners.get(key)

      if (!listeners) {
        listeners = []
        this.listeners.set(key, listeners)
      }

      listeners.push(resolve)
    })
  }

  /**
   * Asettaa avaimen ja sitä vastaavan arvon tauluun.
   * Jos tiedosa on funktioita, jotka odottavat tämän avaimen saavan arvon, kutsutaan näitä funktioita,
   * ilman että niiden valmistumista odotetaan.
   */
  public set(key: K, value: V) {
    this.values.set(key, value)

    const listeners = this.listeners.get(key)

    if (listeners) {
      this.listeners.delete(key)
      listeners.forEach(listener => listener(value))
    }
  }
}
