import BaseError from '@/classes/errors/BaseError'

/**
 * Sovelluksen ennakoimattomat virheet, joista käyttäjälle näytetään vain geneerinen virheilmoitus.
 */
export default class TechnicalError extends BaseError {
  innerError?: Error

  constructor(message: string, innerException?: Error, info?: string) {
    super(message)
    this.innerError = innerException
    this.info = info
  }

  public toString(): string {
    if (!this.innerError) {
      return super.toString()
    }
    return `${super.toString()}\nInnerError: ${this.innerError}`
  }
}
