<template>
  <div ref="list" :class="['safebox-list', { small }]">
    <SafeboxCard v-for="safebox in safeboxes" :key="safebox.values.id.v" :safebox="safebox" :small="small" @select="$emit('select', $event)" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useDraggable } from 'vue-draggable-plus'

import Tuple from '@/classes/Tuple'
import SafeboxCard from '@/components/SafeboxCard.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import Imports from '@/utils/Imports'
import { apiPath } from '@/utils/paths'

const props = defineProps<{ small?: boolean; group?: string }>()
const model = defineModel<Tuple[]>()

defineEmits<{ select: [safebox: Tuple] }>()

const safeboxIds = ref<number[]>([])
const list = ref<HTMLDivElement>()

const safeboxes = computed(() =>
  safeboxIds.value
    .map(id => Imports.appStore.safeboxes.find(safebox => safebox.values.id.v === id))
    .map(value => (value ? [value] : []))
    .flat()
)

watch(
  () => model.value ?? Imports.appStore.safeboxes ?? [],
  newSafeboxes => {
    safeboxIds.value = newSafeboxes.map(tuple => tuple.values.id.v as number)
  }
)

const update = async () => {
  model.value = safeboxes.value

  await apiFetchJSON(`${apiPath}/update-safebox-order`, {
    method: 'POST',
    body: JSON.stringify({
      order: safeboxes.value.map(safebox => safebox.values.id.v),
      group: props.group ?? null
    })
  })

  Imports.appStore.safeboxes.forEach(safebox => {
    if (safeboxes.value.some(s => s.getKeyString() === safebox.getKeyString())) {
      safebox.setValue('group', props.group ?? null)
    }
  })
}

useDraggable(list, safeboxIds, {
  animation: 150,

  group: 'safebox-grid',

  onUpdate: update,
  onAdd: update
})
</script>

<style scoped lang="scss">
.safebox-list {
  --gap: 2em;
  --card-width: 12em;
  --aspect-ratio: #{284 / 169};
  --card-height: calc(var(--card-width) * var(--aspect-ratio));
  --name-height: 4.5em;
}

.safebox-list.small {
  --gap: 1em;
  --card-width: 4em;
  --aspect-ratio: 1;
  --name-height: 0px;
}

.safebox-list {
  $max-columns: 4;

  --padding: 1em;
  --max-width: calc(var(--gap) * #{$max-columns - 1} + var(--card-width) * #{$max-columns});

  padding: var(--padding) calc((100% - var(--max-width)) / 2);

  grid-area: content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: calc(var(--card-height) + 2 * var(--padding) + var(--name-height));
  gap: var(--gap);
  transition-duration: var(--group-duration);
  flex-wrap: wrap;

  :deep(.sortable-ghost) {
    .safebox-card {
      background: none;
      box-shadow: none;
    }

    .background {
      background-color: transparent !important;
      // Temppu, jolla saadaan kustomoitua katkoviivareunuksen välitystä.
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='0.75em' ry='0.75em' stroke='rgba(0,0,0,0.1)' stroke-width='6' stroke-dasharray='10 10' stroke-dashoffset='10' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
    }

    .edit-button {
      display: none;
    }

    .indicators {
      transform: scale(0);
    }

    .safebox-name {
      opacity: 0;
    }
  }

  .q-card {
    cursor: pointer;
    border-radius: 0.75rem;
    background-color: lighten(#f1f2f4, 3);

    .safebox-name {
      color: #6f6f6f;
      font-size: 1rem;
    }
  }
}

.safebox-list.small .sortable-ghost {
  width: var(--card-width);
}

.safebox-list {
  --card-width: 12em;
  --card-aspect-ratio: #{169 / 248};
}

.safebox-list.small {
  --card-width: 4em;
  --card-aspect-ratio: 1;
}
</style>
