<template>
  <div class="root">
    <div class="header">Tapahtumavirta</div>
    <div class="timeline-container">
      <ul v-if="timeline.items.length > 0" class="timeline">
        <li v-for="(entries, key) in timelineGroups" :key="key" class="day-item" :style="{ '--day-color': aikajananvari(entries[0].timestamp) }">
          <div class="day-header">
            <div class="date-dot" />
            <div class="day-header-label">{{ aikaleimaFormaatti(entries[0].timestamp) }}</div>
          </div>
          <SidebarTimelineItemList :items="entries" class="day-items" />
        </li>
      </ul>
      <div v-else class="footer">Ei tapahtumia.</div>
      <q-btn v-if="timeline.more" class="load-more-button" dense color="#5E7388" :loading="timeline.fetching" @click="fetchMore">Näytä lisää</q-btn>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs, { Dayjs } from 'dayjs'
import { filter, groupBy } from 'lodash-es'
import { Component, Vue } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import { RealValueType } from '@/classes/Value'
import AccountAvatar from '@/components/AccountAvatar.vue'
import SidebarTimelineItem from '@/components/SidebarTimelineItem.vue'
import SidebarTimelineItemList from '@/components/SidebarTimelineItemList.vue'
import { tupleMutationEventBus } from '@/utils/api-functions'
import Imports from '@/utils/Imports'

/* eslint-disable camelcase */
type TimelineObjectTemplate<Table extends string = string, Info extends Record<string, unknown> = Record<string, never>> = {
  id: string
  timestamp: Dayjs
  new: boolean
  username: string
  tuple?: Tuple
  type: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8'
  tbl_name: Table
  criteria: string
  full_name: string
  info: Info
  deleted?: Record<string, RealValueType>
  children: number
}
/* eslint-enable camelcase */

export type TimelineObject =
  | TimelineObjectTemplate<'service', { exists: boolean }>
  | TimelineObjectTemplate<'note', { exists: boolean }>
  | TimelineObjectTemplate<'safebox'>
  | TimelineObjectTemplate<'safebox_access', { name: string }>
  | TimelineObjectTemplate<'invite', { invitor: string; invitee: string; status: 'PENDING' | 'ACCEPTED' | 'DECLINED' }>

@Component({
  components: { SidebarTimelineItemList, SidebarTimelineItem, AccountAvatar },
  methods: { dayjs }
})
export default class SidebarTimelineView extends Vue {
  get timeline() {
    return Imports.appStore.getTimeline()
  }

  onTupleChange = (event: string | string[], tuple: Tuple) => {
    if (['service', 'note'].includes(tuple.tab.name)) {
      this.reload()
    }
  }

  mounted(): void {
    tupleMutationEventBus.onAny(this.onTupleChange)
    this.reload()
  }

  beforeDestroy() {
    tupleMutationEventBus.offAny(this.onTupleChange)
  }

  async loadTimeline(id?: string) {
    await Imports.appStore.fetchTimeline(id)
  }

  async reload() {
    await Imports.appStore.fetchTimeline(undefined, 'newer')
  }

  get timelineGroups() {
    /* eslint-disable camelcase */
    return groupBy(
      filter(this.timeline.items, ({ full_name, tbl_name, info, type }) => {
        if (tbl_name === 'safebox_access' && info.name === full_name && type === '1') {
          return false
        }

        return true
      }),
      o => dayjs(o.timestamp).format('M.D.Y')
    )
    /* eslint-enable camelcase */
  }

  aikajananvari(leima: Dayjs): string {
    if (dayjs().isSame(leima, 'day')) return '#2196f3'
    return '#9e9e9e'
  }

  aikaleimaFormaatti(leima: Dayjs): string {
    const pohja = dayjs(leima).format('D.M.')
    if (dayjs().isSame(leima, 'day')) return `TÄNÄÄN ${pohja}`
    return pohja
  }

  actionKey(type: string) {
    return {
      '1': 'created',
      '2': 'viewed',
      '3': 'edited',
      '4': 'deleted',
      '5': 'searched',
      '6': 'exported',
      '7': 'released',
      '8': 'action'
    }[type]
  }

  translateObject(object: Record<string, unknown>, path: string[], context?: string): Record<string, unknown> {
    function isRecord(value: unknown): value is Record<string, unknown> {
      return typeof value === 'object' && value !== null
    }

    return Object.fromEntries(
      Object.entries(object).map(([key, value]) => {
        if (typeof value === 'string') {
          return [key, this.$t(`SidebarTimelineView.values.${path.join('.')}.${key}.${value}`, { context, defaultValue: value })]
        }

        if (isRecord(value)) {
          return [key, this.translateObject(value, [...path, key], context)]
        }

        return [key, value]
      })
    )
  }

  fetchMore() {
    Imports.appStore.fetchTimeline(undefined, 'older')
  }
}
</script>

<style scoped lang="scss">
@use 'sass:math';

$dot-size: 1em;
$indent: 2em;

.timeline-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: stretch;

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0 0 0 / 20%);
  }

  &::-webkit-scrollbar {
    max-width: 5px;
  }
}

ul.timeline {
  list-style: none;
  width: 100%;

  & > li {
    position: relative;

    padding: 0.5em 0;

    &::before {
      $bar-width: 3px;
      content: '';
      display: block;
      position: absolute;
      top: calc($dot-size + 1em);
      left: math.div($dot-size, 2);
      margin-left: math.div($bar-width, -2);
      width: $bar-width;
      background-color: var(--day-color);
      bottom: -0.5em;
      opacity: 0.4;
    }

    &:last-child::before {
      bottom: 0;
    }
  }

  .day-header {
    display: flex;
    align-items: center;
    gap: $indent;

    .day-header-label {
      font-weight: 700;
      font-size: 12px;
    }

    .date-dot {
      background-color: var(--day-color);
      width: $dot-size;
      height: $dot-size;
      border-radius: 9999px;
    }
  }

  .day-items {
    padding: 0 0 0 calc($indent + 1em);
    margin: 0;
  }
}

.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-family: 'Noto Sans';
}

.header,
.footer {
  flex-shrink: 0;
  text-align: center;
}

.header,
.footer,
.timeline-container {
  padding: 0 10px;
  overflow-y: auto;
  margin: 0;
}

.header {
  font-weight: bold;
  font-size: 14px;
}

.q-timeline {
  color: black;
  width: 100%;
}

.q-chip {
  font-weight: bold;
  color: grey;
  background-color: white;
  box-shadow: 0 0 3px 1px #7b787a;
}

.footer {
  padding-top: 10px;
}

.load-more-button {
  align-self: center;
  margin: 1em;
  background-color: #5e7388;
  padding: 0.3em 0.5em;
  flex-shrink: 0;
}
</style>
