<template>
  <div style="margin: 2em">
    <img src="../images/digua-logo.svg" alt="digua" />
    <q-card v-if="step == 'register'" style="background-color: white; border-radius: 0.4em; margin-top: 2em">
      <q-card-section>
        <h6>{{ $t('MobileAuthenticationView.registerCard.header') }}</h6>
      </q-card-section>
      <q-card-section>
        <p>{{ $t('MobileAuthenticationView.registerCard.body') }}</p>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn class="digua-primary" color="primary" rounded @click="registerWebauthn">{{ $t('MobileAuthenticationView.registerCard.button') }}</q-btn>
        <q-btn color="secondary" href="/" style="filter: grayscale(1)" rounded>{{ $t('MobileAuthenticationView.registerCard.cancelButton') }}</q-btn>
      </q-card-actions>
    </q-card>
    <q-card v-else-if="step == 'auth'" style="background-color: white; border-radius: 0.4em; margin-top: 2em">
      <q-card-section>
        <h6>{{ $t(`MobileAuthenticationView.promptCard.${register ? 'register' : 'authOnly'}.header`) }}</h6>
      </q-card-section>
      <q-card-section>
        <p>{{ $t(`MobileAuthenticationView.promptCard.${register ? 'register' : 'authOnly'}.body`) }}</p>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn class="digua-primary" color="primary" rounded @click="loginWebauthn">{{ $t('MobileAuthenticationView.promptCard.authenticateButton') }}</q-btn>
        <q-btn color="secondary" href="/" style="filter: grayscale(1)" rounded>{{ $t('MobileAuthenticationView.promptCard.cancelButton') }}</q-btn>
      </q-card-actions>
    </q-card>
    <q-card v-else-if="step == 'complete' && error" style="background-color: white; border-radius: 0.4em; margin-top: 2em">
      <q-card-section>
        <h6>{{ $t(`MobileAuthenticationView.failureCard.${error}.header`) }}</h6>
      </q-card-section>
      <q-card-section>
        <q-icon
          name="fas fa-shield-xmark"
          size="8em"
          style="
            margin-bottom: 2rem;
            background: linear-gradient(#f66767, #6f0c0c);
            background-clip: text;
            color: transparent;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
            -webkit-background-clip: text;
          "
        />
        <p>{{ $t(`MobileAuthenticationView.failureCard.${error}.body`) }}</p>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn class="digua-primary" color="primary" rounded @click="$router.push('/')">Sulje</q-btn>
      </q-card-actions>
    </q-card>
    <q-card v-else-if="step == 'complete' && !error" style="background-color: white; border-radius: 0.4em; margin-top: 2em">
      <q-card-section>
        <h6>{{ $t('MobileAuthenticationView.successCard.header') }}</h6>
      </q-card-section>
      <q-card-section>
        <q-icon
          name="fas fa-shield-check"
          size="8em"
          style="
            margin-bottom: 2rem;
            background: linear-gradient(#67f667, #0c6f0c);
            background-clip: text;
            color: transparent;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
            -webkit-background-clip: text;
          "
        />
        <p>
          Olet nyt tunnistautunut laitteelle, jolta aloitit tunnistautumisen. Voit sulkea tämän selainikkunan ja jatkaa palvelun käyttöä toisella laitteellasi.
        </p>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn class="digua-primary" color="primary" rounded @click="$router.push('/')">Sulje</q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import { apiFetchJSON } from '@/utils/api-functions'
import { arrayBufferToBase64, base64ToArrayBuffer } from '@/utils/crypt'
import { getAesWrappingKeyFromCredential, webAuthnLogin, webauthnRegister } from '@/utils/crypt-utils'

type AuthenticationError = 'unknown' | 'user-declined'

type AuthenticationResult = {
  error?: AuthenticationError
}

type Step = 'register' | 'auth' | 'complete'

@Component({
  methods: { webauthnRegister }
})
export default class MobileLoginView extends Vue {
  private conn!: WebSocket
  private token = ''
  private name: string | null = null
  private secret = new ArrayBuffer(0)
  private step: Step = 'auth'
  private error: AuthenticationError | null = null

  get register() {
    return !!this.name
  }

  created() {
    const params = new URLSearchParams(document.location.hash.substring(1))
    document.location.hash = ''

    this.token = params.get('token') ?? ''
    this.name = params.get('name')
    this.step = this.name ? 'register' : 'auth'
    const secret = params.get('secret')

    if (secret) {
      this.secret = base64ToArrayBuffer(secret)
    }

    document.location.hash = ''
  }

  async promptForSecurityKey(): Promise<Credential | undefined> {
    // TODO: Hae backendistä sessioon tallennettava random token, jota käytetään WebAuthn challengena
    const credential = await webAuthnLogin().catch(error => {
      console.error(error)
      // Näytetään käyttäjälle geneerinen virheilmoitus seuraavassa ehtolauseessa
    })

    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('LandingPage.securityDeviceLoginFailed')))
      return
    }

    // @ts-ignore
    if (!credential?.getClientExtensionResults()?.prf?.results?.first && !credential?.getClientExtensionResults()?.largeBlob?.blob) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      return
    }

    return credential
  }

  async registerWebauthn() {
    if (!this.name) {
      console.log('No name defined!')
      this.step = 'complete'
      this.error = 'unknown'
      return
    }

    const credential = await webauthnRegister(this.name, this.$store.user).catch(error => {
      console.error(error)
    })
    // @ts-ignore
    console.debug('WebAuthn registration credential', credential, credential?.getClientExtensionResults())
    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed')))
      return
    }
    // @ts-ignore
    if (!credential?.getClientExtensionResults()?.prf?.enabled && !credential?.getClientExtensionResults()?.largeBlob?.supported) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      return
    }

    this.step = 'auth'
  }

  async loginWebauthn(): Promise<void> {
    const credential = await this.promptForSecurityKey()

    if (!credential) {
      return
    }

    const wrappingKey = await getAesWrappingKeyFromCredential(credential)

    await this.pushKey(credential.id, wrappingKey)
  }

  async pushKey(authenticatorId: string, wrappingKey: CryptoKey) {
    const iv = new Uint8Array(32)
    await crypto.getRandomValues(iv)

    const channelPassword = await crypto.subtle.importKey('raw', this.secret, 'PBKDF2', false, ['deriveKey'])
    const channelKey = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: base64ToArrayBuffer(this.token),
        iterations: 1000,
        hash: 'SHA-256'
      },
      channelPassword,
      { name: 'AES-GCM', length: 256 },
      true,
      ['wrapKey']
    )

    const key = await crypto.subtle.wrapKey('raw', wrappingKey, channelKey, { name: 'AES-GCM', iv })

    try {
      const response = await apiFetchJSON<AuthenticationResult>(`mobile-login-push`, {
        method: 'POST',
        body: JSON.stringify({
          token: this.token,
          authenticatorId,
          iv: arrayBufferToBase64(iv),
          key: arrayBufferToBase64(key)
        })
      })

      if (response.error) {
        this.error = response.error
      }
    } catch (err) {
      console.log(err)
      this.error = 'unknown'
    } finally {
      this.step = 'complete'
    }
  }
}
</script>

<style scoped lang="scss">
.q-card {
  flex: 1;
  text-align: center;
  transition-duration: 150ms;
  background-color: white;
  border-radius: 10rem;
  max-width: 30rem;
  margin: 0 auto;
}

.q-btn {
  color: white;
  font-weight: 600;
  height: 3.5em;
  text-transform: none;
  padding: 0 3em;
  background-image: linear-gradient(to bottom, #00d0f1, #048ed3) !important;
}

.q-btn.digua-primary {
  background-image: linear-gradient(to bottom, #00d0f1, #048ed3) !important;
  color: white;
  font-weight: 600;
  height: 3.5em;
  text-transform: none;
  padding: 0 3em;
}
</style>
