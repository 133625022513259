import KTuple, { CRYPT_DATA_ATTR_NAME, CRYPT_IV_ATTR_NAME, TupleJSON } from '@kanto/classes/Tuple'

import type Tab from '@/classes/Tab'
import type Value from '@/classes/Value'
import type { ValueJSON } from '@/classes/Value'
import { decryptStringToString } from '@/utils/crypt'
import Imports from '@/utils/Imports'

export * from '@kanto/classes/Tuple'

export default class Tuple extends KTuple {
  static async fromJSON(json: TupleJSON, tab: Tab, parent?: Tuple | null): Promise<Tuple> {
    if (tab.name === 'invite_key' && Imports.store.user) {
      let cryptoKeyId: number | undefined

      if (Imports.store.user) {
        ;({ cryptoKeyId } = await Imports.store.user.keyPair.get())
      }

      if (json.values.cryptkey_id.v !== cryptoKeyId) {
        // Ei yritetä purkaa tietoja, joihin meillä ei ole avainta.
        delete json.values[CRYPT_DATA_ATTR_NAME]
        delete json.values[CRYPT_IV_ATTR_NAME]
      }
    }

    const tuple = await super.fromJSON(json, tab, parent)
    if (tuple.tab.name === 'note') {
      if (tuple.values.service?.hasContent()) {
        const decryptKey = await Imports.store.user?.getSafeboxKey(tuple.values.safebox.valueToString())
        await decryptRefValue(decryptKey, tuple.values.service)
      } else {
        tuple.values.service.d = ''
      }
    }
    return tuple
  }
}

export async function decryptRefValue(decryptKey: CryptoKey | undefined, value: Value): Promise<Value> {
  if (decryptKey && value.d) {
    // Tuodaan ref-valuen displayValuessa cryptdata ja cryptiv JSON-muotoisena
    const { cryptdata, cryptiv } = JSON.parse(value.getDisplayValue())
    const decryptedJSON = JSON.parse(await decryptStringToString(cryptdata, cryptiv, decryptKey)) as {
      [key: string]: ValueJSON
    }
    value.d = decryptedJSON.name.v as string
  }
  return value
}
