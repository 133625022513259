<template>
  <div :class="['indicators', { vertical }]">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

import SafeboxActivityIndicator from '@/components/SafeboxActivityIndicator.vue'
import SafeboxOwnerIndicator from '@/components/SafeboxOwnerIndicator.vue'
import SafeboxShareIndicator from '@/components/SafeboxShareIndicator.vue'

@Component({
  components: { SafeboxActivityIndicator, SafeboxShareIndicator, SafeboxOwnerIndicator }
})
export default class SafeboxIndicators extends Vue {
  @Prop({ type: Boolean })
  vertical!: boolean
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

$max-indicators: 5;
$overlap: 0.15;

.indicators {
  position: absolute;
  z-index: 0;
  top: #{$indicator-size * -0.33};
  right: #{$indicator-size * -0.33};
  transition-duration: $group-transition-duration;
  transform: scale(1);
  opacity: 1;
  transform-origin: center calc(#{$indicator-size} / 2);
  display: flex;
  flex-direction: column;

  &.vertical {
    flex-direction: row;

    & > :deep(.indicator:not(:first-child)) {
      margin-left: #{$indicator-size * -$overlap};
    }
  }

  &:not(.vertical) {
    & > :deep(.indicator:not(:first-child)) {
      margin-top: #{$indicator-size * -$overlap};
    }
  }
}

@for $i from 1 through $max-indicators {
  .indicators > .indicator:nth-child(#{$i}) {
    z-index: #{$max-indicators - $i};
  }
}
</style>
