import { shallowRef } from 'vue'

import { selectInputField } from '@/classes/DefaultInputFields'
import Page, { PageJSON } from '@/classes/Page'
import Tab from '@/classes/Tab'
import { UserJSON } from '@/classes/User'
import { publicPath } from '@/utils/paths'

export interface AppConfigJSON {
  languages: string[]
  pages: PageJSON[]
  user?: UserJSON
  copyright: string
  loginMethod: { userpass: boolean; sso: boolean; ssoLoginUrl?: string; ssoLogoutUrl?: string; dsUrl?: string }
  settings: { [key: string]: string }
  csrfToken: string
}

export default class AppConfig {
  languages: string[]
  /** Juuritason sivut */
  pages: { [key: string]: Page } = {}
  /** Kaikki sovelluksen tabit */
  tabs: { [key: string]: Tab } = {}
  copyright: string
  /** Onko käytössä salasana ja/tai SSO-autentikointi? */
  loginMethod: { userpass: boolean; sso: boolean; ssoLoginUrl?: string; ssoLogoutUrl?: string; dsUrl?: string }
  /** Sovellus-/ajoympäristökohtaisia vakioita */
  settings: { [key: string]: string } = {}

  constructor(json: AppConfigJSON) {
    this.languages = json.languages
    this.copyright = json.copyright
    this.loginMethod = json.loginMethod
    if (this.loginMethod.ssoLoginUrl && !this.loginMethod.ssoLoginUrl.startsWith('http')) {
      this.loginMethod.ssoLoginUrl = (publicPath + this.loginMethod.ssoLoginUrl).replaceAll('//', '/')
    }
    if (this.loginMethod.ssoLogoutUrl && !this.loginMethod.ssoLogoutUrl.startsWith('http')) {
      this.loginMethod.ssoLogoutUrl = (publicPath + this.loginMethod.ssoLogoutUrl).replaceAll('//', '/')
    }
    this.settings = json.settings
    json.pages.forEach(pageJSON => {
      this.pages[pageJSON.name] = new Page(pageJSON, this)
    })
    Object.values(this.tabs).forEach(tab => {
      Object.values(tab.attributes).forEach(attribute => {
        attribute.inputField = shallowRef(selectInputField(attribute, this))
      })
    })
  }
}
