import KantoUser, { UserJSON } from '@kanto/classes/User'

import TechnicalError from '@/classes/errors/TechnicalError'
import type Tab from '@/classes/Tab'
import type Tuple from '@/classes/Tuple'
import type { TupleJSON } from '@/classes/Tuple'
import { apiFetchJSON, fetchUserKeys } from '@/utils/api-functions'
import { arrayBufferToBase64, base64ToArrayBuffer, createIV } from '@/utils/crypt'
import {
  createSafeboxKey,
  getAuthIDFromIndexedDB,
  getKeypairFromIndexedDB,
  getPublicKey,
  OpenedCryptKey,
  removeAuthIDFromIndexedDB,
  removeKeypairFromIndexedDB,
  storeKeyPairToIndexedDB
} from '@/utils/crypt-utils'
import Imports from '@/utils/Imports'
import { apiPath } from '@/utils/paths'

export * from '@kanto/classes/User'

export interface CryptkeyJSON {
  id: number
  public_key: string
  private_key: string
  private_key_iv: string
  authenticator_id?: string
  user_key?: string
}

export interface RegisterUserJSON {
  user: UserJSON
  signedTos: string
}

const Empty: unique symbol = Symbol('Empty')

class Lazy<T> {
  value: T | typeof Empty = Empty
  promise: Promise<T>

  set!: (value: T) => void

  get ready() {
    return this.value !== Empty
  }

  constructor() {
    this.promise = new Promise(resolve => {
      this.set = value => {
        this.value = value
        resolve(value)
      }
    })
  }

  async get() {
    if (this.value !== Empty) {
      return this.value
    }

    return this.promise
  }
}

export default class User extends KantoUser {
  /** Kirjautuneen käyttäjän avainpari, jonka private keyllä safeboxien avaimet saa purettua. */
  keyPair: Lazy<OpenedCryptKey> = new Lazy()

  /** Käyttäjäkohtaisten tietojen salaamiseen käytetty symmetrinen avain. */
  encryptionKey: Lazy<CryptoKey> = new Lazy()

  /** Käyttäjän kaikki salausavaimet. */
  cryptkeys: CryptkeyJSON[] = []

  /** Kirjautuneen käyttäjän salauslaitteen ID. */
  currentAuthenticatorId?: string

  /** Tallelokeroiden AES-cryptausavaimet, mapin avaimena safeboxin id. */
  safeboxCryptKeys: { [key: string]: CryptoKey } = {}

  /** Onko kaikki saatavilla olevat tallelokeroiden salausavaimet avattu? */
  safeboxCryptKeysDecrypted = false

  /** Lista takaisinkutsufunktioita, jotka odottavat kutsua, kun kyseessä olevan tallalokeron salausavain on avattu. */
  safeboxCryptKeyListeners: { [key: string]: Array<(key: CryptoKey) => void> } = {}

  constructor(json: UserJSON, aesWrappingKey?: CryptoKey, credential?: Credential) {
    super(json)

    this.cryptkeys = this.attributes.cryptkeys.map(cryptkey => JSON.parse(cryptkey) as CryptkeyJSON)

    this.getKeyPair(aesWrappingKey, credential)
    this.getUserEncryptionKey()
    this.decryptSafeboxKeys()
    this.getAuthenticatorId(credential)
  }

  /** Avaa tai luo käyttäjäkohtaisten tietojen salaamiseen käytetyn avaimen. */
  async getUserEncryptionKey() {
    const {
      cryptoKeyId,
      key: { privateKey }
    } = await this.keyPair.get()

    const cryptkey = this.cryptkeys.find(({ id }) => id === cryptoKeyId)!

    let key: CryptoKey

    if (cryptkey.user_key) {
      key = await crypto.subtle.unwrapKey('raw', base64ToArrayBuffer(cryptkey.user_key), privateKey, { name: 'RSA-OAEP' }, { name: 'AES-GCM' }, true, [
        'encrypt',
        'decrypt'
      ])
    } else {
      key = await this.createUserEncryptionKey()
    }

    this.encryptionKey.set(key)
  }

  /** Generoi, salaa ja tallentaa palvelimelle käyttäjäkohtaisten tietojen salaamiseen käytetyn avaimen. */
  async createUserEncryptionKey() {
    const key = await crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256
      },
      true,
      ['encrypt', 'decrypt']
    )

    const keys = await Promise.all(
      this.cryptkeys.map(async cryptkey => {
        const publicKey = await crypto.subtle.importKey('jwk', JSON.parse(cryptkey.public_key), { name: 'RSA-OAEP', hash: 'SHA-512' }, false, ['wrapKey'])

        const wrappedKey = await crypto.subtle.wrapKey('raw', key, publicKey, { name: 'RSA-OAEP' })

        return {
          id: cryptkey.id,
          key: arrayBufferToBase64(wrappedKey)
        }
      })
    )

    await apiFetchJSON(`${apiPath}/set-user-key`, {
      method: 'POST',
      body: JSON.stringify({
        keys
      })
    })

    return key
  }

  async getAuthenticatorId(credential?: Credential) {
    if (credential) {
      this.currentAuthenticatorId = credential.id
      return
    }

    try {
      this.currentAuthenticatorId = await getAuthIDFromIndexedDB()
    } catch (err) {
      console.warn('Käyttäjän auth ID:n hakeminen epäonnistui', err)
    }
  }

  /** Avaa, hakee tai luo käyttäjän avainparin. */
  async getKeyPair(aesWrappingKey?: CryptoKey, credential?: Credential) {
    try {
      const keyPair = aesWrappingKey ? await this.decryptKeypair(aesWrappingKey, credential!.id) : await getKeypairFromIndexedDB()

      this.keyPair.set(keyPair)
    } catch (err) {
      console.warn('Käyttäjän avainparin hakeminen epäonnistui', err)
      if (Imports.router.currentRoute.value.name !== 'login') {
        Imports.router.push({ name: 'login' })
      }
    }
  }

  /** Yrittää avata annetun avainparin käyttäen annettua symmetristä avainta. */
  async tryOpenCryptkey(cryptkey: CryptkeyJSON, aesWrappingKey: CryptoKey): Promise<OpenedCryptKey | null> {
    const iv = cryptkey.private_key_iv
    const wrappedJwkPrivateKey = base64ToArrayBuffer(cryptkey.private_key)
    try {
      const privateKey = await crypto.subtle.unwrapKey(
        'pkcs8',
        wrappedJwkPrivateKey,
        aesWrappingKey,
        { name: 'AES-GCM', iv: new TextEncoder().encode(window.atob(iv)) },
        { name: 'RSA-OAEP', hash: 'SHA-512' },
        true,
        ['decrypt', 'unwrapKey']
      )
      console.log('Käyttäjän avainpari purettu')
      const publicKey = await getPublicKey(privateKey)
      return {
        key: { privateKey, publicKey },
        cryptoKeyId: cryptkey.id
      }
    } catch (err) {
      console.warn('Avainparin purku epäonnistui', err)
    }

    return null
  }

  /** Salasanakirjautumisen jälkeen puretaan RSA-avainpari, tai sen puuttuessa luodaan uusi. */
  async decryptKeypair(aesWrappingKey: CryptoKey, authenticatorId: string): Promise<OpenedCryptKey> {
    let opened: OpenedCryptKey | null = null

    for (const cryptkey of this.cryptkeys) {
      opened = await this.tryOpenCryptkey(cryptkey, aesWrappingKey)

      if (opened) {
        break
      }
    }

    if (!opened) {
      opened = await this.createKeypair(aesWrappingKey, authenticatorId)
    }

    storeKeyPairToIndexedDB(opened)

    return opened
  }

  /** Luo uuden RSA-avainparin, salaa sen annetulla wrappingKeyllä ja vie tuloksen kantaan. */
  async createKeypair(aesWrappingKey: CryptoKey, authenticatorId?: string): Promise<OpenedCryptKey> {
    try {
      const keyPair = await crypto.subtle.generateKey(
        {
          name: 'RSA-OAEP',
          modulusLength: 4096,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: 'SHA-512'
        },
        true,
        ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
      )

      const iv = createIV()
      // AES-GCM:n sijaan olisi helpompaa käyttää AES-KW:ta, mutta mikään format ei tällä hetkellä tuota 8:aan byteen padattyä exporttia, jonka AES-KW vaatii
      const wrappedKey = await crypto.subtle.wrapKey('pkcs8', keyPair.privateKey, aesWrappingKey, {
        name: 'AES-GCM',
        iv: new TextEncoder().encode(window.atob(iv))
      })
      const jwkPublicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey)
      // Salataan kaikki safeboxien avaimet uudella avaimella
      const cryptedSafeboxCryptKeys = await Promise.all(
        Object.entries(this.safeboxCryptKeys).map(async ([safeboxId, safeboxKey]) => {
          const wrappedKey = await crypto.subtle.wrapKey('raw', safeboxKey, keyPair.publicKey, {
            name: 'RSA-OAEP'
          })
          return { safeboxId, key: arrayBufferToBase64(wrappedKey) }
        })
      )

      const { id } = await apiFetchJSON<{ id: number }>(apiPath + '/addUserKeypair', {
        method: 'POST',
        body: JSON.stringify({
          privateKey: arrayBufferToBase64(wrappedKey),
          publicKey: jwkPublicKey,
          iv,
          authenticatorId,
          safeboxKeys: cryptedSafeboxCryptKeys
        })
      })

      return {
        key: keyPair,
        cryptoKeyId: id
      }
    } catch (err) {
      throw new TechnicalError('Avainparin luonti epäonnistui', err as Error)
    }
  }

  async addSafeboxKey(safeboxId: number, key: CryptoKey) {
    console.info('Purettu avain tallelokerolle ' + safeboxId)
    this.safeboxCryptKeys[safeboxId] = key
    const listeners = this.safeboxCryptKeyListeners[safeboxId]
    this.safeboxCryptKeyListeners[safeboxId] = []

    if (listeners) {
      listeners.forEach(listener => listener(key))
    }
  }

  async decryptSafeboxKey(safeboxId: number, encryptedKey: string) {
    // TODO: Korjaa virhetilanne muiden kuin nykyisen avaimen kohdalla
    try {
      const openedCryptKey = await this.keyPair.get()

      // Puretaan kryptattu avain käyttäjän private keyllä
      const key = await crypto.subtle.unwrapKey(
        'raw',
        Uint8Array.from(window.atob(encryptedKey), c => c.charCodeAt(0)),
        openedCryptKey.key.privateKey,
        { name: 'RSA-OAEP' },
        'AES-GCM',
        true,
        ['encrypt', 'decrypt']
      )

      await this.addSafeboxKey(safeboxId, key)
    } catch (err) {
      console.warn(`Tallelokeron ${safeboxId} avaimen purku epäonnistui`, err)
    }
  }

  /** Purkaa käyttäjän tallelokeroiden AES-avaimet käyttäjän avainparilla. */
  async decryptSafeboxKeys(): Promise<void> {
    if (Object.entries(this.safeboxCryptKeys).length > 0) {
      // Avaimet on jo purettu
      return
    }

    await Promise.all(
      this.attributes.safebox_keys.map(keyString => {
        const [id, key] = keyString.split('|', 2)
        return this.decryptSafeboxKey(parseInt(id, 10), key)
      })
    )

    this.safeboxCryptKeysDecrypted = true
  }

  async getSafeboxKey(safebox: string): Promise<CryptoKey | undefined> {
    if (this.safeboxCryptKeysDecrypted || this.safeboxCryptKeys[safebox]) {
      return this.safeboxCryptKeys[safebox]
    }

    let listeners = this.safeboxCryptKeyListeners[safebox]

    if (!listeners) {
      listeners = this.safeboxCryptKeyListeners[safebox] = []
    }

    return new Promise(resolve => listeners.push(resolve))
  }

  async getEncryptKey(tuple: Tuple): Promise<CryptoKey | undefined> {
    let safeboxKey

    if (tuple.tab.name === 'invite_key') {
      if (!tuple.parent) {
        throw new Error('No parent found!')
      }

      const { keys } = await fetchUserKeys({ username: tuple.parent.values.invitee.getDisplayValue() })
      const match = keys.find(key => key.id === tuple.values.cryptkey_id.v)
      return match?.key
    }

    if (tuple.tab.name === 'safebox_group') {
      return this.encryptionKey.get()
    }

    if (tuple.tab.name === 'safebox') {
      safeboxKey = tuple.hasKey() ? tuple.values.id?.valueToString() : tuple.getUniqueId()
    } else {
      // Haetaan muissa tapauksissa:
      // 1. suoraan monikon safebox-viittauksesta (esim. tietokannassa oleva service)
      // 2. isämonikon safebox-viittauksesta (esim. tietokannassa oleva service_attachment)
      // 3. isoisämonikon uniikista id:stä (keskeneräinen service_attachment)
      // 4. isämonikon uniikista id:stä (keskeneräinen service)
      safeboxKey =
        tuple.values.safebox?.valueToString() ??
        tuple.parent?.values.safebox?.valueToString() ??
        tuple.parent?.parent?.getUniqueId() ??
        tuple.parent?.getUniqueId()
    }

    if (!safeboxKey) return undefined

    return this.getSafeboxKey(safeboxKey)
  }

  async getDecryptKey(tupleJSON: TupleJSON, tab: Tab, parentTuple?: Tuple): Promise<CryptoKey | undefined> {
    if (tab.name === 'invite_key') {
      const { key } = await this.keyPair.get()
      return key.privateKey
    }

    if (tab.name === 'safebox_group') {
      return this.encryptionKey.get()
    }

    let safeboxKey

    if (tab.name === 'safebox') {
      if (tupleJSON.tmpKey) {
        const key = await this.getSafeboxKey(tupleJSON.tmpKey)
        if (key) return key
      }
      safeboxKey = tupleJSON.values.id.v?.toString()
    } else {
      safeboxKey = parentTuple?.values.safebox?.valueToString() ?? tupleJSON.values.safebox?.v?.toString()
    }

    if (!safeboxKey) return undefined

    return this.getSafeboxKey(safeboxKey)
  }

  async clearKeys(): Promise<void> {
    return removeKeypairFromIndexedDB()
  }

  async clearAuthID(): Promise<void> {
    return removeAuthIDFromIndexedDB()
  }

  getNewMessageCount(safeboxId?: string): number {
    if (safeboxId === undefined) return 0
    const row = this.attributes.new_chat_messages?.find((row: string) => row.split('|')[0] === safeboxId)
    if (row === undefined) return 0
    return Number(row.split('|')[1])
  }
}

type LinkedRegistrationOptions = {
  authenticatorId: string
  givenName: string
  familyName: string
  aesWrappingKey: CryptoKey
  safeboxes: { id: number; key: CryptoKey }[]
}

function generateKeypair() {
  return crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-512'
    },
    true,
    ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
  )
}

/** Funktio salausavaimen poistamista varten. */
export async function deleteKey(authenticatorId: string, usedAuthenticatorId: string) {
  return apiFetchJSON(apiPath + '/deleteKey', {
    method: 'POST',
    body: JSON.stringify({
      authenticator_id: authenticatorId,
      usedAuthenticator: usedAuthenticatorId
    })
  })
}

/** Apufunktio uuden salauslaitteen rekisteröintiä varten */
export async function registerKey(authenticatorId: string, aesWrappingKey: CryptoKey, safeboxes: { id: number; key: CryptoKey }[]) {
  const keyPair = await generateKeypair()
  const iv = createIV()
  const jwkPublicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey)
  const wrappedPrivateKey = await crypto.subtle.wrapKey('pkcs8', keyPair.privateKey, aesWrappingKey, {
    name: 'AES-GCM',
    iv: new TextEncoder().encode(window.atob(iv))
  })

  const key = await Imports.store.user!.encryptionKey.get()
  const userKey = await crypto.subtle.wrapKey('raw', key, keyPair.publicKey, { name: 'RSA-OAEP' })

  const safeboxKeys = await Promise.all(
    safeboxes.map(async ({ id, key }) => {
      const wrappedSafeboxKey = await crypto.subtle.wrapKey('raw', key, keyPair.publicKey, { name: 'RSA-OAEP' })

      return {
        id,
        key: arrayBufferToBase64(wrappedSafeboxKey)
      }
    })
  )

  return apiFetchJSON(apiPath + '/register/key', {
    method: 'POST',
    body: JSON.stringify({
      public_key: jwkPublicKey,
      private_key: arrayBufferToBase64(wrappedPrivateKey),
      private_key_iv: iv,
      authenticator_id: authenticatorId,
      safebox_keys: safeboxKeys,
      user_key: arrayBufferToBase64(userKey)
    })
  })
}

export async function registerLinkedUser(options: LinkedRegistrationOptions) {
  const keyPair = await generateKeypair()
  const iv = createIV()
  const jwkPublicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey)
  const wrappedPrivateKey = await crypto.subtle.wrapKey('pkcs8', keyPair.privateKey, options.aesWrappingKey, {
    name: 'AES-GCM',
    iv: new TextEncoder().encode(window.atob(iv))
  })

  const safeboxKeys = await Promise.all(
    options.safeboxes.map(async ({ id, key }) => {
      const wrappedSafeboxKey = await crypto.subtle.wrapKey('raw', key, keyPair.publicKey, { name: 'RSA-OAEP' })

      return {
        id,
        key: arrayBufferToBase64(wrappedSafeboxKey)
      }
    })
  )

  return apiFetchJSON(apiPath + '/register/linked', {
    method: 'POST',
    body: JSON.stringify({
      public_key: jwkPublicKey,
      private_key: arrayBufferToBase64(wrappedPrivateKey),
      private_key_iv: iv,
      authenticator_id: options.authenticatorId,
      safebox_keys: safeboxKeys,
      given_name: options.givenName,
      family_name: options.familyName
    })
  })
}

export async function registerUser(
  aesWrappingKey: CryptoKey,
  authenticatorId: string,
  hstCert: string,
  authSignature: string,
  tosSignature: string,
  securityKeySignature: string
): Promise<RegisterUserJSON> {
  return crypto.subtle
    .generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-512'
      },
      true,
      ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
    )
    .then(async keyPair => {
      const iv = createIV()
      // AES-GCM:n sijaan olisi helpompaa käyttää AES-KW:ta, mutta mikään format ei tällä hetkellä tuota 8:aan byteen padattyä exporttia, jonka AES-KW vaatii
      const wrappedKey = await crypto.subtle.wrapKey('pkcs8', keyPair.privateKey, aesWrappingKey, {
        name: 'AES-GCM',
        iv: new TextEncoder().encode(window.atob(iv))
      })
      const jwkPublicKey = await crypto.subtle.exportKey('jwk', keyPair.publicKey)
      // Luodaan käyttäjän safeboxille avain ja salataan se käyttäjän avaimella
      const cryptedSafeboxCryptKey = arrayBufferToBase64(await crypto.subtle.wrapKey('raw', await createSafeboxKey(), keyPair.publicKey, { name: 'RSA-OAEP' }))
      return apiFetchJSON<RegisterUserJSON>(apiPath + '/register', {
        method: 'POST',
        body: JSON.stringify({
          privateKey: arrayBufferToBase64(wrappedKey),
          publicKey: jwkPublicKey,
          iv,
          authenticatorId,
          safeboxKey: cryptedSafeboxCryptKey,
          hstCert,
          authSignature,
          tosSignature,
          securityKeySignature
        })
      })
    })
}
