import dayjs, { PluginFunc } from 'dayjs'

/**
 * DayJS microseconds-plugin mikrosekunteja sisältävien aikaleimojen parsintaan ja formatointiin.
 */
const dayjsMicroseconds = (option: unknown, dayjsClass: typeof dayjs): void => {
  const oldFormat = dayjsClass.prototype.format
  dayjsClass.prototype.format = function (template?: string) {
    return oldFormat.call(this, template?.replace('SSSSSS', this.$microseconds))
  }

  const oldParse = dayjsClass.prototype.parse
  dayjsClass.prototype.parse = function (cfg: { date: unknown }) {
    const date = cfg.date
    oldParse.call(this, cfg)

    if (typeof date === 'string') {
      const fractionMatch = date.match(/\.\d{4,6}/)
      if (fractionMatch) {
        this.$microseconds = fractionMatch[0].substring(1).padEnd(6, '0')
      }
    }
    // Jos mikrosekunteja ei ole parsittavassa merkkijonossa, luodaan ne millisekuntien perusteella
    this.$microseconds = this.$microseconds ?? String(this.$ms * 1000).padStart(6, '0')
  }
}

export default dayjsMicroseconds as unknown as PluginFunc
