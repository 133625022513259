<template>
  <q-header class="k-env-banner">
    <div class="k-env-banner__content">
      <span>
        Env:
        <b>{{ envName }}</b>
      </span>
      <span v-if="!isDev">Version: {{ version }}</span>
      <span v-if="!isDev && version !== commit">Commit: {{ commit }}</span>
      <span v-if="!isDev">Build time: {{ build }} (UTC)</span>
    </div>
  </q-header>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

import appVersion from '@/app-version.json'
import { env } from '@/utils/paths'

/** Muualla kuin tuotantoympäristössä näytettävä banneri ajossa olevan sovellusversion tiedoilla. */
@Component
export default class EnvBanner extends Vue {
  get envName(): string {
    return env
  }

  get isDev(): boolean {
    return env?.toLowerCase() === 'development'
  }

  get version(): string {
    return appVersion.version
  }

  get commit(): string {
    return appVersion.commit
  }

  get build(): string {
    return appVersion.buildTime
  }
}
</script>

<style lang="scss">
.k-env-banner {
  padding: 8px;
  background-color: $orange-2 !important;

  &__content {
    color: black;
    display: flex;
    justify-content: center;
    // Näytetään vain niin monta spania kuin yhdelle riville mahtuu
    flex-wrap: wrap;
    max-height: 1.25rem;
    overflow: hidden;

    span {
      margin: 0 10px;
      white-space: nowrap;
    }
  }
}
</style>
