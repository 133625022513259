import { QuasarLanguage } from 'quasar'
import langEn from 'quasar/lang/en-US.js'
import langFi from 'quasar/lang/fi.js'
import langSv from 'quasar/lang/sv.js'

export default {
  fi: langFi,
  en: langEn,
  sv: langSv
} as { [key: string]: QuasarLanguage }
