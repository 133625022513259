<template>
  <q-dialog ref="dialog">
    <q-card>
      <q-card-section>
        <span class="text-h6">{{ $tt('title') }}</span>
      </q-card-section>
      <q-card-section>
        <i18next :translation="$tt('message')">
          <template #safebox
            ><b>{{ inviteKey.values.safebox_name.getDisplayValue() }}</b></template
          >
          <template #invitor
            ><b><AccountAvatar size="0.66em" with-name :name="invite.values.invitor_name.getDisplayValue()" /></b
          ></template>
        </i18next>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn rounded color="red" @click="rejectInvite">{{ $tt('decline') }}</q-btn>
        <q-btn rounded color="blue" @click="acceptInvite">{{ $tt('accept') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { QDialog } from 'quasar'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import AccountAvatar from '@/components/AccountAvatar.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import { apiFetch } from '@/utils/api-functions-base'
import { arrayBufferToBase64, base64ToArrayBuffer } from '@/utils/crypt'
import Imports from '@/utils/Imports'

const PERMISSION_LIST_NAMES: Record<number, Array<'ar' | 'aw'>> = {
  1: ['ar'],
  2: ['ar', 'aw']
}

@Component({
  components: { AccountAvatar }
})
export default class InviteDialog extends Vue {
  @Prop({ type: Object })
  invite!: Tuple

  @Prop({ type: Object })
  inviteKey!: Tuple

  $tt(key: string) {
    return this.$t(`InviteDialog.${key}`)
  }

  async acceptInvite() {
    const keyBuffer = base64ToArrayBuffer(this.inviteKey.values.safebox_key.getDisplayValue())
    const key = await crypto.subtle.importKey('raw', keyBuffer, { name: 'AES-GCM' }, true, ['encrypt', 'decrypt'])

    const safeboxKeyTab = Table.getTable('safebox_key')
    const safeboxAccessTab = Table.getTable('safebox_access')

    const access = new Tuple(safeboxAccessTab)

    access.setValue('username', Imports.store.user!.name)
    access.setValue('safebox', this.invite.values.safebox.v)
    access.setValue('role', 1)

    access.children.safebox_key = await Promise.all(
      Imports.store.user!.cryptkeys.map(async cryptkey => {
        const publicKey = await crypto.subtle.importKey('jwk', JSON.parse(cryptkey.public_key), { name: 'RSA-OAEP', hash: 'SHA-512' }, false, ['wrapKey'])
        const wrappedKey = await crypto.subtle.wrapKey('raw', key, publicKey, { name: 'RSA-OAEP' })
        const tuple = new Tuple(safeboxKeyTab)
        tuple.setValue('cryptkey_id', cryptkey.id)
        tuple.setValue('crypted_aes_key', arrayBufferToBase64(wrappedKey))
        return tuple
      })
    )

    await apiFetchJSON(`api/safebox_access`, {
      method: 'POST',
      body: JSON.stringify(await access.toJSON())
    })

    const safeboxId = this.invite.values.safebox.v as number
    this.$store.user!.addSafeboxKey(safeboxId, key)
    const permissionListNames = PERMISSION_LIST_NAMES[this.invite.values.role.v as number]
    permissionListNames.forEach(name => this.$store.user!.attributes[name].push(safeboxId.toString()))

    await this.resolveInvite('ACCEPT')
  }

  async rejectInvite() {
    await this.resolveInvite('DECLINE')
  }

  async resolveInvite(action: 'ACCEPT' | 'DECLINE') {
    await apiFetch(`api/invite/${this.invite.getKeyString()}/resolve`, {
      method: 'POST',
      body: JSON.stringify({ action })
    })

    this.ok()
  }

  public hide() {
    ;(this.$refs.dialog as QDialog).hide()
  }

  public show() {
    ;(this.$refs.dialog as QDialog).show()
  }

  @Emit('ok')
  ok() {
    this.hide()
  }
}
</script>

<style scoped lang="scss">
.q-card {
  border-radius: 10px;
}
</style>
