/**
 * Perustoimintojen routejen nimet.
 */
enum RouteName {
  LOGIN = 'login',
  LOGOUT = 'logout',
  SEARCH = 'search',
  LISTING = 'listing',
  CREATE = 'create',
  ACTION = 'action',
  JOB = 'job',
  REPORT = 'report',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  VIEW = 'view',
  CUSTOMVIEW = 'customview'
}

export default RouteName
