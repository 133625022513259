import { serializeError } from 'serialize-error'

export interface ErrorResponseJSON {
  title: string
  detail: string
  status: number
  requestId: string
}

export default class BaseError extends Error {
  /**
   * Selaimen URL virheen sattuessa.
   */
  url: string

  /**
   * Mahdollinen Vue-komponentti, jossa virhe sattui.
   */
  vueComponentName?: string

  /**
   * Mahdollinen error-responsen sisältö.
   */
  fetchResponse?: ErrorResponseJSON | string

  /**
   * Mahdollinen lisätieto virheestä. Ei näytetä käyttäjälle.
   */
  info?: string

  constructor(message: string) {
    super(message)
    this.url = window.location.href
  }

  public toString(): string {
    if (!this.info) {
      return this.message
    }
    return `${this.message}\nInfo: ${this.info}`
  }

  toJSON(): unknown {
    // Estetään infinite loop asettamalla maxDepth
    return serializeError(this, { maxDepth: 10 })
  }
}
