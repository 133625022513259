<template>
  <q-dialog ref="dialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ $tt('title') }}</div>
      </q-card-section>
      <q-card-section>
        <q-select v-model="selected" :options="options" :label="$tt('selectLabel')" />
      </q-card-section>
      <q-card-section>
        {{ $tt(`options.${selected.value}.description`) }}
      </q-card-section>
      <q-card-section v-if="selected.value === 'rename'">
        <q-input v-model="newName" :label="$tt('renameFieldLabel')" />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" @click="submit">{{ $tt('ok') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { QDialog } from 'quasar'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

import { type NameConflictResolution } from '@/classes/importer'

@Component({})
export default class SafeboxImportConflictResolutionDialog extends Vue {
  private selected = {
    value: 'skip' as NameConflictResolution['resolution'],
    label: this.$tt(`options.skip.label`)
  }

  @Prop()
  name!: string

  private newName!: string

  created() {
    this.newName = this.name
  }

  get options() {
    return ['skip', 'rename', 'merge'].map(value => ({
      value,
      label: this.$tt(`options.${value}.label`)
    }))
  }

  public hide() {
    ;(this.$refs.dialog as QDialog).hide()
  }

  public show() {
    ;(this.$refs.dialog as QDialog).show()
  }

  @Emit('ok')
  private submit(): NameConflictResolution {
    this.hide()

    if (this.selected.value === 'rename') {
      return {
        resolution: 'rename',
        name: this.newName
      }
    }

    return {
      resolution: this.selected.value
    }
  }

  $tt(key: string) {
    return this.$t(`SafeboxImportConflictResolutionDialog.${key}`)
  }
}
</script>

<style scoped lang="scss"></style>
