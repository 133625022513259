import '@/utils/paths'
import 'whatwg-fetch'
import 'proxy-polyfill'
import '@/utils/quasar'
import '@/utils/error-handler'

import { beforeVue, catchError, mountApp } from '@/utils/main-hooks'

try {
  beforeVue()

  mountApp()
} catch (e) {
  catchError(e)
}
