import BaseError from '@/classes/errors/BaseError'

/**
 * Käyttäjän toimista aiheutuvat virheet, joista käyttäjälle voidaan näyttää kohdistettu virheviesti.
 */
export default class ClientError extends BaseError {
  /**
   * Käyttäjälle näytettävä virheviestin kieliresurssi.
   */
  userMessageKey: string

  constructor(message: string, userMessageKey: string, info?: string) {
    super(message)
    this.userMessageKey = userMessageKey
    this.info = info
  }
}
