<template>
  <div>
    <p style="max-width: 70ch">{{ $t('AccountManagementView.description') }}</p>
    <q-list bordered separator class="q-mb-lg rounded-borders" style="background-color: white">
      <q-item-label header>{{ $t('AccountManagementView.liteAccounts') }}</q-item-label>
      <template v-if="linkedAccounts.length > 0">
        <q-item v-for="account in linkedAccounts" :key="account.getUniqueId()">
          <q-item-section>
            <q-item-label>{{ account.values.given_name.getDisplayValue() }} {{ account.values.family_name.getDisplayValue() }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
      <template v-else>
        <q-item>
          <q-item-section>
            <q-item-label>{{ $t('AccountManagementView.noLiteAccounts') }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
    <q-card flat bordered class="q-mt-lg">
      <q-card-section>
        <h6 style="font-size: 1em; font-weight: 600; margin: 0; margin-bottom: 1em">Lisää kevytkäyttäjä</h6>
        <div class="q-gutter-md col" style="width: fit-content">
          <q-form ref="liteAccountForm" class="q-plb-md" @submit="startRegistration">
            <div class="row q-col-gutter-md">
              <q-input v-model="givenName" dense outlined required :rules="[minimumLengthInput]" :label="$t('AccountManagementView.givenName')" class="col-6" />
              <q-input
                v-model="familyName"
                dense
                outlined
                required
                :rules="[minimumLengthInput]"
                :label="$t('AccountManagementView.familyName')"
                class="col-6"
              />
            </div>
            <div class="q-pb-md">
              <q-list bordered class="rounded-borders">
                <q-item-label header>{{ $t('AccountManagementView.selectSafeboxesHeader') }}</q-item-label>
                <q-item v-for="safebox in safeboxes" :key="safebox.getUniqueId()" tag="label">
                  <q-item-section side top>
                    <q-checkbox :model-value="safeboxSelected(safebox)" @update:model-value="setSafeboxSelected(safebox, $event)" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ safebox.values.name.getDisplayValue() }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <q-btn type="submit" color="blue" :label="$t('AccountManagementView.addLiteAccount')" style="white-space: nowrap" />
          </q-form>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script lang="ts">
import { Component, mixins } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import { registerLinkedUser } from '@/classes/User'
import MobileAuthenticationDialog from '@/components/MobileAuthenticationDialog.vue'
import { apiFetchJSON } from '@/utils/api-functions'
import { createUserKey, getAesWrappingKeyFromCredential, webAuthnLogin, webauthnRegister } from '@/utils/crypt-utils'
import { SafeboxMixin } from '@/utils/SafeboxMixin'

interface AuthenticatorIDJSON {
  authenticatorID: string
}

@Component({})
export default class AccountManagementView extends mixins(SafeboxMixin) {
  private familyName = ''
  private givenName = ''
  private linkedAccounts: Array<Tuple> = []
  private safeboxIds: Array<string> = []
  private step = 0

  private safeboxSelected(safebox: Tuple) {
    return this.safeboxIds.includes(safebox.values.id.toString())
  }

  private setSafeboxSelected(safebox: Tuple, selected: boolean) {
    const id = safebox.values.id.toString()
    const index = this.safeboxIds.indexOf(id)

    if (selected && index === -1) {
      this.safeboxIds.push(id)
    } else if (!selected && index > -1) {
      this.safeboxIds.splice(index, 1)
    }
  }

  get showFirstStep() {
    return this.step === 1
  }

  get safeboxOptions() {
    return this.safeboxes.map(safebox => ({
      value: safebox.values.id.v,
      label: safebox.values.name.getDisplayValue()
    }))
  }

  mounted() {
    this.refreshLinkedAccounts()
  }

  private minimumLengthInput(val: string) {
    return val.trim().length > 0 || 'Pituuden tulee olla vähintään yhden merkin pituinen'
  }

  private checkPrerequisites() {
    return this.givenName.trim().length < 1 || this.familyName.trim().length < 1
  }

  private async refreshLinkedAccounts() {
    const tab = Table.getTable('profile')

    const tuples = await apiFetchJSON<TupleJSON[]>(`api/profile?parent_account=${encodeURIComponent(this.$store.user!.name)}`)

    this.linkedAccounts = await Promise.all(tuples.map(tuple => Tuple.fromJSON(tuple, tab)))
  }

  async startRegistration() {
    return new Promise(resolve => {
      const dialog = this.$q.dialog({
        title: this.$t('AccountManagementView.registerStartDialog.title'),
        message: this.$t('AccountManagementView.registerStartDialog.message'),
        ok: this.$t('AccountManagementView.registerStartDialog.securityKeyOption'),
        cancel: this.$t('AccountManagementView.registerStartDialog.mobileOption')
      })

      dialog.onCancel(() => this.mobileFlowStart().then(resolve))
      dialog.onOk(() => this.securityKeyFlowStart().then(resolve))
    })
  }

  async mobileFlowStart() {
    return new Promise<void>((resolve, reject) => {
      const dialog = this.$q.dialog({
        component: MobileAuthenticationDialog,
        componentProps: {
          name: `${this.givenName} ${this.familyName}`
        }
      })

      dialog.onOk(async ({ key, authenticatorId }) => {
        await this.registerUser(key, authenticatorId)
        resolve()
      })

      dialog.onCancel(reject)
    })
  }

  async securityKeyFlowStart() {
    if (this.checkPrerequisites()) {
      this.notify(new Notification(NotificationLevel.WARNING, 'Kevytkäyttäjän etu- ja sukunimen tulee olla vähintään yhden merkin pituiset.'))
      return
    }

    let credential = await webauthnRegister(`${this.givenName} ${this.familyName}`, this.$store.user).catch(error => {
      console.error(error)
    })

    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed')))
      return
    }
    // @ts-ignore
    if (!credential?.getClientExtensionResults()?.prf?.enabled && !credential?.getClientExtensionResults()?.largeBlob?.supported) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      return
    }

    let userCryptKey: CryptoKey | null = null
    let largeBlob: ArrayBuffer | undefined
    // @ts-ignore
    if (!credential.getClientExtensionResults()?.prf?.enabled) {
      userCryptKey = await createUserKey()
      largeBlob = await crypto.subtle.exportKey('raw', userCryptKey)
    }
    credential = await webAuthnLogin([credential.id], largeBlob).catch(error => {
      console.error(error)
    })

    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('AccessManagementView.loginFailed.title')))
      return
      // @ts-ignore
    } else if (!credential?.getClientExtensionResults()?.prf?.results?.first && !credential?.getClientExtensionResults()?.largeBlob?.written) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      return
    }

    const aesWrappingKey = userCryptKey ?? (await getAesWrappingKeyFromCredential(credential))

    await this.registerUser(aesWrappingKey, credential.id)
  }

  async registerUser(aesWrappingKey: CryptoKey, authenticatorId: string) {
    const safeboxes = Object.entries(this.$store.user!.safeboxCryptKeys)
      .map(([id, key]) => ({ id: parseInt(id, 10), key: key as CryptoKey }))
      .filter(({ id }) => this.safeboxIds.includes(id.toString()))

    const givenName = this.givenName
    const familyName = this.familyName

    const authenticatorIDJSON = (await registerLinkedUser({
      givenName,
      familyName,
      aesWrappingKey,
      authenticatorId,
      safeboxes
    })) as AuthenticatorIDJSON

    this.familyName = ''
    this.givenName = ''
    this.safeboxIds = []

    // Jotta inputfieldien validointi saadaan nollattua onnistuneen lisäyksen jälkeen
    await this.$nextTick(() => {
      // @ts-ignore
      this.$refs.liteAccountForm.resetValidation()
    })

    // Lisätään käytetty authID storeen, jottei samaa laitetta voida käyttää muihin rekisteröinteihin
    await this.refreshLinkedAccounts()
    this.$store.user!.attributes.children_keys.push(authenticatorIDJSON.authenticatorID)

    this.notify(new Notification(NotificationLevel.SUCCESS, this.$t('AccountManagementView.successNotification', { givenName, familyName })))
  }
}
</script>

<style scoped lang="scss"></style>
