<template>
  <div class="avatar-root">
    <div :class="['avatar', { 'with-name': !!withName, deleted: deleted }]" :style="[{ backgroundColor: color, fontSize: size, color: 'black' }, avatarStyle]">
      {{ initials }}
    </div>
    <span v-if="displayName">{{ displayName }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'

@Component({})
export default class AccountAvatar extends Vue {
  @Prop({ type: [Object, String] })
  account!: Tuple | string

  @Prop()
  avatarStyle?: string

  @Prop({ type: Boolean, default: false })
  deleted!: boolean

  @Prop({
    type: [Boolean, String],
    default: false,
    validator: value => {
      return typeof value === 'boolean' || value === 'family' || value === 'given'
    }
  })
  withName!: boolean | 'family' | 'given'

  @Prop({ type: String })
  size?: string

  @Prop({ type: String })
  color?: string

  @Prop({ type: String })
  name?: string

  @Watch('account', { immediate: true })
  async onAccountChange(account: Tuple | string) {
    if (this.name) {
      return
    }

    const key = typeof account === 'string' ? account : account.getKeyString()
    Imports.appStore.fetch('profile', key)
  }

  get nameParts(): string[] | null {
    if (this.name) {
      const parts = this.name.split(/\s+/)

      if (parts.length === 0) {
        return null
      } else if (parts.length === 1) {
        return parts
      } else {
        return [parts[0], parts.at(-1)!]
      }
    }

    if (!this.fetchedAccount) {
      return null
    }

    return [this.fetchedAccount.values.given_name.getDisplayValue(), this.fetchedAccount.values.family_name.getDisplayValue()]
  }

  get initials() {
    if (!this.nameParts) {
      return ''
    }

    return this.nameParts.map(s => s.at(0)?.toUpperCase() ?? '').join('')
  }

  get displayName() {
    if (!this.nameParts || !this.withName) {
      return ''
    }

    if (this.withName === 'family') {
      return this.nameParts[1]
    } else if (this.withName === 'given') {
      return this.nameParts[0]
    }

    return this.nameParts.join(' ')
  }

  get fetchedAccount(): Tuple | null {
    const id = this.account instanceof Tuple ? this.account.getKeyString() : this.account
    const table = Table.getTable('profile')
    return Imports.appStore.cache[`${table.name}|${id}`]?.value
  }
}
</script>

<style scoped lang="scss">
@use 'sass:math';

.avatar {
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  background: white;
  border-radius: 9999px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.3);
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &.deleted {
    $x-color: darken(rgb(221, 224, 227), 15%);
    $x-width: 3px;
    background-image: linear-gradient(
        45deg,
        transparent 0%,
        transparent calc(50% - #{math.div($x-width, 2)}),
        $x-color calc(50% - #{math.div($x-width, 2)}),
        $x-color calc(50% + #{math.div($x-width, 2)}),
        transparent calc(50% + #{math.div($x-width, 2)}),
        transparent 100%
      ),
      linear-gradient(
        -45deg,
        transparent 0%,
        transparent calc(50% - #{math.div($x-width, 2)}),
        $x-color calc(50% - #{math.div($x-width, 2)}),
        $x-color calc(50% + #{math.div($x-width, 2)}),
        transparent calc(50% + #{math.div($x-width, 2)}),
        transparent 100%
      );
    color: transparent !important;
  }

  &.with-name {
    top: -0.2em;
  }
}

.avatar-root {
  display: inline-block;
  white-space: nowrap;

  span {
    display: inline-block;
    margin-left: 0.6ch;
  }
}
</style>
