/** Tunnetut pääsynvalvonnan kohdelajit. Ks. AccessControl.java */
enum AccessType {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
  BATCH_DELETE = 'batch_delete',
  LISTING = 'listing',
  TITLE = 'title',
  EXPORT = 'export',
  LINK = 'link',
  USE_WILDCARD = 'use_wildcard',
  ADVANCED_SEARCH = 'advanced_search',
  SUMMARIZE = 'summarize',
  AUDIT = 'audit'
}

export default AccessType
