import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import TechnicalError from '@/classes/errors/TechnicalError'
import { initApp } from '@/create-app'
import dayjsMicroseconds from '@/utils/dayjs-microseconds'
import { getString, setEarlyLang } from '@/utils/i18n'

/**
 * Kutsutaan ennen ennen Vue-sovelluksen luomista ja appConfigin hakemista. Store ei ole vielä käytettävissä.
 */
export function beforeVue(): void {
  document.title = getString('AppTitle', '')

  dayjs.extend(customParseFormat)
  dayjs.extend(dayjsMicroseconds)

  setEarlyLang()
}

export function mountApp(): void {
  initApp().mount('#app')
}

/**
 * Kutsutaan sovelluksen alustuksen epäonnistuessa.
 */
export function catchError(error: unknown): void {
  throw new TechnicalError('Sovelluksen alustus epäonnistui: ' + error, error instanceof Error ? error : undefined)
}
