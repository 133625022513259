<template>
  <div class="k-error-details">
    <b>Virhe {{ header }}</b>
    <br />
    Viesti: {{ error.message }}<br />
    <template v-if="baseError">
      Komponentti: {{ baseError.vueComponentName }}<br />
      Info: {{ baseError.info }}<br />
      URL: {{ baseError.url }}<br />
      <template v-if="baseError.fetchResponse">
        Fetch response:<br />
        <pre>{{ baseError.fetchResponse }}</pre>
        <br />
      </template>
    </template>
    Stack:<br />
    <pre>{{ error.stack }}</pre>
    <ErrorDetails v-if="innerError" :error="innerError" :index="index" :inner-depth="innerDepth + 1" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

import BaseError from '@/classes/errors/BaseError'
import TechnicalError from '@/classes/errors/TechnicalError'

@Component
export class ErrorDetails extends Vue {
  @Prop({ type: Error, required: true })
  error!: Error

  @Prop({ type: Number, required: true })
  index!: number

  /** Kuinka syvällä innerError-hierarkiassa ollaan? */
  @Prop({ default: 0 })
  innerDepth!: number

  get innerError(): Error | undefined {
    return (this.baseError as TechnicalError)?.innerError
  }

  get baseError(): BaseError | undefined {
    return this.error instanceof BaseError ? this.error : undefined
  }

  get header(): string {
    if (this.innerDepth === 0) {
      return String(this.index)
    } else {
      return this.index + ' inner exception ' + this.innerDepth
    }
  }
}
export default ErrorDetails
</script>

<style lang="scss">
.k-error-details {
  margin-top: 1em;
}

pre {
  margin: 0;
}
</style>
