/********************************************************************
 * Signature Creation Service module
 * Version 2017-08-18
 * Copyright 2016-2017 Vaestorekisterikeskus
 *
 * Permission is hereby granted, free of charge, to any person
 * obtaining a copy of this software and associated documentation
 * files (the "Software"), to deal in the Software without
 * restriction, including without limitation the rights to use,
 * copy, modify, merge, publish, distribute, sublicense, and/or
 * sell copies of the Software, and to permit persons to whom the
 * Software is furnished to do so, subject to the following
 * conditions:
 *
 * The above copyright notice and this permission notice shall be
 * included in all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES
 * OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
 * NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 * WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
 * FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
 * OTHER DEALINGS IN THE SOFTWARE.
 *
 *********************************************************************/

type Selector = {
  issuers?: string[]
  akis?: string[]
  keyusages?: string[]
  keyalgorithms?: string[]
}

export interface SignatureRequest {
  version?: string
  selector?: Selector
  content: string
  contentType?: string
  hashAlgorithm?: string
  signatureType?: string
}

export interface SignatureResponse {
  version: string
  status: 'ok' | 'failed'
  reasonCode: number
  reasonText: string
  signature?: string
  signatureType?: string
  hashAlgorithm?: string
  chain?: string[]
}

export interface VersionResponse {
  version: string
  httpMethods: string
  contentTypes: string
  signatureTypes: string
  selectorAvailable: boolean
  hashAlgorithms: string
}

export default class SCS {
  secureUrls = ['https://localhost:53952', 'https://127.0.0.1:53952']
  plainUrls = ['http://localhost:53951', 'http://127.0.0.1:53951']

  urls: string[] = []
  url: string | null = null
  availableUrls: string[] = []
  lastAutodiscoveryCheck?: number
  versionInfo?: VersionResponse
  errorMsg?: string
  initPromise?: Promise<void>

  constructor() {
    console.debug('SCS: initiating automatic service discovery...')
    this.initPromise = this.autodiscovery(0)
  }

  /**
   * Get last time check autodiscovery was executed
   */
  private getHowMuchSinceLastAutodiscovery() {
    const lastAutodiscoveryTime = this.getLastAutodiscoveryTime()
    if (!lastAutodiscoveryTime) return Number.MAX_VALUE
    const now = new Date().getTime()
    return now - lastAutodiscoveryTime
  }

  /**
   * Gets the last autodisovery time.
   */
  private getLastAutodiscoveryTime() {
    try {
      return Number(localStorage.getItem('scs_last_autodiscovery_time'))
    } catch (ex) {}
    return this.lastAutodiscoveryCheck
  }

  /**
   * Sets the last autodiscovery time.
   */
  private setLastAutodiscoveryTime() {
    this.lastAutodiscoveryCheck = new Date().getTime()
    try {
      localStorage.setItem('scs_last_autodiscovery_time', String(this.lastAutodiscoveryCheck))
    } catch (ex) {}
  }

  /**
   * Gets SCS URL from localStorage. Returns null, if not found.
   */
  private getScsUrl() {
    try {
      return localStorage.getItem('scsurl')
    } catch (ex) {}
    return null
  }

  /**
   * Sets SCS URL to localStorage.
   */
  private setScsUrl(scsUrl: string) {
    try {
      if (scsUrl != null) localStorage.setItem('scsurl', scsUrl)
      else localStorage.removeItem('scsurl')
    } catch (ex) {}
  }

  private async pingScsUrl(scsUrl: string): Promise<void> {
    const urlVersion = scsUrl.substring(0, scsUrl.indexOf('/sign')) + '/version'
    console.debug('SCS: ping scs url:', urlVersion)

    const headers = new Headers()
    headers.set('Content-Type', 'application/json; charset=UTF-8')
    return fetch(urlVersion, {
      method: 'GET',
      headers
    })
      .then(response => {
        console.debug('SCS: ping successful')
        return response.json() as Promise<VersionResponse>
      })
      .then(response => {
        this.init(response)
      })
      .catch(error => {
        this.errorMsg = error.message
        console.log('SCS: ping failed', error)
        // document.getElementById('scsNotAvailableBtn').click()
      })
  }

  /**
   * Autodiscovery of the SCS URL on localhost from defined port list.
   */
  private async autodiscovery(urlIndex: number): Promise<void> {
    /* Let's check if SCS URL has been stored. */
    this.url = this.getScsUrl()
    if (this.url != null) {
      console.debug('SCS: found SCS URL from localStorage: ' + this.url)
      return this.pingScsUrl(this.url)
    }

    /*
       We need to check whether the document was retrieved
       over http or https as CORS can typically be only be
       done to over https if the document was retrieved over
       https.
    */
    if (urlIndex === 0) {
      this.setLastAutodiscoveryTime()
      if (window.location.protocol === 'https:') {
        this.urls = this.secureUrls
      } else {
        this.urls = this.plainUrls
      }
    }
    if (urlIndex >= this.urls.length) {
      console.debug('SCS: All discovery urls probed, waiting for service discovery...')
      return Promise.resolve()
    }

    const headers = new Headers()
    headers.set('Content-Type', 'application/json; charset=UTF-8')
    const req = fetch(`${this.urls[urlIndex]}/version`, {
      method: 'GET',
      headers
    })
      .then(response => {
        this.url = `${this.urls[urlIndex]}/sign`
        this.setScsUrl(this.url)
        this.availableUrls.push(this.url)
        console.debug('SCS: found SCS service at', this.url)
        return response.json() as Promise<VersionResponse>
      })
      .then(response => {
        this.init(response)
      })
      .catch(error => {
        this.errorMsg = error.message
        console.log('SCS: service not found', error)
      })
    return Promise.all([req, this.autodiscovery(urlIndex + 1)])
      .then(() => {
        return Promise.resolve()
      })
      .finally(() => {
        this.initPromise = undefined
      })
  }

  /**
   * Initialize the SCS service parameters.
   */
  private init(response: VersionResponse) {
    // store possible version parameters of SCS service, i.e., is
    // selector supported, SCS version, supported HTTP methods, etc.
    console.debug('SCS: init', response)
    this.versionInfo = response
  }

  /**
   * Do SCS signing request based on request parameters.
   */
  private async signLocalhost(request: SignatureRequest) {
    const headers = new Headers()
    headers.set('Content-Type', 'application/json; charset=UTF-8')
    return fetch(this.url!, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }).then(response => {
      console.debug('SCS: sign response', response)
      return response.json() as Promise<SignatureResponse>
    })
  }

  /**
   * Check if SCS service is available.
   */
  isAvailable() {
    return this.url !== null
  }

  async sign(
    content: SignatureRequest | string,
    contentType?: string,
    hashAlgorithm?: string,
    signatureType?: string,
    selector?: Selector
  ): Promise<SignatureResponse> {
    if (!this.isAvailable()) {
      if (this.initPromise) {
        console.debug('SCS: waiting for SCS service discovery...')
        return this.initPromise.then(() => {
          return this.sign(content, contentType, hashAlgorithm, signatureType, selector)
        })
      } else if (this.getHowMuchSinceLastAutodiscovery() > 6000) {
        console.debug('SCS: doing autodiscovery, retrying signature generation in one second...')
        return this.autodiscovery(0).then(() => {
          return this.sign(content, contentType, hashAlgorithm, signatureType, selector)
        })
      } else {
        console.log('SCS: SCS is not available')
        return Promise.reject(new Error('SCS is not available'))
      }
    }

    let request: SignatureRequest
    if (typeof content !== 'string') {
      request = content
    } else {
      request = { content, version: '1.0' }
      if (contentType) request.contentType = contentType
      if (hashAlgorithm) request.hashAlgorithm = hashAlgorithm
      if (signatureType) request.signatureType = signatureType
      if (selector) request.selector = selector
    }

    console.debug('SCS: Doing signature with SCS service...', request)
    return this.signLocalhost(request)
  }

  /**
   * Set SCS port manually (e.g., in the case if port autodiscovery fails).
   */
  setURL(newurl: string) {
    this.setScsUrl(newurl)
    this.url = newurl
  }

  getURL() {
    return this.url
  }

  getAvailableURLs() {
    return this.availableUrls
  }

  getVersionInfo() {
    return this.versionInfo
  }

  getErrorMsg() {
    return this.errorMsg
  }
}
