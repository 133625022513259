<template>
  <SafeboxIndicator v-if="show" :tooltip="tooltip">
    <span>{{ ownerInitials }}</span>
  </SafeboxIndicator>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

import Tuple from '@/classes/Tuple'
import SafeboxIndicator from '@/components/SafeboxIndicator.vue'

@Component({
  components: { SafeboxIndicator }
})
export default class SafeboxOwnerIndicator extends Vue {
  @Prop({ type: Object })
  readonly safebox!: Tuple

  get show() {
    return this.safebox.values.username.v !== this.$store.user?.name
  }

  get tooltip() {
    return this.$t('SafeboxOwnerIndicator.tooltip', { owner: this.safebox.values.owner_name.getDisplayValue() })
  }

  get ownerInitials() {
    if (!(this.safebox instanceof Tuple)) {
      return ''
    }

    return this.safebox.values.owner_name
      .getDisplayValue()
      .split(/\s+/, 2)
      .map(name => name.at(0)?.toUpperCase() ?? '')
      .join('')
  }
}
</script>

<style scoped lang="scss">
span {
  font-weight: 600;
  font-size: 1.75em;
}
</style>
