/**
 * Enum, joka kuvaa missä tilanteissa toiminto on käytettävissä.
 */
export enum ActionContext {
  /** Toiminto ei liity mihinkään tauluun tai monikkoon. Se näytetään valikossa omana tabinaan. */
  DETACHED = 'DETACHED',

  /** Toiminto liittyy tyhjään listaukseen. */
  LISTING_EMPTY = 'LISTING_EMPTY',

  /** Toiminto liittyy yhteen tai useampaan listattuun monikkoon. */
  LISTING_NOT_EMPTY = 'LISTING_NOT_EMPTY',

  /** Toiminto liittyy listaukseen, mutta ei siinä näytettäviin monikoihin. */
  LISTING_ANY = 'LISTING_ANY',

  /** Toiminto liittyy johonkin tiettyyn tarkasteltavana olevan monikon katselunäkymään. */
  TUPLE_VIEW = 'TUPLE_VIEW',

  /** Toiminto liittyy johonkin tiettyyn tarkasteltavana olevan monikon muokkausnäkymään. */
  TUPLE_EDIT = 'TUPLE_EDIT',

  /** Toiminto liittyy hakunäkymään. */
  SEARCH = 'SEARCH'
}
