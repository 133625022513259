import { Cookies } from 'quasar'
import { thumbHashToAverageRGBA, thumbHashToDataURL } from 'thumbhash'
import { reactive } from 'vue'

import Tab from '@/classes/Tab'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import Value from '@/classes/Value'
import backgroundImage1 from '@/images/safebox-bg-1.jpg'
import backgroundImage2 from '@/images/safebox-bg-2.jpg'
import backgroundImage3 from '@/images/safebox-bg-3.jpg'
import { apiFetchJSON, fetchAndDecryptFile, fetchUserKeys, getTabUrl as customGetTabUrl, tupleMutationEventBus, uploadFile } from '@/utils/api-functions'
import { arrayBufferToBase64, base64ToArrayBuffer } from '@/utils/crypt'
import { createSafeboxKey } from '@/utils/crypt-utils'
import Imports from '@/utils/Imports'
import { base64StringToBlob } from '~blob-util'

export async function withSafeboxCookie<T>(id: string | null, fn: () => Promise<T>): Promise<T> {
  const oldValue = getSafeboxCookie()
  setSafeboxCookie(id)
  const result = await fn()
  setSafeboxCookie(oldValue ?? null)
  return result
}

export function setSafeboxCookie(id: string | null): void {
  const cookieName = 'safebox_' + Imports.store.user!.name
  const opts = { path: '/' }

  if (id) {
    Cookies.set(cookieName, id, opts)
  } else {
    Cookies.remove(cookieName, opts)
  }
}

export function getSafeboxCookie(): string | undefined {
  return Cookies.get('safebox_' + Imports.store.user!.name)?.toString()
}

export async function createSafebox(tuple: Tuple, accessList?: string[]): Promise<Tuple> {
  if (!Imports.store.user) {
    throw new Error('Käyttäjä ei ole kirjautunut sisään.')
  }

  if (!Imports.store.user.keyPair) {
    throw new Error('Käyttäjä ei ole avainparia!')
  }

  if (accessList === undefined) {
    accessList = [Imports.store.user.name]

    const parent = Imports.store.user.attributes.parent?.[0]

    if (parent) {
      accessList.push(parent)
    }
  }

  // Luodaan safeboxille salausavain
  const safeboxKey = await createSafeboxKey()

  const safeboxKeyTab = Tab.getTab('safebox_key')!
  const safeboxAccessTab = Tab.getTab('safebox_access')!

  const safeboxAccess = await Promise.all(
    accessList.map(async username => {
      const { keys } = await fetchUserKeys({ username })

      const access = new Tuple(safeboxAccessTab)

      access.setValue('username', username)
      access.setValue('role', 2)

      access.children.safebox_key = await Promise.all(
        keys.map(async ({ id, key }) => {
          const wrappedKey = await crypto.subtle.wrapKey('raw', safeboxKey, key, { name: 'RSA-OAEP' })

          const safeboxKeyTuple = new Tuple(safeboxKeyTab)
          safeboxKeyTuple.setValue('cryptkey_id', parseInt(id, 10))
          safeboxKeyTuple.setValue('crypted_aes_key', arrayBufferToBase64(wrappedKey))
          // safeboxKeyTuple.setValue('username', username);
          return safeboxKeyTuple
        })
      )

      return access
    })
  )

  if (!tuple.children.safebox_access) {
    tuple.children.safebox_access = []
  }

  tuple.children.safebox_access.push(...safeboxAccess)

  // Lisätään uuden safeboxin salausavain tilapäisesti käyttäjän avattujen salausavainten
  // listaan, jotta titeolokeron luonnin yhteydessä se olisi käytettävissä.
  Imports.store.user.safeboxCryptKeys[tuple.getUniqueId()] = safeboxKey

  // Käsitellään tietokortit liitteineen
  const services: Tuple[] = []
  for (const service of tuple.children.service ?? []) {
    const serviceAttachments: Tuple[] = []
    for (const attachment of service.children.service_attachment) {
      const insertedAttachment = await insertFile(attachment)
      serviceAttachments.push(insertedAttachment)
    }
    service.children.service_attachment = serviceAttachments
    services.push(service)
  }

  tuple.children.service = services

  const inserted = await insertSafebox(tuple)

  // Backendin upsert-logiikka riippuu originalKey-propertystä, nollataan se insertiin osumiseksi
  Imports.appStore.safeboxes.push(inserted)

  // Lisätään uuden safeboxin salausavain käyttäjän avattuihin salausavaimiin
  Imports.store.user.safeboxCryptKeys[inserted.getKeyString()] = safeboxKey
  delete Imports.store.user.safeboxCryptKeys[tuple.getUniqueId()]

  // Lisätään luku ja kirjoitusoikeus uuteen safeboxiin
  Imports.store.user.attributes.ar.push(inserted.getKeyString())
  Imports.store.user.attributes.aw.push(inserted.getKeyString())

  return inserted
}

const insertSafebox = async (tuple: Tuple) => {
  const origTuple = tuple.origTuple
  tuple.origTuple = undefined
  const tupleJSON = await tuple.toJSON()
  tuple.origTuple = origTuple

  const response = await apiFetchJSON<TupleJSON>(customGetTabUrl(tuple.tab), {
    method: 'POST',
    body: JSON.stringify(tupleJSON)
  })

  response.tmpKey = tuple.getUniqueId()
  return Tuple.fromJSON(response, tuple.tab, tuple.parent)
}

/**
 * Apufunktio (esim. service_attachment-tuplesta kaivettavan) filen kantaanvientiä varten
 * @param tuple pdf-muotoinen liitetiedosto, joka viedään palvelimelle
 */
export async function insertFile(tuple: Tuple): Promise<Tuple> {
  if (tuple.values.file.v) {
    const filename = tuple.values.filename.valueToString()
    const fileBlob = base64StringToBlob(tuple.values.file.v.toString(), 'application/pdf')
    const file = new File([fileBlob], filename)
    const attr = tuple.tab.attributes.file
    const { path } = await uploadFile(file, attr, tuple)
    tuple.values.file = new Value(attr, filename, filename, null, path)
  }
  return tuple
}

const BACKGROUND_IMAGES = [backgroundImage1, backgroundImage2, backgroundImage3]

const BACKGROUND_IMAGE_THUMBHASHES = ['GwgGBQDEaW+Zpod6iHdodgAAAAAA', 'khgGDQBfixN210k0eqS4miwTf1D1', 'FQgOBwCniIh6eIeAdoeIl4d1BwAAAAAA']

const EMPTY_BACKGROUND: string =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII='

type BackgroundStoreEntry = {
  fetching: boolean
  url?: string
}

const backgroundStore = reactive(new Map<string, BackgroundStoreEntry>())

tupleMutationEventBus.on('update.safebox', tuple => {
  backgroundStore.delete(tuple.getKeyString())
})

const getBackgroundStoreEntry = (safebox: Tuple): BackgroundStoreEntry => {
  const key = safebox.getKeyString()

  if (!backgroundStore.has(key)) {
    backgroundStore.set(key, {
      fetching: false
    })
  }

  return backgroundStore.get(key)!
}

const getDefaultBackgroundImageIndex = (safebox: Tuple) => {
  // Palauttaa näennäissatunnaisen numeron tietolokeron ID:n perusteella.
  const seed = safebox.values.id.v as number
  const x = Math.sin(seed) * 10000
  return Math.floor((x - Math.floor(x)) * 3)
}

const fetchBackgroundImage = async (safebox: Tuple, entry: BackgroundStoreEntry) => {
  let url
  let blob: Blob | undefined

  if (safebox.values.background.v instanceof Blob && safebox.values.background.v.size > 0) {
    blob = safebox.values.background.v
  } else if (safebox.values.background.v) {
    entry.fetching = true
    blob = await fetchAndDecryptFile(safebox, safebox.tab.attributes.background)
    safebox.values.background.v = new File([blob], 'background.webp')
  }

  if (blob) {
    url = URL.createObjectURL(blob)
  } else {
    const index = getDefaultBackgroundImageIndex(safebox)
    url = BACKGROUND_IMAGES[index]
  }

  entry.fetching = false
  entry.url = url
}

export const getBackgroundStyles = (safebox?: Tuple) => ({
  backgroundColor: getBackgroundColor(safebox),
  backgroundImage: `url('${getBackgroundImage(safebox)}')`
})

const getBackgroundThumbhash = (safebox: Tuple): Uint8Array | null => {
  const index = getDefaultBackgroundImageIndex(safebox)
  const thumbhash = (safebox.values.background_thumbhash.v as string) ?? BACKGROUND_IMAGE_THUMBHASHES[index]

  if (!thumbhash) return null

  return new Uint8Array(base64ToArrayBuffer(thumbhash))
}

export const getBackgroundColor = (safebox?: Tuple) => {
  if (!safebox) {
    return 'white'
  }

  const thumbhash = getBackgroundThumbhash(safebox)

  if (!thumbhash) {
    return 'white'
  }

  const { r, g, b } = thumbHashToAverageRGBA(thumbhash)

  return `rgb(${r * 255}, ${g * 255}, ${b * 255})`
}

export const getBackgroundImage = (safebox?: Tuple) => {
  if (!safebox) {
    return EMPTY_BACKGROUND
  }

  if (safebox.values.background.v instanceof Blob && safebox.values.background.v.size > 0) {
    return URL.createObjectURL(safebox.values.background.v)
  }

  const entry = getBackgroundStoreEntry(safebox)

  if (entry.url) {
    return entry.url
  }

  if (!entry.fetching) {
    fetchBackgroundImage(safebox, entry)
  }

  const thumbhash = getBackgroundThumbhash(safebox)

  if (thumbhash) {
    return thumbHashToDataURL(thumbhash)
  }

  return EMPTY_BACKGROUND
}
