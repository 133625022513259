import AccessType from '@/classes/AccessType'
import PredicatePermission, { isPredicatePermissionJSON, PredicatePermissionJSON } from '@/classes/PredicatePermission'
import type Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'

type PermissionJSON = boolean | string[] | { loggedin: boolean } | PredicatePermissionJSON

type Permission = boolean | string[] | { loggedin: boolean } | PredicatePermission

export interface AccessControlJSON {
  permissions: { [key: string]: PermissionJSON }
}

export default abstract class AccessControl {
  protected permissions: { [key: string]: Permission } = {}

  protected constructor(permissions: { [key: string]: PermissionJSON }) {
    for (const key of Object.keys(permissions)) {
      const permissionJSON = permissions[key]
      if (isPredicatePermissionJSON(permissionJSON)) {
        this.permissions[key] = new PredicatePermission(permissionJSON)
      } else {
        this.permissions[key] = permissionJSON
      }
    }
  }

  /** Koostuuko pyynnetyn oikeustyypin tarkastelu predikaattioikeuksista? */
  isPredicatePermission(accessType: AccessType): boolean {
    return this.permissions[accessType] instanceof PredicatePermission
  }

  /** Nullataanko arvo, jos oikeus puuttuu? */
  isResetValueAfterPredicateCheck(accessType: AccessType): boolean {
    return (this.permissions[accessType] as PredicatePermission)?.resetValues ?? false
  }

  /** Palauttaa tiedon onko käyttäjällä lupaa pyydettyyn toimintoon. */
  hasPermission(accessType: AccessType, tuple?: Tuple): boolean {
    const permission = this.permissions[accessType]
    if (!permission) {
      // Permission-tietoa ei löydy -> kielletään
      return false
    }
    if (typeof permission === 'boolean') {
      return permission
    }
    if (permission instanceof PredicatePermission) {
      return permission.predicate.matches(tuple)
    }
    const user = Imports.store.user
    if (!Array.isArray(permission)) {
      // loggedin-permission
      return user !== undefined
    }
    if (!user) {
      return false
    }
    return user.isUserInAnyRole(...permission)
  }
}
