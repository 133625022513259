import { Component, toNative, Vue } from 'vue-facing-decorator'

@Component
export class NavigationMixin extends Vue {
  get isAdminRoute(): boolean {
    return (
      this.$route.path.startsWith('/account/') ||
      this.$route.path.startsWith('/safebox/') ||
      this.$route.path.startsWith('/client/') ||
      this.$route.path.startsWith('/guardian/') ||
      this.$route.path.startsWith('/profile/')
    )
  }
}

export default toNative(NavigationMixin)
